// https://jsapi.aixuetang.com/axt-future/knowledgeLearningPlan/pageKnowledgeLearningPlansByCondition?pageSize=20&pageNo=1
import { axios } from '@/axios/request'
// 获取单位列表
export function pageWebsiteAuthCompanys(parameter) {
  return axios({
    url: '/axt-website/websiteAuthCompany/pageWebsiteAuthCompanys',
    data: parameter,
    method: 'post'
  })
}
// 获取学校列表
export function pageWebsiteAuthSchools(parameter) {
  return axios({
    url: '/axt-website/websiteAuthSchool/pageWebsiteAuthSchools',
    data: parameter,
    method: 'post'
  })
}
// 获取整个单位树
export function getManageUnit(parameter) {
  return axios({
    url: '/axt-website/manage_unit/getManageUnit',
    data: parameter,
    method: 'post'
  })
}
// 保存单位
export function saveCompany(parameter) {
  return axios({
    url: '/axt-usercenter/company/saveCompany',
    data: parameter,
    method: 'post'
  })
}
// 编辑单位
export function updateCompany(parameter) {
  return axios({
    url: '/axt-usercenter/company/updateCompany',
    data: parameter,
    method: 'post'
  })
}
// 根据单位获取学校信息
export function selectSchoolsByCompany(parameter) {
  return axios({
    url: '/axt-website/websiteAuthSchool/selectWebsiteAuthSchoolsByAllot',
    data: parameter,
    method: 'post'
  })
}
// 根据单位分配学校
export function allotSchoolsByCompany(parameter) {
  return axios({
    url: '/axt-usercenter/company/allotSchoolsByCompany',
    params: parameter,
    method: 'post'
  })
}
// 获取班级列表
export function getClassesList(parameter) {
  return axios({
    url: '/axt-crm/classManage/getClassesList',
    data: parameter,
    method: 'post'
  })
}
// 获取年纪信息
export function getGradeList(params) {
  return axios({
    url: '/axt-crm/grade/list',
    data: params,
    method: 'post'
  })
}

// 获取未分配的教师列表
export function getNoTeacherData(params) {
  return axios({
    url: '/axt-crm/classManage/getNoTeacherData',
    data: params,
    method: 'post'
  })
}
// 获取已分配的教师列表
export function getTeacherData(params) {
  return axios({
    url: '/axt-crm/classManage/getTeacherData',
    data: params,
    method: 'post'
  })
}
// 获取未分配的学生列表
export function getNoStudentData(params) {
  return axios({
    url: '/axt-crm/classManage/getNoStudentData',
    data: params,
    method: 'post'
  })
}
// 获取已分配的学生列表
export function getStudentData(params) {
  return axios({
    url: '/axt-crm/classManage/getStudentData',
    data: params,
    method: 'post'
  })
}

// 编辑班级
export function updateClasses(params) {
  return axios({
    url: '/axt-crm/classManage/updateClasses',
    data: params,
    method: 'post'
  })
}
// 编辑班级
export function delClass(params) {
  return axios({
    url: '/axt-crm/classManage/delClass',
    data: params,
    method: 'post'
  })
}

// 分配/移除已分配教师
export function addOrDelClassTeacher(params) {
  return axios({
    url: '/axt-crm/classManage/addOrDelClassTeacher',
    data: params,
    method: 'post'
  })
}
// 分配/移除已分配教师
export function addOrDelClassStudent(params) {
  return axios({
    url: '/axt-crm/classManage/addOrDelClassStudent',
    data: params,
    method: 'post'
  })
}
// 添加班级
export function addClasses(params) {
  return axios({
    url: '/axt-crm/classManage/addClasses',
    data: params,
    method: 'post'
  })
}
