<template>
  <div class="indexblogroll">
    <div class="headline">
      <span v-if="showData.titleShow === 1">{{ showData != null? showData.title:'' }}</span>
      <!-- <span class="more font14">更多&gt;&gt;</span> -->
    </div>
    <div class="blobox">
      <Row>
        <Col span="4" v-for="(item,index) in showData.infos" :key="index">
          <div class="imgbox">
            <a v-if="item.urlPath && isshow" :href="item.urlPath" target="_blank">
              <img v-if="item.picPath" :src="setimg(item.picPath,$futureImgUrl)" alt="">
            </a>
            <a v-else href="javascript:;">
              <img v-if="item.picPath" :src="setimg(item.picPath,$futureImgUrl)" alt="">
            </a>
          </div>
        </Col>

      </Row>
    </div>
  </div>

</template>

<script>
export default {
  name: 'IndexSchool',
  props: {
    showData: {
      type: Object,
      default: () => {}
    },
    isshow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  computed: {
    setimg() {
      return (val, imgpath) => {
        const value = imgpath + val
        return value
      }
    }
  },
  mounted() {

  }
}
</script>

<style lang="less" scoped="scoped">
.indexblogroll {
  padding: 30px 0 0;
  background: #fff;
}
  .headline {
    text-align: left;
    line-height: 1.8;
    font-size: 18px;
    height: 34px;
    border-bottom: 2px solid #3583FF;
    position: relative;
    font-weight: 500;
    padding: 0 10px;
    .more {
      color: #3583FF;
      float: right;
      position: relative;
      top: 2px;
      font-size: 13px;
      cursor: pointer;
    }
  }
  .blobox {
    margin-top: 50px;
    padding: 0 10px;
  }
  .imgbox {
    // height: 90px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 17px;
  }
  .imgbox img {
     width: 100%;
     max-height: 100px;
    // height: 100%;
  }

  @media screen and (min-width: 1920px) {
    #content {
      width: 1662px;
    }

  }
</style>
