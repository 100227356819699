<template>
  <div class="whitebg">
    <div v-if="newsData.titleShow == 1 && newsData.title">
      <div class="title">{{ newsData.title }}</div>
      <div class="titlebtm"></div>
    </div>
    <div class="newsimgbox clearfix">
      <div class="newsList" v-for="(news,index) in newsList" :key="index">
        <div v-if="news.titleShow == 1" class="newstitle">
          <div class="title">{{ news.title }}</div>
          <div class="titlebtm"></div>
          <span class="more font14" v-if="newsData.moreShow === 1">更多<img style="height: 12px;margin-left: 2px;position: relative;top: 1px;" src="/images/index/more.png" alt=""></span>
        </div>
        <div class="box" v-if="dataobj[news.id] && dataobj[news.id].length >0">
          <div class="fl">
            <div class="imgbox pointer">
              <a href="javascript:;">
                <img :src="dataobj[news.id] | resetImg('arr',$futureImgUrl)" alt="">
                <p class="font20">{{ dataobj[news.id]?dataobj[news.id][0].firstTitle :'' }}</p>
                <div class="mark"></div>
              </a>
            </div>
          </div>
          <div class="fr">
            <div class="frtop font24"><img src="/images/index/newsIcon.png" alt="">最新资讯<span class="font16 half">RANKING(24小时）</span></div>
            <div class="newslist">
              <div class="frnewsbox pointer" v-for="(item,i) in fesetData(dataobj[news.id])" :key="i">
                <a href="javascript:;">
                  <div class="frnewsnum">{{ i+1 }}</div>
                  <div class="frnewscon">
                    <div class="frnewscontitle font20">{{ item.firstTitle }}</div>
                    <div class="frnewscontent font14">{{ item.remark }}</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAxtNewSourceList } from '@/api/editIndex.js'
export default {
  name: 'EduNewsThree',
  props: {
    newsData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      newsList: [],
      dataobj: {}
    }
  },
  watch: {
    newsData: {
      handler() {
        this.getAxtNewSourceList()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['indexData']),
    fesetData() {
      return (arr) => {
        return arr && arr.length > 0 ? arr.slice(1, 5) : []
      }
    }
  },
  filters: {
    resetImg(val, type, imgurl) {
      const value = val
      let url
      if (type === 'arr') {
        if (value && value.length > 0) {
          url = value[0].surfaceImg
        }
      } else {
        url = value.surfaceImg
      }
      url = url && url.includes('http') ? url : imgurl + url
      return url
    }
  },
  mounted() {
    this.newsList = this.newsData.news.slice(0, 1)
    this.getAxtNewSourceList()
  },
  methods: {
    getAxtNewSourceList() {
      this.newsList.forEach(v => {
        const params = {
          website_id: this.indexData.websiteId,
          cancel_publish: 0
        }
        if (v.dataType === 1) {
          if (!v.dataNewsIds) {
            this.dataobj[v.id] = []
            return
          }
          params.ids = v.dataNewsIds
        } else if (v.dataType === 2) {
          params.classify_id = v.dataNewsTypeId
        }
        // console.log(params, 'qqqqqqq')
        getAxtNewSourceList(params).then((res) => {
          // console.log(res, 'xinwen4')
          if (res.message === 'success') {
            this.$set(this.dataobj, v.id, res.data.rows.slice(0, 5))
          }
        })
      })
      // const params = {
      //   website_id: this.indexData.websiteId,
      //   cancel_publish: 0,
      //   ids: this.newsList[0].dataNewsIds
      // }
      // getAxtNewSourceList(params).then((res) => {
      //   console.log(res, '新闻3')
      //   if (res.message === 'success') {
      //     this.$set(this.dataobj, this.newsList[0].id, res.data.rows)
      //   }
      // })
    }
  }
}
</script>

<style lang="less" scoped="scoped">
.whitebg {
  padding: 0 10px;
  padding-bottom: 30px;
}
  .titlebtm {
   margin-bottom: 45px;
  }
  .newstitle {
    position: relative;
    .more {
      color: #3583FF;
      position: absolute;
      top: 50px;
      right: 20px;
      cursor: pointer;
    }
  }
  .frtop {
    font-size: 18px;
    text-align: left;
    color: #212121;
    font-weight: 500;
    line-height: 28px;
    .half {
      color: #DCDCDC;
      font-weight: 500;
      font-size: 14px;
    }
    img {
      height: 28px;
      line-height: 28px;
      position: relative;
      top: 6px;
    }
  }
  .frnewsnum {
    float: left;
    width: 20px;
    height: 20px;
    margin-top: 5px;
    line-height: 20px;
    text-align: center;
    background: #EF5344;
    color: #FFFFFF;
  }
  .frnewscon {
    padding-left: 30px;
    margin-top: 18px;
    .frnewscontitle {
      color: #212121;
      margin-bottom: 7px;
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
       -webkit-box-orient: vertical;
    }
    .frnewscontent {
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: #666666;
    }
    &:hover .frnewscontitle {
      color: #3583FF;
    }
  }
  .newsimgbox {
    .box {
      display: flex;
      position: relative;
      padding-top: 40px;
      // overflow: hidden;
      .fl {
        // width: 50%;
        float: none;
      }
      .fr {
        flex: 1;
        // position: absolute;
        // right: 0;
        // top: 0;
        // height: 100%;
        // width: calc(50% - 20px);
        margin-left: 20px;
      }
    }
    .fl .imgbox {
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      p {
        width: 100%;
        padding: 0 5%;
        position: absolute;
        left: 0;
        z-index: 1;
        bottom: 10px;
        color: #fff;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: all .5s;
      }
      img {
        height: 100%;
        max-height: 307px;
        padding: 0;
        margin: 0;
        float: left;
        transition: all .5s;
      }
    }
  }
  .newsimgbox
  .newsimgbox .imgbox .mark {
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }
  .newsimgbox .fl .imgbox p {
    bottom: 30px;
  }
  /* .newsimgbox .imgbox:hover .mark, .frcon:hover .mark  {
    height: 100%;
    background: rgba(0,0,0,0.4);
  } */

  .newslist {
    text-align: left;
  }
  .con {
    background-color: #F5F5F7;
    border-radius: 10px;
    text-align: left;
  }
  .con .contitle {
    float: left;
    width: 76px;
    background: #3583FF;
    border-radius: 10px 0px 0px 10px;
    padding: 24px;
    line-height: 1.4;
    color: #fff;
  }
  .con .conbox {
    padding-left: 100px;
    padding-top: 20px;
  }
  .con .conbox p {
    width: 85%;
    margin-bottom: 20px;
    color: #555555;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .con .conbox p:hover {
    color:#3583FF ;
  }
  .con .conbox p:last-of-type {
    margin-bottom: 0;
  }
  .con .conbox p::before {
    content:"";
    display: block;
    float: left;
    width: 3px;
    height: 3px;
    position: relative;
    top: 10px;
    margin-right: 8px;
    background: #555555;
  }
  .con .more {
    position: absolute;
    bottom: 20px;
    right: 40px;
    color:#999999 ;
  }
  .con .more:hover {
    color:#3583FF ;
  }
   @media screen and (max-width: 1440px) {
     .newsimgbox {
       .fl {
         .imgbox {
           img {
            max-height: 250px;
          }
         }
       }
        .frtop {
          font-size: 14px;
          text-align: left;
          color: #212121;
          font-weight: 500;
          line-height: 14px;
          .half {
            color: #DCDCDC;
            font-weight: 500;
            font-size: 12px;
          }
          img {
            height: 20px;
            line-height: 20px;
            position: relative;
            top: 4px;
          }
        }
        .frnewscon {
            margin-top: 12px;
            .frnewscontitle {
              font-size: 14px;
              margin-bottom: 5px;
            }
            .frnewscontent {
              font-size: 12px;
            }
          }
     }
  }
  @media screen and (min-width: 1920px) {

  }
</style>
