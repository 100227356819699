<template>
  <div class="banner">
    <div v-for="(item, index) in bannerArr" :key="index" class="banner_list">
      <div v-if="item.imgUrl" class="imgbox"><img :src="item.imgUrl" alt=""></div>
      <div v-else class="addbox">
        <uploadcropper :isbanner="true" :config="config" :cropoption="cropoption" @filePath="data => filePath(index, data)"/>
      </div>
      <div class="linkbox">
        <a v-if="item.myLink" href="Javascript:;" class="link" @click="addlink(index, item.myLink)" :title="item.myLink">跳转链接: {{ item.myLink }}</a>
        <a v-else href="Javascript:;" class="link btn_addlink" @click="addlink(index)">添加跳转链接</a>
      </div>
      <a href="javascript:;" class="btn_delete" @click="deleteimg(index)"><img src="/images/myNews/delete_n.png" alt=""></a>
    </div>
    <div class="tip">* 建议尺寸： 1920*500px，JPG、PNG格式。</div>
    <el-button class="btn_addlist" round @click="add" v-if="bannerArr.length < 10">
      <img src="/images/index/btn_add.png" alt="">
      添加更多
    </el-button>

    <!--  -->
    <el-dialog title="添加跳转链接" :visible.sync="linkshow" width="570px" class="linkdialog" :before-close="handleClose">
      <span>
        链接地址：<el-input placeholder="http://" v-model="linkinput" clearable ></el-input>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="mini" round>取 消</el-button>
        <el-button type="primary" size="mini" @click="closelink" round>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import uploadcropper from '@/components/upload/uploadcropper.vue'
import { mapGetters } from 'vuex'
import { getAxtLunboSourceList, addAxtLunboSourceByManager, updateAxtLunboSourceByManager } from '@/api/editIndex.js'
export default {
  name: 'EditBanner',
  data() {
    return {
      bannerArr: [],
      efitlink: 0,
      linkshow: false,
      linkinput: null,
      config: {
        baseImageUrl: '',
        size: 10,
        type: ['image/jpeg', 'image/png'],
        errormsg: '上传图片只能是 JPG 或 PNG 格式!'
      },
      cropoption: {
        autoCropWidth: 1920,
        autoCropHeight: 500,
        fixedNumber: [1920, 500]
      }
    }
  },
  components: { uploadcropper },
  props: {
    bannerData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['indexData', 'manageData'])
  },
  watch: {
    bannerData: {
      handler(bewval) {
        console.log(bewval, 'bewval')
        this.bannerArr = []
        this.getpicId()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    add() {
      const obj = {
        myLink: '',
        imgUrl: ''
      }
      this.bannerArr.push(obj)
      this.emitBanner()
    },
    addlink(index, link) {
      this.linkshow = true
      this.efitlink = index
      if (link) {
        this.linkinput = link
      }
    },
    handleClose() {
      this.linkshow = false
      this.linkinput = null
    },
    closelink() {
      this.linkshow = false
      this.linkinput = this.linkinput.trim()
      const params = {
        id: this.bannerArr[this.efitlink].id,
        // myLink: this.linkinput,
        websiteId: this.indexData.websiteId
      }
      if (this.linkinput.includes('http')) {
        params.myLink = this.linkinput
      } else {
        params.myLink = 'http://' + this.linkinput
      }
      updateAxtLunboSourceByManager(params).then((res) => {
        if (res.message === 'success') {
          if (res.data) {
            this.linkinput = null
            this.emitBanner()
          } else {
            this.$toast('系统错误，请联系管理员！')
          }
        }
      })
      // this.bannerArr[this.efitlink].myLink = this.linkinput
    },
    deleteimg(index) {
      this.bannerArr.splice(index, 1)
      this.emitBanner()
    },
    filePath(index, data) {
      console.log(index, data, 'index, data')
      const params = {
        websiteId: this.indexData.websiteId,
        imgName: data.file.name,
        imgUrl: this.$futureImgUrl + data.imgpath,
        myLink: ''
      }
      addAxtLunboSourceByManager(params).then((res) => {
        console.log(res, 'ygggggggggg')
        if (res.message === 'success') {
          this.bannerArr[index].picId = res.data
          this.emitBanner()
        }
      })
    },
    emitBanner() {
      const arr = []
      this.bannerArr.forEach(v => {
        arr.push({
          picId: v.picId
        })
      })
      // this.bannerArr[index].imgUrl = this.$futureImgUrl + data.imgpath
      this.$emit('changedata', {
        type: 'banner',
        data: arr
      })
    },
    getpicId() {
      const pic = []
      const bannerArr = this.bannerData.banners.filter(v => !v.picId)
      for (var i = 0; i < this.bannerData.banners.length; i++) {
        if (this.bannerData.banners[i].picId) {
          pic.push(this.bannerData.banners[i].picId)
        }
      }
      this.picId = pic.join(',')
      console.log(pic, 'picId')
      if (pic.length > 0) {
        const params = {
          website_id: this.indexData.websiteId,
          ids: this.picId
        }
        getAxtLunboSourceList(params).then((res) => {
          if (res.message === 'success') {
            const arr = res.data.rows
            if (arr.length > 0) {
              arr.forEach(v => {
                v.picId = v.id
              })
            }
            this.bannerArr = arr.concat(bannerArr)
          }
        })
      } else {
        this.bannerArr = bannerArr
      }
    }
  }
}
</script>

<style lang="less" scoped>
.banner{
  text-align: left;
  font-size: 20px;
  .banner_list{
    position: relative;
    overflow: hidden;
    border-radius:  6px;
    .imgbox{
      overflow: hidden;
      border-radius:  6px;
      height: 58px;
      background: #F2F2F2;
      @media screen and (min-width: 1600px) {
        &{
          height: 73px;
        }
      }
    }

    img{
      width: 100%;
      display: block;
    }
    .linkbox {
      margin: 5px 0 10px;
      line-height: 20px;
    }
    .link{
      color: #999;
      font-weight: 500;
      font-size: 14px;
    }
    .btn_addlink{
      color: #5096F5;
    }
    &:hover{
      .btn_delete{
        opacity: 1;
        right: 10px;
      }
    }
    .btn_delete{
      position: absolute;
      top: 10px;
      right: -30px;
      height: 28px;
      opacity: 0;
      width: 28px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      transition: 0.4s all ease;
      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
      }
    }
  }
  .tip{
    font-size: 12px;
    color: #999999;
  }
  .btn_addlist{
    margin: 50px 0 20px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #5096F5;
    padding: 0;
    height: 34px;
    img{
      width: 12px;
      margin-right: 10px;
    }
    border-color: #5096F5;
  }
  .linkdialog{
    .el-input{
      width: 350px;
    }
    span{
      color: #7B7B7B;
    }
  }
}
</style>
