<template>
  <div class="indexbanner">
    <div class="bannerbox" v-if="bannerArr.length > 0">
      <Carousel
        :autoplay="bannerArr.length > 1"
        loop
        :arrow="bannerArr.length > 1?'hover':'never'"
        :autoplay-speed="3000"
        :dots="bannerArr.length > 1?'inside':'none'">
        <CarouselItem v-for="(banner,index) in bannerArr" :key="index">
          <div class="carousel" >
            <a :href="banner.myLink" v-if="banner.myLink != null && isshow" target="_blank"><img :src="banner.imgUrl" alt=""></a>
            <img :src="banner.imgUrl" alt="" v-else>
          </div>
        </CarouselItem>
      </Carousel>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getAxtLunboSourceList } from '@/api/editIndex.js'
export default {
  name: 'IndexBanner',
  props: {
    bannerData: {
      type: Object,
      default: () => {}
    },
    isshow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bannerArr: [
        // {
        //   imgUrl: '/images/index/banner.png',
        //   myLink: null
        // },
        // {
        //   imgUrl: '/images/index/banner.png',
        //   myLink: null
        // }
      ],
      bannerList: [],
      dataobj: {},
      value1: 0,
      picId: ''
    }
  },
  mounted() {
    this.bannerList = this.bannerData.banners
    this.getpicId()
  },
  computed: {
    ...mapGetters(['indexData']),
    fesetData() {
      return (arr) => {
        return arr && arr.length > 0 ? arr : []
      }
    }
  },
  watch: {
    bannerData: {
      handler(newVal) {
        console.log('bannerData', newVal)
        // 清空列表重置
        this.bannerArr = []
        // setTimeout(() => {
        //   this.bannerArr = this.bannerData.banners
        // })
        this.bannerList = [...this.bannerData.banners]
        this.getpicId()
      },
      deep: true
    }
  },
  methods: {
    getpicId() {
      const pic = []
      for (var i = 0; i < this.bannerList.length; i++) {
        if (this.bannerList[i].picId) {
          pic.push(this.bannerList[i].picId)
        }
      }
      this.picId = pic.join(',')
      if (this.picId) {
        const params = {
          website_id: this.indexData.websiteId,
          ids: this.picId
        }
        getAxtLunboSourceList(params).then((res) => {
          if (res.message === 'success') {
            this.bannerArr = res.data.rows
          }
        })
      }
    }
  }
}
</script>
<style>
/* .bannerbox .el-carousel__container {
  height: auto;
} */
</style>
<style lang="less" scoped>
.bannerbox {
    width: 100%;
    height: auto;
    min-height: 100px;

    /deep/.ivu-carousel {
      max-height: 500px;
      overflow: hidden;
    }
     img {
      display: block;
      width: 100%;
    }
    .carousel{
      min-height: 100px;
    }
  }
</style>
