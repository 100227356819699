<template>
  <div id="bigdata">
    <div class="title">{{ bigData != null? bigData.title:'' }}</div>
    <div class="titlebtm"></div>
    <div class="databox clearfix">
      <div class="fl dataimgbox">
        <div id="chartpanel" class="wrapper" ref="chartPanel">
          <img src="/images/bigdata.png" alt="">
        </div>
      </div>
      <div class="fr datacon">
        <div class="con school clearfix">
          <div class="imgbox">
            <img src="/images/index/schoolbg.png" alt="">
          </div>
          <div class="context">
            <div class="contitle font24">学校数量</div>
            <div class="connum font18">{{ schoolNum?schoolNum:0 }}所</div>
          </div>
          <div class="perbox font20">
            <i-circle :size="65" :trail-width="10" :stroke-width="10" :percent="100" :stroke-color="['#FFA6A4','#F07B8C']">
              <span class="demo-circle-inner" style="font-size:16px">100%</span>
            </i-circle>
          </div>
        </div>
        <div class="con teacher clearfix">
          <div class="imgbox">
            <img src="/images/index/teabg.png" alt="">
          </div>
          <div class="context">
            <div class="contitle font24">教师数量</div>
            <div class="connum font18">{{ teacherNum?teacherNum:0 }}人</div>
          </div>
          <div class="perbox font20">
            <i-circle :size="65" :trail-width="10" :stroke-width="10" :percent="100" :stroke-color="['#ADA9FF','#726BE4']">
              <span class="demo-circle-inner" style="font-size:16px">100%</span>
            </i-circle>
          </div>
        </div>
        <div class="con student clearfix">
          <div class="imgbox">
            <img src="/images/index/stubg.png" alt="">
          </div>
          <div class="context">
            <div class="contitle font24">学生数量</div>
            <div class="connum font18">{{ studentNum?studentNum:0 }}人</div>
          </div>
          <div class="perbox font20">
            <i-circle :size="65" :trail-width="10" :stroke-width="10" :percent="100" :stroke-color="['#FFD781','#FEB03E']">
              <span class="demo-circle-inner" style="font-size:16px">100%</span>
            </i-circle>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getHpStyleMapByWebsiteId, getSchoolCountByPCA, getTeacherCountByPCA, getStudentCountByPCA, getDataAnalysisByWebsiteId, getTeacherNumAndActivateNum, getStudentNumAndActivateNum, getSchoolCountByWebsiteId } from '@/api/editIndex.js'
export default {
  name: 'BigData',
  props: {
    bigData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      percent: 0,
      color: '#F07B8C',
      scrollHeight: false,
      addresslist: ['中华人民共和国'],
      currentAddress: null,
      type: 0,
      provinceId: null,
      cityId: null,
      areaId: null,
      schoolNum: 0,
      teacherNum: 0,
      studentNum: 0,
      myChart: null,
      geoJson: {},
      websiteId: null,
      parentInfo: [{ cityName: '全国', code: 100000 }, { cityName: '', code: '' }],
      datanum: null,
      flag: false,
      isFlag: false,
      percent1: 0
    }
  },
  computed: {
    ...mapGetters(['indexData'])
  },
  watch: {

  },
  mounted() {
    this.getHpStyleMapByWebsiteId()

    // eslint-disable-next-line
    // this.myChart = echarts.init(this.$refs.chartPanel)
    // this.init(100000, true)
  },
  methods: {
    getHpStyleMapByWebsiteId() {
      const params = {
        website_id: this.indexData.websiteId
      }
      getHpStyleMapByWebsiteId(params).then((res) => {
        // console.log(res, '555555')
        if (res.message === 'success') {
          this.provinceId = res.data.provinceId
          this.cityId = res.data.cityId
          this.areaId = res.data.areaId
          // 获取数量
          if (this.indexData.websiteId === 13) {
            const params = {
              province_id: this.provinceId,
              city_id: this.cityId,
              area_id: this.areaId
            }
            getSchoolCountByPCA(params).then((res) => {
              // console.log(res, '学校')
              if (res.message === 'success') {
                this.schoolNum = res.data
                this.schoolNum = Math.round(this.schoolNum / 10)
              }
            })
            getTeacherCountByPCA(params).then((res) => {
              // console.log(res, '老师')
              if (res.message === 'success') {
                this.teacherNum = res.data
              }
            })
            getStudentCountByPCA(params).then((res) => {
              // console.log(res, '学生')
              if (res.message === 'success') {
                this.studentNum = res.data
              }
            })
          } else {
            this.getOtherarea()
          }
          // 获取地图
          // this.addresslist.push(res.data.mapNameProvince)
          // this.addresslist.push(res.data.mapNameCity)
          // if (res.data.mapNameProvince === '') {
          //   this.init(100000, true)
          //   return false
          // }
          // if (res.data.mapNameArea) {
          //   const nameArr = res.data.data.mapNameArea.split('-')
          //   this.init(nameArr[0], false)
          //   return false
          // }
          // if (res.data.mapNameCity) {
          //   const nameArr = res.data.data.mapNameCity.split('-')
          //   this.init(nameArr[0], false)
          //   return false
          // }
          // if (res.data.mapNameProvince) {
          //   const nameArr = res.data.data.mapNameProvince.split('-')
          //   this.init(nameArr[0], false)
          // }
        }
      })
    },
    getOtherarea() {
      // 获取其他区域教师学生数量
      const params = {
        websiteId: this.indexData.websiteId
      }
      getDataAnalysisByWebsiteId(params).then((res) => {
        // console.log(res, '区域信息')
        this.datanum = res.data
        const arr = this.datanum.split('&')
        const data = {}
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] && arr[i].includes('=')) {
            const newArr = arr[i].split('=')
            data[newArr[0].slice(0, newArr[0].length - 1) + 'List'] = newArr[1].split(',')
          }
        }
        // console.log(this.datanum, arr, data, 'wwwwwww')
        getTeacherNumAndActivateNum(data).then((res) => {
          if (res.message === 'success') {
            this.teacherNum = res.data.teacherNum
          }
        })
        getStudentNumAndActivateNum(data).then((res) => {
          if (res.message === 'success') {
            this.studentNum = res.data.studentNum
          }
        })
      })
      // 获取其他区域学校数量
      const data = {
        website_id: this.indexData.websiteId
      }
      getSchoolCountByWebsiteId(data).then((res) => {
        if (res.message === 'success') {
          this.schoolNum = res.data
        }
      })
    },
    init(adcode, bool) {
      this.isFlag = bool
      this.getGeoJson(adcode).then(data => {
        this.geoJson = data
        this.getMapData(bool)
      })
    },
    getGeoJson(adcode, childAdcode = '') {
      return new Promise((resolve, reject) => {
        function insideFun(adcode, childAdcode) {
          // eslint-disable-next-line
          AMapUI.loadUI(['geo/DistrictExplorer'], DistrictExplorer => {
            var districtExplorer = new DistrictExplorer()
            districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
              if (error) {
                // console.error(error);
                reject(error)
                return
              }
              let Json = areaNode.getSubFeatures()
              if (Json.length === 0) {
                const parent = areaNode._data.geoData.parent.properties.acroutes
                insideFun(parent[parent.length - 1], adcode)
                return
              }
              if (childAdcode) {
                Json = Json.filter(item => {
                  // eslint-disable-next-line no-return-assign
                  return item.properties.adcode = childAdcode
                })
              }
              const mapJson = {
                features: Json
              }
              resolve(mapJson)
            })
          })
        }
        insideFun(adcode, childAdcode)
      })
    },
    getMapData(bool) {
      const that = this
      let mapData = []
      const pointData = []
      let sum = 0
      that.geoJson.features.forEach(item => {
        const value = Math.random() * 3000
        mapData.push({
          name: item.properties.name,
          value: value,
          cityCode: item.properties.adcode
        })
        pointData.push({
          name: item.properties.name,
          value: [item.properties.center[0], item.properties.center[1], value],
          cityCode: item.properties.adcode
        })
        sum += value
      })
      mapData = mapData.sort(function (a, b) { return b.value - a.value })
      this.initEchartMap(mapData, sum, pointData, bool)
    },
    initEchartMap(mapData, sum, pointData, bool) {
      const that = this
      var xData = []
      var yData = []
      mapData.forEach(c => {
        xData.unshift(c.name.replace(/(省|市|自治区|回族|维吾尔|壮族|特别行政区)/g, ''))
        yData.unshift(c.value)
      })
      // 这里做个切换，全国的时候才显示南海诸岛  只有当注册的名字为china的时候才会显示南海诸岛
      // const length = that.parentInfo.length
      // eslint-disable-next-line no-undef
      echarts.registerMap(that.isFlag ? 'china' : 'map', that.geoJson)
      var option = {
        baseOption: {
          // backgroundColor: '#012248',
          geo: {
            map: that.isFlag ? 'china' : 'map',
            zoom: 2,
            roam: true,
            right: '15%',
            top: '35%',
            label: {
              normal: {
                show: !bool,
                fontSize: '14',
                color: '#666', // 省份标签字体颜色
                formatter: p => {
                  switch (p.name) {
                    case '内蒙古自治区':
                      p.name = '内蒙古'
                      break
                    case '西藏自治区':
                      p.name = '西藏'
                      break
                    case '新疆维吾尔自治区':
                      p.name = '新疆'
                      break
                    case '宁夏回族自治区':
                      p.name = '宁夏'
                      break
                    case '广西壮族自治区':
                      p.name = '广西'
                      break
                    case '香港特别行政区':
                      p.name = '香港'
                      break
                    case '澳门特别行政区':
                      p.name = '澳门'
                      break
                  }
                  return p.name
                }
              },
              emphasis: {
                show: true,
                color: '#3583FF'
              }
            },
            itemStyle: {
              normal: {
                color: '#000',
                areaColor: '#eaf4fc',
                borderColor: '#53D9FF',
                borderWidth: 1.3,
                shadowBlur: 0
              },
              emphasis: {
                areaColor: '#bce2e8',
                borderWidth: 1.6
              }
            }
          }
        }
      }
      this.myChart.setOption(option, true)
    }
  }
}
</script>

<style lang="less" scoped>
#bigdata {
  padding: 0 10px 30px;
  background: #F8F9FB;
}
  .wrapper {
    width : 100%;
    height: 100%;
  }
  .databox {
    margin-top: 30px;
  }
  .dataimgbox {
    width: 50%;
    text-align: right;
    height: 400px;
  }
  .dataimgbox img {
    width: 80%;
  }
  .datacon {
    text-align: left;
    width: 50%;
    padding-left: 100px;
  }
  .con {
    // height: 100px;
    padding: 10px 0;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 18px;
    .imgbox {
      margin-top: 8px;
      height: 60px;
      float: left;
      margin-left: 10px;
      img {
        height: 100%;
      }
    }
    .context {
      float: left;

      margin-left: 10px;
      .contitle{
        margin-top: 15px;
        color: #1F2E3D;
        font-weight: 500;
      }
      .connum{
        color: #555555;
      }
    }
    .perbox {
      position: relative;
      float: right;
      height: 60px;
      text-align: center;
      margin-right: 10px;
      color: #555555;
      font-weight: 500;
    }
  }

@media screen and (min-width: 1560px) {
  .con {
    .imgbox {
      float: left;
      margin-left: 30px;
    }
    .context {
      margin-left: 30px;
    }
    .perbox {
      margin-right: 30px;
    }
  }
}
</style>
