<template>
  <div class="table_box">
    <el-table
      :data="tableData"
      ref="multipleTable"
      height="350"
      v-loading="loading"
      @select="select"
      :header-cell-style="{background:'#f2f2f2'}">
      <el-table-column
        type="selection"
        :selectable="selectable"
        width="55">
      </el-table-column>
      <el-table-column label="" width="80px">
        <template slot-scope="scope">
          <img class="tximg" :src="$futureImgUrl + scope.row.picPath" alt="">
        </template>
      </el-table-column>
      <el-table-column label="教师姓名" width="120px">
        <template slot-scope="scope">
          <span class="txname">{{ scope.row.text1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="text2" label="职位" width="200px"></el-table-column>
      <el-table-column prop="text3" label="简介"></el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: 'TableTeacher',
  data() {
    return {
      multipleSelection: [],
      infosdata: []// 记录所有值
    }
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    infos: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    filterarr: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    tableData: {
      handler(newVal) {
        this.$nextTick(() => {
          newVal.forEach(v => {
            const list = this.infosdata.filter(item => item.id === v.id)
            if (list && list.length > 0) {
              this.$refs.multipleTable.toggleRowSelection(v, true)
            }
          })
        })
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.infosdata = [...this.infos]
  },
  methods: {
    selectable(row) {
      console.log(this.filterarr.includes(row.id), this.filterarr, row.id)
      return !this.filterarr.includes(row.id)
    },
    select(selection, row) {
      const arr = [...this.infosdata]
      let isadd = true
      arr.forEach((v, i) => {
        if (row.id === v.id) {
          arr.splice(i, 1)
          isadd = false
        }
      })
      if (isadd) {
        arr.push(row)
      }
      this.infosdata = [...arr]
    }
  }
}
</script>

<style lang="less" scoped>
  .table_box {
    padding-top: 20px;
    .tximg{
      max-height: 50px;
      max-width: 50px;
      vertical-align: middle;
      margin-right: 40px;
    }
    /deep/ .el-table{
      width: 100%;
      border-radius:6px;
      th{
       background:  rgb(242, 242, 242);
       .el-checkbox{
         display: none;
       }
      }
    }
    /deep/th:nth-child(2){
      padding-left: 85px;
    }
  }

</style>
