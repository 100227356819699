// https://jsapi.aixuetang.com/axt-future/knowledgeLearningPlan/pageKnowledgeLearningPlansByCondition?pageSize=20&pageNo=1
import { axios } from '@/axios/request'
// 获取新闻分类
export function getAxtNewClassifyList(parameter) {
  return axios({
    url: '/axt-mhcz/axtNewSource/getAxtNewClassifyList',
    params: parameter,
    method: 'get'
  })
}
// 获取新闻
export function getAxtNewSourceList(parameter) {
  return axios({
    url: '/axt-mhcz/axtNewSource/getAxtNewSourceList',
    params: parameter,
    method: 'get'
  })
}
// 添加文章
export function addAxtNewSourceByManager(parameter) {
  return axios({
    url: '/xwww/axt-mhcz/axtNewSource/addAxtNewSourceByManager',
    data: parameter,
    method: 'post'
  })
}
// 删除文章
export function deleteAxtNewSourceByManager(parameter) {
  return axios({
    url: '/axt-mhcz/axtNewSource/deleteAxtNewSourceByManager',
    params: parameter,
    method: 'post'
  })
}
// 发布新闻
export function publicAxtNewSourceByManager(parameter) {
  return axios({
    url: '/axt-mhcz/axtNewSource/publicAxtNewSourceByManager',
    params: parameter,
    method: 'post'
  })
}
// 修改新闻
export function updateAxtNewSourceByManager(parameter) {
  return axios({
    url: '/xwww/axt-mhcz/axtNewSource/updateAxtNewSourceByManager',
    data: parameter,
    method: 'post'
  })
}
// 获取学校数量
export function selectSchoolCount(parameter) {
  return axios({
    url: '/axt-crm/school/selectSchoolCount',
    data: parameter,
    method: 'post'
  })
}
// 获取教师数量
export function getTeacherNumAndActivateNum(parameter) {
  return axios({
    url: '/axt-statistics/statistics_teacher/getTeacherNumAndActivateNum',
    data: parameter,
    method: 'post'
  })
}
// 获取学生数量
export function getStudentNumAndActivateNum(parameter) {
  return axios({
    url: '/axt-statistics/statistics_student/getStudentNumAndActivateNum',
    data: parameter,
    method: 'post'
  })
}
// 添加教师 学校
export function updateConfigAddHpStyleBodyPlateInfoShow(parameter) {
  return axios({
    url: '/axt-website/hpconfig/updateConfigAddHpStyleBodyPlateInfoShow',
    data: parameter,
    method: 'post'
  })
}
// 修改教师学校信息

export function updateConfigUpdateHpStyleBodyPlateInfoShow(parameter) {
  return axios({
    url: '/axt-website/hpconfig/updateConfigUpdateHpStyleBodyPlateInfoShow',
    data: parameter,
    method: 'post'
  })
}
// 添加新闻分类
export function addAxtNewuClassifyByManager(parameter) {
  return axios({
    url: '/xwww/axt-mhcz/axtNewClassify/addAxtNewuClassifyByManager',
    data: parameter,
    method: 'post'
  })
}
// 删除新闻分类
export function deleteAxtNewClassifyByManager(parameter) {
  return axios({
    url: '/axt-mhcz/axtNewClassify/deleteAxtNewClassifyByManager',
    params: parameter,
    method: 'get'
  })
}
export function websiteApp(parameter) {
  return axios({
    url: '/axt-website/websiteApp/page',
    data: parameter,
    method: 'post'
  })
}
export function checkWebsiteApp(parameter) {
  return axios({
    url: '/axt-website/websiteApp/checkWebsiteApp',
    data: parameter,
    method: 'post'
  })
}
export function getAppToken(parameter) {
  return axios({
    url: '/axt-website/websiteApp/getAppToken',
    data: parameter,
    method: 'post'
  })
}
