<template>
  <div class="newslist">
    <Row v-if="dataobj.length > 0">
      <Col span="24" class="newsltem pointer" v-for="(list,i) in dataobj" :key="i">
        <p :title="list.firstTitle">
          <a href="javascript:;">{{ list.firstTitle }}</a>
        </p>
      </Col>
    </Row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAxtNewSourceList } from '@/api/editIndex.js'
export default {
  name: 'EduNewsThree',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      newsList: [],
      dataobj: []
    }
  },
  computed: {
    ...mapGetters(['indexData'])
  },
  watch: {
    item: {
      handler() {
        this.getAxtNewSourceList()
      },
      deep: true
    }
  },
  mounted() {
    this.getAxtNewSourceList()
  },
  methods: {
    getAxtNewSourceList() {
      console.log('wwwww', this.item)
      const params = {
        website_id: this.indexData.websiteId,
        cancel_publish: 0
      }
      if (this.item.dataType === 1) {
        if (!this.item.dataNewsIds) {
          this.dataobj = []
          return
        }
        params.ids = this.item.dataNewsIds
      } else if (this.item.dataType === 2) {
        params.classify_id = this.item.dataNewsTypeId
        params.pageSize = this.item.dataSize
      }
      getAxtNewSourceList(params).then((res) => {
        if (res.message === 'success') {
          this.dataobj = res.data.rows.slice(0, this.item.dataSize)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped="scoped">
.white {
    background: #fff;
    padding: 0 10px;
  }
  .newsclassify {
    padding: 20px 0;
  }
  .headline {
    font-size: 18px;
    text-align: left;
    line-height: 1.8;
    padding-bottom: 3px;
    border-bottom: 4px solid #3583FF;
    position: relative;
    .more {
      color: #3583FF;
      float: right;
      position: relative;
      top: 8px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .newslist {
    margin-top: 15px;
  }
  .newsltem {
    width: 100%;
    height: 68px;
    background: #F8F9FB;
    border-radius: 10px;
    display: flex;
    line-height: 1.6;
    padding: 0 22px;
    text-align: left;
    margin-bottom:13px ;
    align-items: center;
    p {
      overflow:hidden;
      text-overflow:ellipsis;
      display:-webkit-box;
      -webkit-line-clamp:2;
      -webkit-box-orient:vertical;
      a {
        color: #555;
        display: block;
      }
    }
    &:hover {
      background-color: #E8F1FF;
      p a {
        color: #3583FF;
      }
    }
  }
</style>
