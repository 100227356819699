<template>
  <div class="table_box">
    <el-table
      :data="tableData"
      ref="multipleTable"
      height="350"
      @select="select"
      :header-cell-style="{background:'#f2f2f2'}">
      <el-table-column
        type="selection"
        :selectable="selectable"
        width="55">
      </el-table-column>
      <el-table-column prop="text1" label="学校名称">

      </el-table-column>
      <el-table-column prop="text2" label="学校地址"></el-table-column>

    </el-table>
  </div>
</template>
<script>
export default {
  name: 'TableSchool',
  data() {
    return {
      multipleSelection: [],
      infosdata: []// 记录所有值
    }
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    infos: {
      type: Array,
      default: () => []
    },
    filterarr: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    tableData: {
      handler(newVal) {
        this.$nextTick(() => {
          newVal.forEach(v => {
            const list = this.infosdata.filter(item => item.id === v.id)
            if (list && list.length > 0) {
              this.$refs.multipleTable.toggleRowSelection(v, true)
            }
          })
        })
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.infosdata = [...this.infos]
  },
  methods: {
    selectable(row) {
      console.log(this.filterarr.includes(row.id), this.filterarr, row.id)
      return !this.filterarr.includes(row.id)
    },
    select(selection, row) {
      const arr = [...this.infosdata]
      let isadd = true
      arr.forEach((v, i) => {
        if (row.id === v.id) {
          arr.splice(i, 1)
          isadd = false
        }
      })
      if (isadd) {
        arr.push(row)
      }
      this.infosdata = [...arr]
    }
  }
}
</script>

<style lang="less" scoped>
  .table_box {
    padding-top: 20px;
    /deep/ .el-table{
      width: 100%;
      border-radius:6px;
      th{
       background: rgb(242, 242, 242);
       .el-checkbox{
         display: none;
       }
      }
    }
  }

</style>
