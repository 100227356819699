import { render, staticRenderFns } from "./eduNewsOne.vue?vue&type=template&id=7bef215e&scoped=true&"
import script from "./eduNewsOne.vue?vue&type=script&lang=js&"
export * from "./eduNewsOne.vue?vue&type=script&lang=js&"
import style0 from "./eduNewsOne.vue?vue&type=style&index=0&id=7bef215e&prod&lang=less&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bef215e",
  null
  
)

export default component.exports