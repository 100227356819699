<template>
  <div class="studentManage">
    <div class="studentManageTop">
      <div class="studentNumver intBox">
        <div class="intName">工号</div>
        <el-input v-model="ondition.jobNum" placeholder="请输入内容" class="numberInt int"></el-input>
      </div>
      <div class="userName intBox">
        <div class="intName">用户名</div>
        <el-input v-model="ondition.loginName" placeholder="请输入内容" class="nameInt int"></el-input>
      </div>
      <!-- <div class="attestation intBox selBox">
        <div class="intName">状态</div>
        <el-select v-model="ondition.status" placeholder="请选择">
          <el-option
            v-for="item in attestations"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div> -->
      <div class="sex intBox selBox">
        <div class="intName">学科</div>
        <el-select v-model="ondition.subjectId" placeholder="请选择">
          <el-option
            v-for="item in subjectList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="sex intBox selBox">
        <div class="intName">性别</div>
        <el-select v-model="ondition.sex" placeholder="请选择">
          <el-option
            v-for="item in sexs"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="studentName intBox">
        <div class="intName">姓名</div>
        <el-input v-model="ondition.name" placeholder="请输入内容" class="NameInt int"></el-input>
      </div>
      <div class="telphone intBox">
        <div class="intName">身份证</div>
        <el-input v-model="ondition.cardNumber" placeholder="请输入内容" class="nameInt int"></el-input>
      </div>
      <div class="gtade intBox selBox">
        <div class="intName">用户类型</div>
        <el-select v-model="ondition.roleType" placeholder="请选择">
          <el-option
            v-for="item in roleType"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="intBox">
        <div class="inquireBtn" @click="inquireClick">查询</div>
      </div>
    </div>
    <!-- 按钮组 -->
    <div class="btnBox" v-if="treeData && treeData.type ==='company' || treeData.type ==='school'">
      <!-- <div class="btnItem" @click="addTeacherClick(true)" v-if="treeData && treeData.type ==='school'">
        <img class="addIcon" src="images/icon/addIcon.png" alt=""> 添加教师
      </div> -->
      <el-button icon="el-icon-plus" round @click="addTeacherClick(true)" v-if="treeData && treeData.type ==='school'" >添加教师</el-button>
      <!-- <div class="btnItem" @click="addTeacherClick(false)" v-if="treeData && treeData.type ==='company'">
        <img class="addIcon" src="images/icon/addIcon.png" alt=""> 添加职工
      </div> -->
      <el-button icon="el-icon-plus" round @click="addTeacherClick(false)" v-if="treeData && treeData.type ==='company'">添加职工</el-button>
      <!-- <div class="btnItem" @click="ipmortFile(treeData,'teacher')" v-if="treeData && treeData.type ==='school'">
        批量导入教师
      </div> -->
      <el-button round @click="ipmortFile(treeData,'teacher')" v-if="treeData && treeData.type ==='school'">批量导入教师</el-button>
      <!-- <div class="btnItem" @click="ipmortFile(treeData,'company')" v-if="treeData && treeData.type ==='company'">
        批量导入职工
      </div> -->
      <el-button round @click="ipmortFile(treeData,'company')" v-if="treeData && treeData.type ==='company'">批量导入职工</el-button>
      <!-- <div class="btnItem" @click="delClick('',1)" v-if="treeData && treeData.type ==='company' || treeData.type ==='school'">
        <img class="delIcon" src="images/icon/delIcon.png" alt=""> 批量删除
      </div> -->
      <el-button icon="el-icon-delete" round @click="delClick('',1)" v-if="treeData && treeData.type ==='company' || treeData.type ==='school'">批量删除</el-button>
      <!-- <div class="btnItem" @click="exportTeacherInfo" v-if="treeData && treeData.type ==='company' || treeData.type ==='school'">
        {{ treeData && treeData.type ==='company'? '教职工导出':'教师导出' }}
      </div> -->
      <el-button round @click="exportTeacherInfo" v-if="treeData && treeData.type ==='company' || treeData.type ==='school'">{{ treeData && treeData.type ==='company'? '教职工导出':'教师导出' }}</el-button>
    </div>
    <!-- 表格 -->
    <div class="tableBox">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="tableData"
        @selection-change="handleSelectionChange"
        border
        style="width: 100%">
        <el-table-column
          type="selection"
          width="40">
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          width="80px"
        >
        </el-table-column>
        <el-table-column
          prop="loginName"
          width="80px"
          label="用户名">
        </el-table-column>
        <el-table-column
          width="50px"
          label="性别">
          <template slot-scope="scope">
            {{ scope.row.sex && scope.row.sex === 1 ? '男': '女' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="jobNum"
          width="120px"
          label="工号">
        </el-table-column>
        <el-table-column
          width="100px"
          label="用户类型">
          <template slot-scope="scope">
            {{ scope.row.roleType && scope.row.roleType === 2 ? '教师': '教职工' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          width="140px"
        >
        </el-table-column>
        <el-table-column
          prop="cardNumber"
          width="180px"
          label="身份证号">
        </el-table-column>
        <el-table-column
          prop="subjectName"
          width="80px"
          label="学科">
        </el-table-column>
        <el-table-column
          prop="gradeName"
          width="80px"
          label="年级">
        </el-table-column>
        <el-table-column
          width="100px"
          label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.createTime ? scope.row.createTime.slice(0,10): '' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="operate"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
            <!-- <el-button @click="allotClass(scope.row)" type="text" size="small">分配班级</el-button> -->
            <el-button @click="openReseat(scope.row)" type="text" size="small">重置密码</el-button>
            <el-button @click="delClick(scope.row,0)" type="text" size="small" class="delTxt">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pageBox">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="10"
        :total="teacherInfoTotal"
        :current-page.sync="currentPage"
        @current-change="changePageStudentTable">
      </el-pagination>
    </div>
    <!-- 添加教师 -->
    <div class="addStudentBox">
      <el-dialog :title="isAddTeacher?'添加教师':'添加职工'" :visible.sync="addStudentVisible" @close="addStudentFalse">
        <el-form :model="addTeacherList" >
          <el-form-item label="姓名" :label-width="formLabelWidth">
            <el-input v-model="addTeacherList.name" autocomplete="off" ></el-input>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="性别" :label-width="formLabelWidth">
            <el-radio v-model="addTeacherList.sex" :label="1">男</el-radio>
            <el-radio v-model="addTeacherList.sex" :label="0">女</el-radio>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="身份证号" :label-width="formLabelWidth">
            <el-input v-model="addTeacherList.cardNumber" autocomplete="off" ></el-input>
            <span class="redstar">*</span>
          </el-form-item>
          <!-- <el-form-item label="工号" :label-width="formLabelWidth" v-show="isReadonly">
            <el-input v-model="addTeacherList.number" autocomplete="off"></el-input>
            <span class="redstar">*</span>
          </el-form-item> -->
          <el-form-item label="手机号" :label-width="formLabelWidth">
            <el-input v-model="addTeacherList.phone" autocomplete="off" ></el-input>
          </el-form-item>
          <el-form-item label="邮箱" :label-width="formLabelWidth">
            <el-input v-model="addTeacherList.mail" autocomplete="off"></el-input>
          </el-form-item>
          <!-- <el-form-item label="学校" :label-width="formLabelWidth" v-show="isReadonly">
            <el-input v-model="addTeacherList.school" autocomplete="off"></el-input>
          </el-form-item> -->
          <el-form-item label="学科" :label-width="formLabelWidth" v-show="isAddTeacher">
            <el-select v-model="addTeacherList.subjectIds" multiple placeholder="请选择">
              <el-option
                v-for="item in subjectList.slice(1,subjectList.length)"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="年级" :label-width="formLabelWidth" v-show="isAddTeacher">
            <el-select v-model="addTeacherList.gradeIds" multiple placeholder="请选择">
              <el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <span class="redstar">*</span>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addStudentFalse()">取消</el-button>
          <el-button type="primary" @click="addTeacherSure()" v-if="isAddTeacher">保存</el-button>
          <el-button type="primary" @click="addStaffSure()" v-else v-preventReClick>保存</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 编辑教师 -->
    <div class="addStudentBox">
      <el-dialog title="编辑教师" :visible.sync="editStudentVisible" :before-close="addStudentFalse">
        <el-form :model="editTeacherList" >
          <el-form-item label="姓名" :label-width="formLabelWidth">
            <el-input v-model="editTeacherList.name" autocomplete="off" ></el-input>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="性别" :label-width="formLabelWidth">
            <el-radio v-model="editTeacherList.sex" :label="1">男</el-radio>
            <el-radio v-model="editTeacherList.sex" :label="0">女</el-radio>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="身份证号" :label-width="formLabelWidth">
            <el-input v-model="editTeacherList.cardNumber" autocomplete="off" class="readonlyTrue" readonly></el-input>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="工号" :label-width="formLabelWidth">
            <el-input v-model="editTeacherList.jobNum" autocomplete="off" class="readonlyTrue" readonly ></el-input>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="手机号" :label-width="formLabelWidth">
            <el-input v-model="editTeacherList.phone" autocomplete="off" ></el-input>
          </el-form-item>
          <el-form-item label="邮箱" :label-width="formLabelWidth">
            <el-input v-model="editTeacherList.mail" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="学校" :label-width="formLabelWidth" v-if="editTeacherList.roleType === 2">
            <el-select v-model="editTeacherList.schoolId" placeholder="请选择">
              <el-option
                v-for="(school,n) in schoolEditList"
                :key="n"
                :label="school.schoolName"
                :value="school.schoolId">
              </el-option>
            </el-select></el-form-item>
          <el-form-item label="学科" :label-width="formLabelWidth" v-if="editTeacherList.roleType === 2">
            <el-select v-model="editTeacherList.subjectIds" multiple placeholder="请选择">
              <el-option
                v-for="(item,index) in subjectEditList"
                :key="index"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="年级" :label-width="formLabelWidth" v-if="editTeacherList.roleType === 2">
            <el-select v-model="editTeacherList.gradeIds" multiple placeholder="请选择">
              <el-option v-for="(list,i) in gradeEditList" :key="i" :label="list.name" :value="list.id"></el-option>
            </el-select>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="单位" :label-width="formLabelWidth" v-if="editTeacherList.roleType === 3">
            <el-select v-model="editTeacherList.companyId" placeholder="请选择">
              <el-option v-for="(company,i) in companyList" :key="i" :label="company.companyName" :value="company.companyId"></el-option>
            </el-select>
            <span class="redstar">*</span>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addStudentFalse()">取消</el-button>
          <el-button type="primary" @click="editTeacherSure()" v-preventReClick>保存</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 重置密码提示 + 删除提示 -->
    <!-- <div class="hintBox">
      <el-dialog
        title="提示"
        :visible.sync="hintVisible"
        width="420px"
        :before-close="handleClose">
        <span>是否要重置密码？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="hintVisible = false">取消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确定</el-button>
        </span>
      </el-dialog>
    </div> -->
    <!--分配学校-->
    <el-dialog
      title="分配班级"
      :visible.sync="isChoseClass"
      width="650px"
      height="500px"
      class="choseschool"
      :before-close="handleClose">
      <div class="choseschoolbox">
        <div class="itembox" v-for="(item,index) in schoolList" :key="index">
          <el-checkbox true-label="1" false-label="0" :checked="item.ckeck === 1 || item.ckeck === -1" :disabled="item.ckeck === -1" @change="choseClass(item)">{{ item.schoolName }}</el-checkbox>
        </div>

      </div>
    </el-dialog>
    <!--导入-->
    <import-file v-if="isImportTeacher" :isImport="isImportTeacher" :importData="importData" :importStyle="importStyle" @closeFile="closeFile"></import-file>
  </div>
</template>
<script>
import ImportFile from '@/components/import/importFile.vue'
import { getTeacherList, getGradeList, saveZxxtTeacherInfo, getSubjectList, deleteTeacher, resetPassWord, batchExportTeacherInfo, getTeacherInfo, getEditGradeList, getEditSubjectList, pageWebsiteAuthCompanys, updateTeacherInfo, selectWebsiteAuthSchools } from '@/api/teacherManage.js'
import { mapGetters } from 'vuex'
export default {
  components: {
    ImportFile
  },
  data() {
    return {
      currentPage: 1,
      ondition: {
        jobNum: '', // 工号
        loginName: '', // 用户名
        status: '', // 是否认证
        subjectId: '', // 学科
        sex: '', // 性别
        name: '', // 姓名
        cardNumber: '', // 身份证
        roleType: '' // 用户类型
      },
      isChoseClass: false, // 分配学校
      schoolList: [],
      teacherInfoTotal: 0, // 分页总数
      isImportTeacher: false, // 导入弹窗
      importStyle: {
        importType: 'teacher', // 导入的类型 teacher 教师 company 教职工 student 学生
        title: ''
      },
      importData: {}, // 导入的参数
      attestations: [{
        value: '',
        label: '全部'
      }, {
        value: '0',
        label: '不可用'
      }, {
        value: '1',
        label: '可用'
      }],
      sexs: [{
        value: '',
        label: '全部'
      }, {
        value: '1',
        label: '男'
      }, {
        value: '0',
        label: '女'
      }],
      roleType: [{ // 职工类型
        value: '',
        label: '全部'
      }, {
        value: '2',
        label: '教师'
      }, {
        value: '3',
        label: '教职工'
      }],
      tableData: [],
      addStudentVisible: false, // 添加学生弹窗
      editStudentVisible: false, // 编辑学生弹窗
      hintVisible: true, // 提示弹窗
      isShowSchoolChose: false, // 是否显示学校显示
      isReadonly: false, // 是否只读  （身份证号，工号）
      isAddTeacher: false, // true:添加教师   false：添加职工
      schoolId: null, // 学校id
      companyId: null, // 企业id
      batchDelTeacherIds: '', // 批量删除数据
      formLabelWidth: '90px',
      addTeacherList: {
        name: '',
        sex: 0,
        cardNumber: '',
        phone: '',
        mail: '',
        subjectIds: [],
        gradeIds: ''
      },
      editTeacherList: {
        name: '',
        sex: '0',
        cardNumber: '',
        phone: '',
        number: '',
        grade: '',
        subject: [],
        mail: '',
        date: '',
        department: '',
        post: ''
      },
      subjectSel: [], // 添加教师 选择学科 （多选）
      gradeList: '', // 年级列表
      subjectList: [],
      gradeEditList: [], // 修改时年级列表
      subjectEditList: [], // 修改时学科列表
      companyList: [], // 单位列表
      schoolEditList: [] // 学校列表
    }
  },
  watch: {
    treeData: {
      handler(newVal) {
        console.log(newVal, 'newVal')
        this.getTeacherList(1)
        if (this.treeData.type && this.treeData.type === 'school') {
          this.schoolId = this.treeData.schoolId
          this.companyId = null
          console.log('schoolId', this.schoolId)
        }
        if (this.treeData.type && this.treeData.type === 'company') {
          this.companyId = this.treeData.companyId
          this.schoolId = null
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['treeData', 'indexData'])
  },
  mounted() {
    this.getTeacherList(1)
    this.getGradeData()
    this.getSubjectList()
    this.pageWebsiteAuthCompanys()
    this.selectWebsiteAuthSchools()
  },
  methods: {
    // 获取年纪列表
    getGradeData() {
      const data = {
        status: 1
      }
      getGradeList(data).then((res) => {
        if (res.message === 'success') {
          this.gradeList = res.data
        }
      })
    },
    // 获取学科列表
    getSubjectList() {
      const data = {
        status: 1,
        orderBys: [
          {
            direction: 'asc',
            field: 'id'
          }
        ]
      }
      getSubjectList(data).then((res) => {
        console.log('subject', res)
        if (res.message === 'success') {
          this.subjectList = res.data
          const all = {
            name: '全部',
            id: ''
          }
          this.subjectList.splice(0, 0, all)
        }
      })
    },
    allotClass(row) {
      this.isChoseClass = true
    },
    handleClose() {
      this.isChoseClass = false
    },
    // 获取单位信息
    pageWebsiteAuthCompanys() {
      const data = {
        websiteId: this.indexData.websiteId,
        pageSize: 1000
      }
      pageWebsiteAuthCompanys(JSON.stringify(data)).then(res => {
        console.log(res, 'uuuuuu')
        if (res.message === 'success') {
          this.companyList = res.data.rows
        }
      })
    },
    // 修改时获取年级列表
    getEditGradeList(id) {
      getEditGradeList({ id: id }).then(res => {
        if (res.message === 'success') {
          this.gradeEditList = res.data
        }
      })
    },
    // 修改时获取年级列表
    getEditSubjectList(id) {
      getEditSubjectList({ id: id }).then(res => {
        if (res.message === 'success') {
          this.subjectEditList = res.data
        }
      })
    },
    selectWebsiteAuthSchools() {
      selectWebsiteAuthSchools({}).then(res => {
        if (res.message === 'success') {
          this.schoolEditList = res.data
        }
      })
    },
    // 获取教师列表
    getTeacherList(pageNo) {
      var params = {
        pageNo: pageNo,
        pageSize: 10,
        jobNum: this.ondition.jobNum || null,
        loginName: this.ondition.loginName || null,
        status: this.ondition.status || null,
        subjectId: this.ondition.subjectId || null,
        sex: this.ondition.sex || null,
        name: this.ondition.name || null,
        cardNumber: this.ondition.cardNumber || null,
        roleType: this.ondition.roleType || null,
        provinceId: this.treeData.provinceId,
        cityId: this.treeData.cityId || null,
        areaId: this.treeData.areaId || null
      }
      if (this.treeData.type === 'company') {
        params.companyId = this.treeData.companyId
      } else if (this.treeData.type === 'school') {
        params.schoolId = this.treeData.schoolId
      }
      getTeacherList(params).then((res) => {
        console.log('resss', res)
        if (res.message === 'success') {
          this.teacherInfoTotal = res.data.total
          this.tableData = res.data.rows
          this.currentPage = res.data.pageNo
        }
      })
    },
    // 打开导入
    ipmortFile(row, type) {
      if (this.schoolId === null && this.companyId === null) {
        this.$toast('请先选择单位或学校再进行操作')
        return false
      }

      this.importStyle = {
        importType: type
      }
      if (type === 'teacher') {
        this.importStyle.title = '导入教师'
      } else if (type === 'company') {
        this.importStyle.title = '导入教职工'
      } else if (type === 'student') {
        this.importStyle.title = '导入学生'
      }
      this.importData = row
      this.isImportTeacher = true
    },
    // 关闭导入
    closeFile(res) {
      this.isImportTeacher = res
      this.getTeacherList(1)
    },
    // 分页
    changePageStudentTable(pageNo) {
      this.getTeacherList(pageNo)
    },
    // 查询
    inquireClick() {
      console.log('typeof', Number(this.ondition.jobNum))
      // if(ondition.jobNum !=='' && ondition.jobNum.typeOf() !== 'number') {

      // }
      if (!Number(this.ondition.jobNum) && this.ondition.jobNum !== '') {
        this.ondition.jobNum = ''
        this.$toast('请输入正确的工号')
        return
      }
      this.getTeacherList(1)
    },
    addTeacherClick(type) {
      this.addTeacherList.sex = 1
      this.addStudentVisible = true
      this.isAddTeacher = type
    },
    // 编辑
    edit(row) {
      console.log('reow', row)
      //   展示学校下拉列表
      const data = {
        id: row.id
      }
      getTeacherInfo(JSON.stringify(data)).then(res => {
        if (res.message === 'success') {
          this.editTeacherList = res.data
          if (this.editTeacherList.subjectIds) {
            this.editTeacherList.subjectIds = this.editTeacherList.subjectIds.split(',').map(Number)
          }
          if (this.editTeacherList.gradeIds) {
            this.editTeacherList.gradeIds = this.editTeacherList.gradeIds.split(',').map(Number)
          }
        }
      })
      this.getEditGradeList(row.id)
      this.getEditSubjectList(row.id)
      //
      this.editStudentVisible = true
    },
    editTeacherSure() {
      // 拼接subjecId
      if (this.editTeacherList.roleType === 2) {
        if (!this.editTeacherList.name) {
          this.$toast('请填写名字！')
          return false
        }
        if (this.editTeacherList.subjectIds.length === 0) {
          this.$toast('请选择学科！')
          return false
        }
        if (this.editTeacherList.gradeIds.length === 0) {
          this.$toast('请选择年级！')
          return false
        }
      }
      const data = {
        name: this.editTeacherList.name,
        sex: this.editTeacherList.sex,
        cardNumber: this.editTeacherList.cardNumber,
        jobNum: this.editTeacherList.jobNum,
        phone: this.editTeacherList.phone || null,
        mail: this.editTeacherList.mail || null,
        provinceId: this.treeData.provinceId,
        cityId: this.treeData.cityId || null,
        areaId: this.treeData.areaId || null,
        roleType: this.editTeacherList.roleType,
        id: this.editTeacherList.id
      }
      if (data.roleType === 2) {
        data.schoolId = this.editTeacherList.schoolId
        data.subjectIds = this.editTeacherList.subjectIds.join(',')
        data.gradeIds = this.editTeacherList.gradeIds.join(',')
      }
      if (data.roleType === 3) {
        data.companyId = this.editTeacherList.companyId
      }
      updateTeacherInfo(JSON.stringify(data)).then((res) => {
        console.log(res, '这是修改')
        if (res.success) {
          this.$toast(res.message)
          this.getTeacherList(1)
          this.addStudentFalse()
        } else {
          this.$toast(res.message)
          console.log(this.editTeacherList, 'wdwdw')
        }
      })
    },
    // 保存教师
    addTeacherSure() {
      if (!this.addTeacherList.name) {
        this.$toast('请添加名字！')
        return false
      }
      if (!this.addTeacherList.cardNumber) {
        this.$toast('请输入18位身份证号码！')
        return false
      }
      // if (this.addTeacherList.cardNumber.length !== 18) {
      //   this.$toast('请输入18位身份证号码！')
      //   return false
      // }
      if (this.addTeacherList.subjectIds.length === 0) {
        this.$toast('请选择学科')
        return false
      }
      if (this.addTeacherList.gradeIds.length === 0) {
        this.$toast('请选择年级')
        return false
      }
      const data = {
        name: this.addTeacherList.name,
        sex: this.addTeacherList.sex,
        cardNumber: this.addTeacherList.cardNumber,
        phone: this.addTeacherList.phone || null,
        mail: this.addTeacherList.mail || null,
        provinceId: this.treeData.provinceId,
        cityId: this.treeData.cityId || null,
        areaId: this.treeData.areaId || null,
        roleType: 2
      }
      if (this.isAddTeacher) {
        data.schoolId = this.schoolId
        data.subjectIds = this.addTeacherList.subjectIds.join(',')
        data.gradeIds = this.addTeacherList.gradeIds.join(',')
      } else {
        data.companyId = this.companyId
      }

      saveZxxtTeacherInfo(data).then((res) => {
        if (res.success) {
          this.addStudentVisible = false
          this.$toast(res.message)
          this.getTeacherList(1)
        } else {
          this.$toast(res.message)
        }
      })
    },
    // 添加职工
    addStaffSure() {
      if (!this.addTeacherList.name) {
        this.$toast('请添加名字！')
        return false
      }
      if (!this.addTeacherList.cardNumber) {
        this.$toast('请输入身份证号码！')
        return false
      }
      const data = {
        name: this.addTeacherList.name,
        sex: this.addTeacherList.sex,
        cardNumber: this.addTeacherList.cardNumber,
        phone: this.addTeacherList.phone || null,
        mail: this.addTeacherList.mail || null,
        provinceId: this.treeData.provinceId,
        cityId: this.treeData.cityId || null,
        areaId: this.treeData.areaId || null,
        roleType: 3
      }
      if (this.schoolId === null) {
        data.companyId = this.companyId
      } else {
        data.schoolId = this.schoolId
      }
      saveZxxtTeacherInfo(data).then((res) => {
        if (res.success) {
          this.addStudentVisible = false
          this.$toast(res.message)
          this.getTeacherList(1)
        } else {
          this.$toast(res.message)
        }
      })
    },
    addStudentFalse() {
      if (this.editStudentVisible) {
        this.editStudentVisible = false
        this.editTeacherList = {}
      }
      this.addTeacherList = { sex: 1 }
      this.addStudentVisible = false
      this.isShowSchoolChose = false
    },
    // 删除
    delClick(row, type) {
      if (type === 1) {
        if (this.batchDelTeacherIds === '') {
          this.$toast('请选择要批量删除的数据')
          return
        }
      }
      var data = {}
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        roundButton: true
      }).then(() => {
        if (type === 0) {
          data.ids = row.id
        } else {
          data.ids = this.batchDelTeacherIds
        }
        deleteTeacher(data).then((res) => {
          this.$toast(res.message)
          this.getTeacherList(1)
        })
      }).catch(() => {
      })
    },
    // 选择数据
    handleSelectionChange(val) {
      this.batchDelTeacherIds = ''
      for (let i = 0; i < val.length; i++) {
        if (i === 0) {
          this.batchDelTeacherIds += val[i].id
        } else {
          this.batchDelTeacherIds += ',' + val[i].id
        }
      }
    },
    // 重置密码
    openReseat(obj) {
      this.$confirm('是否要重置密码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        roundButton: true
      }).then(() => {
        this.resetPassword(obj)
      }).catch(() => {

      })
    },
    resetPassword(obj) {
      const data = { id: obj.id }
      resetPassWord(data).then((res) => {
        if (res.success) {
          this.showNewPassword(res.message)
        }
      })
    },
    // 展示新密码
    showNewPassword(res) {
      const html = '<span class="passwordHtml" style="display:inline;margin-right:10px">' + res + '</span>'
      // const html = '<span class="passwordHtml" style="display:inline;margin-right:10px">' + res + '</span><span><img @click="copyPassword" style="width:13px;height:13px;" class="copyImg hover" src="' + this.copyImgSrc + '"></span>'
      this.$alert(html, '提示', {
        dangerouslyUseHTMLString: true,
        roundButton: true,
        type: 'success'
      })
    },
    // 教职工导出
    exportTeacherInfo() {
      if (this.schoolId === null && this.companyId === null) {
        this.$toast('请先选择一所学校或企业再进行操作')
        return false
      }
      var text = ''
      if (this.batchDelTeacherIds === '') {
        text = '确定要导出本学校所有教职工或教师信息么?'
      } else {
        text = '确定要导出所选教职工或教师的信息么?'
      }
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        roundButton: true
      }).then(() => {
        const data = {}
        if (this.schoolId === null) {
          data.companyId = this.companyId
        } else {
          data.schoolId = this.schoolId
        }
        if (this.batchDelTeacherIds === '') { // 未选择
          data.jobNum = this.ondition.jobNum || null
          data.loginName = this.ondition.loginName || null
          data.subjectId = this.ondition.subjectId || null
          data.sex = this.ondition.sex || null
          data.name = this.ondition.name || null
          data.cardNumber = this.ondition.cardNumber || null
          data.roleType = this.ondition.roleType || null
          data.provinceId = this.treeData.provinceId
          data.cityId = this.treeData.cityId || null
          data.areaId = this.treeData.areaId || null
        } else {
          data.ids = this.batchDelTeacherIds
        }
        batchExportTeacherInfo(data, '教职工列表')
      }).catch(() => {

      })
    }
  }
}
</script>
<style lang="less" scoped>
.studentManage {
    width: 100%;
    height: 100%;
    .studentManageTop {
        width: 100%;
        overflow: hidden;
        .intBox {
            float: left;
            margin-right: 32px;
            margin-bottom: 15px;
            // display: flex;
            // flex-direction: row;
            .intName {
                min-width: 30px;
                display: inline;
                font-size: 14px;
                color: #666666;
                padding-right: 10px;
            }
            .int {
                display: inline;
            }
            /deep/.el-input__inner {
                width: 224px;
                height: 34px;
                border-radius: 4px;
                // border: 1px solid #CCCCCC;
                font-size: 14px;
                color: #666666;
            }
            .inquireBtn {
                width: 56px;
                height: 34px;
                background: #5096F5;
                border-radius: 4px;
                font-size: 14px;
                color: #ffffff;
                text-align: center;
                line-height: 34px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .selBox {
            /deep/.el-input__inner {
                width: 123px;
                height: 34px;
                border-radius: 4px;
                font-size: 14px;
                color: #666666;
            }
            /deep/.el-input__icon {
                line-height: 34px;
            }
        }
    }
    .btnBox {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        /deep/.el-button {
          margin-right: 15px;
          padding:9px 23px;
          &.is-round {
            border: 1px solid #5096F5;
            color: #5096F5;
          }
        }
        .btnItem {
            width: 107px;
            height: 30px;
            border-radius: 17px;
            border: 1px solid #5096F5;
            line-height: 30px;
            color: #5096F5;
            font-size: 14px;
            margin-right: 22px;
            .addIcon {
                width: 12px;
                height: 12px;
            }
            .delIcon {
                width: 13px;
                height: 13px;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
    .tableBox {
        margin-top: 20px;
        max-height: calc(100% - 240px);
        overflow-y: auto;
        /deep/.el-table--enable-row-transition .el-table__body td{
            text-align: center;
            font-size: 14px;
            color: #666666;
            font-weight: 500;
        }
        /deep/.el-table th, .el-table tr{
            background: #F2F2F2;
            // border-radius: 4px 4px 0px 0px;
            text-align: center;
            font-size: 14px;
            color: #666666;
            font-weight: 500;
        }
        .delTxt {
            color: #F56C6C;
        }
    }
    .pageBox {
        margin-top: 20px;
        /deep/.el-pagination{
            text-align: right;
        }
    }
    // 添加学生弹窗
    .addStudentBox {
        /deep/.el-dialog {
            width: 650px;
            // height: 518px;
            .el-dialog__header {
                padding: 20px 40px 10px;
                text-align: left;
                font-weight: 600;
                color: #31364D;
                font-size: 16px;
                border-bottom: 1px solid #F2F2F2;
            }
            .el-dialog__body {
                padding: 20px;
                .redstar {
                    color: red;
                    font-size: 14px;
                    float: right;
                }
            }
            .el-form-item {
                margin-bottom: 15px;
                text-align: left;
                .el-form-item__label {
                    text-align: left;
                    width: 90px;
                    color: #333333;
                    padding-left: 20px;
                    line-height: 34px;
                }
                .el-input__icon {
                    line-height: 34px;
                }
                .el-form-item__content {
                    width: 340px;
                    line-height: 34px;
                    .el-input {
                        width: 327px;
                        .el-input__inner {
                            height: 34px;
                            line-height: 34px;
                        }
                    }
                    .readonlyTrue {
                        .el-input__inner {
                            background: #F2F2F2!important;
                            color: #999999;
                            border: none;
                        }
                    }
                }
            }
            .el-dialog__footer {
                text-align: center;
                .el-button {
                    width: 60px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    padding: 0;
                    border-radius: 15px;
                }
            }
        }
    }
}
</style>
