<template>
  <div class="articlebox">
    <div class="classifyManage_cont" ref="myHeight">
      <div class="seachbox clearfix">
        <div class="inputbox">
          <el-input
            placeholder="搜索文章名称"
            prefix-icon="el-icon-search"
            v-model="input2"
            @change="searchlist">
          </el-input>
        </div>
        <div class="selectbox">
          <el-select v-model="value" placeholder="全部分类" @change="choseNewsType">
            <el-option
              label="全部分类"
              :value="0">
            </el-option>
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="tablecontent">
        <el-table :data="tableData" v-loading="loading" style="width: 100%" :header-cell-style="{background:'#f2f2f2'}" @cell-click="viewArticle">
          <el-table-column prop="firstTitle" show-overflow-tooltip min-width="300" label="文章名称"></el-table-column>
          <el-table-column prop="userName" min-width="100" label="文章作者" ></el-table-column>
          <el-table-column prop="classifyName" min-width="100" label="文章分类" ></el-table-column>
          <el-table-column prop="publishTime" min-width="150" label="发布时间" ></el-table-column>
          <el-table-column fixed="right" label="操作" width="200" >
            <!-- <template slot-scope="scope">
              <img class="editImg hover" :src="scope.row.isPressS || scope.row.isShow?showImg_P:showImg_N" @click="showClick(scope.row)">
              <img class="editImg hover" :src="scope.row.isPressD?delImg_P:delImg_N" @click="deleteClick(scope.row)" @mousemove="deleteHover(scope.row)" @mouseout="deleteOut(scope.row)">
            </template> -->
            <template slot-scope="scope">
              <el-button @click.stop="handleClick(scope.row)" type="text" :disabled="scope.row.websiteId=== 0 || scope.row.websiteId !== indexData.websiteId" size="small">编辑</el-button>
              <el-button class="danger" @click.stop="handleDelete(scope.$index, scope.row)" type="text" :disabled="scope.row.websiteId=== 0 || scope.row.websiteId !== indexData.websiteId" size="small">删除</el-button>
              <el-button @click.stop="handlePublish(scope.row)" type="text" :disabled="scope.row.websiteId=== 0 || scope.row.websiteId !== indexData.websiteId" size="small" v-if="scope.row.cancelPublish === 1">发布</el-button>
              <el-button @click.stop="handlePublish(scope.row)" type="text" :disabled="scope.row.websiteId=== 0 || scope.row.websiteId !== indexData.websiteId" size="small" v-if="scope.row.cancelPublish === 0">取发</el-button>
            </template>
          </el-table-column>
        </el-table>

      </div>
      <div class="add_btn">
        <el-button icon="el-icon-plus" round @click="open">添加文章</el-button>
        <div class="page" v-if="total >10">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="10"
            :current-page.sync="currentPage"
            @current-change="changePage">
          </el-pagination>
        </div>
      </div>

      <add-article v-if="showMessage" :dialogVisible="showMessage" :newscontent="newscontent" @submit="submit"></add-article>
      <article-view v-if="isView" :isView="isView" :newsViewCon="newsViewCon" @closeview="closeview"></article-view>
    </div>
  </div>

</template>
<script>
import { mapGetters } from 'vuex'
import { getAxtNewSourceList, deleteAxtNewSourceByManager, publicAxtNewSourceByManager } from '@/api/list.js'
import addArticle from '../messageBox/addArticle.vue'
import articleView from '../messageBox/articleView.vue'
export default {
  components: { addArticle, articleView },
  name: 'ClassifyManage',
  data() {
    return {
      total: 0,
      input2: '',
      tableData: [],
      showImg_N: '/images/myNews/hide.png',
      showImg_P: '/images/myNews/show.png',
      delImg_N: '/images/myNews/delete_n.png',
      delImg_P: '/images/myNews/delete_p.png',
      showMessage: false,
      isView: false,
      options: [],
      value: 0,
      newsTypeName: null,
      firstTitle: null,
      newscontent: {},
      newsViewCon: {},
      currentPage: 1,
      loading: true
    }
  },
  created() {

  },
  computed: {
    ...mapGetters(['indexData', 'newstype'])
  },
  mounted() {
    this.options = this.newstype
    this.getNewsList(1)
  },
  methods: {
    // 翻页
    changePage(page) {
      this.page = page
      this.getNewsList(page, this.newsTypeName, this.firstTitle)
    },
    // 模糊查询
    searchlist() {
      // console.log(this.input2)
      this.firstTitle = this.input2
      this.getNewsList(this.page, this.newsTypeName, this.input2)
    },
    // 选择新闻类型
    choseNewsType(id) {
      if (id === 0) {
        id = null
      }
      this.newsTypeName = id
      this.getNewsList(1, this.newsTypeName, this.firstTitle)
    },
    // 获取新闻列表
    getNewsList(page, type, firstTitle) {
      const data = {
        website_id: this.indexData.websiteId,
        classify_id: type,
        title: firstTitle,
        pageSize: 10,
        pageNo: page
      }
      getAxtNewSourceList(data).then(res => {
        // console.log(res, 'myinfo')
        if (res.message === 'success') {
          this.tableData = res.data.rows
          this.total = res.data.total
          this.currentPage = res.data.pageNo
          this.loading = false
        }
      })
    },
    // 表格操作
    handleClick(res) {
      // this.newscontent = {
      //   firstTitle: res.firstTitle,
      //   classifyName: res.classifyName,
      //   classifyId: res.classifyId,
      //   newContent: res.newContent,
      //   id: res.id,
      //   title: '编辑文章',
      //   changeType: 0,
      //   surfaceImg: res.surfaceImg
      // }
      this.newscontent = res
      this.newscontent.title = '编辑文章'
      this.newscontent.changeType = 0
      this.showMessage = true
    },
    handleDelete(index, row) {
      // console.log(index, row)
      this.$confirm('此操作将永久删除该文章, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          website_id: this.indexData.websiteId,
          news_id: row.id
        }
        deleteAxtNewSourceByManager(data).then((res) => {
          if (res.message === 'success') {
            this.tableData.splice(index, 1)
            this.$message({
              type: 'success',
              message: '删除成功!',
              center: true
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
          center: true
        })
      })
    },
    handlePublish(row) {
      // console.log(index, row)
      if (row.cancelPublish === 0) {
        row.cancelPublish = 1
      } else {
        row.cancelPublish = 0
      }
      const data = {
        website_id: this.indexData.websiteId,
        news_id: row.id,
        status: row.cancelPublish
      }
      this.loading = true
      publicAxtNewSourceByManager(data).then((res) => {
        console.log(res, 'fabu')
        if (res.message === 'success') {
          this.loading = false
          this.$message({
            type: 'success',
            message: '操作成功!',
            center: true
          })
        }
      })
    },
    // 预览文章
    viewArticle(row, column, cell, event) {
      console.log(row, column, cell, event)
      this.newsViewCon = {
        myLink: row.myLink,
        newContent: row.newContent,
        firstTitle: row.firstTitle,
        classifyName: row.classifyName,
        classifyId: row.classifyId,
        id: row.id
      }
      if (column.property === 'firstTitle') {
        this.isView = true
      }
    },
    // 添加文章
    open() {
      this.newscontent = {
        firstTitle: null,
        classifyName: '全部分类',
        classifyId: 0,
        newContent: null,
        changeType: 1
      }
      this.showMessage = true
    },
    // 文章弹窗关闭操作
    submit(res) {
      if (res.changeState) {
        this.getNewsList(1, this.newsTypeName, this.firstTitle)
      }
      this.showMessage = false
    },
    closeview(res) {
      this.isView = false
    }
  }
}
</script>
<style lang="less" scoped>
.classifyManage_cont{
    padding: 0 30px;
    position: relative;
    height: 100%;
    padding-top: 30px;
    overflow: hidden;
}
.tablecontent {
  position: relative;
  max-height: calc(100% - 150px);
  overflow-y: auto;
  .danger {
    color: #f56c6c;
  }
  .danger[disabled]{
    color: #C0C4CC;
  }
}
.page {
  position: absolute;
  top: 5px;
  right: 30px;
}
.articlebox {
  height: 100%;
}
.editImg{
    width: 18px;
    height: 12px;
    margin-right: 15px;
}
.add_btn{
    width: 100%;
    margin-top: 20px;
    text-align: left;
    position: relative;
    .el-button.is-round{
      border: 1px solid #5096F5;
      color: #5096F5;
  }
}

.seachbox {
  padding-bottom: 18px;
}
.inputbox, .selectbox {
  float: left;
}
.inputbox {
  padding-right: 10px;
}

</style>
<style lang="less">
.inputbox .el-input__icon{
  line-height: 40px;
}
 .selectbox .el-input__icon {
  line-height: 35px;
}
.articlebox {
  .el-table{
    border-radius: 10px 10px 0 0;
  }
  .el-table tr {
    cursor: pointer;
  }
  .el-table .cell {
    white-space: nowrap;
  }
  .el-table table {
    text-align: left;
  }
  .el-table__fixed-right::before, .el-table__fixed::before {
    height: 0;
  }
}
.selectbox {
  .is-focus input {
    color: #5096F5;
  }
  .el-icon-arrow-up:before {
    content: "";
  }
  .is-focus .el-icon-arrow-up:before {
    content: "";
    color: #5096F5;
  }
}
</style>
