<template>
  <div class="datamanager">
    <div class="viewAll" v-show="datamanagerType === '62830BAC'">
      <h3 class="title">教育平台数据概览</h3>
      <div class="showallbox">
        <div class="showall">
          <div class="showlist">
            <div class="item">
              <div class="itemtitle"><img src="/images/icon/schoolicon.png" alt="">平台学校</div>
              <div class="itemteacher school">
                <div class="itemname">学校</div>
                <div class="itemnum">{{ schoolNum }} <span class="half">所</span></div>
              </div>
            </div>
            <div class="item">
              <div class="itemtitle"><img src="/images/icon/usericon.png" alt="">用户数</div>
              <div class="itemteacher">
                <div class="itemname">教职工</div>
                <div class="itemnum">{{ teacherNum }} <span class="half">人</span></div>
              </div>
              <div class="itemteacher">
                <div class="itemname">学生</div>
                <div class="itemnum">{{ studentNum }} <span class="half">人</span></div>
              </div>
            </div>
            <div class="item">
              <div class="itemtitle"><img src="/images/icon/jihuoicon.png" alt="">激活用户</div>
              <div class="itemteacher">
                <div class="itemname">教职工</div>
                <div class="itemnum">{{ teacherActive }} <span class="half">人</span></div>
              </div>
              <div class="itemteacher">
                <div class="itemname">学生</div>
                <div class="itemnum">{{ studentActive }} <span class="half">人</span></div>
              </div>
            </div>
            <div class="item">
              <div class="itemtitle"><img src="/images/icon/jihuolvicon.png" alt="">激活率</div>
              <div class="itemteacher">
                <div class="itemname">教职工</div>
                <div class="itemnum">{{ teacherPerNum }} <span class="half">%</span></div>
              </div>
              <div class="itemteacher">
                <div class="itemname">学生</div>
                <div class="itemnum">{{ studentPerNum }} <span class="half">%</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <iframe
      v-show="datamanagerType !== '62830BAC'"
      :src="iframeUrl"
      :class="{fullscreen:isfull}"
      width="100%"
      height="100%"
      frameborder="0"></iframe>
    <el-button v-if="datatype === 'DBBA8CFB' || datatype === 'C215E714'" class="btn_fd" type="text" @click="isfull = !isfull">
      <img src="/images/baseinfo/sx.png" alt="" v-if="isfull">
      <img src="/images/baseinfo/fd.png" alt="" v-else>
    </el-button>
  </div>
</template>
<script>
import { getData } from '@/api/local.js'
import { getTeacherNumAndActivateNum, getStudentNumAndActivateNum, selectSchoolCount } from '@/api/list.js'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'AllData',
  data() {
    return {
      txt: '',
      show: true,
      iframeUrl: '',
      datamanagerType: '',
      schoolNum: 0,
      teacherNum: 0,
      studentNum: 0,
      teacherActive: 0,
      studentActive: 0,
      teacherPerNum: 0,
      studentPerNum: 0,
      isfull: false
    }
  },
  computed: {
    ...mapGetters(['datastr', 'datatype', 'indexData', 'manageData'])
  },
  watch: {
    datatype: {
      handler(newVal) {
        console.log(newVal, 'jjjjjjjj')
        this.iframeUrl = this.datastr
        this.datamanagerType = this.datatype || '62830BAC'
      },
      deep: true,
      immediate: true
    }

  },
  mounted() {
    this.getDataAnalysisAuthByUser()
  },
  methods: {
    ...mapMutations(['SET_DATASTE', 'SET_DATAANALYSIS']),
    close() {
      console.log('close')
      this.show = false
    },
    // 获取数据管理的参数
    getDataAnalysisAuthByUser() {
      const params = {
        websiteId: this.indexData.websiteId,
        userId: this.manageData.userId
      }
      getData(params).then((res) => {
        if (res.message === 'success') {
          this.SET_DATAANALYSIS(res.data)
          this.getNum(res.data)
        }
      })
    },
    getNum(list) {
      const arr = list.split('&')
      const data = {}
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] && arr[i].includes('=')) {
          const newArr = arr[i].split('=')
          data[newArr[0].slice(0, newArr[0].length - 1) + 'List'] = newArr[1].split(',')
        }
      }
      selectSchoolCount(data).then((res) => {
        // console.log(res, 'schoolnum')
        if (res.message === 'success') {
          this.schoolNum = res.data
        }
      })
      getTeacherNumAndActivateNum(data).then((res) => {
        console.log(res, 'teacherlist')
        if (res.message === 'success') {
          this.teacherNum = res.data.teacherNum
          this.teacherActive = res.data.teacherActivateNum

          if (res.data.teacherNum > 0) {
            this.teacherPerNum = res.data.teacherActivateNum * 100 / res.data.teacherNum
            this.teacherPerNum = this.teacherPerNum.toFixed(2)
          } else {
            this.teacherActive = 0
          }
        }
      })
      getStudentNumAndActivateNum(data).then((res) => {
        // console.log(res, 'student;ist')
        if (res.message === 'success') {
          this.studentNum = res.data.studentNum
          this.studentActive = res.data.studentActivateNum
          if (res.data.studentNum > 0) {
            this.studentPerNum = res.data.studentActivateNum * 100 / res.data.studentNum
            this.studentPerNum = this.studentPerNum.toFixed(2)
          } else {
            this.studentPerNum = 0
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .viewAll {
    margin-top:45px;
    padding: 0 40px;
  }
  .datamanager {
    width: calc(100% - 0px);
    height: calc(100% - 0px);
    position: absolute;
    // left: 20px;
    // top: 20px;
    .btn_fd{
      position: fixed;
      top: 10px;
      right: 10px;
      z-index: 999;
      width: 30px;
      height: 30px;
      padding: 0;
      img{
        width: 30px;
        height: 30px;
      }
    }
    iframe{
      transition: all 0.3s ease;
    }
    .fullscreen{
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      z-index: 999;
    }
  }
  .title {
    color: #454545;
    font-size: 18px;
    font-weight: bold;
    float: none;
    text-align: left;
    text-shadow: 0 1px 0 #ffffff;
    padding-bottom: 30px;
    position: relative;
  }
  .showall {
    margin: 16px;
    color: #666;
    .showtitle {
      padding: 0 20px;
      background:#efefef;
      border-bottom: 1px solid #F0F8FF;
      height: 36px;
      line-height: 36px;
      text-align: left;
    }
    .showlist {
      text-align: center;
      .item {
        text-align: center;
        overflow: hidden;
        display: inline-block;
        width: 360px;
        height: 250px;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px 2px #ECECEC;
        border-radius: 10px;
        margin: 0 10px 20px;
        .itemtitle {
          margin: 40px 0;
          font-size: 16px;
          color: #444;
          font-weight: 600;
          line-height: 32px;
          height: 32px;
          img {
            position: relative;
            top: -3px;
            height:32px ;
            margin-right: 12px;
            vertical-align: middle;
          }
        }

        .itemteacher {
          margin: 0 50px 30px;
          display: flex;
          &.school {
            padding-top: 30px;
          }
          .itemname {
            text-align: left;
            flex: 1;
            height: 30px;
            font-size: 16px;
            line-height: 30px;
          }
          .itemnum {
            flex: 1;
            height: 30px;
            font-size: 22px;
            color: #333;
            line-height: 30px;
            text-align: right;
            .half {
              color: #666;
              font-size: 14px;
            }
          }
        }
      }

    }
  }
  // @media screen and (min-width: 1620px) {
  //   .showall {
  //     .showlist {
  //       .item {
  //         width: 400px;
  //         height: 250px;
  //         box-shadow: 0px 2px 10px 2px #ECECEC;
  //         border-radius: 10px;
  //         margin: 0 20px 20px;
  //       }
  //     }
  //   }
  // }
  @media screen and (min-width: 1920px) {
    .viewAll {
      margin-top:65px;
      padding: 0 40px;
    }
    .showall {
      .showlist {
        // width: 80%;

      .item {
        width: 360px;
        height: 250px;
        box-shadow: 0px 2px 10px 2px #ECECEC;
        border-radius: 10px;
        // margin: 0 80px 90px;
        .itemtitle {
          margin: 40px 0;
          font-size: 18px;
          line-height: 32px;
          img {
            height:32px ;
            margin-right: 12px;
          }
        }

        .itemteacher {
          margin: 0 50px 30px;
          display: flex;
          &.school {
            padding-top: 30px;
          }
          .itemname {
            text-align: left;
            flex: 1;
            height: 30px;
            font-size: 18px;
            line-height: 30px;
          }
          .itemnum {
            flex: 1;
            height: 30px;
            font-size: 24px;
            line-height: 30px;
            text-align: right;

          }
        }
      }

    }
    }
  }
</style>
