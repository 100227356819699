<template>
  <div class="editcontent">
    <h5 class="title">{{ title }}</h5>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EditContent',
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="less" scoped>
  .editcontent {
    background: #fff;
    height: calc(100% - 40px);
    margin-top: 40px;
    overflow: auto;
    box-sizing: border-box;
    border-radius: 6px 0 0 6px;
    &::-webkit-scrollbar {
      width:7px;
    }
    /* 滚动槽 */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
      border-radius:10px;
    }
    /* 滚动条滑块 */
    &::-webkit-scrollbar-thumb {
      border-radius:10px;
      background:rgba(0,0,0,0.1);
      -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background:rgba(0,0,0,0.1);
    }
    .title{
      height: 62px;
      border: 1px solid #F2F2F2;
      line-height: 62px;
      padding-left: 20px;
      font-size: 16px;
      font-weight: 600;
      color: #31364D;
      text-align: left;
    }
    .content{
      padding: 20px;
    }
  }

</style>
