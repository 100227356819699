<template>
  <div class="netnav">
    <el-form label-width="100px">
      <el-form-item label="导航栏预览">
        <div class="navlist">
          <div class="imgbox"><img :src="formdata.imgicon" alt=""></div>
          <!-- <el-row :gutter="20" style="padding-left:50px;flex:1" > -->
          <!-- <div class="navDemo" v-for="item in tableData" :key="item.name">{{ item.name }}</div> -->
          <Tabs value="" style="width:100%">
            <Tab-pane name="home" label="首页" class="navDemo"></Tab-pane>
            <Tab-pane
              v-for="(item,index) in tableData"
              :name="item.name"
              :key="index"
              :label="item.name"
              class="navDemo"
            ></Tab-pane>
          </Tabs>
          <!-- </el-row> -->
        </div>
      </el-form-item>
      <el-form-item class="editorbox" label="导航栏logo">
        <uploadcropper @filePath="filePath" :config="config" :cropoption="cropoption"></uploadcropper>
      </el-form-item>
      <el-form-item class="editorbox table" label="导航栏设置">
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{background:'#f2f2f2'}"
          :row-class-name="tableRowClassName"
          :cell-class-name="tableCellClassName">
          <el-table-column label="导航名称" min-width="80%">
            <template slot-scope="scope">
              <!-- <div>{{ scope.row.name }}</div> -->
              <div
                v-if="scope.row.index === rowIndex && scope.column.index === columnIndex"
              >

                <el-input v-model="scope.row.name" ref="editInput" @blur="inputBlur(scope)"></el-input>
              </div>
              <div v-else>{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" min-width="20%">
            <template slot-scope="scope">
              <el-button class="danger" type="text" :disabled="scope.row.websiteId=== 0 || scope.row.websiteId !== indexData.websiteId" size="small">
                <img class="editImg hover" :src="editImg_P" @click="editorClick(scope.$index, scope.row,scope.column)">
              </el-button>
              <el-button class="danger" type="text" :disabled="scope.row.websiteId=== 0 || scope.row.websiteId !== indexData.websiteId" size="small">
                <img class="editImg hover" :src="delImg_P" @click="deleteClick(scope.row)">
              </el-button>
              <!-- <img class="editImg hover" :src="scope.row.state === 1?showImg_P:showImg_N" @click="showClick(scope.row)"> -->
              <!-- <img class="editImg hover" :src="scope.row.isPressD?delImg_P:delImg_N" @click="deleteClick(scope.row)" @mousemove="deleteHover(scope.row.name)" @mouseout="deleteOut(scope.row.name)"> -->
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item class="" label="">
        <el-button round icon="el-icon-plus" class="addBtn" @click="addClick()">添加</el-button>
      </el-form-item>
      <el-form-item class="saveBtnBox" label="">
        <el-button type="success" round class="saveBtn" @click="saveClick">保存</el-button>
      </el-form-item>
    </el-form>
    <add-nav
      v-if="isShowAddBox"
      @submit="handleClick"
      :dialogVisible="isShowAddBox"
      :linkInfo="linkList"
      :headerInfo="headerInfo"
      :editorData="editorData"></add-nav>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getConfigHpStyleHeader } from '@/api/local.js'
import { getConfigHpStyleHeaderNavLink, deleteConfigUpdateHpStyleHeaderNav, updateConfigHpStyleHeader } from '@/api/massage.js'
import uploadcropper from '@/components/upload/uploadcropper.vue'
import addNav from '../messageBox/addNav.vue'
export default {
  name: 'Platform',
  data() {
    return {
      formdata: {
        imgicon: 'images/logo.png',
        plattitle: '平台名称'
      },
      config: {
        baseImageUrl: '',
        tip: '* 建议尺寸： 140*40px，JPG、PNG格式，图片小于1M。',
        size: 1,
        type: ['image/jpeg', 'image/png'],
        errormsg: '上传图片只能是 JPG 或 PNG 格式!'
      },
      cropoption: {
        autoCropWidth: 140,
        autoCropHeight: 40,
        fixedNumber: [140, 40]
      },
      netlink: 'http://www.aixueyun.cn/',
      tableData: [],
      editImg_P: '/images/baseinfo/editor.png',
      delImg_P: '/images/baseinfo/detel.png',
      rowIndex: -1, // 行索引
      columnIndex: -1, // 列索引
      spanNum: 12, // Layout布局 分割
      isShowAddBox: false,
      id: null,
      headerInfo: null, // header信息
      editorData: null, // 编辑的item信息
      linkList: null // 內链接数据
    }
  },
  components: {
    uploadcropper, addNav
  },
  computed: {
    ...mapGetters(['indexData'])
  },
  mounted() {
    this.netlink = this.indexData.siteUrl
    this.getConfigHpStyleHeader()
    this.getConfigHpStyleHeaderNavLink()
  },
  methods: {
    filePath(res) {
      this.formdata.imgicon = this.$futureImgUrl + res.imgpath
    },
    getConfigHpStyleHeader(type) {
      const params = {
        website_id: this.indexData.websiteId
      }
      getConfigHpStyleHeader(params).then((res) => {
        if (res.message === 'success') {
          this.tableData = res.data.nav_list
          this.headerInfo = res.data
          if (type === undefined) { // undefined 不更新logo
            if (res.data.logoPath.indexOf('http') > -1) {
              this.formdata.imgicon = res.data.logoPath
            } else {
              this.formdata.imgicon = this.$futureImgUrl + res.data.logoPath
            }
          }
          this.spanNum = Math.floor(24 / res.data.nav_list.length)
        }
      })
    },
    getConfigHpStyleHeaderNavLink() {
      getConfigHpStyleHeaderNavLink().then((res) => {
        if (res.message === 'success') {
          this.linkList = res.data
        }
      })
    },
    // 把每一行的索引加到行数据中
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    // 把每一列的索引加到列数据中
    tableCellClassName({ column, columnIndex }) {
      column.index = columnIndex
    },
    // 编辑
    editorClick(index, row, column) {
      console.log(index, row)
      this.editorData = {
        index: index,
        data: row
      }
      this.isShowAddBox = true
      // this.rowIndex = row.index
      // this.columnIndex = column.index - 1
      // this.$nextTick(() => {
      //   this.$refs.editInput.focus()
      //   console.log(this.$refs.editInput)
      // })
    },
    inputBlur(res) {
      console.log(res)
      this.rowIndex = this.columnIndex = -1
    },
    // 删除
    deleteClick(res) {
      console.log(res)
      const params = {
        id: res.id,
        websiteId: this.indexData.websiteId
      }
      deleteConfigUpdateHpStyleHeaderNav(params).then((res) => {
        console.log(res)
        if (res.data) {
          this.$toast('删除成功')
          this.getConfigHpStyleHeader(1) // 带参数：单独更新导航栏设置 不更新logo
        }
      })
    },
    addClick() {
      this.isShowAddBox = true
      this.editorData = null // null：添加   不为null：编辑
    },
    handleClick(res) {
      this.isShowAddBox = res.close
      if (res.success) {
        this.getConfigHpStyleHeader(1)
        this.editorData = null // null：添加   不为null：编辑
      }
    },
    saveClick() {
      const params = {
        id: this.headerInfo.id,
        websiteId: this.indexData.websiteId,
        styleId: this.headerInfo.styleId,
        logoPath: this.formdata.imgicon
      }
      updateConfigHpStyleHeader(params).then((res) => {
        if (res.data) this.$toast('保存成功')
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .netnav {
    padding: 30px;
    background: #fff;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width:7px;
    }
    /* 滚动槽 */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
      border-radius:10px;
    }
    /* 滚动条滑块 */
    &::-webkit-scrollbar-thumb {
      border-radius:10px;
      background:rgba(0,0,0,0.1);
      -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background:rgba(0,0,0,0.1);
    }
    .el-form-item__label{
      color: #333333;
    }
    .navlist {
      background: #F2F2F2;
      display: flex;
      flex-wrap: nowrap;
      height: 60px;
      border-radius: 5px;
      line-height:60px ;
      /deep/ .ivu-tabs-bar{
        border: none;
        margin: 12px 20px;
        .ivu-tabs-nav-next, .ivu-tabs-nav-prev{
          line-height: 37px;
        }
        .ivu-tabs-tab{
          padding: 8px 0px;
        }
      }
      .imgbox {
        width: 150px;
        img {
          height: 40px;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
      .navDemo{
          font-size: 14px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #999999;
          line-height: 20px;
          float: left;
          line-height:60px ;
          margin: 0 20px;
        }
    }
    .editorbox {

      /deep/.avatar-uploader {
        position: relative;
        text-align: left;
        .el-upload {
          height: 85px;
          float: left;
          img {
            height: 100%;
          }
        }
        .el-upload__tip {
          line-height: 1.4;
          float: left;
          margin: 40px 0 0 20px;
        }
      }
      &.table {
        /deep/.el-table {
          border-radius: 10px 10px 0 0;
          table {
            width: 100%;
            th>.cell{
              // font-size: 16px;
              color: #666666;
            }
            thead{
              background: #F2F2F2;
            }
            th{
              padding: 5px 0;
            }
            tr td:first-of-type,
            tr th:first-of-type{
              padding-left: 30px;
            }
          }
        }
      }
      .editImg{
        width:20px;
        height: 14px;
      }
    }
    .addBtn{
      border: 1px solid #5096F5;
      color: #5096F5;
      float: left;
      width: 107px;
    }
    .saveBtnBox{
      margin-top: 39px;
      .saveBtn{
        width: 194px;
        height: 44px;
        background: #5096F5;
        border-color: #5096F5;
        line-height: 1;
      }
    }
  }

</style>
