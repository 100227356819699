<template>
  <div class="articlebox">
    <div class="classifyManage_cont" ref="myHeight">
      <div class="seachbox clearfix">
        <div class="inputbox">
          <el-input
            placeholder="搜索学校名称"
            prefix-icon="el-icon-search"
            v-model="input2"
            @change="searchlist">
          </el-input>
        </div>
      </div>
      <div class="tablecontent">
        <el-table :data="tableData" v-loading="loading" style="width: 100%" :header-cell-style="{background:'#f2f2f2'}">
          <el-table-column prop="text1" show-overflow-tooltip label="学校名称"></el-table-column>
          <el-table-column prop="text2" show-overflow-tooltip min-width="100" label="学校地址" ></el-table-column>
          <el-table-column fixed="right" label="操作" width="200" >
            <!-- <template slot-scope="scope">
              <img class="editImg hover" :src="scope.row.isPressS || scope.row.isShow?showImg_P:showImg_N" @click="showClick(scope.row)">
              <img class="editImg hover" :src="scope.row.isPressD?delImg_P:delImg_N" @click="deleteClick(scope.row)" @mousemove="deleteHover(scope.row)" @mouseout="deleteOut(scope.row)">
            </template> -->
            <template slot-scope="scope">
              <el-button class="text" @click.stop="handleChange(scope.$index, scope.row)" type="text" :disabled="scope.row.websiteId=== 0 || scope.row.websiteId !== indexData.websiteId" size="small">修改</el-button>
              <el-button class="danger" @click.stop="handleDelete(scope.$index, scope.row)" type="text" :disabled="scope.row.websiteId=== 0 || scope.row.websiteId !== indexData.websiteId" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

      </div>
      <div class="add_btn">
        <el-button icon="el-icon-plus" round @click="open">添加学校</el-button>
        <div class="page" v-if="total >10">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="10"
            :current-page.sync="currentPage"
            @current-change="changePage">
          </el-pagination>
        </div>
      </div>
    </div>
    <!--  -->
    <el-dialog
      title="添加学校"
      :visible.sync="linkshow"
      width="650px"
      height="500px;"
      class="linkdialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :before-close="handleClose">
      <el-form label-width="100px">
        <el-form-item label="学校名称">
          <el-input
            class="addname"
            v-model="formdata.infoname"
            maxlength="20"
            show-word-limit
            placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item class="editorbox" label="学校宣传照">
          <uploadcropper @filePath="filePath" :config="config" :cropoption="cropoption"></uploadcropper>
        </el-form-item>
        <el-form-item label="学校地址">
          <el-input class="addname" v-model="formdata.schooladress" placeholder="请输入名称"></el-input>
        </el-form-item>
        <!-- <el-form-item label="学校链接">
          <el-input class="addname" v-model="formdata.schoolnet" placeholder="http://"></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="mini" round>取 消</el-button>
        <el-button type="primary" size="mini" @click="closelink" round>确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>
<script>
import { mapGetters } from 'vuex'
import { getHpStyleInfoShowsByType, updateConfigDeleteHpStyleBodyPlateInfoShow } from '@/api/editIndex.js'
import { updateConfigAddHpStyleBodyPlateInfoShow, updateConfigUpdateHpStyleBodyPlateInfoShow } from '@/api/list.js'
import uploadcropper from '@/components/upload/uploadcropper.vue'
// import addArticle from '../messageBox/addArticle.vue'
export default {
  components: { uploadcropper },
  name: 'ClassifyManage',
  data() {
    return {
      total: 0,
      input2: '',
      tableData: [],
      showImg_N: '/images/myNews/hide.png',
      showImg_P: '/images/myNews/show.png',
      delImg_N: '/images/myNews/delete_n.png',
      delImg_P: '/images/myNews/delete_p.png',
      options: [],
      value: 0,
      linkshow: false,
      isChange: false,
      loading: true,
      infoname: '',
      textarea: '',
      page: 1,
      schooladress: '',
      config: {
        baseImageUrl: '',
        tip: '* 建议尺寸： 653*637px，JPG、PNG格式。',
        size: 10,
        type: ['image/jpeg', 'image/png'],
        errormsg: '上传图片只能是 JPG、PNG 格式!'
      },
      formdata: {},
      cropoption: {
        autoCropWidth: 653,
        autoCropHeight: 637,
        fixedNumber: [653, 637]
      },
      currentPage: 1
    }
  },
  created() {

  },
  computed: {
    ...mapGetters(['indexData', 'newstype'])
  },
  mounted() {
    this.options = this.newstype
    this.getHpStyleInfoShowsByType()
  },
  methods: {
    // 翻页
    changePage(page) {
      this.page = page
      this.getHpStyleInfoShowsByType(page, this.input2)
    },
    // 模糊查询
    searchlist() {
      this.getHpStyleInfoShowsByType(1, this.input2)
    },
    // 选择新闻类型
    choseNewsType(id) {
      if (id === 0) {
        id = null
      }
      this.newsTypeName = id
      this.getHpStyleInfoShowsByType(1, this.input2)
    },
    // 获取信息列表
    getHpStyleInfoShowsByType(page, title) {
      console.log(this.indexData)
      this.loading = true
      const data = {
        websiteId: this.indexData.websiteId,
        type: 2,
        text1: title || null,
        pageNo: page || 1,
        pageSize: 10,
        orderBys: [{
          field: 'id',
          direction: 'desc'
        }]
      }
      getHpStyleInfoShowsByType(data).then(res => {
        console.log(res)
        if (res.message === 'success') {
          this.loading = false
          this.tableData = res.data.rows || []
          this.total = res.data.total
          this.currentPage = res.data.pageNo
          if (res.data.pageNo > 1 && res.data.rows.length === 0) {
            this.getHpStyleInfoShowsByType(res.data.pageNo - 1)
          }
        }
      })
    },
    handleClose() {
      this.isChange = false
      this.linkshow = false
      this.formdata = {}
      this.config.baseImageUrl = ''
    },
    closelink() {
      if (!this.formdata.infoname || !this.formdata.infoname.trim()) {
        this.$toast('学校名称不能为空')
        return
      }
      if (!this.formdata.baseImageUrl) {
        this.$toast('学校宣传照不能为空')
        return
      }
      // const url = this.formdata.schoolnet && this.formdata.schoolnet.includes('http') ? this.formdata.schoolnet : 'http://' + this.formdata.schoolnet
      const params = {
        websiteId: this.indexData.websiteId,
        type: 2,
        text1: this.formdata.infoname.trim(),
        text2: this.formdata.schooladress,
        // urlPath: url,
        picPath: this.formdata.baseImageUrl
      }
      console.log(this.isChange, 'oooooooooo')
      if (!this.isChange) {
        params.styleId = 0
        params.bodyId = 0
        params.plateId = 0
        updateConfigAddHpStyleBodyPlateInfoShow(params).then((res) => {
          if (res.message === 'success') {
            this.linkshow = false
            this.input2 = ''
            this.getHpStyleInfoShowsByType()
          }
        })
      } else {
        params.styleId = this.formdata.styleId
        params.bodyId = this.formdata.bodyId
        params.plateId = this.formdata.plateId
        params.id = this.formdata.id
        updateConfigUpdateHpStyleBodyPlateInfoShow(params).then((res) => {
          if (res.message === 'success') {
            this.linkshow = false
            this.isChange = false
            this.input2 = ''
            this.getHpStyleInfoShowsByType(this.page)
          }
        })
      }
      this.formdata = {}
      this.config.baseImageUrl = ''
    },
    // 获取上传图片地址
    filePath(res) {
      console.log(res, 'path')
      this.config.baseImageUrl = this.$futureImgUrl + res.imgpath
      this.formdata.baseImageUrl = res.imgpath
    },
    // 表格操作
    handleDelete(index, row) {
      console.log(index, row)
      const str = row.plateId !== 0 ? '该学校已在首页展示, 是否继续删除操作?' : '此操作将永久删除该学校, 是否继续?'
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          websiteId: this.indexData.websiteId,
          id: row.id
        }
        updateConfigDeleteHpStyleBodyPlateInfoShow(data).then((res) => {
          if (res.message === 'success') {
            this.tableData.splice(index, 1)
            this.$message({
              type: 'success',
              message: '删除成功!',
              center: true
            })
            // 删除最后一页只剩一条数据的情况
            if (this.total > 10 && index === 0 && this.tableData.length === 1) {
              this.getHpStyleInfoShowsByType(this.page - 1)
            }
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
          center: true
        })
      })
    },
    handleChange(index, row) {
      this.formdata = {
        infoname: row.text1,
        schooladress: row.text2,
        // schoolnet: row.urlPath,
        baseImageUrl: row.picPath,
        styleId: row.styleId,
        bodyId: row.bodyId,
        plateId: row.plateId,
        id: row.id
      }
      this.config.baseImageUrl = this.$futureImgUrl + row.picPath
      this.linkshow = true
      this.isChange = true
    },
    // 添加文章
    open() {
      this.linkshow = true
    }
  }
}
</script>
<style lang="less" scoped>
.classifyManage_cont{
    padding: 0 30px;
    position: relative;
    height: 100%;
    padding-top: 30px;
    overflow: hidden;
    background: #fff;
}
.tximg {
  height: 50px;
}
.tablecontent {
  position: relative;
  max-height: calc(100% - 150px);
  overflow-y: auto;
  .danger {
    color: #f56c6c;
  }
  .danger[disabled]{
    color: #C0C4CC;
  }
}
.page {
  position: absolute;
  top: 5px;
  right: 30px;
}
.articlebox {
  height: 100%;
}
.editImg{
    width: 18px;
    height: 12px;
    margin-right: 15px;
}
.add_btn{
    width: 100%;
    margin-top: 20px;
    text-align: left;
    position: relative;
    .el-button.is-round{
      border: 1px solid #5096F5;
      color: #5096F5;
  }
}
.linkdialog {
  text-align: left;
  /deep/.el-form-item__content {
    width: 400px;
  }
  .editorbox /deep/.el-form-item__content {
    // width: 100%;
    .el-upload__tip {
      position: absolute;
      top: 25px;
      left: 90px;
      line-height: 1.6;
    }
  }
  /deep/.el-upload__tip {
    display: inline-block;
    margin-left: 20px;
  }
  /deep/.el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &.el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 14px;
      color: #8c939d;
      width: 85px;
      height: 85px;
      line-height: 85px;
      text-align: center;
    }
    .avatar {
      height: 85px;
      display: block;
    }
  }
}
.seachbox {
  padding-bottom: 18px;
}
.inputbox, .selectbox {
  float: left;
}
.inputbox {
  padding-right: 10px;
  /deep/.el-input__icon{
    line-height: 40px;
  }
}
</style>
<style lang="less">
// .inputbox .el-input__icon{
//   line-height: 40px;
// }
 .selectbox .el-input__icon {
  line-height: 35px;
}
.articlebox {
  .el-table{
    border-radius: 10px 10px 0 0;
  }
  .el-table tr {
    cursor: pointer;
  }
  .el-table .cell {
    white-space: nowrap;
  }
  .el-table table {
    text-align: left;
  }
  .el-table__fixed-right::before, .el-table__fixed::before {
    height: 0;
  }
}
.tablecontent {
  .el-table th,
  .el-table td{
    padding-left: 10px;
  }
  .el-table tr td:first-of-type,
  .el-table tr th:first-of-type{
    padding-left: 30px;
    padding-right: 20px;
  }
}
.selectbox {
  .is-focus input {
    color: #5096F5;
  }
  .el-icon-arrow-up:before {
    content: "";
  }
  .is-focus .el-icon-arrow-up:before {
    content: "";
    color: #5096F5;
  }
}
</style>
