<template>
  <div class="edupt">
    <div class="tabs">
      <div class="title">爱学云智慧教育平台</div>
      <div class="titlebtm"></div>
      <div class="section">
        <div class="tablist">
          <div class="con" v-for="(item,index) in navList" :key="index" :class="{'on':index+1 === navIndex}" @mouseenter="mouseenter(index+1)">
            <div class="contop">
              <p><img :src="item.imgpath" alt=""></p>
              <p>{{ item.name }}</p>
            </div>
            <div class="conbtm"></div>
          </div>

        </div>
      </div>
    </div>
    <div class="tabconbox">
      <div class="section">
        <div class="tabconlist">
          <div class="tabconitem">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide v-for="(info,index) in infoList" :key="index">
                <Row :gutter="16">
                  <Col span="6" class="itemrow" v-for="(student) in info" :key="student.id">
                    <div>
                      <div class="fl one"><img :src="student.imgpath" alt=""></div>
                      <div class="two">
                        <div class="itemtitle">{{ student.name }}</div>
                        <div class="itemcon">{{ student.content }}</div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
            <!-- <a href="javascript:;" class="openbtn">{{btntext}}</a> -->
            <div class="intobtn">
              <div class="introbox" @click="toTarget(toTargetName)">
                {{ toTargetText }}<img src="/images/index/enter.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Pagination, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Pagination, Mousewheel])
export default {
  name: 'EduPlatform',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        direction: 'vertical',
        slidesPerView: 1,
        mousewheel: true
      },
      navIndex: 1,
      navList: [
        { name: '学生', imgpath: '/images/index/xuesheng.png' },
        { name: '教师', imgpath: '/images/index/jiaoshi.png' },
        { name: '学校管理者', imgpath: '/images/index/school.png' },
        { name: '区域管理者', imgpath: '/images/index/quyu.png' }
      ],
      teacherinfo: [
        { name: '备课', imgpath: '/images/index/jsbeike.png', content: '精品备课包，一键导入，轻松备课', id: 1 },
        { name: '授课', imgpath: '/images/index/jsshouke.png', content: '提前设置上课内容，登录后一键上课', id: 2 },
        { name: '预习作业', imgpath: '/images/index/jsyxzy.png', content: '给学生派发各种学习任务', id: 3 },
        { name: '试卷中心', imgpath: '/images/index/jssjzx.png', content: '各地真题试卷，自由选择', id: 4 },
        { name: '班级管理', imgpath: '/images/index/jsbjgl.png', content: '班级学生基本信息管理', id: 5 },
        { name: '家校互动', imgpath: '/images/index/jsjxhd.png', content: '学生情况，家长可以及时了解', id: 6 },
        { name: '成绩分析', imgpath: '/images/index/jscjfx.png', content: '班级学生学习情况数据统计分析', id: 7 },
        { name: '资源中心', imgpath: '/images/index/jszyzx.png', content: '丰富的教学资源', id: 8 },
        { name: '网络教研', imgpath: '/images/index/jswljy.png', content: '协同教研，优质课程分享', id: 9 },
        { name: '教师发展', imgpath: '/images/index/jsjsfz.png', content: '促进教师素养提升、专业发展', id: 10 },
        { name: '我的资源', imgpath: '/images/index/jswdzy.png', content: '个性化教学资源管理', id: 11 },
        { name: '我的课程', imgpath: '/images/index/jswdkc.png', content: '教室优质课程创建管理', id: 12 }
      ],
      studentinfo: [
        { name: '同步学习', imgpath: '/images/index/xstbxx.png', content: '与学校学习同步', id: 1 },
        { name: '我的预习', imgpath: '/images/index/xswdyx.png', content: '提前预习要学习的知识', id: 2 },
        { name: '我的作业', imgpath: '/images/index/xswdzy.png', content: '已学完的知识课后巩固', id: 3 },
        { name: '我的微课', imgpath: '/images/index/xswdwk.png', content: '动漫微课随时学习', id: 4 },
        { name: '学习计划', imgpath: '/images/index/xsxxjh.png', content: '按照完善的学习计划进行', id: 5 },
        { name: '直播课', imgpath: '/images/index/xszbk.png', content: '名师在线，拔高辅导', id: 6 },
        { name: '每日一练', imgpath: '/images/index/xsmryl.png', content: '海量试题，在线刷题', id: 7 },
        { name: '真题评测', imgpath: '/images/index/xsztcp.png', content: '真题试卷，模拟测评', id: 8 },
        { name: '学习资源', imgpath: '/images/index/xsxxzy.png', content: '丰富多样的学习资源，课外知识拓展', id: 9 },
        { name: '错题本', imgpath: '/images/index/xsctb.png', content: '错题全面汇总，反复练习', id: 10 },
        { name: '学情报告', imgpath: '/images/index/xsxqbg.png', content: '多维度数据统计，学习情况全面掌握', id: 11 },
        { name: '家校互动', imgpath: '/images/index/xsjxhd.png', content: '学生情况，家长可以及时了解', id: 12 }
      ],
      schoolinfo: [
        { name: '人员管理', imgpath: '/images/index/xxrygl.png', content: '学校所有教师学生系统化管理', id: 1 },
        { name: '权限管理', imgpath: '/images/index/xxqxgl.png', content: '根据角色不同分配不同权限', id: 2 },
        { name: '数据管理', imgpath: '/images/index/xxsjgl.png', content: '校内各项信息集中管理', id: 3 },
        { name: '校本资源管理', imgpath: '/images/index/xxxbzygl.png', content: '校内教学资源共享管理', id: 4 },
        { name: '大数据监测', imgpath: '/images/index/xxdsjjc.png', content: '详细的数据埋点，数据统计监测', id: 5 },
        { name: '大数据评价', imgpath: '/images/index/xxdsjpj.png', content: '多维度数据分析评价系统', id: 6 },
        { name: '大数据看板', imgpath: '/images/index/xxdsjkb.png', content: '动态数据驾驶舱大屏看板', id: 7 },
        { name: '大数据预警', imgpath: '/images/index/xxdsjyj.png', content: '数据监测，实时通知', id: 8 }
      ],
      managerinfo: [
        { name: '政务公开', imgpath: '/images/index/qyzwgk.png', content: '活动信息、决策文件公开展示', id: 1 },
        { name: '新闻发布', imgpath: '/images/index/qyxwfb.png', content: '最新教育新闻、评论公开发布', id: 2 },
        { name: '通知公告', imgpath: '/images/index/qytzgg.png', content: '教育局各项通知公告发布下达', id: 3 },
        { name: '成果展示', imgpath: '/images/index/qycgzs.png', content: '优秀教学教研活动成果展示', id: 4 },
        { name: '名师展示', imgpath: '/images/index/qymszs.png', content: '各学校优秀教师、名师工作室展示', id: 5 },
        { name: '名校展示', imgpath: '/images/index/qymxzs.png', content: '优秀学校风采全面展示', id: 6 },
        { name: '机构管理', imgpath: '/images/index/qyjggl.png', content: '区域内相关机构单位管理平台', id: 7 },
        { name: '人员管理', imgpath: '/images/index/qyrygl.png', content: '所有学校所有教职员工、学生系统化管理', id: 8 },
        { name: '权限管理', imgpath: '/images/index/qyqxgl.png', content: '根据角色不同分配不同权限', id: 9 },
        { name: '数据管理', imgpath: '/images/index/qysjgl.png', content: '校内各项信息集中管理', id: 10 },
        { name: '区域资源展示', imgpath: '/images/index/qyzyzs.png', content: '区域内优质教学资源展示', id: 11 },
        { name: '区域资源管理', imgpath: '/images/index/qyzygl.png', content: '区域内优质共享资源管理', id: 12 },
        { name: '大数据监视', imgpath: '/images/index/qydsjjs.png', content: '详细的数据埋点，数据统计监测', id: 13 },
        { name: '大数据评价', imgpath: '/images/index/qydsjpj.png', content: '多维度数据分析评价系统', id: 14 },
        { name: '大数据看板', imgpath: '/images/index/qydsjkb.png', content: '动态数据驾驶舱大屏看板', id: 15 },
        { name: '大数据预警', imgpath: '/images/index/qydsjyj.png', content: '数据监测，实时通知', id: 16 }
      ],
      infoList: [],
      toTargetName: 'student',
      toTargetText: '进入学生空间',
      isShowMessage: false,
      msg: null,
      btntext: '展开',
      adminType: 0,
      siteUsermanageUrl: null
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted() {
    this.setInfo(this.studentinfo)
  },
  methods: {
    setInfo(data) {
      const result = []
      for (let i = 0; i < data.length; i += 8) {
        result.push(data.slice(i, i + 8))
      }
      this.infoList = result
      // console.log(this.infoList)
    },
    mouseenter(index) {
      this.navIndex = index
      if (index === 1) {
        this.toTargetName = 'student'
        this.toTargetText = '进入学生空间'
        this.setInfo(this.studentinfo)
      } else if (index === 2) {
        this.toTargetName = 'teacher'
        this.toTargetText = '进入教师空间'
        this.setInfo(this.teacherinfo)
      } else if (index === 3) {
        this.toTargetName = 'school'
        this.toTargetText = '进入学校管理者空间'
        this.setInfo(this.schoolinfo)
      } else if (index === 4) {
        this.toTargetName = 'area'
        this.toTargetText = '进入区域管理者空间'
        this.setInfo(this.managerinfo)
      }
      setTimeout(() => {
        this.swiper.update()
        this.swiper.slideTo(0)
      }, 0)
    }

  }
}
</script>
<style>
  .tabconbox .swiper-container {
    height: 244px;
    width: 98%;
    margin-bottom: 20px;
    /* overflow: hidden; */
  }
  .tabconbox .swiper-slide {
    height:244px;
  }
  .tabconbox .swiper-container-vertical>.swiper-pagination-bullets {
    right: 0px;
  }
  .tabconbox .swiper-pagination-bullet {
    border-radius: 0;
    width: 3px;
    height: 16px;
  }
</style>
<style lang="less" scoped>
.section {
    margin: 0 auto;
    overflow: hidden;
  }
  .edupt .tabs {
    width: 100%;
    background-color: #fff;
  }

  .tablist {
    display: flex;
    flex-wrap: nowrap;
    justify-content:space-between;
    margin-top: 40px;
    padding: 0 40px;
  }
  .con {
    cursor: pointer;
    width: 20%;
  }
  .contop {
    text-align: center;
  }
  .contop p {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 500;
  }
  .contop img {
    width: 50%;
    max-width: 110px;
    border-radius: 50%;
  }
  .con:hover .contop img {
    box-shadow: 0 5px 10px #D5D5D5;
  }
  .conbtm {
    height: 10px;
    width: 50%;
    margin: 20px auto 0;
    max-width: 204px;
    min-width: 150px;
  }
  .con.on .conbtm {
    background: url(/images/index/conbtm.png) no-repeat;
    background-size: 100%;
  }
  .tabconbox {
    background-color: #F8F9FB;
    padding: 45px 0;
    /* display:none; */
  }
  .tabconlist {
    text-align: left;
    position: relative;
  }
  .fl {
    float: left;
  }
  .one{
     width: 66px;
  }
  .tabconlist .one img {
    width: 100%;
    transition: all 1s;
  }
  .two {
    padding-left: 79px;
  }
  .itemtitle {
    font-size: 16px;
    font-weight: 500;
    color: #444444;
    line-height: 30px;
    margin-bottom: 8px;
  }
  .itemcon {
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }
  .itemrow {
    /* margin-bottom: 30px; */
    padding: 15px 0;
  }
  .intobtn {
    /* width: 230px; */
    text-align: center;
  }
  .introbox {
    display: inline-block;
    padding: 0 35px;
    height: 40px;
    background: #3583FF;
    border-radius: 27px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin: 0 auto;
    transition: all .5s;
  }
  .intobtn a {
    color: #fff;
  }
  .intobtn img {
    height: 18px;
    margin-left: 5px;
    position: relative;
    top: 2px;
  }
  .introbox:hover {
    background-color: #1F65D3;
  }
  .tabconitem {
    display: none;
  }
  .tabconitem:first-of-type {
    display: block;
  }
  .openbtn {
    position: absolute;
    right: 0px;
    bottom: 20px;
    color: #999;
    font-size: 14px;
  }
  @media screen and (min-width: 1920px) {
    .section {
      width: 70%;
      min-width: 1100px;
    }
    .one{
      width: 86px;
    }
    .itemtitle {
      font-size: 18px;
    }
    .itemcon {
      font-size: 16px;
    }
    .two {
      padding-left: 96px;
    }
    .conbtm {
      height: 10px;
    }
    .introbox {
    padding: 0 50px;
    height: 54px;
    border-radius: 27px;
    text-align: center;
    line-height: 54px;
    color: #fff;
    font-size: 18px;
    }
  }
</style>
