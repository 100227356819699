<template>
  <div>
    <div class="school">
      <div class="search">
        <div class="searchbtn">名称 <el-input v-model="classList.name" placeholder="请输入内容" clearable></el-input></div>
        <div class="searchbtn">班级代码 <el-input v-model="classList.classCode" placeholder="请输入内容" clearable></el-input></div>
        <div class="searchbtn">
          状态 <el-select v-model="classList.state" placeholder="全部">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="searchbtn">
          年级 <el-select v-model="classList.gradeId" placeholder="全部">
            <el-option label="全部" value="null"></el-option>
            <el-option
              v-for="item in gradeLists"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="searchbtn">
          <el-button type="primary" @click="getClass()">查询</el-button>
        </div>

        <!-- <el-button icon="el-icon-plus" round @click="addAdmin()">添加单位</el-button> -->
      </div>
      <div class="addClass">
        <el-button round plain @click="addClass"><i class="el-icon-plus"></i> 添加班级</el-button>
      </div>
      <div class="top">
        <el-table
          :data="tableData"
          v-loading="loading"
          border
          max-height="100%"
          :header-cell-style="{background:'#f2f2f2'}">
          <el-table-column prop="name" label="名称" align="center" width="110px" fixed></el-table-column>
          <el-table-column prop="number" align="center" label="班级代码" width="240px"></el-table-column>
          <el-table-column align="center" label="状态" width="80px">
            <template slot-scope="scope">
              {{ scope.row.status === 1? '正常':'已删除' }}
            </template>
          </el-table-column>
          <el-table-column prop="gName" align="center" label="年级" width="80px"></el-table-column>
          <el-table-column align="center" label="创建时间" width="140px">
            <template slot-scope="scope">
              {{ scope.row.createTime && scope.row.createTime.slice(0,10) }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="教师" width="80px">
            <template slot-scope="scope">
              <el-button @click.stop="lookTeacherList(scope.row)" type="text" size="small" >查看</el-button>
            </template>
          </el-table-column>
          <el-table-column align="center" label="学生" width="80px">
            <template slot-scope="scope">
              <el-button @click.stop="lookStudentList(scope.row)" type="text" size="small" >查看</el-button>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope" width="450px">
              <el-button @click.stop="editAdminBtn(scope.row)" type="text" size="small" >编辑</el-button>
              <el-button @click.stop="openTeacher(scope.row,1)" type="text" size="small">分配教师</el-button>
              <el-button @click.stop="openStudent(scope.row,1)" type="text" size="small">分配学生</el-button>
              <!-- <el-button @click.stop="ipmortFile(scope.row,'teacher')" type="text" size="small">导入教师</el-button>
              <el-button @click.stop="ipmortFile(scope.row,'student')" type="text" size="small">导入学生</el-button> -->
              <el-button class="primary" @click.stop="handleDelete(scope.$index, scope.row)" type="text" v-if="scope.row.status === 0" size="small">恢复</el-button>
              <el-button class="danger" @click.stop="handleDelete(scope.$index, scope.row)" type="text" size="small" v-else>删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="botoom">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page.sync="currentPage"
          @current-change="changePage">
        </el-pagination>
      </div>
    </div>

    <!--添加班级-->
    <el-dialog
      title="添加班级"
      :visible.sync="isAddClass"
      width="650px"
      height="400px;"
      class="admindialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :before-close="handleClose">
      <el-form label-width="80px">
        <el-form-item label="名称" class="adminitem">
          <el-input class="addname" v-model="addClassData.name" placeholder="单位"></el-input>
          <span class="sup">*</span>
        </el-form-item>
        <el-form-item label="年级" class="adminitem">
          <el-select v-model="addClassId" value-key="id" placeholder="请选择" @change="changeGrade">
            <el-option
              v-for="item in gradeLists"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学生" class="adminitem">
          <el-button size="small" @click="openStudent(allotData,0)">点击选择学生</el-button>
        </el-form-item>
        <el-form-item label="老师" class="adminitem">
          <el-button size="small" @click="openTeacher(allotData,0)">点击选择老师</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="mini" round>取 消</el-button>
        <el-button type="primary" size="mini" round @click="submitForm()">保 存</el-button>
      </span>
    </el-dialog>
    <!--编辑班级-->
    <el-dialog
      title="编辑班级"
      :visible.sync="iseditClass"
      width="500px"
      height="400px;"
      class="admindialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :before-close="handleClose">
      <el-form label-width="80px">
        <el-form-item label="名称" class="adminitem">
          <el-input class="addname" v-model="editAdmin.name" placeholder="单位"></el-input>
          <span class="sup">*</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer edit">
        <el-button @click="handleClose" size="mini" round>取 消</el-button>
        <el-button type="primary" size="mini" round @click="submitEditClass()">保 存</el-button>
      </span>
    </el-dialog>
    <!---->
    <!--分配老师-->
    <el-dialog
      title="分配老师"
      :visible.sync="isDistributionTeacher"
      width="650px"
      height="400px;"
      class="distribution"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :before-close="handleClose">
      <div class="distribox">
        <div class="distri">
          <div class="distrititle"><span></span>未分配教师</div>
          <div class="districon">
            <div class="searchname">
              <el-input v-model="formInline.noTeacher" placeholder="教师"></el-input>
              <el-button type="primary" @click="getNoTeacher(allotData)" size="small">查询</el-button>
            </div>
            <div class="peopleList">
              <p class="item" v-for="(list,i) in noDistList" :key="i">{{ list.name }} <span class="addbtn" @click="addToTeacher(list,i)"><i class="el-icon-plus"></i>添加</span></p>
            </div>
          </div>
        </div>
        <div class="distri">
          <div class="distrititle"><span></span>已分配教师</div>
          <div class="districon">
            <div class="searchname">
              <el-input v-model="formInline.hasTeacher" placeholder="教师"></el-input>
              <el-button type="primary" @click="getAllotTeacher(allotData)" size="small">查询</el-button>
            </div>
            <div class="peopleList">
              <p class="item" v-for="(item,index) in hasDisList" :key="index">{{ item.teacherName }} <span class="removerbtn" @click="removeToTeacher(item,index)"><i class="el-icon-minus"></i>移除</span></p>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="mini" round>取 消</el-button>
        <el-button type="primary" size="mini" round @click="saveAllotList()">保 存</el-button>
      </span>
    </el-dialog>
    <!--分配学校-->
    <el-dialog
      title="分配学生"
      :visible.sync="isDistributionSudent"
      width="650px"
      height="400px"
      class="distribution"
      :before-close="handleClose">
      <div class="distribox">
        <div class="distri">
          <div class="distrititle"><span></span>未分配学生</div>
          <div class="districon">
            <div class="searchname">
              <el-input v-model="formInline.noStudent" placeholder="学生"></el-input>
              <el-button type="primary" @click="getNoStudent(allotData)" size="small">查询</el-button>
            </div>
            <div class="peopleList">
              <p class="item" v-for="(list,i) in noDistStuList" :key="i">{{ list.fullName }} <span class="addbtn" @click="addToStudent(list,i)"><i class="el-icon-plus"></i>添加</span></p>
            </div>
          </div>
        </div>
        <div class="distri">
          <div class="distrititle"><span></span>已分配学生</div>
          <div class="districon">
            <div class="searchname">
              <el-input v-model="formInline.hasStudent" placeholder="审批人"></el-input>
              <el-button type="primary" @click="getAllotStudent(allotData)" size="small">查询</el-button>
            </div>
            <div class="peopleList">
              <p class="item" v-for="(item,index) in hasDisStuList" :key="index">{{ item.studentName }} <span class="removerbtn" @click="removeToStudent(item,index)"><i class="el-icon-minus"></i>移除</span></p>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="mini" round>取 消</el-button>
        <el-button type="primary" size="mini" round @click="saveAllotStudentList()">保 存</el-button>
      </span>
    </el-dialog>
    <!--查看教职工-->
    <el-dialog
      title="教师列表"
      :visible.sync="isTeacherList"
      width="650px"
      class="looklist"
      :before-close="handleClose">
      <div class="list">
        <el-table
          ref="singleTable"
          :data="teacherList"
          border
          max-height="380px"
          :header-cell-style="{background:'#f2f2f2'}"
          style="width: 100%">
          <el-table-column type="index" label="序号" width="88" align="center">
          </el-table-column>
          <el-table-column property="teacherName" label="姓名" width="230" align="center">
          </el-table-column>
          <el-table-column property="sex" label="性别" width="70" align="center">
          </el-table-column>
          <el-table-column property="jobNum" label="工号" align="center">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!--查看学生-->
    <!--查看教职工-->
    <el-dialog
      title="学生列表"
      :visible.sync="isStudentList"
      width="650px"
      class="looklist"
      :before-close="handleClose">
      <div class="list">
        <el-table
          ref="singleTable"
          :data="studentList"
          border
          max-height="380px"
          :header-cell-style="{background:'#f2f2f2'}"
          style="width: 100%">
          <el-table-column type="index" label="序号" width="88" align="center">
          </el-table-column>
          <el-table-column property="studentName" label="姓名" width="230" align="center">
          </el-table-column>
          <el-table-column property="sex" label="性别" width="70" align="center">
          </el-table-column>
          <el-table-column property="stuNum" label="学号" align="center">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <!--导入-->
    <import-file v-if="isImportTeacher" :isImport="isImportTeacher" :importData="importData" :importStyle="importStyle" @closeFile="closeFile"></import-file>
  </div>

</template>
<script>
import ImportFile from '@/components/import/importFile.vue'
import { mapMutations, mapGetters } from 'vuex'
import { getClassesList, getGradeList, getNoTeacherData, getTeacherData, getNoStudentData, getStudentData, updateClasses, delClass, addOrDelClassTeacher, addOrDelClassStudent, addClasses } from '@/api/management.js'
export default {
  name: 'RightClass',
  components: {

    ImportFile
  },
  data() {
    return {
      currentPage: 1, // 页码
      total: 0, // 总数
      pageSize: 15,
      importStyle: {
        importType: 'teacher', // 导入的类型 teacher 教师 company 教职工 student 学生
        title: ''
      },
      importData: {}, // 导入的参数
      allotData: {}, // 作为打开未分配教师学生的参数
      openListType: 1, // 打开分配教师/学生弹窗 的作用 1为列表打开 0为添加班级打开
      isAddClass: false, // 添加班级
      iseditClass: false, // 编辑班级
      isDistributionTeacher: false, // 分配教师
      isDistributionSudent: false, // 分配学生
      noDistList: [], // 未分配列表
      hasDisList: [], // 已分配列表
      hasDisStuList: [],
      noDistStuList: [],
      saveAllotListId: [], // 保存已分配操作老师的id集合
      saveAllotListStudentId: [], // 保存学生id集合
      isTeacherList: false,
      isStudentList: false,
      isImportTeacher: false,
      classList: {}, // 单位信息
      addClassId: null, // 绑定添加班级时的ID
      addClassData: {
        // gradeId: null
      }, // 添加班级信息
      formInline: {
        user: '',
        region: ''
      },
      editAdmin: {}, // 编辑单位信息
      adminname: '', // 单位名称
      options: [{
        value: null,
        label: '全部'
      }, {
        value: 1,
        label: '正常'
      }, {
        value: 0,
        label: '已删除'
      }],
      gradeLists: [], // 年级列表
      tableData: [], // 班级列表
      teacherList: [], // 查看教师
      studentList: [], // 查看学生
      loading: false //
    }
  },
  watch: {
    treeData: {
      handler(newVal) {
        // console.log(newVal, 'newVal')
        if (this.treeData.type !== 'company') {
          this.getClass()
        } else {
          this.$toast('请选择省、市、区或学校查看！')
        }

        // 清空列表重置
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['treeData'])
  },
  mounted() {
    this.getGradeData()
  },
  methods: {
    ...mapMutations(['SET_STRUCTURE', 'SET_SAVEOK']),
    // 获取年级列表
    getGradeData() {
      const data = {
        status: 1
      }
      getGradeList(JSON.stringify(data)).then((res) => {
        if (res.message === 'success') {
          this.gradeLists = res.data
        }
      })
    },
    // 获取班级列表
    getClass(pageNo) {
      const params = {
        provinceId: this.treeData.provinceId,
        cityId: this.treeData.cityId || null,
        areaId: this.treeData.areaId || null,
        schoolId: this.treeData.schoolId || null,
        name: this.classList.name || null,
        number: this.classList.classCode || null,
        gradeId: this.classList.gradeId || null,
        pageNo: pageNo || 1,
        pageSize: this.pageSize
      }
      if (this.classList.state !== null) {
        params.status = this.classList.state
      }
      getClassesList(JSON.stringify(params)).then(res => {
        if (res.message === 'success') {
          // this.$toast('保存成功')
          this.total = res.data.total
          this.currentPage = res.data.pageNo
          this.tableData = res.data.rows
          if (res.data.pageNo > 1 && res.data.rows.length === 0) {
            this.getClass(res.data.pageNo - 1)
          }
        }
      })
    },
    // 添加班级
    submitForm() {
      if (!this.addClassData.name) {
        this.$toast('请添加班级名称！')
        return false
      }
      if (!this.addClassData.gradeId) {
        this.$toast('请选择年级！')
        return false
      }
      const params = {
        schoolId: this.treeData.schoolId,
        gradeId: this.addClassData.gradeId,
        name: this.addClassData.name
      }
      const teacherListArr = []
      const studentListArr = []
      this.hasDisList.forEach(el => {
        teacherListArr.push(el.teacherId)
      })
      this.hasDisStuList.forEach(el => {
        studentListArr.push(el.studentId)
      })

      if (teacherListArr.length > 0) {
        params.teacherIdStr = teacherListArr.join(',')
      }
      if (studentListArr.length > 0) {
        params.studentIdStr = studentListArr.join(',')
      }
      // console.log(this.hasDisList, this.hasDisStuList, params, teacherListArr, studentListArr, '999wwwww')
      addClasses(JSON.stringify(params)).then(res => {
        if (res.success === true) {
          this.getClass()
          this.handleClose()
        } else {
          this.$toast(res.message)
        }
      })
    },
    // 编辑班级
    submitEditClass() {
      if (!this.editAdmin.name) {
        this.$toast('班级名字不能为空！')
        return false
      }
      updateClasses(JSON.stringify(this.editAdmin)).then(res => {
        if (res.success === true) {
          this.$toast('班级名字修改成功！')
          this.iseditClass = false
          this.getClass()
        } else {
          this.$toast(res.message)
        }
      })
    },
    ipmortFile(row, type) {
      this.importStyle = {
        importType: type
      }
      if (type === 'teacher') {
        this.importStyle.title = '导入教师'
      } else if (type === 'company') {
        this.importStyle.title = '导入教职工'
      } else if (type === 'student') {
        this.importStyle.title = '导入学生'
      }
      this.importData = row
      this.isImportTeacher = true
    },
    closeFile(res) {
      this.isImportTeacher = res
    },
    onSubmit() {
      console.log('submit!')
    },
    handleClick(row) {
      console.log(row)
    },
    handleDelete(index, row) {
      var text = '删除'
      if (row.status === 1) {
        text = '删除'
      } else {
        text = '恢复'
      }
      this.$confirm('确定' + text + '吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        roundButton: true
      }).then(() => {
        const data = {
          id: row.id,
          status: row.status
        }
        delClass(JSON.stringify(data)).then(res => {
          if (res.message === 'success') {
            this.$message({
              type: 'success',
              message: text + '成功!'
            })
            if (row.status === 1) {
              this.tableData[index].status = 0
            } else {
              this.tableData[index].status = 1
            }
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消' + text
        })
      })
    },
    handleClose(type) {
      if (this.openListType === 1) {
        this.addClassId = null
        this.addClassData = {}
        this.isAddClass = false
        this.isTeacherList = false
        this.isStudentList = false
        this.iseditClass = false
        this.hasDisList = []
        this.hasDisStuList = []
        this.noDistList = []
        this.noDistStuList = []
      }
      if (type !== 'save') {
        this.hasDisList = []
        this.hasDisStuList = []
      }
      this.isDistributionTeacher = false
      this.isDistributionSudent = false
      this.formInline = {}
      if (!this.isDistributionTeacher || !this.isDistributionSudent) {
        this.openListType = 1
      }
    },
    lookTeacherList(row) {
      this.isTeacherList = true
      this.getAllotTeacher(row)
    },
    lookStudentList(row) {
      this.isStudentList = true
      this.getAllotStudent(row)
    },
    // 添加班级时选择年级
    changeGrade(value) {
      if (!this.addClassData.gradeId) {
        this.addClassData.gradeId = value
      } else {
        if (value && this.addClassData.gradeId !== value && (this.hasDisList.length > 0 || this.hasDisStuList.length > 0)) {
          this.$confirm('更改年级后已分配的教师、学生将重置。确定更改？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              this.addClassData.gradeId = value
              this.hasDisList = [] // 已分配列表
              this.hasDisStuList = [] // 已分配列表
              this.noDistList = []
              this.noDistStuList = []
              this.$message({
                type: 'success',
                message: '更改成功！'
              })
            })
            .catch(() => {
              this.addClassId = this.addClassData.gradeId
              this.$message({
                type: 'info',
                message: '已取消更改！'
              })
            })
        } else {
          this.addClassData.gradeId = value
        }
      }
      this.allotData = {
        ...this.allotData,
        gradeId: this.addClassData.gradeId
      }
    },
    // 获取已分配老师列表
    getAllotTeacher(row) {
      const params = {
        schoolId: row.schoolId,
        gradeId: row.gradeId,
        classId: row.id
      }
      if (this.formInline.hasTeacher) {
        params.teacherName = this.formInline.hasTeacher
      }
      getTeacherData(JSON.stringify(params)).then(res => {
        console.log(res, 'teacher1')
        if (res.message === 'success') {
          // this.$toast('保存成功')
          const data = res.data
          data.forEach(element => {
            if (element.sex === 1) {
              element.sex = '男'
            } else {
              element.sex = '女'
            }
            // element.type1 = 'yfp'
            // element.type2 = 'yfp'
          })
          this.hasDisList = data // 已分配列表
          this.teacherList = data // 查看教师
          // this.hasDisOldList = data // 已分配列表原始数据
        }
      })
    },
    // 获取未分配教师
    getNoTeacher(row) {
      const params = {
        gradeId: row.gradeId,
        schoolId: row.schoolId
      }
      if (this.openListType === 1) {
        params.id = row.id
      }
      if (this.openListType === 0) {
        params.type = 0
      }
      if (this.formInline.noTeacher) {
        params.teacherName = this.formInline.noTeacher
      }
      getNoTeacherData(JSON.stringify(params)).then(res => {
        console.log(res, 'teacher2')
        if (res.message === 'success') {
          const data = res.data
          data.forEach(element => {
            element.type1 = 'wfp'
            element.type2 = 'wfp'
          })
          this.noDistList = data // 未分配列表
          // this.noDistOldList = res.data // 未分配列表原始数据
        }
      })
    },
    // 获取已分配学生列表
    getAllotStudent(row) {
      const params = {
        gradeId: row.gradeId,
        schoolId: row.schoolId,
        classId: row.id
      }
      if (this.formInline.hasStudent) {
        params.studentName = this.formInline.hasStudent
      }
      getStudentData(JSON.stringify(params)).then(res => {
        if (res.message === 'success') {
          // this.$toast('保存成功')
          const data = res.data
          data.forEach(element => {
            if (element.sex === 1) {
              element.sex = '男'
            } else {
              element.sex = '女'
            }
          })
          this.hasDisStuList = data // 已分配列表
          this.studentList = data // 查看教师
          // this.hasDisOldList = data // 已分配列表原始数据
        }
      })
    },
    // 获取未分配学生
    getNoStudent(row) {
      const params = {
        gradeId: row.gradeId,
        schoolId: row.schoolId
      }

      if (this.formInline.noStudent) {
        params.studentName = this.formInline.noStudent
      }
      getNoStudentData(JSON.stringify(params)).then(res => {
        if (res.message === 'success') {
          const data = res.data
          this.noDistStuList = data // 未分配列表
        }
      })
    },
    // 打开分配老师
    openTeacher(row, type) {
      console.log(row, '343434343')
      if (type === 0 && !row.gradeId) {
        this.$toast('请选择年级！')
        return false
      }
      this.isDistributionTeacher = true
      this.openListType = type
      this.allotData = row
      if (type === 1) {
        this.getAllotTeacher(row)
      }
      if (this.hasDisList.length === 0) {
        this.getNoTeacher(row)
      }
    },
    // 打开分配学生
    openStudent(row, type) {
      if (type === 0 && !row.gradeId) {
        this.$toast('请选择年级！')
        return false
      }
      this.openListType = type
      this.isDistributionSudent = true
      this.allotData = row
      if (type === 1) {
        this.getAllotStudent(row)
      }
      if (this.hasDisStuList.length === 0) {
        this.getNoStudent(row)
      }
    },
    // 保存已分配教师
    saveAllotList() {
      console.log(this.hasDisList, this.noDistList, 'sava')
      const idHasDisList = []
      const idNoDisList = []
      this.hasDisList.forEach(v => {
        idHasDisList.push(v.teacherId)
      })
      this.noDistList.forEach(el => {
        idNoDisList.push(el.id)
      })
      // 添加班级的时候 保存分配后的id集合作为参数传给新建班级
      if (this.openListType === 0) {
        this.saveAllotListId = idHasDisList
        this.handleClose('save')
      } else {
        if (idHasDisList.length > 0) {
          const data = {
            schoolId: this.allotData.schoolId,
            gradeId: this.allotData.gradeId,
            classId: this.allotData.id,
            operationType: 1,
            ids: idHasDisList.join(',')
          }
          addOrDelClassTeacher(JSON.stringify(data)).then(res => {
            if (res.message === 'success') {

            }
          })
        }
        if (idNoDisList.length > 0) {
          const data = {
            schoolId: this.allotData.schoolId,
            gradeId: this.allotData.gradeId,
            classId: this.allotData.id,
            operationType: 0,
            ids: idNoDisList.join(',')
          }
          addOrDelClassTeacher(JSON.stringify(data)).then(res => {
            if (res.message === 'success') {

            }
          })
        }
        this.$toast('分配成功！')
        this.handleClose()
      }
    },
    // 保存分配学生
    saveAllotStudentList() {
      console.log(this.hasDisStuList, this.noDistStuList, 'sava')
      const idHasDisList = []
      const idNoDisList = []
      this.hasDisStuList.forEach(v => {
        idHasDisList.push(v.studentId)
      })
      this.noDistStuList.forEach(el => {
        idNoDisList.push(el.id)
      })
      // 添加班级的时候 保存分配后的id集合作为参数传给新建班级
      if (this.openListType === 0) {
        this.saveAllotListStudentId = idHasDisList
        this.handleClose('save')
      } else {
        if (idHasDisList.length > 0) {
          const data = {
            schoolId: this.allotData.schoolId,
            gradeId: this.allotData.gradeId,
            class_id: this.allotData.id,
            operationType: 1,
            ids: idHasDisList.join(',')
          }
          addOrDelClassStudent(JSON.stringify(data)).then(res => {
            if (res.message === 'success') {

            }
          })
        }
        if (idNoDisList.length > 0) {
          const data = {
            schoolId: this.allotData.schoolId,
            gradeId: this.allotData.gradeId,
            class_id: this.allotData.id,
            operationType: 0,
            ids: idNoDisList.join(',')
          }
          addOrDelClassStudent(JSON.stringify(data)).then(res => {
            if (res.message === 'success') {

            }
          })
        }
        this.$toast('分配成功！')
        this.handleClose()
      }
    },
    // 编辑单位
    editAdminBtn(row) {
      this.iseditClass = true
      this.editAdmin = {
        name: row.name,
        id: row.id
      }
    },
    // 打开添加班级
    addClass() {
      if (this.treeData.type !== 'school') {
        this.$toast('请先选择学校再添加班级！')
        return false
      }
      this.isAddClass = true
      this.hasDisList = []
      this.hasDisStuList = []
      this.noDistList = []
      this.noDistStuList = []
      console.log(this.treeData, '777777')
      this.allotData = {
        schoolId: this.treeData.schoolId
      }
      console.log(this.allotData, 'dakai')
    },
    // 翻页
    changePage(page) {
      this.getClass(page)
    },
    // 添加未分配到已分配教师
    addToTeacher(obj, index) {
      const data = {
        teacherName: obj.name,
        teacherId: obj.id,
        type2: 'yfp',
        type1: obj.type1
      }
      this.hasDisList.push(data)
      this.noDistList.splice(index, 1)
    },
    // 移除已分配到未分配教师
    removeToTeacher(obj, index) {
      const data = {
        name: obj.teacherName,
        id: obj.teacherId,
        type2: 'wfp',
        type1: obj.type1
      }
      this.noDistList.push(data)
      this.hasDisList.splice(index, 1)
    },
    addToStudent(obj, index) {
      const data = {
        studentName: obj.fullName,
        studentId: obj.id
      }
      this.hasDisStuList.push(data)
      this.noDistStuList.splice(index, 1)
    },
    removeToStudent(obj, index) {
      const data = {
        fullName: obj.studentName,
        id: obj.studentId
      }
      this.noDistStuList.push(data)
      this.hasDisStuList.splice(index, 1)
    },
    unique(arr, arr1) {
      var tempArray1 = []
      var tempArray2 = []
      for (var i = 0; i < arr1.length; i++) {
        tempArray1[arr1[i].id] = true
      }
      for (var j = 0; j < arr.length; j++) {
        if (!tempArray1[arr[j].id]) {
          tempArray2.push(arr[j])
        }
      }
      return tempArray2
    }
  }
}
</script>
<style lang="less" scoped>
  .search {
    text-align: left;
    margin-bottom: 0px;
    .searchbtn {
      display: inline-block;
      margin-bottom: 15px;
      color: #666666;
      /deep/.el-input {
        width: 224px;
        height: 34px!important;

        margin-right: 40px;
        margin-left: 10px;
        input {
          height: 100%;
        }
        .el-input__suffix{
          position: absolute;
          top: -2px;
        }
      }
      /deep/.el-button {
        margin-right: 5px;
        padding: 9px 20px;
        &.is-round {
          border: 1px solid #5096F5;
          color: #5096F5;
        }
      }
      /deep/.el-select {
        .el-input__icon {
          line-height: 34px;
        }
        .el-input {
          width: 123px;
        }
      }
    }
  }
  .addClass {
    padding-top: 10px;
    margin-bottom: 20px;
    text-align: left;
    /deep/.el-button {
      padding: 9px 20px;
      &.is-round {
        border: 1px solid #5096F5;
        color: #5096F5;
      }
      &:hover {
        background: #ecf5ff;
      }
    }

    }
  .school {
    height: 100%;
    .top {
      max-height: calc(100% - 300px);
      position: relative;
      border-bottom: 1px solid #EBEEF5;
      overflow-y: auto;
      /deep/.el-table {

        th {
          padding: 8px 0;
        }
        td {
          padding: 5px 0;
        }
        .danger {
          color: #f56c6c;
        }
      }
    }
    .botoom {
      margin-top: 20px;
      /deep/.el-pagination {
        position: absolute;
        right: 20px;
      }
    }
  }
  .admindialog {
    /deep/.el-dialog__header {
      text-align: left;
      padding: 20px 40px 10px;
      border-bottom: 1px solid #F2F2F2;
    }
    /deep/.el-dialog__body {
      padding: 15px 40px;
      .el-form-item__label {
        text-align: left;
      }
      .adminitem {
        margin-bottom: 15px;
        .el-form-item__label {
          height: 34px;
        }
        .el-form-item__content {
          text-align: left;
          width: 327px;
          height: 34px;
          .el-select {
            width: 100%;
          }
          .el-input {
            width: 100%;
          }
          .el-button {
            width: 100%;
            border: 1px solid #5096F5;
            color: #5096F5;
            background: #ecf5ff;

          }
          .el-input__inner {
            height: 34px;
          }
          .sup {
            display: block;
            position: absolute;
            right: -15px;
            top: 0;
            color: red;
          }
        }

      }
    }
    /deep/.el-dialog__footer {
      text-align: center;
      padding-top: 30px;
      &.edit {
        padding-top: 30px;
      }
    }
  }
  .choseschool {
     /deep/.el-dialog__header {
      text-align: left;
      padding: 20px 40px 10px;
      border-bottom: 1px solid #F2F2F2;
    }
    /deep/.el-dialog__body {
      padding: 15px 40px 50px;
      .choseschoolbox {
        width: 570px;
        height: 373px;
        box-sizing: border-box;
        padding: 15px 20px;
        border: 1px solid #F2F2F2;
        overflow-y: auto;
        // display: flex;
        // flex-wrap: wrap;
        &::-webkit-scrollbar {
          width:7px;
          height: 7px;
        }
        /* 滚动槽 */
        &::-webkit-scrollbar-track {
          -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.2);
          border-radius:10px;
        }
        /* 滚动条滑块 */
        &::-webkit-scrollbar-thumb {
          border-radius:10px;
          background:rgba(0,0,0,0.2);
          -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.2);
        }
        &::-webkit-scrollbar-thumb:window-inactive {
          background:rgba(0,0,0,0.2);
        }
      }
    }
  }
  .distribution {
    /deep/.el-dialog__header {
      padding: 20px 40px 10px;
      border-bottom: 1px solid #F2F2F2;
    }
    .distribox {
      display: flex;
      justify-content: space-between;
      .distri {
        width: 295px;
        .distrititle {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
          text-align: left;
          span {
            display: inline-block;
            width: 3px;
            height: 16px;
            background: #333333;
            position: relative;
            top: 2px;
            margin-right: 10px;
          }
        }
        .districon {
          width: 295px;
          height: 357px;
          margin-top: 15px;
          border: 1px solid #F2F2F2;
          .searchname {
            padding: 15px 5px 0;
            /deep/.el-input {
              width:197px ;
              margin-right: 10px;
              .el-input__inner {
                height: 34px;
                line-height: 34px!important;
              }
            }
          }
          .peopleList {
            padding: 15px 10px 0 20px;
            height: calc(100% - 50px);
            overflow-y:auto ;
            &::-webkit-scrollbar {
              width:7px;
              height: 7px;
            }
            /* 滚动槽 */
            &::-webkit-scrollbar-track {
              -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.2);
              border-radius:10px;
            }
            /* 滚动条滑块 */
            &::-webkit-scrollbar-thumb {
              border-radius:10px;
              background:rgba(0,0,0,0.2);
              -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.2);
            }
            &::-webkit-scrollbar-thumb:window-inactive {
              background:rgba(0,0,0,0.2);
            }
            .item {
              position: relative;
              line-height: 20px;
              padding-right: 60px;
              text-align: left;
              margin-bottom: 10px;
              span {
                cursor: pointer;
                display: block;
                width: 60px;
                text-align: center;
                position: absolute;
                top: 0;
                right: 0;

              }
              .addbtn {
                color: #5096F5;
                /deep/.el-icon-plus {
                  margin-right: 3px;
                }
              }
              .removerbtn {
                color: #F56C6C;
                /deep/.el-icon-minus {
                  margin-right: 3px;
                }
              }
            }
          }
        }

      }
    }
    /deep/.el-dialog__footer{
      text-align: center;
    }
  }
  .looklist {
     /deep/.el-dialog__header {
      text-align: left;
      padding: 20px 40px 10px;
      border-bottom: 1px solid #F2F2F2;
    }
    /deep/.el-dialog__body {
      padding: 15px 40px 50px;
      .list {
        width: 570px;
        // height: 385px;
        // box-sizing: border-box;
        // padding: 15px 20px;
        // border: 1px solid #F2F2F2;
        .page {
          margin-top: 15px;
        }
      }
    }
  }

</style>
