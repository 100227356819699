<template>
  <div id="white">
    <div v-if="newsData.titleShow == 1 && newsData.title">
      <div class="title">{{ newsData.title }}</div>
      <div class="titlebtm"></div>
    </div>
    <div class="newsclassify">
      <Row :gutter="27">
        <Col span="12" v-for="(news,index) in newsList" :key="index">
          <div class="con clearfix">
            <div class="contitle font28">{{ news.title }}</div>
            <!-- <div class="conbox" v-if="dataobj[news.id] && dataobj[news.id].length >0">
              <p class="font18 pointer" v-for="(item,i) in dataobj[news.id]" :key="i" :title="item.firstTitle">
                <a v-if="item.recordTypeStr == '外部报道'" :href="item.myLink" target="_blank">{{ item.firstTitle }}</a>
                <router-link v-else :to="{name:'newsDetail', query: {id:item.id}}">{{ item.firstTitle }}</router-link>
              </p>
            </div> -->
            <news-item :item="news"></news-item>
            <div class="more font14 pointer" v-if="newsData.moreShow == 1">更多&gt;&gt;</div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAxtNewSourceList } from '@/api/editIndex.js'
import NewsItem from '@/components/indexContent/NewsItem3.vue'
export default {
  name: 'EduNewsThree',
  props: {
    newsData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      newsList: [],
      dataobj: {}
    }
  },
  components: {
    NewsItem
  },
  watch: {
    newsData: {
      handler() {
        this.getAxtNewSourceList()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['indexData'])
  },
  mounted() {
    this.newsList = this.newsData.news.slice(0, 2)
    this.getAxtNewSourceList()
  },
  methods: {
    getAxtNewSourceList() {
      this.newsList.forEach(v => {
        const params = {
          website_id: this.indexData.websiteId,
          cancel_publish: 0
        }
        if (v.dataType === 1) {
          if (!v.dataNewsIds) {
            this.dataobj[v.id] = []
            return
          }
          params.ids = v.dataNewsIds
        } else if (v.dataType === 2) {
          params.classify_id = v.dataNewsTypeId
        }

        getAxtNewSourceList(params).then((res) => {
          // console.log(res, 'xinwen6')
          if (res.message === 'success') {
            this.$set(this.dataobj, v.id, res.data.rows.slice(0, 4))
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped="scoped">
#white {
  background: #fff;
  padding: 0 10px;
}
  .newslist {
    text-align: left;
  }
  .con {
    background-color: #F5F5F7;
    border-radius: 10px;
    text-align: left;
    display: flex;
    height: 100%;
    .contitle {
      float: left;
      writing-mode:vertical-lr;
      text-align: center;
      letter-spacing: 5px;
      width: 70px;
      background: #3583FF;
      border-radius: 10px 0px 0px 10px;
      padding: 24px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.4;
      color: #fff;
    }
    .conbox {
      flex: 1;
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      p {
        width: 85%;
        font-size: 16px;
        margin-bottom: 15px;
        color: #555555;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        a {
          color: #555;
          display: block;
        }
        &:hover a {
          color:#3583FF ;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
        &::before {
          content:"";
          display: block;
          float: left;
          width: 3px;
          height: 3px;
          position: relative;
          top: 10px;
          margin-right: 8px;
          background: #555555;
        }
      }
    }
    .more {
      position: absolute;
      bottom: 10px;
      right: 20px;
      color:#999999 ;
      &:hover {
        color:#3583FF ;
      }
    }
  }
  .newsclassify {
    padding:30px 0 45px;
  }
  @media screen and (min-width: 1920px) {

  }
</style>
