<template>
  <el-upload
    class="avatar-uploader"
    :action="this.$baseUrl + '/axt-resources/upload/ueditorUpload?action=uploadimage&encode=utf-8'"
    :show-file-list="false"
    :data="formdata"
    name="upfile"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload">
    <div class="downbtn"><span class="icon-shangchuan"></span>上传文件</div>
  </el-upload>
</template>
<script>
export default {
  name: 'Upload',
  props: {
    isbanner: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      imageUrl: '/images/upload_icon.png',
      formdata: {}
    }
  },
  watch: {

  },
  methods: {
    // shangchuan
    handleAvatarSuccess(res, file) {
      this.$emit('filePath', file)
    },
    beforeAvatarUpload(file) {
      if (!this.config.type.includes(this.requeFile(file.name))) {
        this.$message.error(this.config.errormsg)
        return false
      }
      const data = {}
      data.id = 'file_'
      data.name = file.name
      data.type = file.type
      data.size = file.size
      data.lastModifiedDate = file.lastModifiedDate
      if (file.size > this.config.size * 1024 * 1024) {
        this.$message.error('上传文件大小不能超过 ' + this.config.size + 'MB!')
        return false
      }
      this.formdata = data
    },
    requeFile(file) {
      const fileName = file.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
      const fileNameLen = file.length // 取到文件名长度
      const fileType = file.substring(fileName + 1, fileNameLen) // 截
      return fileType
    }
  }
}
</script>
<style lang="less" scoped>
.downbtn {
  color: #5096F5;
  font-size: 12px;
  margin-bottom: 5px;
  .icon-xiazai, .icon-shangchuan {
    font-family: "font_family" !important;
    font-size: 14px;
    &::before {
      position: relative;
      top: 1px;
      margin-right: 4px;
    }
  }
}
</style>
