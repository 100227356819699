<template>
  <el-dialog
    title="添加分类"
    :visible.sync="dialogVisible"
    width="500px"
    :modal="true"
    :close-on-click-modal="false"
    :append-to-body="true"
    :before-close="handleClose">
    <div class="addClassify_info">
      <el-form label-width="80px">
        <el-form-item label="分类名称">
          <el-input class="addname" v-model="classifyTitle" placeholder="请输入名称"></el-input>
        </el-form-item>
      </el-form>
      <div class="waring"><span>*</span>最多不超过10个字</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose(0)">取 消</el-button>
      <el-button type="primary" @click="handleClose(1)">确 定</el-button>
    </span>
  </el-dialog>

  <!-- <div class="addClassify_title">添加分类</div>
      <div class="addClassify_info">
        <el-form label-width="80px">
          <el-form-item label="分类名称">
            <el-input class="addname" v-model="classifyTitle" placeholder="请输入名称"></el-input>
          </el-form-item>
        </el-form>
        <div class="waring"><span>*</span>最多不超过10个字</div>
      </div>
      <div class="addClassify_btn">
        <el-row>
          <el-button round class="cancel_btn btn" @click="emit(0)">取消</el-button>
          <el-button type="primary" round class="sure_btn btn" @click="emit(1)">确定</el-button>
        </el-row>
      </div> -->
</template>
<script>
import { addAxtNewuClassifyByManager } from '@/api/list.js'
import { mapGetters } from 'vuex'
export default {
  name: 'AddClassify',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      classifyTitle: ''
    }
  },
  computed: {
    ...mapGetters(['indexData'])
  },
  methods: {
    handleClose(res) {
      if (res === 1) {
        if (this.classifyTitle.length > 10) {
          this.$message({
            message: '最多不超过10个字哦',
            type: 'warning'
          })
          return
        } else if (this.classifyTitle.trim() === '') {
          this.$message({
            message: '分类名称不能为空',
            type: 'warning'
          })
          return
        }

        const params = {
          websiteId: this.indexData.websiteId,
          name: this.classifyTitle,
          state: 1
        }
        addAxtNewuClassifyByManager(params).then((res) => {
          if (res.message === 'success') {
            this.$toast('添加成功')
            const data = { changeState: true, isClose: true }
            this.$emit('submit', data)
          }
        })
      } else {
        const data = { changeState: false, isClose: true }
        this.$emit('submit', data)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.addClassify_cont{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.15);
  z-index: 999;
  /deep/.el-form-item__content{
    width: 347px;
    border-radius: 10px;
    .el-input__inner{
      height: 35px;
      line-height: 35px;
      border: 1px solid #ccc;
    }
    .el-form-item{
      margin-bottom: 10px;
    }
    .el-form-item__label{
      font-size: 14px;
      color: #7B7B7B;
      text-align: left;
    }
  }
}
.addClassify_box{
  width:570px;
  height: 227px;
  background: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border-radius: 10px;
}
.addClassify_title{
    padding-left:20px;
    margin-top: 20px;
    font-size: 16px;
    color: #333;
    text-align: left;
    font-weight: 600;
}
.addClassify_info{
    color: #ccc;
}

.waring{
  font-size: 12px;
  color: #999;
  text-align: left;
  padding-left: 80px;
}
.waring span{
    color: red;
}
.btn{
  width: 60px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  padding: 0!important;
}
.cancel_btn{
  color: #999999!important;
  border: 1px solid #CCCCCC!important;
}
.sure_btn{
  background: #5096F5;
  color: white!important;
}
.addClassify_btn{
    text-align: right;
    padding-right: 20px;
}
</style>
