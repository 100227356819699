import { axios } from '@/axios/request'
// 获取学生列表
export function getStudent(params) {
  return axios({
    url: '/axt-crm/studentManage/getStudentList',
    data: params,
    method: 'post'
  })
}
// 保存添加学生
export function saveStudentInfo(params) {
  return axios({
    url: '/axt-crm/studentManage/saveStudentInfo',
    data: params,
    method: 'post'
  })
}
// 获取年纪信息
export function getGradeList(params) {
  return axios({
    url: '/axt-crm/grade/list',
    data: params,
    method: 'post'
  })
}
// 删除学生
export function delStudent(params) {
  return axios({
    url: '/axt-crm/studentManage/delStudent',
    data: params,
    method: 'post'
  })
}
// 批量删除学生
export function batchDelStudent(url, params) {
  return axios({
    url: url,
    data: params,
    method: 'post'
  })
}
// 批量导出学生
export function batchExportStudentInfo(params, name) {
  return axios({
    url: '/axt-crm/studentManage/batchExportStudentInfo',
    data: params,
    method: 'post',
    responseType: 'blob'
  }).then((res) => {
    console.log(23423423)
    const link = document.createElement('a')
    const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
    link.style.display = 'none'
    link.href = URL.createObjectURL(blob)
    // link.download = res.headers['content-disposition'] //下载后文件名
    link.download = name// 下载的文件名
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}
// 编辑
export function editStudent(url) {
  return axios({
    url: url,
    method: 'post'
  })
}
// 获取学校信息
export function getSchoolInfo(params) {
  return axios({
    url: '/axt-website/websiteAuthSchool/selectWebsiteAuthSchools',
    data: params,
    method: 'post'
  })
}
// 保存编辑信息
export function updateStudentInfo(params) {
  return axios({
    url: '/axt-crm/studentManage/updateStudentInfo',
    data: params,
    method: 'post'
  })
}
// 重置密码
export function resetStudentPassword(params) {
  return axios({
    url: '/axt-crm/studentManage/resetStudentPassword',
    data: params,
    method: 'post'
  })
}
// 获取教师列表
export function getTeacherList(params) {
  return axios({
    url: '/axt-crm/teacherManage/getTeacherList',
    data: params,
    method: 'post'
  })
}
// 保存添加教师
export function saveZxxtTeacherInfo(params) {
  return axios({
    url: '/axt-usercenter/teacher/saveTeacherInfo',
    data: params,
    method: 'post'
  })
}
// 查询学科
export function getSubjectList(params) {
  return axios({
    url: '/axt-crm/subject/list',
    data: params,
    method: 'post'
  })
}
// 删除教师
export function deleteTeacher(params) {
  return axios({
    url: '/axt-crm/teacherManage/deleteTeacher',
    data: params,
    method: 'post'
  })
}
// 教师 重置密码
export function resetPassWord(params) {
  return axios({
    url: '/axt-crm/teacherManage/resetPassWord',
    data: params,
    method: 'post'
  })
}
// 批量导出教师
export function batchExportTeacherInfo(params, name) {
  return axios({
    url: '/axt-crm/teacherManage/batchExportTeacherInfo',
    data: params,
    method: 'post',
    responseType: 'blob'
  }).then((res) => {
    console.log(23423423)
    const link = document.createElement('a')
    const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
    link.style.display = 'none'
    link.href = URL.createObjectURL(blob)
    // link.download = res.headers['content-disposition'] //下载后文件名
    link.download = name// 下载的文件名
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}
// 批量导入教师
export function batchImportTeacherInfo(params) {
  return axios({
    url: '/axt-usercenter/teacher/batchImportTeacherInfo',
    params: params,
    method: 'post'
  })
}
// 批量导入学生
export function batchImportStudentInfo(params) {
  return axios({
    url: '/axt-crm/studentManage/batchImportStudentInfo',
    params: params,
    method: 'post'
  })
}
// 批量保存教师
export function saveImportTeacherInfo(params) {
  return axios({
    url: '/axt-usercenter/teacher/saveImportTeacherInfo',
    params: params,
    method: 'post'
  })
}
// 批量保存学生
export function saveImportStudentInfo(params) {
  return axios({
    url: '/axt-crm/studentManage/saveImportStudentInfo',
    params: params,
    method: 'post'
  })
}
// 教师 信息
export function getTeacherInfo(params) {
  return axios({
    url: '/axt-crm/teacherManage/getTeacherInfo',
    data: params,
    method: 'post'
  })
}

// 教师 修改时获取年级列表
export function getEditGradeList(params) {
  return axios({
    url: '/axt-crm/teacherManage/getGradeList',
    params: params,
    method: 'post'
  })
}

// 教师 修改时获取学科列表
export function getEditSubjectList(params) {
  return axios({
    url: '/axt-crm/teacherManage/getSubjectList',
    params: params,
    method: 'post'
  })
}
// 教师 修改时获取单位列表
export function pageWebsiteAuthCompanys(params) {
  return axios({
    url: '/axt-website/websiteAuthCompany/pageWebsiteAuthCompanysAll',
    data: params,
    method: 'post'
  })
}
// 教师 修改时获取学校列表
export function selectWebsiteAuthSchools(params) {
  return axios({
    url: '/axt-website/websiteAuthSchool/selectWebsiteAuthSchools',
    data: params,
    method: 'post'
  })
}
// 教师 修改时获取年级列表
export function updateTeacherInfo(params) {
  return axios({
    url: '/axt-crm/teacherManage/updateTeacherInfo',
    data: params,
    method: 'post'
  })
}
