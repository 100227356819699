<template>
  <div class="footerInfo">
    <el-form label-width="100px">
      <el-form-item label="底部预览">
        <div class="footerPreview">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="name">{{ footerData.text1 }}</div>
              <div class="info">
                <el-row :gutter="10">
                  <!-- <el-col :span="8">主办单位：{{ textarea1 }}</el-col> -->
                  <div style="text-align:left;width:100%;">技术支持：{{ footerData.text2 }}</div>
                  <!-- <el-col :span="8">课程支持：慕华成志教育科技有限公司</el-col> -->
                </el-row>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="computyName">{{ footerData.text3 }}</div>
              <div class="beian"><a href="https://beian.miit.gov.cn">ICP证：京ICP备16003915号-1</a></div>
            </el-col>
          </el-row>
        </div>
      </el-form-item>
      <el-form-item label="主办单位">
        <el-input
          type="textarea"
          resize="none"
          :rows="2"
          placeholder="文案输入，具体内容… …."
          v-model="footerData.text1"
          maxlength="20"
          show-word-limit>
        </el-input>
      </el-form-item>
      <el-form-item label="技术支持">
        <el-input
          type="textarea"
          :rows="2"
          resize="none"
          placeholder="文案输入，具体内容… …."
          v-model="footerData.text2"
          maxlength="38"
          show-word-limit>
        </el-input>
      </el-form-item>
      <el-form-item label="版权信息">
        <el-input
          type="textarea"
          :rows="2"
          resize="none"
          placeholder="文案输入，具体内容… …."
          v-model="footerData.text3"
          maxlength="40"
          show-word-limit>
        </el-input>
      </el-form-item>
      <el-form-item class="saveBtnBox" label="">
        <el-button type="success" round class="saveBtn" @click="saveBtnClick">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getConfigHpStyleFooter, updateConfigHpStyleFooter } from '@/api/massage.js'
export default {
  name: 'Footerinfo',
  data() {
    return {
      footerData: {
        id: 0,
        qrCode: '',
        recordInfo: '',
        style: 0,
        styleId: 0,
        text1: '',
        text2: '',
        text3: '',
        websiteId: 0
      }
    }
  },
  computed: {
    ...mapGetters(['indexData'])
  },
  mounted() {
    this.getConfigHpStyleFooter()
  },
  methods: {
    getConfigHpStyleFooter() {
      getConfigHpStyleFooter(this.indexData.websiteId).then((res) => {
        if (res.message === 'success') {
          this.footerData = res.data
        }
      })
    },
    saveBtnClick() {
      updateConfigHpStyleFooter(this.footerData).then((res) => {
        if (res.data) {
          this.$toast('保存成功')
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.footerInfo{
   padding: 30px;
    background: #fff;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width:7px;
    }
    /* 滚动槽 */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
      border-radius:10px;
    }
    /* 滚动条滑块 */
    &::-webkit-scrollbar-thumb {
      border-radius:10px;
      background:rgba(0,0,0,0.1);
      -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background:rgba(0,0,0,0.1);
    }
    .el-form-item__label{
      color: #333333;
    }
    .footerPreview{
      height: 124px;
      background: #2E2D2D;
      color: #797878;
      border-radius: 10px;
      padding-left: 20px;
      .name{
        text-align: left;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        margin-top: 31px;
      }
      .computyName{
        margin-top: 31px;
        text-align: left;
      }
      .beian{
        text-align: left;
        a {
          color: #797878;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    // 输入框
    /deep/.el-textarea__inner{
      max-width: 935px;
      height: 145px;
    }
    /deep/.el-textarea .el-input__count {
      right: unset ;
      left: 890px;
    }
    .saveBtnBox{
      margin-top: 39px;
      .saveBtn{
        width: 194px;
        height: 44px;
        background: #5096F5;
        border-color: #5096F5;
        line-height: 1;
      }
    }
}
</style>
