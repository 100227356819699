<template>
  <div class="studentManage">
    <div class="studentManageTop">
      <div class="studentNumver intBox">
        <div class="intName">学号</div>
        <el-input v-model="studentNumber" placeholder="请输入内容" class="numberInt int"></el-input>
      </div>
      <div class="studentRoll intBox">
        <div class="intName">学籍号</div>
        <el-input v-model="studentRoll" placeholder="请输入内容" class="rollInt int"></el-input>
      </div>
      <div class="userName intBox">
        <div class="intName">用户名</div>
        <el-input v-model="userName" placeholder="请输入内容" class="nameInt int"></el-input>
      </div>
      <div class="attestation intBox selBox">
        <div class="intName">是否认证</div>
        <el-select v-model="attestationValue" placeholder="请选择">
          <el-option
            v-for="item in attestations"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="sex intBox selBox">
        <div class="intName">性别</div>
        <el-select v-model="sexValue" placeholder="请选择">
          <el-option
            v-for="item in sexs"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="state intBox selBox">
        <div class="intName">状态</div>
        <el-select v-model="stateValue" placeholder="请选择">
          <el-option
            v-for="item in states"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="gtade intBox selBox">
        <div class="intName">年级</div>
        <el-select v-model="gtadeValue" placeholder="请选择">
          <el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="studentName intBox">
        <div class="intName">姓名</div>
        <el-input v-model="studentName" placeholder="请输入内容" class="NameInt int"></el-input>
      </div>
      <div class="telphone intBox">
        <div class="intName">手机号</div>
        <el-input v-model="telphone" placeholder="请输入内容" class="nameInt int"></el-input>
      </div>
      <div class="intBox">
        <div class="inquireBtn" @click="inquireClick">查询</div>
      </div>
    </div>
    <!-- 按钮组 -->
    <div class="btnBox" v-if="isSchoolPoint">
      <!-- <div class="btnItem" @click="addStudentClick">
        <img class="addIcon" src="images/icon/addIcon.png" alt=""> 添加学生
      </div> -->
      <el-button icon="el-icon-plus" round @click="addStudentClick" >添加学生</el-button>
      <!-- <div class="btnItem" @click="ipmortFile(treeData,'student')">
        批量导入
      </div> -->
      <el-button round @click="ipmortFile(treeData,'student')" >批量导入</el-button>

      <!-- <div class="btnItem" @click="exportStudentInfo">
        批量导出
      </div> -->
      <el-button round @click="exportStudentInfo" >批量导出</el-button>
      <!-- <div class="btnItem" @click="batchDelStudent">
        <img class="delIcon" src="images/icon/delIcon.png" alt=""> 批量删除
      </div> -->
      <el-button icon="el-icon-delete" round @click="batchDelStudent" >批量删除</el-button>
    </div>
    <!-- 表格 -->
    <div class="tableBox">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="40">
        </el-table-column>
        <el-table-column
          prop="fullName"
          label="姓名"
          width="80px"
        >
        </el-table-column>
        <el-table-column
          prop="stuNum"
          label="学号"
          width="100px"
        >
        </el-table-column>
        <el-table-column
          prop="userName"
          width="80px"
          label="用户名">
        </el-table-column>
        <el-table-column
          prop="sex"
          width="50px"
          label="性别">
        </el-table-column>
        <el-table-column
          prop="birthdayStr"
          width="100px"
          label="生日">
        </el-table-column>
        <el-table-column
          prop="school_number"
          width="100px"
          label="学籍号">
        </el-table-column>
        <el-table-column
          prop="grade_name"
          width="80px"
          label="年级">
        </el-table-column>
        <el-table-column
          prop="className"
          width="70px"
          label="班级">
        </el-table-column>
        <el-table-column
          prop="contactNum"
          width="120px"
          label="手机号">
        </el-table-column>
        <el-table-column
          prop="email"
          width="180px"
          label="邮箱">
        </el-table-column>
        <el-table-column
          prop="createTimeStr"
          width="100px"
          label="创建时间">
        </el-table-column>
        <el-table-column
          prop="operate"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
            <!-- <el-button @click="distributionClass(scope.row)" type="text" size="small">分配班级</el-button> -->
            <el-button @click="resetPassword(scope.row)" type="text" size="small">重置密码</el-button>
            <el-button @click="deleteClick(scope.row)" type="text" size="small" class="delTxt ">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pageBox">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="studentDataTotal"
        :page-size="10"
        :current-page.sync="currentPage"
        @current-change="changePageStudentTable">
      </el-pagination>
    </div>
    <!-- 添加学生 -->
    <div class="addStudentBox">
      <el-dialog :title="isShowSchoolChose?'编辑学生':'添加学生'" :visible.sync="addStudentVisible" @close="addStudentFalse">
        <el-form :model="addStudentList" >
          <el-form-item label="学校" v-show="isShowSchoolChose" :label-width="formLabelWidth">
            <el-select v-model="addStudentList.schoolId" filterable placeholder="请选择">
              <el-option
                v-for="item in schoolList"
                :key="item.schoolId"
                :label="item.schoolName"
                :value="item.schoolId">
              </el-option>
            </el-select>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="姓名" :label-width="formLabelWidth">
            <el-input v-model="addStudentList.fullName" autocomplete="off" ></el-input>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="性别" :label-width="formLabelWidth">
            <el-radio v-model="addStudentList.sex" label="1">男</el-radio>
            <el-radio v-model="addStudentList.sex" label="0">女</el-radio>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="学籍号" :label-width="formLabelWidth">
            <el-input v-model="addStudentList.school_number" autocomplete="off"></el-input>
            <span class="redstar">*</span>
          </el-form-item>
          <!-- <el-form-item label="学号" :label-width="formLabelWidth" >
            <el-input v-model="addStudentList.stuNum" autocomplete="off" ></el-input>
            <span class="redstar">*</span>
          </el-form-item> -->
          <el-form-item label="年级" :label-width="formLabelWidth">
            <el-select v-model="addStudentList.gradeId" placeholder="请选择">
              <el-option v-for="item in addStudentsGradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="手机号" :label-width="formLabelWidth">
            <el-input v-model="addStudentList.contactNum" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" :label-width="formLabelWidth">
            <el-input v-model="addStudentList.email" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="生日" :label-width="formLabelWidth">
            <!-- <el-input v-model="addStudentList.birthday" autocomplete="off"></el-input> -->
            <el-date-picker
              v-model="addStudentList.birthdayStr"
              type="date"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addStudentFalse()">取消</el-button>
          <el-button type="primary" @click="addStudentSure()" v-preventReClick>保存</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 编辑学生 -->
    <div class="addStudentBox">
      <el-dialog title="编辑学生" :visible.sync="editStudentVisible" @close="editStudentFalse">
        <el-form :model="editStudentList" >
          <el-form-item label="学校" :label-width="formLabelWidth">
            <el-select v-model="editStudentList.schoolId" filterable placeholder="请选择">
              <el-option
                v-for="item in schoolList"
                :key="item.schoolId"
                :label="item.schoolName"
                :value="item.schoolId">
              </el-option>
            </el-select>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="姓名" :label-width="formLabelWidth">
            <el-input v-model="editStudentList.fullName" autocomplete="off" ></el-input>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="性别" :label-width="formLabelWidth">
            <el-radio v-model="editStudentList.sex" label="1">男</el-radio>
            <el-radio v-model="editStudentList.sex" label="0">女</el-radio>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="学号" :label-width="formLabelWidth" >
            <el-input v-model="editStudentList.stuNum" autocomplete="off" class="readonlyTrue" readonly></el-input>
          </el-form-item>
          <el-form-item label="学籍号" :label-width="formLabelWidth">
            <el-input v-model="editStudentList.school_number" autocomplete="off" class="readonlyTrue" readonly></el-input>
          </el-form-item>
          <el-form-item label="年级" :label-width="formLabelWidth">
            <el-select v-model="editStudentList.gradeId" placeholder="请选择">
              <el-option v-for="item in addStudentsGradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <span class="redstar">*</span>
          </el-form-item>
          <el-form-item label="手机号" :label-width="formLabelWidth">
            <el-input v-model="editStudentList.contactNum" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" :label-width="formLabelWidth">
            <el-input v-model="editStudentList.email" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="生日" :label-width="formLabelWidth">
            <!-- <el-input v-model="editStudentList.birthday" autocomplete="off"></el-input> -->
            <el-date-picker
              v-model="editStudentList.birthdayStr"
              type="date"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editStudentFalse()">取消</el-button>
          <el-button type="primary" @click="editStudentSure()" v-preventReClick>保存</el-button>
        </div>
      </el-dialog>
    </div>
    <!--分配学校-->
    <el-dialog
      title="分配学校"
      :visible.sync="isChoseClass"
      width="650px"
      height="500px"
      class="choseschool"
      :before-close="handleClose">
      <div class="choseschoolbox">
        <div class="itembox" v-for="(item,index) in schoolList" :key="index">
          <el-checkbox true-label="1" false-label="0" :checked="item.ckeck === 1 || item.ckeck === -1" :disabled="item.ckeck === -1" @change="choseClass(item)">{{ item.schoolName }}</el-checkbox>
        </div>

      </div>
    </el-dialog>
    <!--导入-->
    <import-file v-if="isImportTeacher" :isImport="isImportTeacher" :importData="importData" :importStyle="importStyle" @closeFile="closeFile"></import-file>
  </div>
</template>
<script>
import ImportFile from '@/components/import/importFile.vue'
import { getStudent, saveStudentInfo, getGradeList, delStudent, batchDelStudent, batchExportStudentInfo, editStudent, getSchoolInfo, updateStudentInfo, resetStudentPassword } from '@/api/teacherManage.js'
import { mapGetters } from 'vuex'
// import { baseUrl } from '@/axios/setBaseurl.js'
export default {
  components: {
    ImportFile
  },
  data() {
    return {
      currentPage: 1,
      studentNumber: '', // 学号
      studentRoll: '', // 学籍号
      userName: '', // 用户名
      attestationValue: '', // 是否认证
      sexValue: '', // 性别
      stateValue: '1', // 状态
      gtadeValue: '', // 年级
      studentName: '', // 姓名
      telphone: '', // 手机号
      isImportTeacher: false, // 导入弹窗
      isSchoolPoint: false, // 是否为学校节点
      importStyle: {
        importType: 'teacher', // 导入的类型 teacher 教师 company 教职工 student 学生
        title: ''
      },
      importData: {}, // 导入的参数
      attestations: [{
        value: '',
        label: '全部'
      }, {
        value: '0',
        label: '是'
      }, {
        value: '1',
        label: '否'
      }],
      sexs: [{
        value: '',
        label: '全部'
      }, {
        value: '1',
        label: '男'
      }, {
        value: '0',
        label: '女'
      }],
      states: [{
        value: '',
        label: '全部'
      }, {
        value: '1',
        label: '可用'
      }, {
        value: '0',
        label: '不可用'
      }],
      tableData: [],
      schoolList: [],
      gradeList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      addStudentsGradeList: [], // 添加学生时的年级列表  没有全部选项
      studentDataTotal: 0, // 学生data总数
      addStudentVisible: false, // 添加学生
      editStudentVisible: false, // 编辑学生
      isShowSchoolChose: false, // true:编辑  false:添加
      isReadonly: false, // 是否只读  学号，学籍号
      formLabelWidth: '90px',
      schoolId: null,
      multipleSelection: [], // 选中状态集合
      batchDelStudentIds: '', // 批量删除学生id集合
      isChoseClass: false, // 分配学校
      copyImgSrc: '/images/icon/copy.png',
      addStudentList: {
        fullName: '',
        sex: '1',
        school_number: '',
        gradeId: '',
        contactNum: '',
        email: '',
        birthdayStr: '',
        schoolId: ''
      },
      edit_id: '', // 保存编辑时 所需id
      editStudentList: { // 编辑学生数据
        fullName: '',
        sex: '1',
        school_number: '',
        gradeId: '',
        contactNum: '',
        email: '',
        birthday: '',
        schoolId: ''
      }
    }
  },
  watch: {
    treeData: {
      handler(newVal) {
        if (this.treeData.type !== 'company') {
          this.getStudentData(1)
        } else {
          this.$toast('请选择省、市、区或学校查看！')
          return false
        }
        if (this.treeData.type && this.treeData.type === 'school') {
          this.isSchoolPoint = true
          this.schoolId = this.treeData.schoolId
          console.log('schoolId', this.schoolId)
        } else {
          this.isSchoolPoint = false
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['treeData', 'indexData'])
  },
  mounted() {
    this.getStudentData(1)
    this.getGradeData()
    this.getSchoolList()
    console.log('date', new Date())
  },
  methods: {
    getSchoolList() {
      const data = {
        provinceId: this.treeData.provinceId,
        cityId: this.treeData.cityId || null,
        areaId: this.treeData.areaId || null
      }
      getSchoolInfo(data).then((res) => {
        console.log('res', res)
        if (res.message === 'success') {
          this.schoolList = res.data
        }
      })
    },
    getGradeData() {
      const data = {
        status: 1
      }
      getGradeList(data).then((res) => {
        if (res.message === 'success') {
          this.addStudentsGradeList = JSON.parse(JSON.stringify(res.data))
          this.gradeList = res.data
          console.log('jlsjdlsf', this.addStudentsGradeList)
          const all = {
            name: '全部',
            id: ''
          }
          this.gradeList.splice(0, 0, all)
        }
      })
    },
    getStudentData(pageNo) {
      var params = {
        pageNo: pageNo,
        pageSize: '10',
        stuNum: this.studentNumber || null,
        school_number: this.studentRoll || null,
        userName: this.userName || null,
        auth_state: this.attestationValue || null,
        sex: this.sexValue || null,
        status: this.stateValue || null,
        gradeId: this.gtadeValue || null,
        fullName: this.studentName || null,
        contactNum: this.telphone || null,
        schoolId: this.treeData.schoolId || null,
        provinceId: this.treeData.provinceId,
        cityId: this.treeData.cityId || null,
        areaId: this.treeData.areaId || null
      }
      getStudent(params).then((res) => {
        if (res.message === 'success') {
          this.studentDataTotal = res.data.total
          for (let i = 0; i < res.data.rows.length; i++) {
            const result = res.data.rows[i]
            if (result.sex === 1) {
              result.sex = '男'
            } else {
              result.sex = '女'
            }
            if (result.birthday) {
              const time = new Date(result.birthday)
              result.birthday = time.getFullYear() + '-' + time.getMonth() + '-' + time.getDate()
            }
          }
          this.tableData = res.data.rows
          this.currentPage = res.data.pageNo
        }
      })
    },
    // 删除
    deleteClick(row) {
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        roundButton: true
      }).then(() => {
        const data = { id: row.id }
        delStudent(data).then((res) => {
          if (res.message === 'success') {
            this.getStudentData(1)
          }
        })
      }).catch(() => {
      })
    },
    // 打开导入
    ipmortFile(row, type) {
      if (this.schoolId === null) {
        this.$toast('请先选择学校再进行操作！')
        return false
      }
      this.importStyle = {
        importType: type
      }
      if (type === 'teacher') {
        this.importStyle.title = '导入教师'
      } else if (type === 'company') {
        this.importStyle.title = '导入教职工'
      } else if (type === 'student') {
        this.importStyle.title = '导入学生'
      }
      this.importData = row
      this.isImportTeacher = true
    },
    // 关闭导入
    closeFile(res) {
      this.isImportTeacher = res
      this.getStudentData(1)
    },
    handleClose() {

    },
    // 选中row
    handleSelectionChange(val) {
      this.batchDelStudentIds = ''
      for (let i = 0; i < val.length; i++) {
        if (i === 0) {
          this.batchDelStudentIds += val[i].id
        } else {
          this.batchDelStudentIds += ',' + val[i].id
        }
      }
      console.log(this.batchDelStudentIds, 'kkkk')
    },
    // 批量删除学生
    batchDelStudent() {
      if (this.batchDelStudentIds === '') {
        this.$toast('请选择要批量删除的数据')
        return
      }
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        roundButton: true
      }).then(() => {
        const data = { ids: this.batchDelStudentIds }
        const url = '/axt-crm/studentManage/batchDelStudent?ids=' + this.batchDelStudentIds
        batchDelStudent(url, data).then((res) => {
          if (res.message === '删除成功') {
            this.getStudentData(1)
          }
        })
      }).catch(() => {
      })
    },
    // 批量导出学生信息
    exportStudentInfo() {
      if (this.schoolId === null) {
        this.$toast('请先选择一所学校再导出')
        return
      }
      var text = ''
      if (this.batchDelStudentIds === '') {
        text = '确定要导出本学校所有学生信息么?'
      } else {
        text = '确定要导出所选学生的信息么?'
      }
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        roundButton: true
      }).then(() => {
        const data = { schoolId: this.schoolId }
        if (this.batchDelStudentIds === '') { // 未选择
          data.stuNum = this.studentNumber || null
          data.school_number = this.studentRoll || null
          data.userName = this.userName || null
          data.auth_state = this.attestationValue || null
          data.sex = this.sexValue || null
          data.status = this.stateValue || null
          data.gradeId = this.gtadeValue || null
          data.fullName = this.studentName || null
          data.contactNum = this.telphone || null
          data.schoolId = this.treeData.schoolId || null
          data.provinceId = this.treeData.provinceId
          data.cityId = this.treeData.cityId || null
          data.areaId = this.treeData.areaId || null
        } else {
          data.ids = this.batchDelStudentIds
        }
        batchExportStudentInfo(data, '学生列表')
      }).catch(() => {

      })
    },
    // 查询
    inquireClick() {
      if (!Number(this.studentNumber) && this.studentNumber !== '') {
        this.studentNumber = ''
        this.$toast('请输入正确的学号')
        return
      }
      this.getStudentData(1)
    },
    // 分页
    changePageStudentTable(pageNo) {
      this.getStudentData(pageNo)
    },
    addStudentClick() {
      if (this.schoolId === null) {
        this.$toast('请先选择一所学校再添加学生')
        return
      }
      this.addStudentVisible = true
      this.isShowSchoolChose = false
    },
    // 编辑
    edit(row) {
      const url = '/axt-crm/studentManage/getStudentInfo?student_id=' + row.id
      this.edit_id = row.id
      editStudent(url).then((res) => {
        console.log('编辑学生', res)
        if (res.message === 'success') {
          if (res.data.sex === 1) {
            res.data.sex = '1'
          } else {
            res.data.sex = '0'
          }
          this.editStudentList = res.data
          console.log('jlsjdlsf', this.editStudentList)
        }
      })
      this.editStudentVisible = true
    },
    // 保存编辑
    editStudentSure() {
      console.log(typeof (this.editStudentList.birthday))
      const data = {
        id: this.editStudentList.id,
        schoolId: this.editStudentList.schoolId,
        fullName: this.editStudentList.fullName,
        sex: this.editStudentList.sex,
        gradeId: this.editStudentList.gradeId,
        contactNum: this.editStudentList.contactNum,
        email: this.editStudentList.email,
        birthdayStr: this.editStudentList.birthdayStr,
        // birthday: this.editStudentList.birthday.toString().substring(0, 10),
        school_number: this.editStudentList.school_number
      }
      updateStudentInfo(data).then((res) => {
        if (res.message === 'success') {
          this.$toast('编辑成功')
          this.editStudentVisible = false
          this.getStudentData(1)
        } else {
          this.$toast(res.message)
        }
      })
    },
    // 分配班级
    distributionClass(res) {
      this.isChoseClass = true
    },
    // 重置密码
    resetPassword(res) {
      this.$confirm('是否重置密码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        roundButton: true
      }).then(() => {
        const data = {
          id: res.id
        }
        resetStudentPassword(data).then((obj) => {
          if (obj.message === 'success') {
            this.showNewPassword(obj.data)
          }
        })
      }).catch(() => {
      })
    },
    // 展示新密码
    showNewPassword(res) {
      const html = '<span class="passwordHtml" style="display:inline;margin-right:10px">' + res + '</span>'
      // const html = '<span class="passwordHtml" style="display:inline;margin-right:10px">' + res + '</span><span><img @click="copyPassword" style="width:13px;height:13px;" class="copyImg hover" src="' + this.copyImgSrc + '"></span>'
      this.$alert(html, '提示', {
        dangerouslyUseHTMLString: true,
        roundButton: true,
        type: 'success'
      })
    },
    addStudentSure() {
      console.log(this.addStudentList)
      if (this.addStudentList.fullName === '' || this.addStudentList.sex === '' || this.addStudentList.school_number === '' || this.addStudentList.gradeId === '') {
        this.$toast('请将信息填写完整')
        return
      }
      this.addStudentList.schoolId = this.treeData.schoolId
      this.saveStudentInfo()
      console.log('jjj', this.addStudentList)
    },
    copyPassword() {
      console.log(123213)
    },
    saveStudentInfo() {
      saveStudentInfo(this.addStudentList).then((res) => {
        console.log('添加学生', res)
        if (res.success) {
          this.$toast('保存成功')
          this.addStudentVisible = false
          this.getStudentData(1)
          this.addStudentList = {
            fullName: '',
            sex: '1',
            school_number: '',
            gradeId: '',
            contactNum: '',
            email: '',
            birthdayStr: '',
            schoolId: ''
          }
        } else {
          this.$toast(res.message)
        }
      })
    },
    addStudentFalse() {
      this.addStudentVisible = false
      this.isShowSchoolChose = false
      this.isReadonly = false
    },
    editStudentFalse() {
      this.editStudentVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
.studentManage {
    width: 100%;
    height: 100%;
    .studentManageTop {
        width: 100%;
        overflow: hidden;
        .intBox {
            float: left;
            margin-right: 32px;
            margin-bottom: 15px;
            // display: flex;
            // flex-direction: row;
            .intName {
                min-width: 30px;
                display: inline;
                font-size: 14px;
                color: #666666;
                padding-right: 10px;
            }
            .int {
                display: inline;
            }
            /deep/.el-input__inner {
                width: 224px;
                height: 34px;
                border-radius: 4px;
                // border: 1px solid #CCCCCC;
                font-size: 14px;
                color: #666666;
            }
            .inquireBtn {
                width: 56px;
                height: 34px;
                background: #5096F5;
                border-radius: 4px;
                font-size: 14px;
                color: #ffffff;
                text-align: center;
                line-height: 34px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .selBox {
            /deep/.el-input__inner {
                width: 123px;
                height: 34px;
                border-radius: 4px;
                font-size: 14px;
                color: #666666;
            }
            /deep/.el-input__icon {
                line-height: 34px;
            }
        }
    }
    .btnBox {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        /deep/.el-button {
          margin-right: 15px;
          padding:9px 23px;
          &.is-round {
            border: 1px solid #5096F5;
            color: #5096F5;
          }
        }
        .btnItem {
            width: 107px;
            height: 30px;
            border-radius: 17px;
            border: 1px solid #5096F5;
            line-height: 30px;
            color: #5096F5;
            font-size: 14px;
            margin-right: 22px;
            .addIcon {
                width: 12px;
                height: 12px;
            }
            .delIcon {
                width: 13px;
                height: 13px;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
    .tableBox {
        margin-top: 20px;
        max-height: calc(100% - 280px);
        overflow-y: auto;
        /deep/.el-table--enable-row-transition .el-table__body td{
            text-align: center;
            font-size: 14px;
            color: #666666;
            font-weight: 500;
        }
        /deep/.el-table th, .el-table tr{
            background: #F2F2F2;
            // border-radius: 4px 4px 0px 0px;
            text-align: center;
            font-size: 14px;
            color: #666666;
            font-weight: 500;
        }
        /deep/.el-table td, .el-table th {
          padding: 5px 0;
        }
        .delTxt{
          color:#F56C6C;
        }
    }
    .pageBox {
        margin-top: 20px;
        /deep/.el-pagination{
            text-align: right;
        }
    }
    // 添加学生弹窗
    .addStudentBox {
        /deep/.el-dialog {
            width: 650px;
            // height: 518px;
            .el-dialog__header {
                padding: 20px 40px 10px;
                text-align: left;
                font-weight: 600;
                color: #31364D;
                font-size: 16px;
                border-bottom: 1px solid #F2F2F2;
            }
            .el-dialog__body {
                padding: 20px;
                .redstar {
                    color: red;
                    font-size: 14px;
                    float: right;
                }
            }
            .el-form-item {
                margin-bottom: 15px;
                text-align: left;
                .el-form-item__label {
                    text-align: left;
                    width: 90px;
                    color: #333333;
                    padding-left: 20px;
                    line-height: 34px;
                }
                .el-input__icon {
                    line-height: 34px;
                }
                .el-form-item__content {
                    width: 340px;
                    line-height: 34px;
                    .el-input {
                        width: 327px;
                        .el-input__inner {
                            height: 34px;
                            line-height: 34px;
                        }
                    }
                    .readonlyTrue {
                        .el-input__inner {
                            background: #F2F2F2!important;
                            color: #999999;
                            border: none;
                        }
                    }
                }
            }
            .el-dialog__footer {
                text-align: center;
                .el-button {
                    width: 60px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    padding: 0;
                    border-radius: 15px;
                }
            }
        }
    }
    // 分配班级 弹窗
    .choseschool {
     /deep/.el-dialog__header {
      text-align: left;
      padding: 20px 40px 10px;
      border-bottom: 1px solid #F2F2F2;
    }
    /deep/.el-dialog__body {
      padding: 15px 40px 50px;
      .choseschoolbox {
        width: 570px;
        height: 373px;
        box-sizing: border-box;
        padding: 15px 20px;
        border: 1px solid #F2F2F2;
        overflow-y: auto;
        // display: flex;
        // flex-wrap: wrap;
        &::-webkit-scrollbar {
          width:7px;
          height: 7px;
        }
        /* 滚动槽 */
        &::-webkit-scrollbar-track {
          -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.2);
          border-radius:10px;
        }
        /* 滚动条滑块 */
        &::-webkit-scrollbar-thumb {
          border-radius:10px;
          background:rgba(0,0,0,0.2);
          -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.2);
        }
        &::-webkit-scrollbar-thumb:window-inactive {
          background:rgba(0,0,0,0.2);
        }
        .itembox {
          float: left;
          padding-right: 10px;
          width: 50%;
          line-height: 30px;
          text-align: left;
          .el-checkbox {
            white-space: normal;
          }
          .el-checkbox__label {
            display: inline;
          }
          .el-checkbox__input.is-checked+.el-checkbox__label {
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
