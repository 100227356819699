<template>
  <div class="classifybox">
    <div class="classifyManage_cont">
      <el-table :data="tableData" style="width: 100%" :header-cell-style="{background:'#f2f2f2'}">
        <el-table-column prop="name" label="分类名称" ></el-table-column>
        <el-table-column fixed="right" label="操作" >
          <template slot-scope="scope">
            <img class="editImg hover" :src="scope.row.state === 1?showImg_P:showImg_N" @click="showClick(scope.row)">
            <img class="editImg hover" :src="scope.row.websiteId === 0 && scope.row.websiteId !== indexData.websiteId?delImg_N:delImg_P" @click="scope.row.websiteId !== 0 && scope.row.websiteId === indexData.websiteId && deleteClick(scope.$index,scope.row)">
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="add_btn">
      <el-button icon="el-icon-plus" round @click="open">添加分类</el-button>
    </div>
    <add-classify v-if="showMessage" :dialogVisible="showMessage" @submit="submit"></add-classify>
  </div>

</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { getAxtNewClassifyList, deleteAxtNewClassifyByManager } from '@/api/list.js'
import addClassify from '../messageBox/addClassify.vue'
export default {
  components: { addClassify },
  name: 'ClassifyManage',
  data() {
    return {
      tableData: [],
      showImg_N: '/images/myNews/hide.png',
      showImg_P: '/images/myNews/show.png',
      delImg_N: '/images/myNews/delete_n.png',
      delImg_P: '/images/myNews/delete_p.png',
      showMessage: false,
      addTitle: ''

    }
  },
  computed: {
    ...mapGetters(['indexData'])
  },
  watch: {
    indexData: {
      handler(newVal) {
        this.getList()
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {

  },
  methods: {
    ...mapMutations(['SET_NEWSTYPE']),
    getList() {
      const data = {
        website_id: this.indexData.websiteId
      }
      getAxtNewClassifyList(data).then(res => {
        // console.log(res, 'myinfo')
        if (res.message === 'success') {
          this.tableData = res.data.rows
          this.SET_NEWSTYPE(this.tableData)
        }
      })
    },
    showClick(res) {
      // console.log('测试', res)
    },
    deleteClick(index, row) {
      this.$confirm('即将删除该新闻分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          website_id: this.indexData.websiteId,
          id: row.id
        }
        deleteAxtNewClassifyByManager(params).then((res) => {
          if (res.message === 'success') {
            this.tableData.splice(index, 1)
            this.$message({
              type: 'success',
              message: '删除成功!',
              center: true
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
          center: true
        })
      })
    },
    open() {
      this.showMessage = true
    },
    submit(res) {
      console.log(res, 'ddddd')
      if (res.changeState) {
        this.getList()
      }
      this.showMessage = false
    }
  }
}
</script>
<style lang="less" scoped>
.classifyManage_cont{
    padding: 0 30px;
    position: relative;
    max-height: calc(100% - 30px);
    padding-top: 30px;
    overflow: auto;
}

.classifybox {
  /deep/.el-table{
    border-radius: 10px 10px 0 0;
    table {
      width: 100%;
    }
    th>.cell{
      font-size: 16px;
      color: #666666;
    }
    thead{
      background: #F2F2F2;
    }
    & tr td:first-of-type,
    & tr th:first-of-type{
      padding-left: 30px;
    }
    & th,
    & td{
      padding-left: 10px;
    }
    & tr td:first-of-type,
    & tr th:first-of-type{
      padding-left: 30px;
      padding-right: 20px;
    }
  }
}
.classifybox {
  height: 100%;
}
.editImg{
    width: 18px;
    height: 12px;
    margin-right: 15px;
}
.add_btn{
    width: 96%;
    margin: 20px auto;
    text-align: left;
}
</style>
