import { axios } from '@/axios/request'
// 修改平台信息
export function updataPlatformData(parameter) {
  return axios({
    url: '/axt-website/hpconfig/updateConfigHpStyle',
    data: parameter,
    method: 'post'
  })
}
// 获取配置-header导航内链
export function getConfigHpStyleHeaderNavLink(parameter) {
  return axios({
    url: '/axt-website/hpconfig/getConfigHpStyleHeaderNavLink',
    data: parameter,
    method: 'get'
  })
}
// 添加header 导航
export function updateConfigAddHpStyleHeaderNav(parameter) {
  return axios({
    url: '/axt-website/hpconfig/updateConfigAddHpStyleHeaderNav',
    data: parameter,
    method: 'post'
  })
}
// 修改header 导航
export function updateConfigUpdateHpStyleHeaderNav(parameter) {
  return axios({
    url: '/axt-website/hpconfig/updateConfigUpdateHpStyleHeaderNav',
    data: parameter,
    method: 'post'
  })
}
// 删除header 导航
export function deleteConfigUpdateHpStyleHeaderNav(parameter) {
  return axios({
    url: '/axt-website/hpconfig/deleteConfigUpdateHpStyleHeaderNav',
    data: parameter,
    method: 'post'
  })
}
// 修改header icon
export function updateConfigHpStyleHeader(parameter) {
  return axios({
    url: '/axt-website/hpconfig/updateConfigHpStyleHeader',
    data: parameter,
    method: 'post'
  })
}
// 获取配置-footer
export function getConfigHpStyleFooter(parameter) {
  return axios({
    url: '/axt-website/hpconfig/getConfigHpStyleFooter?website_id=' + parameter,
    method: 'get'
  })
}
// 修改配置-footer
export function updateConfigHpStyleFooter(parameter) {
  return axios({
    url: '/axt-website/hpconfig/updateConfigHpStyleFooter',
    data: parameter,
    method: 'post'
  })
}
