<template>
  <div class="micro">
    <div id="content">
      <Row :gutter="30">
        <Col span="14">
          <div class="headline font24">
            <span>爱学堂微课</span>
            <a class="more font14" href="javascript:;">更多<img style="height: 12px;margin-left: 2px;position: relative;top: 1px;" src="/images/index/more.png" alt=""></a>
          </div>
          <div class="microbox">
            <Row :gutter="21">
              <Col span="8" v-for="item in microList" :key="item.id">
                <div class="microcon pointer" :title="item.name">
                  <div class="imgbox"><img :src="$futureImgUrl +'/'+ item.imgurl" alt=""></div>
                  <!-- <div class="imgbox"><img :src="imgurl+ item.imgurl" alt=""></div> -->
                  <div class="text">{{ item.name }}</div>
                </div>
              </Col>
            </Row>

          </div>
        </Col>

        <Col span="10">
          <div class="headline font24">
            <span>资源中心</span>
            <a class="more font14" href="javascript:;">更多<img style="height: 12px;margin-left: 2px;position: relative;top: 1px;" src="/images/index/more.png" alt=""></a>
          </div>
          <div class="resbox">
            <div class="restop">
              <img src="/images/index/restop.png" alt="">
            </div>
            <div class="resbtm">
              <div class="tab">
                <Row>
                  <Col span="8"><div :class="tabactive === 1? 'tabitem active':'tabitem'" @click="choseTab(1)">课件</div></Col>
                  <Col span="8"><div :class="tabactive === 2? 'tabitem active':'tabitem'" @click="choseTab(2)">学案</div></Col>
                  <Col span="8"><div :class="tabactive === 3? 'tabitem active':'tabitem'" @click="choseTab(3)">试卷</div></Col>
                </Row>
              </div>
              <div class="tabbox" v-show="tabactive === 1">
                <div class="tabcon pointer" v-for="(ppt,index) in pptList" :key="index" :title="ppt.name">
                  <div class="fl tabcontext font16" @click="view(ppt)">
                    <img src="/images/index/ppt_icon.png" alt="">
                    {{ ppt.name }}</div>
                  <div class="fr tabcontime font14">{{ ppt.createTime }}</div>
                </div>
              </div>
              <div class="tabbox" v-show="tabactive === 2">
                <div class="tabcon pointer" v-for="(casetext,index) in caseList" :key="index" :title="casetext.name">
                  <div class="fl tabcontext font16" @click="view(casetext)">
                    <img src="/images/index/docImg.png" alt="">
                    {{ casetext.name }}
                  </div>
                  <div class="fr tabcontime font14">{{ casetext.createTime }}</div>
                </div>
              </div>
              <div class="tabbox" v-show="tabactive === 3">
                <div class="tabcon pointer" v-for="(testPaper,index) in testPaperList" :key="index" :title="testPaper.handoutTitle">
                  <div class="fl tabcontext font16" @click="view(testPaper)">
                    <img src="/images/index/docImg.png" alt="">
                    {{ testPaper.handoutTitle }}
                  </div>
                  <div class="fr tabcontime font14">{{ testPaper.handoutCreateTime }}</div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import { pageKnowledgeVideosByCondition, pageKnowledgePPTsByCondition, pageKnowledgeLearningPlansByCondition, pageHandoutList } from '@/api/editIndex.js'
export default {
  name: 'Micro',
  data() {
    return {
      tabactive: 1,
      msg: 'tab1',
      microList: null, // 微课列表  array
      pptList: null, // 课件    列表
      caseList: null, // 学案
      testPaperList: null, // 试卷
      isShowMessage: false,
      shareSchoolId: null,
      teacherId: null,
      userType: null,
      siteResUrl: null,
      imgurl: 'https://qcban.aixuetang.com/res/' // 微课图片地址  线上域名
    }
  },
  created() {

  },
  mounted() {
    this.getinfo()
  },
  methods: {
    choseTab(name) {
      this.tabactive = name
    },
    getinfo() {
      const videoData = {
        pageSize: 10,
        pageNo: 1,
        gradeIds: null,
        subjectId: null,
        versionId: null,
        distinct_videoId: 1
      }
      // 获取video
      pageKnowledgeVideosByCondition(videoData).then((res) => {
        // console.log(res, 'video')
        if (res.message === 'success') {
          this.microList = res.data.rows.slice(0, 6)
        }
      })
      // 获取ppt
      const pptData = {
        pageSize: 20,
        pageNo: 1
      }
      pageKnowledgePPTsByCondition(pptData).then((res) => {
        // console.log(res, 'ppt')
        if (res.message === 'success') {
          this.pptList = res.data.rows.slice(0, 6)
        }
      })
      // 获取课件

      pageKnowledgeLearningPlansByCondition(pptData).then((res) => {
        // console.log(res, 'kj')
        if (res.message === 'success') {
          this.caseList = res.data.rows.slice(0, 6)
        }
      })
      // 获取试卷
      const paperparams = {
        pageSize: 20,
        pageNo: 1,
        orderBys: [
          {
            field: 'handout_create_time',
            direction: 'desc'
          }
        ]
      }
      pageHandoutList(paperparams).then((res) => {
        // console.log(res, 'paper')
        if (res.message === 'success') {
          this.testPaperList = res.data.rows.slice(0, 6)
        }
      })
    }
  },
  components: {}
}
</script>

<style lang="less" scoped="scoped">
  .micro {
    padding: 60px 0 0px;
  }
  .headline {
    text-align: left;
    line-height: 1.8;
    font-size: 18px;
    border-bottom: 2px solid #3583FF;
    position: relative;
    font-weight: 500;
    .more {
      color: #3583FF;
      float: right;
      position: relative;
      top: 2px;
      font-size: 13px;
      cursor: pointer;
    }
  }
  .microbox, .resbox {
    margin-top: 35px;
  }
  .microcon {
    margin-bottom: 30px;
    border: 1px solid #E7E7E7;
    border-radius: 14px;
    overflow: hidden;
    transition: all .5s;
    .imgbox {
      height: 76px;
      position: relative;
      overflow: hidden;
      img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        min-width: 100%;
        min-height: 100%;
        max-width: 150%;
        max-height: 150%;
      }
    }
    .text {
      padding: 10px 6%;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #34495E;
      text-align: left;
      font-size: 14px;
    }
  }
  .restop{
    height: 60px;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    img{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
    }
  }
  .resbtm {
    margin-top: 10px;
    border-radius: 14px;
    border: 1px solid #E7E7E7;
    overflow: hidden;
    .tab {
      background: #F4F4F4;
      height: 40px;

      line-height: 40px;
      .tabitem {
        color: #34495E;
        font-size: 16px;
        cursor: pointer;
        &.active {
          color: #FFFFFF;
          background-color:#3583FF ;
        }
      }
    }
  }
  .tabbox {
    height: 155px;
    padding: 0 20px;
    overflow: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar{
      width:10px;
      height:10px;
      /**/
    }
    &::-webkit-scrollbar-track{
      background: rgb(239, 239, 239);
      border-radius:2px;
    }
    &::-webkit-scrollbar-thumb{
      background: #bfbfbf;
      border-radius:10px;
    }
    &::-webkit-scrollbar-corner{
      background: #179a16;
    }
  }
  .tabcon {
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #E7E7E7;
    &:last-of-type {
      border-bottom: none;
    }
    .tabcontext {
      width: 66%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
      color: #34495E;
      font-weight: 500;
      img {
        height: 38px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    .tabcontime {
      width: 30%;
      color:#9A9A9A ;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:hover .tabcontext {
      color: #3583FF;
    }
  }
  @media screen and (min-width: 1400px) {
    .microcon {
      .imgbox {
        height: 100px;
      }
      .text {
        font-size: 16px;
      }
    }
    .tabbox {
      height: 208px;
    }
  }
  @media screen and (min-width: 1920px) {
    .microcon {
      .imgbox {
        height: 120px;
      }
      .text {
        font-size: 16px;
      }
    }
    .tabbox {
      height: 248px;
    }
  }
</style>
