// https://jsapi.aixuetang.com/axt-future/knowledgeLearningPlan/pageKnowledgeLearningPlansByCondition?pageSize=20&pageNo=1
import { axios } from '@/axios/request'
// 获取新闻分类
export function upload(parameter, params) {
  return axios({
    url: '/axt-resources/upload/ueditorUpload',
    params: params,
    data: parameter,
    method: 'post'
  })
}
