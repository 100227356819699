<template>
  <div class="white">
    <div v-if="newsData.titleShow == 1 && newsData.title">
      <div class="title">{{ newsData.title }}</div>
      <div class="titlebtm"></div>
    </div>
    <div class="newsclassify" ref="scroll">
      <Row :gutter="30">
        <Col span="6" v-for="(news,index) in newsList" :key="index">
          <div class="headline font24">
            <span>{{ news.title }}</span>
            <span class="more font14" v-if="newsData.moreShow == 1">更多<img style="height: 12px;margin-left: 2px;position: relative;top: 1px;" src="/images/index/more.png" alt=""></span>
          </div>
          <!-- <div class="newslist">
            <Row>
              <Col span="24" class="newsltem pointer" v-for="(item,i) in dataobj[news.id]" :key="i">
                <p :title="item.firstTitle">
                  <a href="javascript:;">{{ item.firstTitle }}</a>
                </p>
              </Col>
            </Row>
          </div> -->
          <news-item :item="news"></news-item>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import { getAxtNewSourceList } from '@/api/editIndex.js'
import NewsItem from '@/components/indexContent/NewsItem1.vue'
export default {
  name: 'EduNewsOne',
  props: {
    newsData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      newsList: [],
      dataobj: {}
    }
  },
  components: {
    NewsItem
  },
  watch: {
    newsData: {
      handler(newval) {
        this.newsList = this.newsData.news
        console.log(newval, 'kwwwwwwwwwwwwww')
        // this.getAxtNewSourceList()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['indexData'])
  },
  mounted() {
    this.newsList = this.newsData.news
    // this.getAxtNewSourceList()
    // this.$toast('你好')
  },
  methods: {

  }
}
</script>

<style lang="less" scoped="scoped">
  .white {
    background: #fff;
    padding: 0 10px;
  }
  .newsclassify {
    padding: 20px 0;
  }
  .headline {
    font-size: 18px;
    text-align: left;
    line-height: 1.8;
    height: 39px;
    padding-bottom: 3px;
    border-bottom: 4px solid #3583FF;
    position: relative;
    .more {
      color: #3583FF;
      float: right;
      position: relative;
      top: 8px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .newslist {
    margin-top: 15px;
  }
  .newsltem {
    width: 100%;
    height: 68px;
    background: #F8F9FB;
    border-radius: 10px;
    display: flex;
    line-height: 1.6;
    padding: 0 22px;
    text-align: left;
    margin-bottom:13px ;
    align-items: center;
    p {
      overflow:hidden;
      text-overflow:ellipsis;
      display:-webkit-box;
      -webkit-line-clamp:2;
      -webkit-box-orient:vertical;
      a {
        color: #555;
        display: block;
      }
    }
    &:hover {
      background-color: #E8F1FF;
      p a {
        color: #3583FF;
      }
    }
  }
</style>
