<template>
  <div class="articlebox">
    <div class="classifyManage_cont" ref="myHeight">
      <div class="seachbox clearfix">
        <div class="inputbox">
          <el-input
            placeholder="搜索关键字"
            prefix-icon="el-icon-search"
            v-model="input2"
            @change="searchlist">
          </el-input>
        </div>
      </div>
      <div class="tablecontent">
        <el-table :data="tableData" v-loading="loading" style="width: 100%" :header-cell-style="{background:'#f2f2f2'}" @row-click="(row)=>showdetail(row)">
          <el-table-column prop="appLogo" show-overflow-tooltip min-width="150" label="应用图标">
            <template slot-scope="scope">
              <img class="tximg" :src="$futureImgUrl + scope.row.appLogo" alt="">
            </template>
          </el-table-column>
          <el-table-column prop="appName" show-overflow-tooltip min-width="100" label="应用名称" ></el-table-column>
          <el-table-column prop="appShortName" show-overflow-tooltip min-width="100" label="简称" ></el-table-column>
          <el-table-column prop="createTime" show-overflow-tooltip min-width="120" label="提交时间" ></el-table-column>
          <el-table-column prop="state" show-overflow-tooltip min-width="80" label="审核状态" >
            <template slot-scope="scope">
              <span v-if="scope.row.state === 0" class="c0"><img src="/images/icon/c0.png" alt="">待审</span>
              <span v-if="scope.row.state === 1" class="c1"><img src="/images/icon/c1.png" alt="">通过</span>
              <span v-if="scope.row.state === 2" class="c2"><img src="/images/icon/c2.png" alt="">冻结</span>
            </template>
          </el-table-column>
          <el-table-column prop="checkTime" show-overflow-tooltip min-width="120" label="操作时间" ></el-table-column>
          <el-table-column prop="redirectUrl" show-overflow-tooltip min-width="150" label="接入URL" >
            <template slot-scope="scope">
              <a href="javascript:;">{{ scope.row.redirectUrl }}</a>
            </template>
          </el-table-column>
          <el-table-column prop="appInfo" show-overflow-tooltip min-width="150" label="应用简介" ></el-table-column>
          <el-table-column fixed="right" label="操作" width="120" >
            <!-- <template slot-scope="scope">
              <img class="editImg hover" :src="scope.row.isPressS || scope.row.isShow?showImg_P:showImg_N" @click="showClick(scope.row)">
              <img class="editImg hover" :src="scope.row.isPressD?delImg_P:delImg_N" @click="deleteClick(scope.row)" @mousemove="deleteHover(scope.row)" @mouseout="deleteOut(scope.row)">
            </template> -->
            <template slot-scope="scope">
              <el-button class="text" v-if="scope.row.state ===1" @click.stop="handleChange(scope.row,2)" type="text" size="small">冻结</el-button>
              <el-button class="text" v-if="scope.row.state ===2" @click.stop="handleChange(scope.row,1)" type="text" size="small">恢复</el-button>
              <el-button class="text" v-if="scope.row.state ===0" @click.stop="handleChange(scope.row,1)" type="text" size="small">通过</el-button>
              <el-button class="text" @click.stop="toapp(scope.row)" type="text" :disabled="scope.row.state=== 0 || scope.row.state ===2" size="small">跳转</el-button>

            </template>
          </el-table-column>
        </el-table>

      </div>
      <el-dialog
        title="应用信息详情"
        :visible.sync="linkshow"
        width="650px"
        height="500px;"
        :close-on-click-modal="false"
        class="linkdialog"
        :destroy-on-close="true"
        :before-close="closelink">
        <div class="appinfo">
          <img :src="$futureImgUrl + info.appLogo" class="logo" alt="">
          <div class="list">
            <div class="listitem">
              <div class="tit">应用名称</div>
              <div class="txt">{{ info.appName }}</div>
            </div>
            <div class="listitem">
              <div class="tit">简称</div>
              <div class="txt">{{ info.appShortName }}</div>
            </div>
          </div>
          <div class="list">
            <div class="listitem">
              <div class="tit">注册时间</div>
              <div class="txt">{{ info.createTime }}</div>
            </div>
            <div class="listitem">
              <div class="tit">应用域名访问跳转URL</div>
              <div class="txt"><a href="javascript:;">{{ info.redirectUrl }}</a></div>
            </div>
          </div>
          <div class="list">
            <div class="listitem1">
              <div class="tit">应用描述</div>
              <div class="txt">{{ info.appInfo }}</div>
            </div>
          </div>
          <div class="line"></div>
          <div class="list">
            <div class="listitem">
              <div class="tit">当前状态</div>
              <div class="txt">
                <span v-if="info.state === 0" class="c0"><img src="/images/icon/c0.png" alt="">待审</span>
                <span v-if="info.state === 1" class="c1"><img src="/images/icon/c1.png" alt="">通过</span>
                <span v-if="info.state === 2" class="c2"><img src="/images/icon/c2.png" alt="">冻结</span>
              </div>
            </div>
            <div class="listitem">
              <div class="tit">操作时间</div>
              <div class="txt">{{ info.checkTime }}</div>
            </div>
          </div>
          <div class="list">
            <div class="listitem">
              <div class="tit">AppID</div>
              <div class="txt">
                {{ info.appId }}
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="mini" style="width:80px" @click="closelink" round>关闭</el-button>
        </span>
      </el-dialog>
    </div>
    <!--  -->
  </div>
</template>
<script>
import { websiteApp, checkWebsiteApp, getAppToken } from '@/api/list.js'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'AllData',
  data() {
    return {
      page: 1,
      tableData: [],
      input2: '',
      linkshow: false,
      info: {}
    }
  },
  computed: {
    ...mapGetters(['datastr', 'datatype', 'indexData', 'manageData'])
  },
  watch: {
    datatype: {
      handler(newVal) {
        console.log(newVal, 'jjjjjjjj')
        this.page = 1
        this.getwebsiteApp()
      },
      deep: true,
      immediate: true
    }

  },
  mounted() {
    this.getwebsiteApp()
  },
  methods: {
    ...mapMutations(['SET_DATASTE']),
    showdetail(row) {
      console.log(row)
      this.info = row
      this.linkshow = true
    },
    closelink() {
      this.linkshow = false
    },
    getwebsiteApp() {
      let state
      if (this.datatype === 2) {
        state = 0
      }
      if (this.datatype === 3) {
        state = 2
      }
      const params = {
        pageNo: this.page,
        pageSize: 10,
        state: state,
        appName: this.input2
      }
      websiteApp(params).then((res) => {
        if (res.message === 'success') {
          this.tableData = res.data.rows
        }
      })
    },
    handleChange(item, state) {
      checkWebsiteApp({
        appId: item.appId,
        state: state
      }).then((res) => {
        if (res.message === 'success') {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getwebsiteApp()
        } else {
          this.$message({
            message: res.message,
            type: 'error'
          })
        }
      })
    },
    toapp(item) {
      getAppToken({
        appId: item.appId
      }).then(res => {
        if (res.message === 'success') {
          if (item.redirectUrl.includes('http')) {
            window.open(item.redirectUrl + '?token=' + res.data.data, '_blank')
          } else {
            window.open('http://' + item.redirectUrl + '?token=' + res.data.data, '_blank')
          }
        } else {
          this.$message({
            message: res.message,
            type: 'error'
          })
        }
      })
    },
    changePage(page) {
      this.page = page
      this.getwebsiteApp()
    },
    searchlist() {
      this.page = 1
      this.getwebsiteApp()
    }
  }
}
</script>
<style lang="less" scoped>
.classifyManage_cont{
    padding: 0 30px;
    position: relative;
    height: 100%;
    padding-top: 30px;
    overflow: hidden;
    background: #fff;
}
.tximg {
  max-height: 50px;
  max-width: 50px;
}
.c0{
  color: #FFB758;
  img{
    vertical-align: middle;
    margin-right: 5px;
  }
}
.c1{
  color: #69C33D;
  img{
    vertical-align: middle;
    margin-right: 5px;
  }
}
.c2{
  color: #F56C6C;
  img{
    vertical-align: middle;
    margin-right: 5px;
  }
}
.tablecontent {
  position: relative;
  max-height: calc(100% - 150px);
  overflow-y: auto;
  .danger {
    color: #f56c6c;
  }
  .danger[disabled]{
    color: #C0C4CC;
  }
}
.page {
  position: absolute;
  top: 5px;
  right: 30px;
}
.articlebox {
  height: 100%;
}
.editImg{
    width: 18px;
    height: 12px;
    margin-right: 15px;
}

.seachbox {
  padding-bottom: 18px;
}
.inputbox, .selectbox {
  float: left;
}
.inputbox {
  padding-right: 10px;
  /deep/.el-input__icon{
    line-height: 40px;
  }
}
.linkdialog{
  text-align: left;
  .appinfo{
    position: relative;
    padding-left: 110px;
    padding-top: 20px;
    .logo{
      position: absolute;
      left: 0px;
      top: 20px;
      width: 70px;
      height: 70px;
    }
    .line{
      height: 1px;
      margin-bottom: 30px;
background: #F2F2F2;
    }
    .list{
      margin-bottom: 30px;
      display: flex;
      .listitem{
        width: 50%;
      }
      .tit{
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 14px;
        margin-bottom: 10px;
      }
      .txt{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
      }
    }
  }
}
</style>
<style lang="less">
// .inputbox .el-input__icon{
//   line-height: 40px;
// }
 .selectbox .el-input__icon {
  line-height: 35px;
}
.articlebox {
  .el-table{
    border-radius: 10px 10px 0 0;
  }
  .el-table tr {
    cursor: pointer;
  }
  .el-table .cell {
    white-space: nowrap;
  }
  .el-table table {
    text-align: left;
  }
  .el-table__fixed-right::before, .el-table__fixed::before {
    height: 0;
  }
}
.tablecontent {
  .el-table th,
  .el-table td{
    padding-left: 10px;
  }
  .el-table tr td:first-of-type,
  .el-table tr th:first-of-type{
    padding-left: 30px;
    padding-right: 20px;
  }
}
.selectbox {
  .is-focus input {
    color: #5096F5;
  }
  .el-icon-arrow-up:before {
    content: "";
  }
  .is-focus .el-icon-arrow-up:before {
    content: "";
    color: #5096F5;
  }
}
</style>
