<template>
  <div class="mynews_cont">
    <div class="myNews_tab_main">
      <index-manage :isView="true" class="indexMain"></index-manage>
      <index-edit class="indexEdit"></index-edit>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
import indexManage from '@/components/indexManage/indexManage.vue'
import indexEdit from '@/components/indexManage/indexEdit.vue'
export default {
  name: 'PageManage',
  components: { indexManage, indexEdit },
  data() {
    return {
      objData: {

      },
      tabInfo: [],
      mainHei: '', // .myNews_tab_main高度
      editindex: 0,
      showitem: {}, // 模块数据
      iseditBodyChange: false
    }
  },
  computed: {
    ...mapGetters(['navMain', 'editBody'])
  },
  watch: {
    navMain: {
      handler(newVal) {
        // console.log(newVal, 'newVal')
        this.getMainCon()
      },
      deep: true,
      immediate: true
    },
    editBody: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal, 'wwwww')
        this.iseditBodyChange = true
      },
      deep: true
    }
  },
  mounted() {

  },
  methods: {
    ...mapMutations(['SET_DATASTE', 'SET_NAVMAIN']),
    getMainCon() {
      if (this.navMain) {
        this.tabInfo = this.navMain.children ? this.navMain.children : []
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    console.log('dwdwwdwd', this.iseditBodyChange)
    next()
    // if (this.iseditBodyChange) {
    //   this.$confirm('即将删除该新闻分类, 是否继续?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {

    //   }).catch(() => {
    //     this.$message({
    //       type: 'info',
    //       message: '已取消删除',
    //       center: true
    //     })
    //   })
    // }
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
  }

}
</script>
<style lang="less" scoped>
  @indexEditWidth: 268px;
.mynews_cont{
  position: absolute;
  left: 0;
  top: 0;
  width:100%;
  height: 100%;
  overflow: hidden;
}
.myNews_tab_main{
  display: flex;
  .indexMain {
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
    width: calc(100% - @indexEditWidth);
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width:7px;
    }
    /* 滚动槽 */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
      border-radius:10px;
    }
    /* 滚动条滑块 */
    &::-webkit-scrollbar-thumb {
      border-radius:10px;
      background:rgba(0,0,0,0.1);
      -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background:rgba(0,0,0,0.1);
    }
  }
  .indexEdit {
    box-sizing: border-box;
    width: @indexEditWidth;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0px;
  }
  @media screen and (min-width: 1600px) {
    .indexMain {
      width: calc(100% - @indexEditWidth - 50px);
    }
    .indexEdit {
      width: calc(@indexEditWidth + 50px);
    }
  }
}
</style>
