<template>
  <div class="whitebg">
    <div v-if="newsData.titleShow == 1 && newsData.title">
      <div class="title">{{ newsData.title }}</div>
      <div class="titlebtm"></div>
    </div>
    <div class="newsimgbox clearfix" ref="scroll">
      <div class="newsList" v-for="(news,index) in newsList" :key="index">
        <div v-if="news.titleShow == 1 && news.title" class="newstitle">
          <div class="title">{{ news.title }}</div>
          <div class="titlebtm"></div>
          <span class="more font14" v-if="newsData.moreShow === 1">更多<img style="height: 12px;margin-left: 2px;position: relative;top: 1px;" src="/images/index/more.png" alt=""></span>
        </div>
        <div class="box" v-if="dataobj[news.id] && dataobj[news.id].length >0">
          <div class="newsfl">
            <div class="imgbox pointer">
              <a href="javascript:;">
                <img :src="dataobj[news.id] | resetImg('arr',$futureImgUrl)" alt="">
                <p class="font20">{{ dataobj[news.id]?dataobj[news.id][0].firstTitle :'' }}</p>
                <div class="mark"></div>
              </a>
            </div>
          </div>
          <div class="newsfr">
            <div class="imgbox pointer" v-for="(item,i) in fesetData(dataobj[news.id])" :key="i">
              <a href="javascript:;">
                <img :src="item | resetImg('obj',$futureImgUrl)" alt="">
                <p class="font13">{{ item.firstTitle }}</p>
                <div class="mark"></div>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAxtNewSourceList } from '@/api/editIndex.js'
export default {
  name: 'EduNewsOne',
  props: {
    newsData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      newsList: [],
      dataobj: {}
    }
  },
  watch: {
    newsData: {
      handler() {
        console.log('444444')
        this.getAxtNewSourceList()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['indexData']),
    fesetData() {
      return (arr) => {
        return arr && arr.length > 0 ? arr.slice(1, 5) : []
      }
    }
  },
  filters: {
    resetImg(val, type, imgurl) {
      const value = val
      let url
      if (type === 'arr') {
        if (value && value.length > 0) {
          url = value[0].surfaceImg
        }
      } else {
        url = value.surfaceImg
      }
      // url && url.includes('http') ? url : imgurl + url
      return url
    }
  },
  mounted() {
    this.newsList = this.newsData.news.slice(0, 1)
    this.getAxtNewSourceList()
  },
  methods: {
    getAxtNewSourceList() {
      // this.newsList.forEach(v => {
      //   const params = {
      //     website_id: this.indexData.websiteId,
      //     cancel_publish: 0,
      //     ids: v.dataNewsIds
      //   }
      //   getAxtNewSourceList(params).then((res) => {
      //     // console.log(res, '新闻一')
      //     if (res.message === 'success') {
      //       this.$set(this.dataobj, v.id, res.data.rows)
      //     }
      //   })
      // })

      // 只获取一组数据
      // const params = {
      //   website_id: this.indexData.websiteId,
      //   cancel_publish: 0,
      //   ids: this.newsList[0].dataNewsIds
      // }
      // getAxtNewSourceList(params).then((res) => {
      //   // console.log(res, '新闻一')
      //   if (res.message === 'success') {
      //     this.$set(this.dataobj, this.newsList[0].id, res.data.rows)
      //   }
      // })
      this.newsList.forEach(v => {
        const params = {
          website_id: this.indexData.websiteId,
          cancel_publish: 0
        }
        if (v.dataType === 1) {
          if (!v.dataNewsIds) {
            this.dataobj[v.id] = []
            return
          }
          params.ids = v.dataNewsIds
        } else if (v.dataType === 2) {
          params.classify_id = v.dataNewsTypeId
        }
        getAxtNewSourceList(params).then((res) => {
          // console.log(res, 'xinwen4')
          if (res.message === 'success') {
            this.$set(this.dataobj, v.id, res.data.rows.slice(0, 5))
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped="scoped">
  @marginBtn :18px;
  .whitebg {
   background: #fff;
   padding: 0 10px;
 }
  .titlebtm {
   margin-bottom: 45px;
  }
  .newstitle {
    position: relative;
    .more {
      color: #3583FF;
      position: absolute;
      top:50px;
      right: 20px;
      cursor: pointer;
    }
  }
  .newsimgbox {
    padding-bottom: 20px;
    .imgbox {
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      p {
        width: 100%;
        padding: 0 5%;
        position: absolute;
        left: 0;
        bottom: 10px;
        color: #fff;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: all .5s;
        z-index: 1;
      }
      a {
        display: block;
        height: 100%;
        overflow: hidden;
      }
    }
  }
  .box {
    display: flex;
    padding-top: 40px;
  }
  .newsimgbox .newsfl {
    width: 50%;
    max-height: 500px;
    overflow: hidden;
    /* height: 330px; */
  }
  .newsimgbox .newsfr {
    width: calc(50% - 20px);
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .newsimgbox .newsfr .imgbox {
    width: calc((100% - 20px) / 2);
    float: left;
    height: calc((100% - @marginBtn) / 2);
    margin-bottom: @marginBtn;
    margin-right: 20px;
  }

  .newsimgbox .newsfr .imgbox:nth-of-type(2n+2) {
    margin-right: 0;
  }

  .newsimgbox .newsfl .imgbox img {
    width: 100%;
  }
  .newsimgbox .newsfr .imgbox img {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .newsimgbox .imgbox .mark {
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }

  .newsimgbox .newsfl .imgbox p {
    bottom: 30px;
    transition: all .5s;
  }
  .headline {
    text-align: left;
    line-height: 1.8;
    padding-bottom: 10px;
    border-bottom: 4px solid #3583FF;
    position: relative;
  }

  .headline .more {
    color: #3583FF;
    float: right;
    position: relative;
    top: 10px;
    cursor: pointer;
  }

  .newslist {
    margin-top: 15px;
  }

  .newsltem {
    width: 100%;
    height: 78px;
    background: #F8F9FB;
    border-radius: 10px;
    display: table;
    line-height: 1.6;
    padding: 0 22px;
    text-align: left;
    margin-bottom: 13px;
  }

  .newsltem p {
    display: table-cell;
    vertical-align: middle;
  }

  .newsltem:hover {
    background-color: #E8F1FF;
  }

  .newsltem:hover p {
    color: #3583FF;
  }
  .newsclassify {
    padding: 45px 0;
  }

  @media screen and (min-width: 1920px) {
    .newsimgbox .newsfr .imgbox {
      margin-bottom: 21px;
    }
    .newsimgbox .newsfr .imgbox {
      height: calc((100% - @marginBtn - 3px) / 2);
      margin-bottom: @marginBtn + 3px;
    }
  }
</style>
