<template>
  <div id="white">
    <div v-if="newsData.titleShow == 1 && newsData.title">
      <div class="title">{{ newsData.title }}</div>
      <div class="titlebtm"></div>
    </div>
    <div class="newsclassify">
      <Row :gutter="35">
        <Col span="8" v-for="(news,index) in newsList" :key="index" class="margin-b">
          <div class="headline font24">
            <span class="headnum">0{{ index+1 }}</span>
            <span>{{ news.title }}</span>
            <span class="more" v-if="newsData.moreShow == 1">更多<img style="height: 12px;margin-left: 2px;position: relative;top: 1px;" src="/images/index/more.png" alt=""></span>
          </div>
          <!-- <div class="newslist" v-if="dataobj[news.id]">
            <div class="newsitem pointer clearfix" v-for="(item,i) in dataobj[news.id]" :key="i">
              <a href="javascript:;">
                <div class="newsitemtitle" :title="item.firstTitle">{{ item.firstTitle }}</div>
                <div class="newsitemtime" :title="item.publishTime?item.publishTime.slice(0,10):''">{{ item.publishTime?item.publishTime.slice(0,10):'' }}</div>
              </a>
            </div>
          </div> -->
          <news-item :item="news"></news-item>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NewsItem from '@/components/indexContent/NewsItem2.vue'
export default {
  name: 'EduNewstwo',
  props: {
    newsData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      newsList: [],
      dataobj: {}
    }
  },
  components: {
    NewsItem
  },
  watch: {
    newsData: {
      handler() {
        // this.getAxtNewSourceList()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['indexData'])
  },
  mounted() {
    this.newsList = this.newsData.news.slice(0, 3)
  },
  methods: {

  }
}
</script>

<style lang="less" scoped="scoped">
  #white {
    background: #fff;
    padding: 0 10px;
  }
  .margin-b {
    margin-bottom: 30px;
  }
  .headline {
    text-align: left;
    line-height: 1.8;
    padding-bottom: 3px;
    position: relative;
    font-weight: 500;
  }
  .headline .headnum {
    font-size: 24px;
    font-weight: 800;
    color: #3583FF;
    margin-right: 5px;
  }
  .headline .more {
    color: #3583FF;
    position: absolute;
    bottom: 9px;
    right: 0;
    font-size: 12px;
    cursor: pointer;
  }
  .newslist {
    margin-top: 15px;
    background-color: #F5F5F7;
    border-radius: 10px;
    padding: 25px;
    text-align: left;
    height: calc(100% - 50px);
  }
  .newsitem {
    margin-bottom: 20px;
  }
  .newsitem a {
    display: block;
  }
  .newsitem:last-of-type {
    margin-bottom: 0;
  }
  .newsitemtitle {
    float: left;
    width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #555;
  }
  .newsitemtime {
    float: right;
    width: 35%;
    color: #A7A7A7;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .newsitem:hover .newsitemtitle {
    color: #3583FF;
  }
  .frcon {
    background-color: #F5F5F7;
    border-radius: 10px;
    margin-bottom: 23px;
    padding-right: 20px;
    text-align: left;
    position: relative;
    overflow: hidden;
  }
  .frcon:last-of-type {
    margin-bottom: 0;
  }
  .newstitle {
    font-weight: 500;
    color: #212121;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 15px;
    margin-bottom: 0px;
  }
  .frcon:hover .newstitle{
    color: #3583FF;
  }
  .newstext {
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .newstime {
    position: absolute;
    bottom: 15px;
    left: 300px;
  }
  .newsclassify {
    padding: 25px 0 5px;
  }
  @media screen and (min-width: 1920px) {
    #content {
      width: 87.5%;
    }
    .newsimgbox .fl {
      width: 828px;
    }
    .newsimgbox .fr {
      width: 808px;
    }
    .newsimgbox .fl .imgbox {
      width: 828px;
    }
    .newsimgbox .fr .imgbox {
      width: 393px;
      height: 221px;
    }
    .newstitle {
      padding-top: 25px;
      margin-bottom: 15px;
    }
    .frcon {
      margin-bottom: 25px;
    }
    .newstext {
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    .newstime {
      bottom: 20px;
      left: 416px;
    }
  }

</style>
