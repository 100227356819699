<template>
  <div id="editor"></div>
</template>
<script>
import { upload } from '@/api/upload.js'
import E from 'wangeditor'
// import { getUUID } from '@/utils/util.js'
export default {
  name: 'Wangeditor',
  props: {
    newContent: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      updatenewscontent: null,
      editor: null
    }
  },
  mounted() {
    this.editor = new E('#editor')
    // 配置菜单栏，设置不需要的菜单
    this.editor.config.excludeMenus = [
      'video',
      'todo',
      'emoticon'
    ]
    // this.editor.config.colors = [
    //   '#000000',
    //   '#eeece0',
    //   '#1c487f',
    //   '#4d80bf'
    // ]
    this.editor.config.uploadImgServer = this.$baseUrl + '/axt-resources/upload/ueditorUpload'
    this.editor.config.uploadImgMaxSize = 20 * 1024 * 1024 // 2M
    this.editor.config.uploadFileName = 'upfile'
    this.editor.config.uploadImgMaxLength = 5 // 一次最多上传 5 个图片
    this.editor.config.showLinkImg = false // 禁止上传网图
    const that = this
    this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
      // resultFiles 是 input 中选中的文件列表
      // insertImgFn 是获取图片 url 后，插入到编辑器的方法
      const arr = resultFiles
      arr.forEach((item, i) => {
        const data = {}
        data.id = 'file_' + i
        data.name = item.name
        data.type = item.type
        data.size = item.size
        data.lastModifiedDate = item.lastModifiedDate
        if (item.size > 20 * 1024 * 1024) {
          return false
        }
        // 文件上传，formdata 要用到的参数及方法
        var formData = new FormData()
        formData.append('upfile', item)
        formData.append('id', data.id)
        formData.append('name', data.name)
        formData.append('type', data.type)
        formData.append('size', data.size) // action=uploadimage&encode=utf-8
        formData.append('lastModifiedDate', data.lastModifiedDate)
        const params = {
          action: 'uploadimage',
          encode: 'utf-8'
        }
        upload(formData, params).then((res) => {
          console.log(this)
          if (res.state === 'SUCCESS') {
            insertImgFn(that.$futureImgUrl + res.url)
          } else {
            that.$message({
              message: '图片上传失败',
              type: 'warning'
            })
          }

          // insertImgFn('https://qcban.aixuetang.com/res' + res.url)
        })
      })

      // 上传图片，返回结果，将图片插入到编辑器中
      // insertImgFn(imgUrl)
    }
    this.editor.create()
    if (this.newContent) {
      this.editor.txt.html(this.newContent)
    }
  },
  methods: {
    getContent() {
      this.updatenewscontent = this.editor.txt.html()
    }
  }
}
</script>
<style lang="less">
  #editor  {
    .w-e-text{
      min-height: 320px!important;
    }
    &>div:first-of-type {
      border-radius: 8px 8px 0 0;
    }
    &>div:last-of-type {
      border-radius: 0 0 8px 8px;
    }
  }
</style>
<style lang="less" scoped>
  #editor {
    width: 100%;
    height: 400px;
  }
</style>
