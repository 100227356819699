<template>
  <div class="index" id="indexMain_box">
    <!-- <head-top></head-top> -->
    <div
      class="indexitem"
      v-for="(item,index) in indexEditData.plates"
      :key="index"
      :id="'indexitem' + index"
      :class="{'active':selectIndex === index,'mouseactive':mouseIndex === index}"
      @click=" item.type !== 2 && item.type !== 4 && item.type !==5 && editcon(index,item)"
      @mouseenter=" item.type !== 2 && item.type !== 4 && item.type !==5 && mouseEditcon(index,$event)"
      @mouseleave="mouseleaveEdit()">
      <div class="itemcon">
        <!-- 轮播 -->
        <banner v-if="item.type ===1" :bannerData="item"></banner>
        <!-- 爱学云智慧教育平台 -->
        <edu-platform v-if="item.type ===2"></edu-platform>
        <!--news-->
        <div v-if="item.type ===3">
          <edu-news-one v-if="item.style === 1" :newsData="item"></edu-news-one>
          <edu-news-two v-if="item.style === 2" :newsData="item"></edu-news-two>
          <edu-news-three v-if="item.style === 3" :newsData="item"></edu-news-three>
          <edu-news-one-style-one v-if="item.style === 4" :newsData="item"></edu-news-one-style-one>
          <edu-news-two-style-two v-if="item.style === 5" :newsData="item"></edu-news-two-style-two>
          <edu-news-three-style-three v-if="item.style === 6" :newsData="item"></edu-news-three-style-three>
        </div>
        <big-data v-if="item.type ===4" :bigData="item"></big-data>
        <!--resouse-->
        <div v-if="item.type ===5">
          <micro></micro>
          <package></package>
        </div>
        <!--show-->
        <div v-if="item.type ==6">
          <teacher v-if="item.style ==1" :showData="item"></teacher>
          <index-school v-if="item.style ==2" :showData="item"></index-school>
          <blogroll v-if="item.style ==3" :showData="item"></blogroll>
        </div>
      </div>
      <div class="editbox" v-if="item.type !== 2 && item.type !== 4 && item.type !==5">
        <div class="editTop editbtn" v-if="index > 0" @click="movemodule(index, true)"></div>
        <div class="editDown editbtn" v-if="indexEditData.plates && index <( indexEditData.plates.length-1)" @click="movemodule(index)"></div>
        <div class="editdelete editbtn" @click="deletemodule(index)"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { getConfigHpStyleBody } from '@/api/editIndex.js'
import { scroll_tomodule } from '@/utils/util.js'
import headTop from '@/components/headtop/headTop.vue'
import Banner from '@/components/indexContent/Banner.vue'
import eduPlatform from '@/components/indexContent/eduPlatform.vue'
import eduNewsOne from '@/components/indexContent/eduNewsOne.vue'
import eduNewsTwo from '@/components/indexContent/eduNewsTwo.vue'
import eduNewsThree from '@/components/indexContent/eduNewsThree.vue'
import eduNewsOneStyleOne from '@/components/indexContent/eduNewsOneStyleOne.vue'
import eduNewsTwoStyleTwo from '@/components/indexContent/eduNewsTwoStyleTwo.vue'
import eduNewsThreeStyleThree from '@/components/indexContent/eduNewsThreeStyleThree.vue'
import bigData from '@/components/indexContent/bigData.vue'
import micro from '@/components/indexContent/microVideo.vue'
import Package from '@/components/indexContent/package.vue'
import Teacher from '@/components/indexContent/teacher.vue'
import indexSchool from '@/components/indexContent/indexSchool.vue'
import Blogroll from '@/components/indexContent/blogroll.vue'
export default {
  name: 'IndexManage',
  props: {
    isView: {
      type: Boolean,
      default: false
    }
  },
  components: {
    headTop, Banner, eduPlatform, eduNewsOne, eduNewsTwo, eduNewsThree, eduNewsOneStyleOne, eduNewsTwoStyleTwo, eduNewsThreeStyleThree, bigData, micro, Package, Teacher, indexSchool, Blogroll
  },
  data() {
    return {
      mouseIndex: -1,
      indexEditData: {}
    }
  },
  computed: {
    ...mapGetters(['manageData', 'indexData', 'editBody', 'selectIndex'])
  },
  watch: {
    editBody: {
      handler(newVal) {
        const obj = this.editBody
        this.indexEditData = obj
        console.log(obj, 'vuex 数据')
      },
      deep: true
    }
  },
  mounted() {
    // if (this.isView) {
    //   this.getData()
    // } else {
    //   this.indexEditData = this.editBody
    // }
    // this.getData()
    this.SET_INDEX(-1)
    this.getConfigHpStyleBody()
  },
  methods: {
    ...mapMutations(['SET_BODY', 'SET_INDEX']),

    editcon(num, item) {
      if (!this.isView) {
        return
      }
      console.log(num, 'sss', item)
      this.SET_INDEX(num)
    },
    mouseEditcon(num, event) {
      this.mouseIndex = num
    },
    mouseleaveEdit() {
      this.mouseIndex = null
    },
    getConfigHpStyleBody() {
      const data = {
        website_id: this.indexData.websiteId
      }
      getConfigHpStyleBody(data).then((res) => {
        if (res.message === 'success') {
          const obj = res.data
          this.indexEditData = obj
          this.SET_BODY(this.indexEditData)
          const arr = obj.plates
          let ind = -1
          arr.forEach((v, i) => {
            if (v.type === 1 || v.type === 3 || v.type === 6) {
              if (ind === -1) {
                ind = i
              }
            }
          })
          console.log(ind, 'ind')
          this.SET_INDEX(ind)
          // 默认选择第一个模块
        }
      })
    },
    deletemodule(index) {
      this.$confirm('即将删除此模块, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.editBody.plates.splice(index, 1)
        const arr = this.editBody.plates
        let ind = -1
        arr.forEach((v, i) => {
          if (v.type === 1 || v.type === 3 || v.type === 6) {
            if (ind === -1) {
              ind = i
            }
          }
        })
        this.SET_INDEX(ind)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
          center: true
        })
      })
    },
    movemodule(index, bool) {
      if (bool) {
        // up
        if (index === 0) {
          // 加固
          return
        }
        // this.SET_INDEX(index - 1)
        // 在上一项插入该项
        this.editBody.plates.splice(index - 1, 0, (this.editBody.plates[index]))
        // 删除后一项
        this.editBody.plates.splice(index + 1, 1)
        this.$nextTick(() => {
          console.log('up' + index, this.editBody.plates)
          scroll_tomodule(index - 1)
          setTimeout(() => {
            this.SET_INDEX(index - 1)
          }, 100)
        })
      } else {
        // down
        if (index === (this.editBody.plates.length - 1)) {
          return
        }
        // this.SET_INDEX(index + 1)
        // 在下一项插入该项
        this.editBody.plates.splice(index + 2, 0, (this.editBody.plates[index]))
        // 删除前一项
        this.editBody.plates.splice(index, 1)
        this.$nextTick(() => {
          console.log('down' + index, this.editBody.plates)
          scroll_tomodule(index + 1)
          setTimeout(() => {
            this.SET_INDEX(index + 1)
          }, 100)
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .index {
    padding: 10px 0 20px;
    position: relative;
    .indexitem {
      position: relative;
      cursor: pointer;
      padding-right: 50px;
      transition: all 0.3s;
      /deep/.title {
        padding-top: 30px;
        font-size: 24px;
        font-weight: 500;
        color: #444444;
        @media screen and (min-width: 1920px) {
          &{
            font-size: 30px;
          }
        }
      }
      /deep/.titlebtm {
        width: 73px;
        height: 2px;
        background: #3583FF;
        margin: 10px auto 0;

      }
      .editbox {
        position: absolute;
        z-index: 999;
        top: 50%;
        right: 13px;
        transform: translateY(-50%);
        display: none;
        .editbtn {
          width: 27px;
          height: 27px;
          margin-bottom: 15px;
          cursor: pointer;
        }
        .editbtn:last-of-type {
          margin-bottom: 0;
        }
        .editTop {
          background: url(/images/icon/upbtn.png) no-repeat;
          background-size: cover;
        }
        .editTop:hover {
          background: url(/images/icon/upbtnbg.png) no-repeat;
          background-size: cover;
        }
        .editDown {
          background: url(/images/icon/downbtn.png) no-repeat;
          background-size: cover;
        }
        .editDown:hover {
          background: url(/images/icon/downbtnbg.png) no-repeat;
          background-size: cover;
        }
        .editdelete {
          background: url(/images/icon/delete.png) no-repeat;
          background-size: cover;
        }
        .editdelete:hover {
          background: url(/images/icon/deletebg.png) no-repeat;
          background-size: cover;
        }
      }
      .itemcon {
        min-height: 100px;

      }
      &.active,
      &.mouseactive {
        transform: translateY(30px);
        margin-bottom: 60px;
        .itemcon{
          border: 2px dashed #5096F5;
          box-shadow: 0px 12px 25px 0px rgba(0, 0, 0, 0.3);
        }
      }
      &:first-of-type.active .itemcon,
      &:first-of-type.mouseactive .itemcon {
        transform: translateY(0px);
        margin: 0 0 30px;
      }
      &.active .editbox,
      &.mouseactive .editbox {
        display: block;
      }
    }
  }
</style>
