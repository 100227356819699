<template>
  <div class="indexschool">
    <div class="schooltite">
      <div class="headline font24">
        <span v-if="showData.titleShow === 1">{{ showData != null? showData.title:'' }}</span>
        <span class="more font14" :data-type="showData.moreShow" v-if="showData.moreShow === 1">更多<img style="height: 12px;margin-left: 2px;position: relative;top: 1px;" src="/images/index/more.png" alt=""></span>
      </div>
    </div>
    <div class="schoolbox clearfix">
      <div class="fr sright">
        <Row :gutter="20">
          <Col span="8" v-for="(item,index) in showData.infos.slice(1,7)" :key="index">
            <div class="schcon schright">
              <img :src="setimg(item.picPath,$futureImgUrl)" alt="">
              <div class="intro">{{ item.text1 }}</div>
            </div>
          </Col>
        </Row>
      </div>
      <div class="fl schleft" v-if="showData.infos.length > 0">
        <div class="schcon">
          <img :src="setimg(showData.infos[0].picPath,$futureImgUrl)" alt="">
          <div class="intro">{{ showData.infos[0].text1 }}</div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: 'IndexSchool',
  props: {
    showData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {

    }
  },
  computed: {
    setimg() {
      return (val, imgpath) => {
        const value = imgpath + val
        return value
      }
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped="scoped">
  .indexschool {
    padding: 30px 0;
  }
  .headline {
    text-align: left;
    line-height: 1.8;
    font-size: 18px;
    height: 34px;
    border-bottom: 2px solid #3583FF;
    position: relative;
    font-weight: 500;
    padding: 0 10px;
    .more {
      color: #3583FF;
      float: right;
      position: relative;
      top: 2px;
      font-size: 13px;
      cursor: pointer;
    }
  }
  .schoolbox {
    margin-top: 50px;
    display: flex;
    padding: 0 10px;
  }
  .schleft {
     width: 38.8%;
  }
  .sright {
     width: calc(61.2% - 20px);
     margin-right: 20px;
  }
  .schcon {
    position: relative;
    overflow: hidden;
    border-radius: 14px;
    line-height: 1;
  }
  .schright {
    margin-bottom: 25px;
  }
  .schcon .intro {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 40px;
    line-height: 40px;
    color: #fff;
    background-color: rgba(0,0,0,.5);
    text-align: left;
    width: 100%;
    padding:0 15px;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .schcon img {
    display: block;
    width: 100%;
    transition: all .5s;
  }
  .schcon:hover img {
    transform: scale(1.1);
  }
  @media screen and (min-width: 1800px) {
    .schleft {
      width: 39.3%;
    }
    .sright {
      width: calc(60.7% - 20px);
      margin-right: 20px;
    }
    .schcon .intro {
    font-size: 18px;
  }
  }

</style>
