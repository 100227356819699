<template>
  <span>
    <el-upload
      class="avatar-uploader"
      :action="this.$baseUrl + '/axt-resources/upload/ueditorUpload?action=uploadimage&encode=utf-8'"
      :show-file-list="false"
      :auto-upload="false"
      :fileList="fileList"
      :data="formdata"
      name="upfile"
      :on-change="changeUpload"
    >
      <span v-if="isbanner" class="banner">
        <img src="/images/index/btn_add.png">添加图片
      </span>
      <img v-if="!isbanner" :src="config.baseImageUrl?config.baseImageUrl:imageUrl" class="avatar">
      <div slot="tip" class="el-upload__tip" v-if="config.tip">{{ config.tip }}</div>
    </el-upload>
    <!-- vueCropper 剪裁图片实现-->
    <el-dialog title="图片剪裁" :visible.sync="dialogVisible" append-to-body width="1000px">
      <div class="cropper-content">
        <div class="cropper" style="text-align:center">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="true"
            :autoCropWidth="option.autoCropWidth"
            :autoCropHeight="option.autoCropHeight"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox"
          ></vueCropper>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
      </div>
    </el-dialog>
  </span>
</template>
<script>
import { upload } from '@/api/upload.js'
export default {
  name: 'Upload',
  props: {
    isbanner: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => {}
    },
    cropoption: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      imageUrl: '/images/upload_icon.png',
      formdata: {},
      dialogVisible: false,
      loading: false,
      option: {},
      fileList: []
    }
  },
  watch: {
    'config.baseImageUrl': {
      handler(newVal) {
        this.imageUrl = newVal || '/images/upload_icon.png'
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // console.log(this.cropoption, this.option, 'cropoption')
    const fixed = this.cropoption.fixed !== 'nofixed'
    this.option = {
      img: '', // 裁剪图片的地址
      info: this.cropoption.info || true, // 裁剪框的大小信息
      outputSize: this.cropoption.outputSize || 1, // 裁剪生成图片的质量
      outputType: this.cropoption.outputType || 'png', // 裁剪生成图片的格式
      canScale: this.cropoption.canScale || false, // 图片是否允许滚轮缩放
      autoCrop: this.cropoption.autoCrop || true, // 是否默认生成截图框
      autoCropWidth: this.cropoption.autoCropWidth || undefined, // 默认生成截图框宽度
      autoCropHeight: this.cropoption.autoCropHeight || undefined, // 默认生成截图框高度
      fixedBox: this.cropoption.fixedBox || false, // 固定截图框大小 不允许改变
      fixed: fixed, // 是否开启截图框宽高固定比例
      fixedNumber: this.cropoption.fixedNumber || [7, 5], // 截图框的宽高比例
      full: this.cropoption.full || true, // 是否输出原图比例的截图
      canMoveBox: this.cropoption.canMoveBox || true, // 截图框能否拖动
      original: this.cropoption.original || false, // 上传图片按照原始比例渲染
      centerBox: this.cropoption.centerBox || false, // 截图框是否被限制在图片里面
      infoTrue: this.cropoption.infoTrue || true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
    }
    // console.log(this.option, 'oooooooooooo')
  },
  methods: {
    beforeUpload(file) {
      console.log(file, 'file')

      const data = {}
      data.id = 'file_'
      data.name = file.name
      data.type = file.type
      data.size = file.size
      data.lastModifiedDate = file.lastModifiedDate

      if (file.size > this.config.size * 1024 * 1024) {
        this.$message.error('上传图片大小不能超过 ' + this.config.size + 'MB!')
        return false
      }
      const formData = new FormData()
      formData.append('upfile', file)

      for (const k in data) {
        formData.append(k, data[k])
      }
      const params = {
        action: 'uploadimage',
        encode: 'utf-8'
      }
      upload(formData, params).then((res) => {
        console.log(this)
        if (res.state === 'SUCCESS') {
          this.fileList = [{
            name: file.name,
            url: this.$futureImgUrl + res.url
          }]
          this.imageUrl = this.$futureImgUrl + res.url
          this.$emit('filePath', { imgpath: res.url, file: file })
          this.dialogVisible = false
        } else {
          this.$message({
            message: '图片上传失败',
            type: 'warning'
          })
        }

        // insertImgFn('https://qcban.aixuetang.com/res' + res.url)
      })
    },
    getObjectURL(file) {
      var url = null
      if (window.createObjectURL !== undefined) { // basic
        url = window.createObjectURL(file)
      } else if (window.URL !== undefined) { // mozilla(firefox)兼容火狐
        url = window.URL.createObjectURL(file)
      } else if (window.webkitURL !== undefined) { // webkit or chrome
        url = window.webkitURL.createObjectURL(file)
      }
      return url
    },
    changeUpload(file) {
      this.fileinfo = file
      console.log(file, this.config.type)
      if (!this.config.type.includes('image/x-icon')) {
        if (!this.config.type.includes(file.raw.type)) {
          this.$message.error(this.config.errormsg)
          return false
        }
      } else {
        if (!['image/x-icon', 'image/png', 'image/jpeg', 'image/vnd.microsoft.icon'].includes(file.raw.type)) {
          this.$message.error(this.config.errormsg)
          return false
        }
      }

      const url = this.getObjectURL(file.raw)
      console.log(url, 'getObjectURL')
      this.option.img = url
      this.dialogVisible = true
      this.loading = false
    },
    finish() {
      this.$refs.cropper.getCropBlob((data) => {
        const filetype = this.option.outputType === 'x-icon' ? 'ico' : this.option.outputType
        const fileName = this.fileinfo.name.substring(0, this.fileinfo.name.indexOf('.')) + '.' + filetype
        this.loading = true
        const file = new File([data], fileName, { type: 'image/' + this.option.outputType, lastModified: Date.now() })
        console.log(file, fileName, 'data')
        // 上传服务器
        this.beforeUpload(file)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.banner{
  width: 221px;
  height: 73px;
  background: #F2F2F2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  @media screen and (min-width: 1600px) {
    &{
      width: 278px;
    }
  }
  img{
    width: 12px;
    margin-right: 10px;
  }
}
.cropper-content {
  .cropper {
    width: auto;
    height: 500px;
  }
}
</style>
