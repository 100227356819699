<template>
  <el-upload
    class="avatar-uploader"
    :action="this.$baseUrl + '/axt-resources/upload/ueditorUpload?action=uploadimage&encode=utf-8'"
    :show-file-list="false"
    :data="formdata"
    name="upfile"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload">
    <span v-if="isbanner" class="banner">
      <img src="/images/index/btn_add.png">添加图片
    </span>
    <img v-if="!isbanner" :src="config.baseImageUrl?config.baseImageUrl:imageUrl" class="avatar">
    <div slot="tip" class="el-upload__tip" v-if="config.tip">{{ config.tip }}</div>
  </el-upload>
</template>
<script>
export default {
  name: 'Upload',
  props: {
    isbanner: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      imageUrl: '/images/upload_icon.png',
      formdata: {}
    }
  },
  watch: {
    'config.baseImageUrl': {
      handler(newVal) {
        this.imageUrl = newVal || '/images/upload_icon.png'
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // shangchuan
    handleAvatarSuccess(res, file) {
      console.log(res, '444444444444')
      this.imageUrl = this.$futureImgUrl + res.url
      this.$emit('filePath', { imgpath: res.url, file: file })
    },
    beforeAvatarUpload(file) {
      // console.log(file, 'file')
      if (!this.config.type.includes(file.type)) {
        this.$message.error(this.config.errormsg)
        return false
      }
      const data = {}
      data.id = 'file_'
      data.name = file.name
      data.type = file.type
      data.size = file.size
      data.lastModifiedDate = file.lastModifiedDate
      if (file.size > this.config.size * 1024 * 1024) {
        this.$message.error('上传图片大小不能超过 ' + this.config.size + 'MB!')
        return false
      }
      this.formdata = data
    }
  }
}
</script>
<style lang="less" scoped>
.banner{
  width: 278px;
  height: 73px;
  background: #F2F2F2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
    img{
      width: 12px;
      margin-right: 10px;
    }
}
</style>
