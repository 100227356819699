<template>
  <div class="teacher">
    <div class="headline font24">
      <span v-if="showData.titleShow === 1">{{ showData != null? showData.title:'' }}</span>
      <span class="more font14" :data-type="showData.moreShow" v-if="showData.moreShow === 1">更多<img style="height: 12px;margin-left: 2px;position: relative;top: 1px;" src="/images/index/more.png" alt=""></span>
    </div>
    <div class="teacherbox">
      <Row v-if="showData != null">
        <Col span="4" v-for="(item,index) in showData.infos.slice(0,6)" :key="index">
          <div class="teacon">
            <div class="imgbox">
              <img :src="setimg(item.picPath,$futureImgUrl)" alt="">
              <div class="mark">
                <p class="font14" v-html="setStr(item.text3)"></p>
              </div>
            </div>
            <div class="teaintro">
              <div class="introhead font22">{{ item.text1 }}</div>
              <div class="introtext font14" :title="item.text2?item.text2:''">{{ item.text2?item.text2:'' }}</div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexTeacher',
  props: {
    showData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tabactive: 1,
      msg: 'tab1',
      teacherList: [],
      toNextListStr: ''
    }
  },
  computed: {
    setStr() {
      return (val) => {
        const value = val ? val.replace(/\r/g, '<br>') : ''
        return value
      }
    },
    setimg() {
      return (val, imgpath) => {
        const value = imgpath + val
        return value
      }
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped="scoped">
  .teacher {
    padding: 30px 0;
  }
  .headline {
    text-align: left;
    line-height: 1.8;
    font-size: 18px;
    height: 34px;
    border-bottom: 2px solid #3583FF;
    position: relative;
    font-weight: 500;
    padding: 0 10px;
    .more {
      color: #3583FF;
      float: right;
      position: relative;
      top: 2px;
      font-size: 13px;
      cursor: pointer;
    }
  }
  .teacherbox {
    padding-top: 45px;
  }
  .teacon {
    height: 100%;
    .imgbox {
      width: 80%;
      max-height: calc(100% - 69px);
      margin: 0 auto;
      position: relative;
      border-radius: 10px 10px 0 0;
      overflow: hidden;
      line-height: 1;
      background: #e4e4e4;
      img {
        max-height: 300px;
        display: block;
        width: 100%;
        line-height: 1;
      }
      .mark {
        position: absolute;
        left: 0;
        top: 500px;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.5);
        transition: all .5s;
        line-height: 1.5;
        p {
          position: absolute;
          padding: 0 5px;
          bottom: 15px;
          left: 0;
          font-size: 12px;
          text-align: left;
          color: #fff;
        }
      }
      &:hover .mark {
        top: 0;
        transition: all .5s;
      }
    }
  }
  .teaintro {
    background: #577EF6;
    border-radius: 8px;
    position: relative;
    padding-bottom: 10px;
    width: 90%;
    margin: 0 auto;
    .introhead {
      color: #fff;
      width: 90%;
      margin: 0 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-top: 4px;
      font-size: 14px;
    }
    .introtext {
      width: 70%;
      height: 25px;
      line-height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: linear-gradient(180deg, #FEE0BA 0%, #F5D1AB 100%);
      border-radius: 4px;
      font-size: 12px;
      color: #794409;
      font-weight: 600;
      margin: 6px auto 0;
      padding: 0 5px;
    }
  }
  @media screen and (min-width: 1920px) {
    .teaintro .introhead {
      font-size: 16px;
    }
    .teaintro .introtext {
      font-size: 14px;
    }
  }
</style>
