<template>
  <div class="module_edit">
    <div class="tools">
      <el-button size="mini" plain round @click="showaddmodule">添加模块</el-button>
      <el-button size="mini" plain round @click="previvew">预览</el-button>
      <el-button type="primary" size="mini" round @click="save">保存并发布</el-button>
      <!-- <el-button size="mini" plain round @click="send">发布</el-button> -->
    </div>
    <EditContent :title="showitem.title">
      <!-- 轮播 -->
      <EditBanner v-if="showitem.type ===1" :bannerData="showitem" @changedata="changedata"></EditBanner>
      <div v-if="showitem.type ===3">
        <EditNews :newsData="showitem" @changedata="changedata"></EditNews>
      </div>
      <!-- 友情链接 -->
      <div v-if="showitem.type ===6">
        <EditTeacher v-if="showitem.style ==1" :itemData="showitem" @changedata="changedata"></EditTeacher>
        <EditSchool v-if="showitem.style ==2" :itemData="showitem" @changedata="changedata"></EditSchool>
        <EditLink v-if="showitem.style ==3" :itemData="showitem" @changedata="changedata"></EditLink>
      </div>
    </EditContent>
    <!-- 添加模块 -->
    <el-dialog title="添加模块" :visible.sync="visible" width="500px" class="dialog" :before-close="handleClose">
      <div class="content">
        <div class="name">板块类型</div>
        <el-radio-group v-model="moduletype" class="select_box">
          <el-radio :label="1">轮播图</el-radio>
          <el-radio :label="2">新闻板块</el-radio>
          <el-radio :label="3">教师板块</el-radio>
          <el-radio :label="4">学校板块</el-radio>
          <el-radio :label="5">自定义板块</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="mini" round>取 消</el-button>
        <el-button type="primary" size="mini" @click="addmodule" round>确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="预览"
      :visible.sync="isView"
      width="80%"
      :before-close="handleClose">
      <index-view v-if="isView"></index-view>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { updateConfigHpStyleBody } from '@/api/editIndex.js'
import EditContent from '@/components/netManage/EditContent.vue'
import EditBanner from '@/components/netManage/EditBanner.vue'
import EditLink from '@/components/netManage/EditLink.vue'
import EditSchool from '@/components/netManage/EditSchool.vue'
import EditTeacher from '@/components/netManage/EditTeacher.vue'
import EditNews from '@/components/netManage/EditNews.vue'
import IndexView from '@/components/indexManage/indexView.vue'
import { scroll_tomodule } from '@/utils/util.js'
export default {
  name: 'IndexEdit',
  data() {
    return {
      visible: false,
      moduletype: 1,
      showitem: {},
      isView: false,
      isSave: false
    }
  },
  components: {
    EditContent,
    EditBanner,
    EditLink,
    EditSchool,
    EditTeacher,
    EditNews,
    IndexView
  },
  watch: {
    selectIndex: {
      handler(newval) {
        console.log(newval, 'newval')
        this.showitem = this.selectIndex !== -1 ? this.editBody.plates[this.selectIndex] : {}
      },
      deep: true,
      immediate: true
    },
    editBody: {
      handler(newval) {
        this.showitem = this.selectIndex !== -1 ? newval.plates[this.selectIndex] : {}
        console.log('editBody', newval)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['editBody', 'selectIndex'])
  },
  methods: {
    ...mapMutations(['SET_BODY', 'ADD_MODULE']),
    showaddmodule() {
      this.visible = true
    },
    handleClose() {
      this.visible = false
      this.isView = false
    },
    addmodule() {
      this.ADD_MODULE({
        key: this.moduletype,
        vm: this
      })
      this.visible = false
    },
    previvew() {
      // this.$toast('预览')
      this.isView = true
    },
    save() {
      const params = this.editBody
      const errorobj = { errormsg: '', index: 0 }
      // console.log(params, 'params22222222222')
      params.plates.forEach((v, i) => {
        console.log(v, 'plate')
        if (errorobj.errormsg) {
          return false
        }
        if (v.type === 1) {
          if (v.banners.length === 0) {
            errorobj.errormsg = '请添加轮播图'
            errorobj.index = i
            return false
          }
        }
        if (v.type === 3) {
          v.news.forEach((item, index) => {
            if (item.dataType === 1 && item.dataNewsIds === '0') {
              errorobj.errormsg = '请添加新闻'
              errorobj.index = i
              return false
            } else {
              // this.isSave = true
            }
          })
        }
        if (v.type === 6) {
          if (v.infos.length === 0) {
            errorobj.errormsg = '请添加展示信息'
            errorobj.index = i
            return false
          }
        }
      })
      if (errorobj.errormsg) {
        this.$toast(errorobj.errormsg)
        scroll_tomodule(errorobj.index)
        return false
      }
      // if (this.isSave) {
      updateConfigHpStyleBody(JSON.stringify(params)).then((res) => {
        console.log(res)
        if (res.message === 'success') {
          if (res.data === true) {
            this.$toast('保存成功')
            scroll_tomodule(0)
          } else {
            this.$toast('保存失败，请联系管理员！')
          }
        }
      })
      // }
    },
    send() {
      this.$toast('发布')
    },
    changedata(obj) {
      console.log(obj, 'sss')
      const editBody = { ...this.editBody }
      console.log(editBody, 'bianji')
      if (obj.type === 'banner') {
        editBody.plates[this.selectIndex].banners = obj.data
      }
      if (obj.type === 'link') {
        editBody.plates[this.selectIndex] = { ...obj.data }
      }
      if (obj.type === 'news') {
        editBody.plates[this.selectIndex] = { ...obj.data }
      }
      // 滚动到指定index
      // scroll_tomodule(this.selectIndex)
      this.SET_BODY(editBody)
      console.log(this.editBody, 'changedata')
    }
  }

}
</script>

<style lang="less" scoped>
.module_edit {
  position: relative;
  .tools {
    position: absolute;
    height: 30px;
    padding: 5px 0;
    right: 0;
    top: 0;
    width: 100%;
    background: #F2F2F2;
  }
  .editcontent {
    background: #fff;
    height: calc(100% - 40px);
    margin-top: 40px;
    overflow: auto;
    box-sizing: border-box;
     &::-webkit-scrollbar {
      width:7px;
    }
    /* 滚动槽 */
    &::-webkit-scrollbar-track {
      box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
      border-radius:10px;
    }
    /* 滚动条滑块 */
    &::-webkit-scrollbar-thumb {
      border-radius:10px;
      background:rgba(0,0,0,0.1);
      box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background:rgba(0,0,0,0.1);
    }
  }
  .dialog {
    text-align: left;
    .content{
      display: flex;
      .name{
        min-width: 100px;
      }
      .el-radio{
        margin: 4px 30px 20px 0;
        min-width: 80px;
      }
    }
  }
}
</style>
