<template>
  <div style="height:100%">
    <div class="newslist" v-if="dataobj">
      <div class="newsitem pointer clearfix" v-for="(list,i) in dataobj" :key="i">
        <a href="javascript:;">
          <div class="newsitemtitle" :title="list.firstTitle">{{ list.firstTitle }}</div>
          <div class="newsitemtime" :title="list.publishTime?list.publishTime.slice(0,10):''">{{ list.publishTime?list.publishTime.slice(0,10):'' }}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAxtNewSourceList } from '@/api/editIndex.js'
export default {
  name: 'EduNewsThree',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      newsList: [],
      dataobj: []
    }
  },
  computed: {
    ...mapGetters(['indexData'])
  },
  watch: {
    item: {
      handler() {
        this.getAxtNewSourceList()
      },
      deep: true
    }
  },
  mounted() {
    this.getAxtNewSourceList()
  },
  methods: {
    getAxtNewSourceList() {
      const params = {
        website_id: this.indexData.websiteId,
        cancel_publish: 0
      }
      if (this.item.dataType === 1) {
        if (!this.item.dataNewsIds) {
          this.dataobj = []
          return
        }
        params.ids = this.item.dataNewsIds
      } else if (this.item.dataType === 2) {
        params.classify_id = this.item.dataNewsTypeId
        params.pageSize = this.item.dataSize
      }

      getAxtNewSourceList(params).then((res) => {
        if (res.message === 'success') {
          this.dataobj = res.data.rows.slice(0, this.item.dataSize)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped="scoped">
#white {
    background: #fff;
    padding: 0 10px;
  }
  .margin-b {
    margin-bottom: 30px;
  }
  .headline {
    text-align: left;
    line-height: 1.8;
    padding-bottom: 3px;
    position: relative;
    font-weight: 500;
  }
  .headline .headnum {
    font-size: 24px;
    font-weight: 800;
    color: #3583FF;
    margin-right: 5px;
  }
  .headline .more {
    color: #3583FF;
    position: absolute;
    bottom: 9px;
    right: 0;
    font-size: 12px;
    cursor: pointer;
  }
  .newslist {
    margin-top: 15px;
    background-color: #F5F5F7;
    border-radius: 10px;
    padding: 25px;
    text-align: left;
    height: calc(100% - 50px);
  }
  .newsitem {
    margin-bottom: 20px;
  }
  .newsitem a {
    display: block;
  }
  .newsitem:last-of-type {
    margin-bottom: 0;
  }
  .newsitemtitle {
    float: left;
    width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #555;
  }
  .newsitemtime {
    float: right;
    width: 35%;
    color: #A7A7A7;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .newsitem:hover .newsitemtitle {
    color: #3583FF;
  }
  .frcon {
    background-color: #F5F5F7;
    border-radius: 10px;
    margin-bottom: 23px;
    padding-right: 20px;
    text-align: left;
    position: relative;
    overflow: hidden;
  }
  .frcon:last-of-type {
    margin-bottom: 0;
  }
  .newstitle {
    font-weight: 500;
    color: #212121;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 15px;
    margin-bottom: 0px;
  }
  .frcon:hover .newstitle{
    color: #3583FF;
  }
  .newstext {
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .newstime {
    position: absolute;
    bottom: 15px;
    left: 300px;
  }
  .newsclassify {
    padding: 25px 0 5px;
  }
  @media screen and (min-width: 1920px) {
    #content {
      width: 87.5%;
    }
    .newsimgbox .fl {
      width: 828px;
    }
    .newsimgbox .fr {
      width: 808px;
    }
    .newsimgbox .fl .imgbox {
      width: 828px;
    }
    .newsimgbox .fr .imgbox {
      width: 393px;
      height: 221px;
    }
    .newstitle {
      padding-top: 25px;
      margin-bottom: 15px;
    }
    .frcon {
      margin-bottom: 25px;
    }
    .newstext {
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    .newstime {
      bottom: 20px;
      left: 416px;
    }
  }

</style>
