<template>
  <div>
    <div class="seach_box">
      <el-input prefix-icon="el-icon-search" size="small" v-model="searchname" :placeholder="placeholder"></el-input>
      <el-button size="small" @click="search">搜索</el-button>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SeachModule',
  props: {
    placeholder: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      searchname: ''
    }
  },
  methods: {
    search() {
      this.$emit('searchData', this.searchname)
    }
  }
}
</script>

<style lang="less" scoped>
  .seach_box {
    /deep/.el-input{
      width: 340px;
      margin-right: 10px;
      .el-input__inner{
        border-radius: 6px;
      }
    }
    /deep/ .el-button{
      border-radius: 6px;
    }
  }

</style>
