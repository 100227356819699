<template>
  <div class="package">
    <div class="packagetitle">
      <div class="headline font24">
        <span>优质备课包 </span>
        <a class="more font14" href="javascript:;">更多<img style="height: 12px;margin-left: 2px;position: relative;top: 1px;" src="images/index/more.png" alt=""></a>
      </div>
    </div>
    <div class="packagebox">
      <Row :gutter="22">
        <Col span="19">
          <Row :gutter="22">
            <Col span="6" v-for="(item,index) in packageList" :key="index">
              <div class="packagecon clearfix" @click="toResPackage(item.id)" :title="item.name">
                <div class="imgbox fl"><img :src="imgurl + item.imgPath" alt=""></div>
                <div class="titlebox fr">
                  <div class="packtitle">{{ item.name|setName }}</div>
                  <!-- <div class="packgrade font14">{{item.gradeName.length > 5?item.gradeName.slice(0,5):item.gradeName}}</div> -->
                  <div class="packgrade font14" :title="item.versionName">{{ item.versionName.length > 5 ?item.versionName.substring(0,5)+'...':item.versionName }}</div>
                  <!-- <div class="packtype font14">{{item.type == 0?'同步课':'专题课'}}</div> -->
                  <!-- <div class="packtype font14">{{item.type == 0?'人教版（PED）':'专题课'}}</div> -->
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span="5">
          <div class="packageright">
            <img src="images/index/packagexx.png" alt="">
            <img src="images/index/packagecz.png" alt="">
            <img src="images/index/packagegz.png" alt="">
          </div>

        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import { pageCourse } from '@/api/editIndex.js'
export default {
  name: 'Package',
  data() {
    return {
      tabactive: 1,
      msg: 'tab1',
      imgurl: 'https://qcban.aixuetang.com/res/', // 微课图片地址  线上域名
      packageList: null,
      siteResUrl: null
    }
  },
  filters: {
    setName(val) {
      return val.split('（')[0]
    }
  },
  mounted() {
    this.siteResUrl = localStorage.getItem('siteResUrl')
    this.getLessonPackageData()
  },
  methods: {
    choseTab(name) {
      if (name === 1) {
        this.msg = 'tab1'
      } else if (name === 2) {
        this.msg = 'tab2'
      } else {
        this.msg = 'tab3'
      }
      this.tabactive = name
    },
    getLessonPackageData() {
      const params = {
        name: null,
        gradeId: null,
        subjectId: null,
        catalogId: null,
        versionId: null,
        type: null,
        publishStatus: 1,
        startTime: null,
        endTime: null,
        pageNo: 1,
        pageSize: 50,
        status: 1,
        source: 1,
        orderBys: [{
          direction: 'desc',
          field: 'update_time'
        }]

      }
      pageCourse(params).then((res) => {
        if (res.message === 'success') {
          const packageList = res.data.rows.slice(0, 8)
          for (let i = 0; i < 8; i++) {
            if (!packageList[i].versionName) packageList[i].versionName = ''
            if (!packageList[i].gradeName) packageList[i].gradeName = ''
            if (!packageList[i].subjectName) packageList[i].subjectName = ''
          }
          this.packageList = packageList
          console.log(this.packageList)
        }
      })
      // console.log(params,"222222")
    }
  }
}
</script>

<style lang="less" scoped="scoped">
  .package {
    padding: 50px 0 10px;
  }
  .headline {
    text-align: left;
    line-height: 1.8;
    font-size: 18px;
    border-bottom: 2px solid #3583FF;
    position: relative;
    font-weight: 500;
    .more {
      color: #3583FF;
      float: right;
      position: relative;
      top: 2px;
      font-size: 13px;
      cursor: pointer;
    }
  }
  .packagebox {
    margin-top: 40px;
  }
  .packagecon {
    border: 1px solid #DADADA;
    background-color: #fff;
    border-radius: 14px;
    padding: 12px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all .5s;
    .imgbox {
      width: 46%;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      img {
        border-radius: 10px;
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%,-50%);
        // min-width: 100%;
        // min-height: 100%;
        width: 100%;
      }
    }
    .titlebox {
      width: 45%;
      text-align: left;
      position: relative;
      .packtitle {
        font-weight: 500;
        color: #212121;
        font-size: 16px;
        margin-top: 6px;
        padding-right: 10px;
        overflow:hidden;/*超出隐藏*/
        text-overflow:ellipsis;/*文本溢出时显示省略标记*/
        display:-webkit-box;/*设置弹性盒模型*/
        -webkit-line-clamp:1;/*文本占的行数,如果要设置2行加...则设置为2*/
        -webkit-box-orient:vertical;/*子代元素垂直显示*/
      }
      .packgrade {
        color: #8F8F8F;
        margin: 15px 0 0;
        font-size: 12px;
        overflow:hidden;/*超出隐藏*/
        text-overflow:ellipsis;/*文本溢出时显示省略标记*/
        display:-webkit-box;/*设置弹性盒模型*/
        -webkit-line-clamp:1;/*文本占的行数,如果要设置2行加...则设置为2*/
        -webkit-box-orient:vertical;/*子代元素垂直显示*/
      }
      .packtype {
        color: #3583FF;
        background-color: #DFEBFF;
        width: 70%;
        min-height: 26px;
        line-height: 26px;
        text-align: center;
        border-radius: 14px 14px 14px 0px;
      }
    }
  }
  .packageright {
    img {
      width: 100%;
      border-radius: 10px;
      margin-bottom: 15px;
    }
  }
  @media screen and (min-width: 1440px) {
    .packagecon {
      margin-bottom: 22px;
      .imgbox {
        border-radius: 10px;
        img {
          width: 100%;
        }
      }
      .titlebox {
        .packtitle {
        margin-top: 12px;
        }
        .packgrade {
          margin: 30px 0 0;
          font-size: 14px;
        }
      }
    }

  }
</style>
