<template>
  <router-view></router-view>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
  }
}
</script>
<style lang="less" scoped>

</style>
