import { axios } from '@/axios/request'
// 获取管理员列表
export function getManagerList(parameter) {
  return axios({
    url: '/axt-usercenter/manage_teacher/getManagerList',
    data: parameter,
    method: 'post'
  })
}
// 查看操作权限
export function getManagerAuthMenuByTeacherId(parameter) {
  return axios({
    url: '/axt-website/manage_menu/getManagerAuthMenuByTeacherId',
    params: parameter,
    method: 'get'
  })
}
// 查看数据权限
export function getManageUnit(parameter) {
  return axios({
    url: '/axt-website/manage_unit/getManageUnit',
    data: parameter,
    method: 'post'
  })
}
// 获取操作权限列表
export function getManageMenuList(parameter) {
  return axios({
    url: '/axt-website/manage_menu/getManageMenuList',
    method: 'get'
  })
}
// 保存管理员权限
export function saveManagerAuth(parameter) {
  return axios({
    url: '/axt-usercenter/manage_teacher/saveManagerAuth',
    data: parameter,
    method: 'post'
  })
}
// 删除管理员权限
export function deleteManagerAuth(url) {
  return axios({
    url: url,
    method: 'post'
  })
}
