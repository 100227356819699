<template>
  <router-view></router-view>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'NetManage',
  data() {
    return {

    }
  },
  created() {
    console.log(typeof this.manageData.adminType, 'this.manageData.adminType')
    // this.mainHei = this.$refs.content.offsetHeight - 60 + 'px'
    if (this.manageData.adminType !== '1') {
      this.$router.push({ path: '/mynews/newsmanagement' })
    }
  },
  computed: {
    ...mapGetters(['manageData'])
  },
  methods: {

  }
}
</script>
<style lang="less" scoped>

</style>
