<template>
  <div class="banner">
    <div class="edit_box">
      <div class="title">标题栏</div>
      <div class="radio_box">
        <el-radio-group v-model="linkobj.titleShow" @change="change">
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="0">不显示</el-radio>
        </el-radio-group>
      </div>
    </div>
    <!-- <div class="edit_box">
      <div class="title">是否展示<span>更多</span></div>
      <div class="radio_box">
        <el-radio-group v-model="linkobj.moreShow" @change="change">
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="0">不显示</el-radio>
        </el-radio-group>
      </div>
    </div> -->
    <div class="edit_box">
      <div class="title">标题名称</div>
      <div class="radio_box">
        <el-input v-model="linkobj.title" placeholder="请输入标题名称" clearable :disabled="linkobj.titleShow === 0" @change="change"/>
        <div class="line"></div>
      </div>
    </div>
    <div class="title">展示内容</div>
    <div v-for="(item, index) in linkobj.infos" :key="index" class="banner_list">
      <img v-if="item.picPath" :title="item.picPath" :src="$futureImgUrl+item.picPath" alt="">
      <div v-else class="addbox">
        <uploadcropper :isbanner="true" :config="config" :cropoption="cropoption" @filePath="data => filePath(index, data)"/>
      </div>
      <div class="linkbox">
        <a v-if="item.urlPath" href="Javascript:;" class="link" @click="addlink(index, item.urlPath)" :title="item.urlPath">跳转链接: {{ item.urlPath }}</a>
        <a v-else href="Javascript:;" class="link btn_addlink" @click="addlink(index)">添加跳转链接</a>
      </div>
      <a href="javascript:;" class="btn_delete" @click="deleteimg(index)"><img src="/images/myNews/delete_n.png" alt=""></a>
    </div>
    <div class="tip">* 建议尺寸： 150*55px，JPG、PNG格式。</div>
    <el-button class="btn_addlist" round @click="add">
      <img src="/images/index/btn_add.png" alt="">
      添加更多
    </el-button>

    <!--  -->
    <el-dialog title="添加跳转链接" :visible.sync="linkshow" width="570px" class="linkdialog" :before-close="handleClose">
      <span>
        链接地址：<el-input placeholder="http://" v-model="linkinput" clearable ></el-input>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="mini" round>取 消</el-button>
        <el-button type="primary" size="mini" @click="closelink" round>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import uploadcropper from '@/components/upload/uploadcropper.vue'
import { mapGetters } from 'vuex'
import { updateConfigAddHpStyleBodyPlateInfoShow, updateConfigUpdateHpStyleBodyPlateInfoShow } from '@/api/list.js'
export default {
  name: 'EditLink',
  data() {
    return {
      linkobj: {},
      efitlink: 0,
      linkshow: false,
      linkinput: null,
      config: {
        baseImageUrl: '',
        size: 10,
        type: ['image/jpeg', 'image/png'],
        errormsg: '上传图片只能是 JPG 或 PNG 格式!'
      },
      cropoption: {
        autoCropWidth: 150,
        autoCropHeight: 55,
        fixedNumber: [150, 55]
      }
    }
  },
  components: { uploadcropper },
  props: {
    itemData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['indexData'])
  },
  watch: {
    itemData: {
      handler() {
        this.getlist()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    add() {
      const obj = {
        picPath: '',
        urlPath: '',
        plateId: 0,
        sortId: 0,
        styleId: 0,
        type: 3,
        bodyId: 0,
        websiteId: this.indexData.websiteId
      }
      this.linkobj.infos.push(obj)
      this.$emit('changedata', {
        type: 'link',
        data: this.linkobj
      })
    },
    addlink(index, link) {
      this.linkshow = true
      this.efitlink = index
      if (link) {
        this.linkinput = link
      }
    },
    handleClose() {
      this.linkshow = false
      this.linkinput = null
    },
    closelink() {
      this.linkshow = false
      this.linkinput = this.linkinput.trim()
      const params = {
        id: this.linkobj.infos[this.efitlink].id
        // urlPath: this.linkinput
      }
      if (this.linkinput.includes('http')) {
        params.urlPath = this.linkinput
      } else {
        params.urlPath = 'http://' + this.linkinput
      }
      updateConfigUpdateHpStyleBodyPlateInfoShow(params).then((res) => {
        if (res.message === 'success') {
          if (res.data) {
            this.linkobj.infos[this.efitlink].urlPath = params.urlPath
            console.log(this.linkobj, 'this.linkobj')
            this.linkinput = null
            this.$emit('changedata', {
              type: 'link',
              data: this.linkobj
            })
          }
        }
      })
    },
    deleteimg(index) {
      this.linkobj.infos.splice(index, 1)
      this.$emit('changedata', {
        type: 'link',
        data: this.linkobj
      })
    },
    filePath(index, data) {
      console.log(index, data, 'index, data')
      const params = {
        bodyId: 0,
        picPath: data.imgpath,
        plateId: 0,
        sortId: 0,
        styleId: 0,
        type: 3,
        urlPath: '',
        websiteId: this.indexData.websiteId
      }
      updateConfigAddHpStyleBodyPlateInfoShow(params).then((res) => {
        if (res.message === 'success') {
          this.linkobj.infos[index].picPath = data.imgpath
          this.linkobj.infos[index].id = res.data
        }
      })
      this.$emit('changedata', {
        type: 'link',
        data: this.linkobj
      })
    },
    getlist() {
      this.linkobj = {}
      this.linkobj = { ...this.itemData }
    },
    change() {
      console.log('s')
      this.$emit('changedata', {
        type: 'link',
        data: this.linkobj
      })
    }
  }
}
</script>

<style lang="less" scoped>
.banner{
  text-align: left;
  font-size: 20px;
  .title{
    font-size: 14px;
    font-weight: 500;
    color: #666666;
    span{
      color: #5096F5;
    }
  }
  & >.title{
    margin-bottom: 10px;
  }
  .radio_box{
    margin:5px 0 20px;
    /deep/.el-input{
      margin-top: 10px;
      .el-input__inner{
        border-radius: 6px;
      }
    }
    .line{
      margin-top: 20px;
      height: 3px;
      background: #F3F5FA;
    }
  }
  .banner_list{
    position: relative;
    overflow: hidden;
    border-radius:  0 6px 0 6px;
    img{
      max-width: 100%;
      display: block;
      border-radius: 6px;
    }
    .linkbox {
      margin: 5px 0 10px;
      line-height: 20px;
    }
    .link{
      color: #999;
      font-weight: 500;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
    .btn_addlink{
      color: #5096F5;
    }
    &:hover{
      .btn_delete{
        opacity: 1;
        right: 10px;
      }
    }
    .btn_delete{
      position: absolute;
      top: 10px;
      right: -30px;
      height: 28px;
      opacity: 0;
      width: 28px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      transition: 0.4s all ease;
      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
      }
    }
  }
  .tip{
    font-size: 12px;
    color: #999999;
  }
  .btn_addlist{
    margin: 50px 0 20px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #5096F5;
    padding: 0;
    height: 34px;
    img{
      width: 12px;
      margin-right: 10px;
    }
    border-color: #5096F5;
  }
  .linkdialog{
    .el-input{
      width: 350px;
    }
    span{
      color: #7B7B7B;
    }
  }
}
</style>
