<template>
  <div id="headtop">
    <Row class="headContent">
      <Col span="3" class="imgbox"><img src="/images/index/logo.png" alt=""></Col>
      <Col span="18">
        <Row class="nav">
          <Col :span="navLength" class="pointer navtab" v-for="(item,index) in navList" :key="index">
            {{ item }}
          </Col>
        </Row>
      </Col>

      <Col span="3">
        <div class="loginbtn pointer">登录</div>
      </Col>
    </Row>
  </div>

</template>
<script>
export default {
  name: 'HeadTop',
  props: {

  },
  data() {
    return {
      navLength: 4,
      navList: [
        '首页',
        '资源中心',
        '新闻中心',
        '名师风采',
        '学校展示',
        'app下载'
      ]
    }
  },
  created() {

  },
  watch: {

  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style scoped>
  #headtop {
    width: 100%;
    padding:0 2.5%;
    height: 60px;
    line-height: 60px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 3px 15px -4px rgba(0, 0, 0, 0.07);
  }
  #headtop.active {
    height: 60px;
    line-height: 60px;
  }
  .headContent .imgbox {
    text-align: left;
  }
  .headContent .imgbox img {
    height: 30px;
    vertical-align: middle;
    transition: all .5s;
  }
  .nav {
    justify-content:flex-end;
  }
  .loginbtn {
    float: right;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    color: #444444;
    border: 1px solid #444444;
    margin-top: 15px;
    border-radius: 4px;
    transition: all .5s;
  }
  #headtop.active .loginbtn {
    margin-top: 15px;
  }
</style>
