<template>
  <div class="articlebox">
    <div class="classifyManage_cont" ref="myHeight">
      <div class="seachbox clearfix">
        <div class="inputbox">
          <el-input
            placeholder="请输入通知标题"
            prefix-icon="el-icon-search"
            v-model="input2"
            @change="searchlist">
          </el-input>
        </div>
      </div>
      <div class="tablecontent">
        <el-table :data="tableData" v-loading="loading" style="width: 100%" :header-cell-style="{background:'#f2f2f2'}">
          <el-table-column prop="text1" show-overflow-tooltip label="通知标题"></el-table-column>
          <el-table-column prop="text1" show-overflow-tooltip label="通知范围"></el-table-column>
          <el-table-column prop="text1" show-overflow-tooltip min-width="100" label="通知时间" ></el-table-column>
          <el-table-column fixed="right" label="操作" width="200" >
            <!-- <template slot-scope="scope">
              <img class="editImg hover" :src="scope.row.isPressS || scope.row.isShow?showImg_P:showImg_N" @click="showClick(scope.row)">
              <img class="editImg hover" :src="scope.row.isPressD?delImg_P:delImg_N" @click="deleteClick(scope.row)" @mousemove="deleteHover(scope.row)" @mouseout="deleteOut(scope.row)">
            </template> -->
            <template slot-scope="scope">
              <el-button class="danger" @click.stop="handleDelete(scope.$index, scope.row)" type="text" :disabled="scope.row.websiteId=== 0 || scope.row.websiteId !== indexData.websiteId" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

      </div>
      <div class="add_btn">
        <el-button icon="el-icon-plus" round @click="open">添加学校</el-button>
        <div class="page" v-if="total >10">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="10"
            @current-change="changePage">
          </el-pagination>
        </div>
      </div>
    </div>
    <!--  -->
    <el-dialog
      title="添加学校"
      :visible.sync="linkshow"
      width="650px"
      height="500px;"
      class="linkdialog"
      :destroy-on-close="true"
      :before-close="handleClose">
      <el-form label-width="100px">
        <el-form-item label="学校名称">
          <el-input class="addname" v-model="formdata.infoname" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item class="editorbox" label="学校宣传照">
          <upload @filePath="filePath" :isschoolinfo="true" :config="config"></upload>
        </el-form-item>
        <el-form-item label="学校地址">
          <el-input class="addname" v-model="formdata.schooladress" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="学校链接">
          <el-input class="addname" v-model="formdata.schoolnet" placeholder="http://"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="mini" round>取 消</el-button>
        <el-button type="primary" size="mini" @click="closelink" round>确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>
<script>
import { mapGetters } from 'vuex'
import { getHpStyleInfoShowsByType } from '@/api/editIndex.js'
import upload from '@/components/upload/upload.vue'
// import addArticle from '../messageBox/addArticle.vue'
export default {
  components: { upload },
  name: 'ClassifyManage',
  data() {
    return {
      total: 0,
      input2: '',
      tableData: [],
      showImg_N: '/images/myNews/hide.png',
      showImg_P: '/images/myNews/show.png',
      delImg_N: '/images/myNews/delete_n.png',
      delImg_P: '/images/myNews/delete_p.png',
      options: [],
      value: 0,
      linkshow: false,
      loading: true,
      infoname: '',
      textarea: '',
      schooladress: '',
      config: {
        baseImageUrl: '',
        tip: '*建议上传jpg/png文件，建议尺寸： 828*466px，且不超过 10Mb',
        size: 10,
        type: ['image/jpeg', 'image/png'],
        errormsg: '上传图片只能是 JPG 或 PNG 格式!'
      },
      schoolnet: '',
      formdata: {}
    }
  },
  created() {

  },
  computed: {
    ...mapGetters(['indexData', 'newstype'])
  },
  mounted() {
    this.options = this.newstype
    this.getHpStyleInfoShowsByType(1)
  },
  methods: {
    // 翻页
    changePage(page) {
      this.page = page
      this.getHpStyleInfoShowsByType(page, this.newsTypeName, this.firstTitle)
    },
    // 模糊查询
    searchlist() {
      // console.log(this.input2)
      this.firstTitle = this.input2
      this.getHpStyleInfoShowsByType(this.page, this.newsTypeName, this.input2)
    },
    // 选择新闻类型
    choseNewsType(id) {
      if (id === 0) {
        id = null
      }
      this.newsTypeName = id
      this.getHpStyleInfoShowsByType(1, this.newsTypeName, this.firstTitle)
    },
    // 获取信息列表
    getHpStyleInfoShowsByType() {
      console.log(this.indexData)
      this.loading = true
      const data = {
        websiteId: this.indexData.websiteId,
        type: 2,
        pageNo: 1,
        pageSize: 10
      }
      getHpStyleInfoShowsByType(data).then(res => {
        console.log(res)
        if (res.message === 'success') {
          this.loading = false
          this.tableData = res.data.rows || []
          this.total = res.data.total
        }
      })
    },
    handleClose() {
      this.linkshow = false
      this.formdata = {}
    },
    closelink() {
      this.linkshow = false

      this.formdata = {}
    },
    // 获取上传图片地址
    filePath(res) {
      console.log(res, 'path')
      this.config.baseImageUrl = this.$futureImgUrl + res.imgpath
      this.formdata.baseImageUrl = res.imgpath
    },
    // 表格操作
    handleDelete(index, row) {
      // console.log(index, row)
      this.$confirm('此操作将永久删除该老师, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // const data = {
        //   website_id: this.indexData.websiteId,
        //   news_id: row.id
        // }
        // deleteAxtNewSourceByManager(data).then((res) => {
        //   if (res.message === 'success') {
        //     this.tableData.splice(index, 1)
        //     this.$message({
        //       type: 'success',
        //       message: '删除成功!',
        //       center: true
        //     })
        //   }
        // })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
          center: true
        })
      })
    },
    // 添加文章
    open() {
      this.linkshow = true
    }
  }
}
</script>
<style lang="less" scoped>
.classifyManage_cont{
    padding: 0 30px;
    position: relative;
    height: 100%;
    padding-top: 30px;
    overflow: hidden;
    background: #fff;
}
.tximg {
  height: 50px;
}
.tablecontent {
  position: relative;
  max-height: calc(100% - 150px);
  overflow-y: auto;
  .danger {
    color: #f56c6c;
  }
  .danger[disabled]{
    color: #C0C4CC;
  }
}
.page {
  position: absolute;
  top: 5px;
  right: 30px;
}
.articlebox {
  height: 100%;
}
.editImg{
    width: 18px;
    height: 12px;
    margin-right: 15px;
}
.add_btn{
    width: 100%;
    margin-top: 20px;
    text-align: left;
    position: relative;
    .el-button.is-round{
      border: 1px solid #5096F5;
      color: #5096F5;
  }
}
.linkdialog {
  text-align: left;
  /deep/.el-form-item__content {
    width: 319px;
  }
  .editorbox /deep/.el-form-item__content {
    // width: 100%;
    .el-upload__tip {
      position: absolute;
      top: 25px;
      left: 90px;
      line-height: 1.6;
    }
  }
  /deep/.el-upload__tip {
    display: inline-block;
    margin-left: 20px;
  }
  /deep/.el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &.el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 14px;
      color: #8c939d;
      width: 85px;
      height: 85px;
      line-height: 85px;
      text-align: center;
    }
    .avatar {
      height: 85px;
      display: block;
    }
  }
}
.seachbox {
  padding-bottom: 18px;
}
.inputbox, .selectbox {
  float: left;
}
.inputbox {
  padding-right: 10px;
  /deep/.el-input__icon{
    line-height: 40px;
  }
}
</style>
<style lang="less">
// .inputbox .el-input__icon{
//   line-height: 40px;
// }
 .selectbox .el-input__icon {
  line-height: 35px;
}
.articlebox {
  .el-table{
    border-radius: 10px 10px 0 0;
  }
  .el-table tr {
    cursor: pointer;
  }
  .el-table .cell {
    white-space: nowrap;
  }
  .el-table table {
    text-align: left;
  }
  .el-table__fixed-right::before, .el-table__fixed::before {
    height: 0;
  }
}
.tablecontent {
  .el-table th,
  .el-table td{
    padding-left: 10px;
  }
  .el-table tr td:first-of-type,
  .el-table tr th:first-of-type{
    padding-left: 30px;
    padding-right: 20px;
  }
}
.selectbox {
  .is-focus input {
    color: #5096F5;
  }
  .el-icon-arrow-up:before {
    content: "";
  }
  .is-focus .el-icon-arrow-up:before {
    content: "";
    color: #5096F5;
  }
}
</style>
