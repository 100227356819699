<template>
  <el-dialog
    :title="title"
    :visible="dialogVisible"
    width="800"
    :modal="true"
    :close-on-click-modal="false"
    :append-to-body="true"
    :before-close="handleClose"
    class="addClassify_cont">
    <div class="addClassify_info">
      <el-form label-width="80px">
        <el-form-item label="文章名称">
          <el-input class="addname" v-model="classifyTitle" maxlength="30" show-word-limit placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item class="editorbox" label="文章封面">
          <uploadcropper @filePath="filePath" :config="config" :cropoption="cropoption"></uploadcropper>
        </el-form-item>
        <el-form-item label="文章分类">
          <el-select v-model="value" placeholder="全部分类">
            <el-option
              label="全部分类"
              :value="0">
            </el-option>
            <el-option
              v-for="item in newstype"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="editorbox" label="文章类型">
          <el-radio-group v-model="recordTypeStr" @change="change" :disabled="newscontent && newscontent.changeType === 0">
            <el-radio :label="0">原创</el-radio>
            <el-radio :label="1">外部报道</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="editorbox" label="文章详情" v-if="recordTypeStr === 0">
          <wangeditor ref="editor" :newContent="newscontent.newContent"></wangeditor>
        </el-form-item>
        <el-form-item label="外部链接" v-if="recordTypeStr === 1">
          <el-input v-model="myLink" show-word-limit placeholder="http://"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button round class="btn" @click="handleClose(0)">取 消</el-button>
        <el-button round class="btn" type="primary" @click="handleClose(1)">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import uploadcropper from '@/components/upload/uploadcropper.vue'
import { addAxtNewSourceByManager, updateAxtNewSourceByManager } from '@/api/list.js'
import { mapGetters } from 'vuex'
import wangeditor from '@/components/wangeditor/wangeditor.vue'
export default {
  name: 'AddArticle',
  components: {
    wangeditor,
    uploadcropper
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    newscontent: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // dialogVisible: true,
      classifyTitle: '',
      value: 0,
      title: '',
      recordTypeStr: 0,
      myLink: '',
      config: {
        baseImageUrl: '',
        tip: '*建议上传jpg/png文件，建议尺寸： 828*466px，且不超过 10Mb',
        size: 10,
        type: ['image/jpeg', 'image/png'],
        errormsg: '上传图片只能是 JPG 或 PNG 格式!'
      },
      cropoption: {
        autoCropWidth: 828,
        autoCropHeight: 466,
        fixedNumber: [828, 466]
      }
    }
  },
  created() {

  },
  watch: {
    dialogVisible() {

    },
    newscontent: {
      handler(newVal) {
        console.log(newVal, this.newstype, 'cccccccccc')
        this.classifyTitle = newVal.firstTitle || ''
        // this.value = newVal.classifyId || 0
        const arr = this.newstype.filter(v => {
          return v.id === newVal.classifyId
        })
        if (arr && arr.length > 0) {
          this.value = newVal.classifyId
        } else {
          this.value = 0
        }
        if (newVal.recordTypeStr === '外部报道') {
          this.recordTypeStr = 1
          this.myLink = newVal.myLink
        }
        this.title = newVal.title || '添加文章'
        this.config.baseImageUrl = newVal.surfaceImg || ''
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['indexData', 'newstype'])
  },
  mounted() {

  },
  methods: {
    change() {
      console.log(this.recordTypeStr, 'recordTypeStr')
    },
    handleClose(res) {
      if (res === 1) {
        if (this.classifyTitle.trim() === '') {
          this.$message({
            message: '文章标题名称不能为空',
            type: 'warning'
          })
          return
        }
        if (this.recordTypeStr === 0) {
          this.$refs.editor.getContent()
          // console.log(this.$refs.editor.updatenewscontent, 'hhhhhhhhhhh')
          if (!this.$refs.editor.updatenewscontent) {
            this.$message({
              message: '文章内容不能为空',
              type: 'warning'
            })
            return
          }
        }

        if (!this.config.baseImageUrl) {
          this.$message({
            message: '文章封面不能为空',
            type: 'warning'
          })
          return false
        }
        const params = {
          firstTitle: this.classifyTitle,
          // newContent: this.$refs.editor.updatenewscontent,
          // recordTypeStr: '原创',
          websiteId: this.indexData.websiteId,
          surfaceImg: this.config.baseImageUrl
        }
        if (this.recordTypeStr === 0) {
          params.recordTypeStr = '原创'
          params.newContent = this.$refs.editor.updatenewscontent
        } else if (this.recordTypeStr === 1) {
          params.recordTypeStr = '外部报道'
          params.myLink = this.myLink && this.myLink.includes('http') ? this.myLink : 'http://' + this.myLink
        }
        if (this.value !== 0) {
          params.classifyId = this.value
        }
        if (this.newscontent && this.newscontent.changeType === 1) {
          addAxtNewSourceByManager(params).then((res) => {
            if (res.message === 'success') {
              const data = { changeState: true, isClose: true }
              this.$emit('submit', data)
            }
          })
        } else if (this.newscontent && this.newscontent.changeType === 0) {
          params.id = this.newscontent.id
          updateAxtNewSourceByManager(params).then((res) => {
            // console.log(res, 'xiugai')
            if (res.message === 'success') {
              const data = { changeState: true, isClose: true }
              this.$emit('submit', data)
            }
          })
        }
      } else {
        const data = { changeState: false, isClose: true }
        this.$emit('submit', data)
      }
    },
    // 获取上传图片地址
    filePath(res) {
      console.log(res, 'path')
      this.config.baseImageUrl = this.$futureImgUrl + res.imgpath
    }
  }
}
</script>
<style lang="less" scoped>
.addClassify_box{
  width:850px;
  padding: 20px;
  box-sizing: border-box;
  background: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border-radius: 10px;
}
.viewimg {
    height: 85px;
  }
.addClassify_title{
    font-size: 16px;
    color: #333;
    text-align: left;
    font-weight: 600;
}
.addClassify_info{
    /deep/.el-upload__tip {
    display: inline-block;
    margin-left: 20px;
  }
  /deep/.el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &.el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 14px;
      color: #8c939d;
      width: 85px;
      height: 85px;
      line-height: 85px;
      text-align: center;
    }
    .avatar {
      // width: 85px;
      height: 85px;
      display: block;
    }
  }
}

.waring{
  font-size: 12px;
  color: #999;
  text-align: left;
  padding-left: 80px;
}
.waring span{
    color: red;
}
.btn{
  width: 60px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  padding: 0!important;
}
.cancel_btn{
  color: #999999!important;
  border: 1px solid #CCCCCC!important;
}
.sure_btn{
  background: #5096F5;
  color: white!important;
}
.addClassify_btn{
    text-align: right;
    padding-right: 20px;
}
</style>
