import { axios } from '@/axios/request'
// 获取网站管理信息
export function getHpStyleByWebsiteId(parameter) {
  return axios({
    url: '/axt-website/hp/getHpStyleByWebsiteId',
    params: parameter,
    method: 'get'
  })
}
// 获取banner
export function getAxtLunboSourceList(parameter) {
  return axios({
    url: '/axt-mhcz/axtLunboSource/getAxtLunboSourceList',
    params: parameter,
    method: 'get'
  })
}
// 获取新闻
export function getAxtNewSourceList(parameter) {
  return axios({
    url: '/axt-mhcz/axtNewSource/getAxtNewSourceList',
    params: parameter,
    method: 'get'
  })
}

// 根据websiteId获取 区域信息
export function getHpStyleMapByWebsiteId(parameter) {
  return axios({
    url: '/axt-website/hp/getHpStyleMapByWebsiteId',
    params: parameter,
    method: 'get'
  })
}
// 根据区域信息获取学校数量
export function getSchoolCountByPCA(parameter) {
  return axios({
    url: '/axt-crm/datacount/getSchoolCountByPCA',
    params: parameter,
    method: 'get'
  })
}

// 根据区域信息获取学生数量
export function getStudentCountByPCA(parameter) {
  return axios({
    url: '/axt-crm/datacount/getStudentCountByPCA',
    params: parameter,
    method: 'get'
  })
}

// 获取分地区 区域信息
export function getDataAnalysisByWebsiteId(parameter) {
  return axios({
    url: '/axt-website/website_auth/getDataAnalysisByWebsiteId',
    data: parameter,
    method: 'post'
  })
}

// 获取区域学校数量信息
export function getSchoolCountByWebsiteId(parameter) {
  return axios({
    url: '/axt-website/website_auth/getSchoolCountByWebsiteId',
    params: parameter,
    method: 'get'
  })
}
// 根据区域信息获取教师数量
export function getTeacherCountByPCA(parameter) {
  return axios({
    url: '/axt-crm/datacount/getTeacherCountByPCA',
    params: parameter,
    method: 'get'
  })
}

// 获取区域教师数量
export function getTeacherNumAndActivateNum(parameter) {
  return axios({
    url: '/axt-statistics/statistics_teacher/getTeacherNumAndActivateNum',
    data: parameter,
    method: 'post'
  })
}
// 获取区域学生数量
export function getStudentNumAndActivateNum(parameter) {
  return axios({
    url: '/axt-statistics/statistics_student/getStudentNumAndActivateNum',
    data: parameter,
    method: 'post'
  })
}

// 获取微课

export function pageKnowledgeVideosByCondition(parameter) {
  return axios({
    url: '/axt-future/knowledgeVideo/pageKnowledgeVideosByCondition',
    params: parameter,
    method: 'get'
  })
}
// 获取ppt

export function pageKnowledgePPTsByCondition(parameter) {
  return axios({
    url: '/axt-future/knowledgePPT/pageKnowledgePPTsByCondition',
    params: parameter,
    method: 'get'
  })
}
// 获取课件

export function pageKnowledgeLearningPlansByCondition(parameter) {
  return axios({
    url: '/axt-future/knowledgeLearningPlan/pageKnowledgeLearningPlansByCondition',
    params: parameter,
    method: 'get'
  })
}
// 获取试卷
export function pageHandoutList(parameter) {
  return axios({
    url: '/axt-examination/daily_practice/pageHandoutList',
    data: parameter,
    method: 'post'
  })
}
// 获取展示信息
export function getHpStyleInfoShowsByType(parameter) {
  return axios({
    url: '/axt-website/hpconfig/pageConfigHpStyleBodyPlateInfoShowList',
    data: parameter,
    method: 'post'
  })
}
// 获取备课包信息
export function pageCourse(parameter) {
  return axios({
    url: '/axt-resources/package_prepare/pageCourse',
    data: parameter,
    method: 'post'
  })
}
// 获取页面信息
export function getConfigHpStyleBody(parameter) {
  return axios({
    url: '/axt-website/hpconfig/getConfigHpStyleBody',
    params: parameter,
    method: 'get'
  })
}
// 删除展示信息
export function updateConfigDeleteHpStyleBodyPlateInfoShow(parameter) {
  return axios({
    url: '/axt-website/hpconfig/updateConfigDeleteHpStyleBodyPlateInfoShow',
    data: parameter,
    method: 'post'
  })
}
// 修改首页配置
export function updateConfigHpStyleBody(parameter) {
  return axios({
    url: '/axt-website/hpconfig/updateConfigHpStyleBody',
    data: parameter,
    method: 'post'
  })
}
// 添加banner
export function addAxtLunboSourceByManager(parameter) {
  return axios({
    url: '/axt-mhcz/axtLunboSource/addAxtLunboSourceByManager',
    data: parameter,
    method: 'post'
  })
}
// 修改banner
export function updateAxtLunboSourceByManager(parameter) {
  return axios({
    url: '/axt-mhcz/axtLunboSource/updateAxtLunboSourceByManager',
    data: parameter,
    method: 'post'
  })
}
