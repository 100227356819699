<template>
  <router-view></router-view>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Management',
  data() {
    return {
      txt: ''
    }
  },
  computed: {
    ...mapGetters(['indexData'])
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang="less" scoped>
.management {
  width: 100%;
  height:100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0px;
}
</style>
