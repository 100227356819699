<template>
  <div class="inform">
    <div class="myNews_tab_box">
      <div class="myNews_tab hover">
        <div v-for="(item,index) in tabInfo" :key="index" :class="showTabMain === index?'myNews_tab_item myNews_tab_active':'myNews_tab_item'" @click="choseTab(index,item)"><span>{{ item.name }}</span></div>
      </div>
    </div>
    <div class="myNews_tab_main">
      <left-class class="leftcontent"></left-class>
      <right-class class="rightcontent"></right-class>
    </div>
  </div>
</template>
<script>
import LeftClass from '@/components/management/leftClass.vue'
import RightClass from '@/components/management/rightClass.vue'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Authority',
  data() {
    return {
      txt: '',
      tabInfo: [],
      showTabMain: 0 // 默认显示分类管理 0，文章管理 1
    }
  },
  components: {
    LeftClass,
    RightClass
  },
  watch: {
    navMain: {
      handler(newVal) {
        console.log(newVal, 'newVal')
        this.tabInfo = this.navMain.children ? this.navMain.children : []
        this.tabInfo = this.tabInfo.filter((v) => {
          // return v.code !== '5E42E0AD'
        })
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['navMain'])
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['SET_NAVMAIN']),
    choseTab(res, obj) {
      // 切换tab内容
      this.showTabMain = res
      // console.log(obj)
    }
  }
}
</script>
<style lang="less" scoped>
.inform {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .myNews_tab_main {
    position: relative;
    background: #fff;
    padding-top: 20px;
    height: calc(100% - 65px);
    display: flex;
    height: 100%;
      .leftcontent {
      width: 264px;
      margin: 0 20px;
      border: 1px solid #F2F2F2;
      border-radius: 4px 4px 0px 0px;
      position: relative;
    }
    .rightcontent {
      flex: 1;
      width: calc(100% - 334px);
      margin-right: 30px;
      // border: 1px solid #F2F2F2;
    }
  }
}

</style>
