<template>
  <div class="banner">
    <div class="edit_box">
      <div class="title">标题栏</div>
      <div class="radio_box">
        <el-radio-group v-model="linkobj.titleShow" @change="change">
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="0">不显示</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="edit_box">
      <div class="title">是否展示<span>更多</span></div>
      <div class="radio_box">
        <el-radio-group v-model="linkobj.moreShow" @change="change">
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="0">不显示</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="edit_box">
      <div class="title">标题名称</div>
      <div class="radio_box">
        <el-input v-model="linkobj.title" placeholder="请输入标题名称" clearable :disabled="linkobj.titleShow === 0" @change="change"/>
        <div class="line"></div>
      </div>
    </div>
    <div class="title">展示内容</div>
    <div class="edit_list">
      <div v-for="(item, index) in linkobj.infos" :key="index" class="list">
        <div class="txt" :title="item.text1">{{ item.text1 }}</div>
        <a href="javascript:;" class="btn_delete" @click="deleteimg(index)"><img src="/images/myNews/delete_n.png" alt=""></a>
      </div>
      <el-button class="btn_addlist" @click="add">
        <img src="/images/index/btn_add.png" alt="">
        选择学校
      </el-button>
    </div>

    <!--  -->
    <el-dialog title="选择学校" :visible.sync="linkshow" width="880px" class="linkdialog" :before-close="handleClose">
      <SeachModule placeholder="搜索学校名称" v-if="linkshow" @searchData="searchData">
        <TableSchool ref="tabledata" :loading="loading" :tableData="tableData" :infos="linkobj.infos" :filterarr="filterarr"/>
        <el-pagination
          v-if="total > 10"
          class="pagination"
          background
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </SeachModule>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="mini" round>取 消</el-button>
        <el-button type="primary" size="mini" @click="closelink" round>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SeachModule from '@/components/netManage/SeachModule.vue'
import TableSchool from '@/components/netManage/TableSchool.vue'
import { getHpStyleInfoShowsByType } from '@/api/editIndex.js'
export default {
  name: 'EditSchool',
  data() {
    return {
      linkobj: {},
      efitlink: 0,
      linkshow: false,
      linkinput: null,
      loading: false,
      tableData: [],
      total: 0,
      filterarr: []
    }
  },
  components: { SeachModule, TableSchool },
  props: {
    itemData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['indexData', 'editBody', 'selectIndex'])
  },
  watch: {
    itemData: {
      handler() {
        this.getlist()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    add() {
      this.filterarr = []
      this.linkshow = true
      const arr = this.editBody.plates.filter((v, i) => {
        return i !== this.selectIndex && v.type === 6 && v.style === 2
      })
      const ids = []
      if (arr && arr.length > 0) {
        arr.forEach(v => {
          if (v.infos && v.infos.length > 0) {
            v.infos.forEach(vv => {
              ids.push(vv.id)
            })
          }
        })
        this.filterarr = ids
        console.log(ids, 'ids')
      }
      this.getHpStyleInfoShowsByType()
    },
    handleClose() {
      this.linkshow = false
    },
    closelink() {
      const data = this.$refs.tabledata.infosdata
      if (data.length > 7) {
        this.$toast('最多选7所学校')
        return false
      }
      this.linkshow = false
      this.linkobj.infos = [...data]
      this.$emit('changedata', {
        type: 'link',
        data: this.linkobj
      })
    },
    deleteimg(index) {
      this.linkobj.infos.splice(index, 1)
      this.$emit('changedata', {
        type: 'link',
        data: this.linkobj
      })
      console.log(this.linkobj, 'this.linkobj')
    },
    getlist() {
      this.linkobj = { ...this.itemData }
      console.log(this.linkobj)
    },
    change() {
      console.log('change')
      this.$emit('changedata', {
        type: 'link',
        data: this.linkobj
      })
    },
    getHpStyleInfoShowsByType(page, title) {
      console.log(this.indexData)
      this.loading = true
      const data = {
        websiteId: this.indexData.websiteId,
        type: 2,
        text1: title || null,
        pageNo: page || 1,
        pageSize: 10
      }
      getHpStyleInfoShowsByType(data).then(res => {
        console.log(res)
        if (res.message === 'success') {
          this.loading = false
          this.tableData = res.data.rows || []
          this.total = res.data.total
        }
      })
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.getHpStyleInfoShowsByType(val)
    },
    searchData(val) {
      console.log(val)
      this.getHpStyleInfoShowsByType(1, val)
    }
  }
}
</script>

<style lang="less" scoped>
.banner{
  text-align: left;
  font-size: 20px;
  .title{
    font-size: 14px;
    font-weight: 500;
    color: #666666;
    span{
      color: #5096F5;
    }
  }
  .radio_box{
    margin:5px 0 20px;
    /deep/.el-input{
      margin-top: 10px;
      .el-input__inner{
        border-radius: 8px;
      }
    }
    .line{
      margin-top: 20px;
      height: 3px;
      background: #F3F5FA;
    }
  }
  .edit_list{
    border-radius:  6px;
    background: #F2F2F2;
    margin: 20px 0 0;
    padding: 10px;
    .list{
      margin-bottom: 5px;
      height: 44px;
      border-radius: 6px;
      background: #fff;
      line-height: 44px;
      font-size: 12px;
      color: #666666;
      padding: 0 30px;
      position: relative;
      .txt{
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .btn_delete{
      position: absolute;
      top: 10px;
      right: 10px;
      height: 28px;
      width: 28px;
      transition: 0.4s all ease;
      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;

      }
    }
  }

  .btn_addlist{
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #5096F5;
    padding: 0;
    height: 44px;
    img{
      width: 12px;
      margin-right: 10px;
    }
    border: none;
  }
  .linkdialog{
    .el-input{
      width: 350px;
    }
    span{
      color: #7B7B7B;
    }
    .pagination{
      text-align: center;
      margin-top: 20px;
    }
  }
}
</style>
