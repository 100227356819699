<template>
  <div>
    <div class="tabs">
      <div class="tab" :class="active === 1? 'active':''" @click="changeTab(1)">单位</div>
      <div class="tab" :class="active === 0? 'active':''" @click="changeTab(0)">学校</div>
    </div>
    <div class="school" v-if="active === 0">
      <div class="search">
        <el-button icon="el-icon-plus" round @click="distributionSchool(treeData)" v-if="treeData && treeData.type === 'company'">分配学校</el-button>
        <el-input v-model="seachSchoolname" placeholder="请输入内容" clearable></el-input>
        <el-button type="primary" @click="getPageSchools()">查询</el-button>
      </div>
      <div class="top">
        <el-table
          :data="schoolData"
          v-loading="loading"
          border
          :header-cell-style="{background:'#f2f2f2'}">
          <el-table-column prop="schoolName" label="学校名称" align="center"></el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row,'school')" type="text" size="small">查看教职工</el-button>
              <el-button @click="lookStudentList(scope.row)" type="text" size="small">查看学生</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="botoom">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page.sync="currentPage"
          @current-change="changePage">
        </el-pagination>
      </div>
    </div>
    <div class="school admin" v-else>
      <div class="search">
        <el-button icon="el-icon-plus" round @click="oenAddAdmin()" v-if="!isOpenadmin">添加单位</el-button>
        <el-button icon="el-icon-plus" round @click="openAddZiAdmin()" v-else>添加子单位</el-button>
      </div>
      <div class="top">
        <el-table
          :data="companyData"
          v-loading="loading"
          border
          :header-cell-style="{background:'#f2f2f2'}">
          <el-table-column prop="companyName" label="单位名称" align="center"></el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button @click.stop="editAdminBtn(scope.row)" type="text" size="small" >编辑</el-button>
              <el-button @click="handleClick(scope.row,'company')" type="text" size="small">查看教职工</el-button>
              <el-button @click.stop="frozen(scope.row,scope.$index)" type="text" size="small" v-if="scope.row.companyState === 1">冻结</el-button>
              <el-button @click.stop="frozen(scope.row,scope.$index)" type="text" size="small" v-else>解冻</el-button>
              <el-button @click.stop="distributionSchool(scope.row)" type="text" size="small">分配学校</el-button>
              <!-- <el-button class="danger" @click.stop="handleDelete(scope.$index, scope.row)" type="text" size="small">删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="botoom">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page.sync="currentPage"
          @current-change="changePage">
        </el-pagination>
      </div>
    </div>

    <!--添加单位-->
    <el-dialog
      title="添加单位"
      :visible.sync="addadmin"
      width="650px"
      class="admindialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :before-close="handleClose">
      <el-form label-width="80px">
        <el-form-item label="所属省" class="adminitem">
          <el-input v-model="adminData.provinceName" :disabled="true"></el-input>
          <span class="sup">*</span>
        </el-form-item>
        <el-form-item label="所属市" class="adminitem">
          <el-input v-model="adminData.cityName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="所属区" class="adminitem">
          <el-input v-model="adminData.areaName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="单位名称" class="adminitem">
          <el-input class="addname" v-model="adminData.name" placeholder="单位"></el-input>
          <span class="sup">*</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" round @click="submitForm()">保 存</el-button>
        <el-button @click="handleClose" size="mini" round>取 消</el-button>
      </span>
    </el-dialog>
    <!--编辑单位-->
    <el-dialog
      title="编辑单位"
      :visible.sync="iseditAdmin"
      width="650px"
      height="400px;"
      class="admindialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :before-close="handleClose">
      <el-form label-width="80px">
        <el-form-item label="所属省" class="adminitem">
          <el-input v-model="editAdmin.provinceName" :disabled="true"> </el-input>
          <span class="sup">*</span>
        </el-form-item>
        <el-form-item label="所属市" class="adminitem">
          <el-input v-model="editAdmin.cityName" :disabled="true"> </el-input>
        </el-form-item>
        <el-form-item label="所属区" class="adminitem">
          <el-input v-model="editAdmin.areaName" :disabled="true"> </el-input>
          </el-select>
        </el-form-item>
        <el-form-item label="单位名称" class="adminitem">
          <el-input class="addname" v-model="editAdmin.name" placeholder="单位"></el-input>
          <span class="sup">*</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" round @click="editCompony()">保 存</el-button>
        <el-button @click="handleClose" size="mini" round>取 消</el-button>
      </span>
    </el-dialog>
    <!---->
    <!--添加子单位-->
    <el-dialog
      title="添加子单位"
      :visible.sync="isAddZiAdmin"
      width="650px"
      height="400px;"
      class="admindialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :before-close="handleClose">
      <el-form label-width="80px">
        <el-form-item label="所属单位" class="adminitem">
          <el-input placeholder="请输入内容" v-model="addZiAdmin.admin" :disabled="true"> </el-input>
          <span class="sup">*</span>
        </el-form-item>
        <el-form-item label="单位名称" class="adminitem">
          <el-input class="addname" v-model="addZiAdmin.name" placeholder="单位"></el-input>
          <span class="sup">*</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" round @click="savaZiAdmin()">保 存</el-button>
        <el-button @click="handleClose" size="mini" round>取 消</el-button>
      </span>
    </el-dialog>
    <!--分配学校-->
    <el-dialog
      title="分配学校"
      :visible.sync="isChoseschool"
      width="650px"
      height="500px"
      class="choseschool"
      :before-close="handleClose">
      <div class="choseschoolbox">
        <div class="itembox" v-for="(item,index) in schoolList" :key="index">
          <el-checkbox true-label="1" false-label="0" :checked="item.ckeck === 1 || item.ckeck === -1" :disabled="item.ckeck === -1" @change="choseSchool(item)">{{ item.schoolName }}</el-checkbox>
        </div>

      </div>
    </el-dialog>

    <!--查看教职工-->
    <el-dialog
      title="教职工列表"
      :visible.sync="isTeacherList"
      width="650px"
      class="looklist"
      :before-close="handleClose">
      <div class="list">
        <el-table
          ref="singleTable"
          :data="allTeacher"
          border
          max-height="360px"
          :header-cell-style="{background:'#f2f2f2'}"
          style="width: 100%">
          <el-table-column type="index" label="序号" width="50" align="center">
          </el-table-column>
          <el-table-column property="name" label="姓名" width="120" align="center">
          </el-table-column>
          <el-table-column property="sex" label="性别" width="120" align="center">
          </el-table-column>
          <el-table-column property="jobNum" label="工号" align="center">
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="listTotal"
            :page-size="10"
            :current-page.sync="listcurrentPage"
            @current-change="listchangePage">
          </el-pagination>
        </div>

      </div>
    </el-dialog>
    <!--查看教职工-->
    <el-dialog
      title="学生列表"
      :visible.sync="isStudentList"
      width="650px"
      class="looklist"
      :before-close="handleClose">
      <div class="list">
        <el-table
          ref="singleTable"
          :data="allStudent"
          border
          max-height="360px"
          :header-cell-style="{background:'#f2f2f2'}"
          style="width: 100%">
          <el-table-column type="index" label="序号" width="50" align="center">
          </el-table-column>
          <el-table-column property="fullName" label="姓名" width="120" align="center">
          </el-table-column>
          <el-table-column property="sex" label="性别" width="120" align="center">
          </el-table-column>
          <el-table-column property="stuNum" label="学号" align="center">
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="listTotal"
            :page-size="10"
            :current-page.sync="listcurrentPage"
            @current-change="listchangePage">
          </el-pagination>
        </div>

      </div>
    </el-dialog>
  </div>

</template>
<script>
import { pageWebsiteAuthCompanys, pageWebsiteAuthSchools, saveCompany, updateCompany, selectSchoolsByCompany, allotSchoolsByCompany } from '@/api/management.js'
import { getStudent, getTeacherList } from '@/api/teacherManage.js'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'RightContent',
  components: {
  },
  data() {
    return {
      currentPage: 1, // 页码
      listcurrentPage: 1, // 页码
      total: 0, // 总数
      listTotal: 0, // 弹窗列表总数
      pageSize: 15,
      lookData: {}, // 查看教职工的参数
      lookType: '', // 查看教职工类型 school 学校 company 单位
      active: 1, // tab切换
      isSchool: 0, // 学校单位切换
      isOpenadmin: false, // 添加单位or子单位
      addadmin: false, // 添加单位
      iseditAdmin: false, // 编辑单位
      isAddZiAdmin: false, // 添加子单位
      isTeacherList: false, // 查看教职工
      isStudentList: false, // 查看学生列表
      isChoseschool: false, // 分配学校的开关
      adminData: {}, // 单位信息
      editAdmin: { // 编辑单位信息

      },
      addZiAdmin: {

      },
      seachSchoolname: null, // 单位名称
      schoolData: [{
        date: '2016-05-02',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }],
      schoolparams: {}, // 获取学校的参数
      companyData: [],
      allTeacher: [], // 全部职工
      allStudent: [], // 全部学生
      schoolList: [], // 分配学校的列表
      loading: false, //
      disData: {},
      distribution: {} // 分配学校的单位信息
    }
  },
  watch: {
    treeData: {
      handler(newVal) {
        console.log(newVal, 'newVal')
        if (newVal.type === 'school') {
          this.$toast('请选择单位！')
          return false
        }
        if (newVal.companyId) {
          this.isOpenadmin = true
        } else {
          this.isOpenadmin = false
        }
        if (this.active === 1) {
          this.getPageCompanys()
        } else {
          this.getPageSchools()
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['treeData', 'indexData'])
  },
  mounted() {

  },
  methods: {
    ...mapMutations(['SET_SAVEOK']),
    // 获取单位
    getPageCompanys(pageNo) {
      const params = {
        pageNo: pageNo || 1,
        pageSize: this.pageSize,
        websiteId: this.indexData.websiteId,
        orderBys: [
          {
            direction: 'desc',
            field: 'create_time'
          }
        ]
      }
      if (this.treeData.provinceId) {
        params.provinceId = this.treeData.provinceId
      }
      if (this.treeData.cityId) {
        params.cityId = this.treeData.cityId
      }
      if (this.treeData.areaId) {
        params.areaId = this.treeData.areaId
      }
      if (this.treeData.companyId) {
        params.parentCompanyId = this.treeData.companyId
      } else {
        params.parentCompanyId = -1
      }
      pageWebsiteAuthCompanys(JSON.stringify(params)).then((res) => {
        console.log(res, '44444')
        if (res.message === 'success') {
          // this.$toast('保存成功')
          this.total = res.data.total
          this.currentPage = res.data.pageNo
          this.companyData = res.data.rows
          if (res.data.pageNo > 1 && res.data.rows.length === 0) {
            this.getPageCompanys(res.data.pageNo - 1)
          }
        }
      })
    },
    // 获取学校列表
    getPageSchools(pageNo) {
      const params = {
        pageNo: pageNo || 1,
        pageSize: this.pageSize,
        websiteId: this.indexData.websiteId,
        orderBys: [
          {
            direction: 'desc',
            field: 'create_time'
          }
        ]
      }
      if (this.seachSchoolname) {
        params.schoolName = this.seachSchoolname
      }
      if (this.treeData.provinceId) {
        params.provinceId = this.treeData.provinceId
      }
      if (this.treeData.cityId) {
        params.cityId = this.treeData.cityId
      }
      if (this.treeData.areaId) {
        params.areaId = this.treeData.areaId
      }
      if (this.treeData.companyId) {
        params.companyId = this.treeData.companyId
      }
      pageWebsiteAuthSchools(JSON.stringify(params)).then((res) => {
        console.log(res, '44444')
        if (res.message === 'success') {
          // this.$toast('保存成功')
          this.total = res.data.total
          this.currentPage = res.data.pageNo
          this.schoolData = res.data.rows
          if (res.data.pageNo > 1 && res.data.rows.length === 0) {
            this.getPageSchools(res.data.pageNo - 1)
          }
        }
      })
    },
    // 获取学生列表
    lookStudentList(row) {
      this.isStudentList = true
      this.schoolparams = row
      this.getStudentData(this.schoolparams)
    },
    getStudentData(row, pageNo) {
      const params = {
        pageNo: pageNo || 1,
        pageSize: '10',
        schoolId: row.schoolId || null,
        provinceId: row.provinceId,
        cityId: row.cityId || null,
        areaId: row.areaId || null
      }
      getStudent(params).then((res) => {
        if (res.message === 'success') {
          this.listTotal = res.data.total
          this.listcurrentPage = res.data.pageNo
          const tableData = res.data.rows
          for (let i = 0; i < tableData.length; i++) {
            const result = tableData[i]
            if (result.sex === 1) {
              result.sex = '男'
            } else {
              result.sex = '女'
            }
          }
          this.allStudent = tableData
        }
      })
    },
    // 查看教职工
    handleClick(row, type, page) {
      this.isTeacherList = true
      this.getTeacherList(row, type, page)
    },
    // 获取教师列表
    getTeacherList(row, type, pageNo) {
      this.lookData = row
      this.lookType = type
      var params = {
        pageNo: pageNo || 1,
        pageSize: '10',
        provinceId: row.provinceId,
        cityId: row.cityId || null,
        areaId: row.areaId || null
      }
      if (type === 'company') {
        params.companyId = row.companyId
      } else if (type === 'school') {
        params.schoolId = row.schoolId
      }
      getTeacherList(params).then((res) => {
        if (res.message === 'success') {
          this.listTotal = res.data.total
          this.listcurrentPage = res.data.pageNo
          const data = res.data.rows
          for (let i = 0; i < data.length; i++) {
            const result = data[i]
            if (result.sex === 1) {
              result.sex = '男'
            } else {
              result.sex = '女'
            }
            if (result.roleType === 2) {
              result.roleType = '教师'
            } else if (result.roleType === 3) {
              result.roleType = '教职工'
            }
            result.createTime = result.createTime.slice(0, 10)
          }
          this.allTeacher = data
        }
      })
    },
    // 添加单位
    submitForm() {
      if (!this.adminData.name) {
        this.$toast('请填写单位名称！')
        return false
      }
      saveCompany(JSON.stringify(this.adminData)).then((res) => {
        console.log(res, '55555555')
        if (res.message === 'success') {
          this.handleClose()
          this.getPageCompanys()
          this.$toast('添加成功')
          this.SET_SAVEOK(true)
        }
      })
    },
    // 添加子单位
    savaZiAdmin() {
      if (!this.addZiAdmin.name) {
        this.$toast('请填写子单位名称！')
        return false
      }
      const params = {
        provinceName: this.treeData.provinceName,
        cityName: this.treeData.cityName || null,
        areaName: this.treeData.areaName || null,
        provinceId: this.treeData.provinceId,
        cityId: this.treeData.cityId || null,
        areaId: this.treeData.areaId || null,
        pid: this.treeData.companyId || null,
        name: this.addZiAdmin.name
      }
      saveCompany(JSON.stringify(params)).then((res) => {
        console.log(res, '55555555')
        if (res.message === 'success') {
          this.handleClose()
          this.getPageCompanys()
          this.$toast('添加成功')
          this.SET_SAVEOK(true)
        }
      })
    },
    // 修改单位信息
    editCompony() {
      if (this.editAdmin && !this.editAdmin.name) {
        this.$toast('请添加单位名称！')
        return false
      }
      updateCompany(JSON.stringify(this.editAdmin)).then((res) => {
        console.log(res, '55555555')
        if (res.message === 'success') {
          this.handleClose()
          this.getPageCompanys()
          this.$toast('编辑成功！')
          this.SET_SAVEOK(true)
        }
      })
    },
    // 冻结解冻
    frozen(row, index) {
      const params = {
        provinceName: row.provinceName,
        provinceId: row.provinceId

      }
      if (row.cityName) {
        params.cityName = row.cityName
      }
      if (row.areaName) {
        params.areaName = row.areaName
      }
      if (row.cityId) {
        params.cityId = row.cityId
      }
      if (row.areaId) {
        params.areaId = row.areaId
      }
      if (row.companyId) {
        params.id = row.companyId
      }
      if (row.companyState === 0) {
        params.state = 1
      } else {
        params.state = 0
      }
      updateCompany(JSON.stringify(params)).then((res) => {
        if (res.message === 'success') {
          this.companyData[index].companyState = params.state
          this.SET_SAVEOK(true)
        }
      })
    },
    // 切换tab
    changeTab(id) {
      console.log(id, 'oooo')
      this.active = id
      if (id === 1) {
        this.getPageCompanys()
      } else {
        this.getPageSchools()
      }
    },

    handleDelete(index, row) {
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        roundButton: true
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleClose() {
      if (this.addadmin) {
        this.addadmin = false
      }
      if (this.isChoseschool || this.iseditAdmin || this.isAddZiAdmin) {
        this.getPageSchools()
        this.SET_SAVEOK(true)
      }
      if (this.isChoseschool) {
        this.isChoseschool = false
      }
      if (this.iseditAdmin) {
        this.iseditAdmin = false
      }
      if (this.isAddZiAdmin) {
        this.isAddZiAdmin = false
      }
      if (this.isTeacherList) {
        this.isTeacherList = false
      }
      if (this.isStudentList) {
        this.isStudentList = false
      }
    },
    // 打开分配学校的弹窗 获取学校列表
    distributionSchool(row) {
      console.log(row, 'kkjjjjjj')
      this.isChoseschool = true
      this.schoolList = []
      this.distribution = {
        provinceId: row.provinceId
      }
      if (row.cityId) {
        this.distribution.cityId = row.cityId
      }
      if (row.areaId) {
        this.distribution.areaId = row.areaId
      }
      if (row.companyId) {
        this.distribution.companyId = row.companyId
      }
      selectSchoolsByCompany(JSON.stringify(this.distribution)).then((res) => {
        if (res.message === 'success') {
          this.schoolList = [...res.data]
        }
      })
    },
    // 编辑单位
    editAdminBtn(row) {
      this.iseditAdmin = true
      console.log(row, 'oooo')
      this.editAdmin = {
        provinceName: row.provinceName,
        cityName: row.cityName || null,
        areaName: row.areaName || null,
        provinceId: row.provinceId,
        cityId: row.cityId || null,
        areaId: row.areaId || null,
        id: row.companyId || null,
        name: row.companyName
      }
    },
    // 分配学校
    choseSchool(row) {
      const params = {
        companyId: this.distribution.companyId,
        schoolId: row.schoolId
      }
      if (row.ckeck === 0) {
        params.type = 1
      } else if (row.ckeck === 1) {
        params.type = 0
      }
      allotSchoolsByCompany(params).then((res) => {
        if (res.message === 'success') {

        }
      })
    },
    // 打开添加单位
    oenAddAdmin() {
      this.addadmin = true
      this.adminData = {
        provinceName: this.treeData.provinceName,
        cityName: this.treeData.cityName || null,
        areaName: this.treeData.areaName || null,
        provinceId: this.treeData.provinceId,
        cityId: this.treeData.cityId || null,
        areaId: this.treeData.areaId || null,
        pid: this.treeData.companyId || null
      }
    },
    // 打开添加单位
    openAddZiAdmin() {
      this.isAddZiAdmin = true
      this.addZiAdmin = {
        provinceId: this.treeData.provinceId,
        cityId: this.treeData.cityId || null,
        areaId: this.treeData.areaId || null,
        pid: this.treeData.companyId || null,
        admin: this.treeData.name
      }
    },
    // 翻页
    changePage(page) {
      if (this.active === 1) {
        this.getPageCompanys(page)
      } else {
        this.getPageSchools(page)
      }
    },
    listchangePage(page) {
      if (this.lookType) {
        this.getTeacherList(this.lookData, this.lookType, page)
      } else {
        this.getStudentData(this.schoolparams, page)
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .search {
    text-align: left;
    margin-bottom: 16px;
    /deep/.el-input {
      width: 244px;
      height: 34px;
      line-height: 34px;
      margin-right: 15px;
      input {
        height: 100%;
      }
      .el-input__suffix{
        position: absolute;
        top: -2px;
      }
    }
    /deep/.el-button {
      margin-right: 15px;
      padding:9px 23px;
      &.is-round {
        border: 1px solid #5096F5;
        color: #5096F5;
      }
    }
  }
  .tabs {
    display: flex;
    height: 40px;
    line-height: 40px;
    // border-bottom: 1px solid #F2F2F2;
    margin-bottom: 10px;
    .tab {
      width: 100px;
      height: 34px;
      line-height: 34px;
      border-radius: 3px;
      border: 1px solid #F2F2F2;
      position: relative;
      cursor: pointer;
      // &::after {
      //   content: '';
      //   display: block;
      //   width: 84px;
      //   height: 3px;
      //   position: absolute;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   bottom: 0;
      // }
      &.active {
        color:#5096F5;
        border: 1px solid #5096F5;
        &::after {
          background: #5096F5;
        }
      }

    }
  }
  .school {
    height: 100%;
    .top {
      max-height: calc(100% - 240px);
      border-bottom: 1px solid #EBEEF5;
      overflow-y: auto;
      /deep/.el-table {
        th {
          padding: 8px 0;
        }
        td {
          padding: 5px 0;
        }
        .danger {
          color: #f56c6c;
        }
      }
    }
    // &.admin {
    //   .top {
    //     max-height: calc(100% - 240px);
    //   }
    // }
    .botoom {
      margin-top: 20px;
      /deep/.el-pagination {
        position: absolute;
        right: 20px;
      }
    }
  }
  .admindialog {
    /deep/.el-dialog__header {
      text-align: left;
      padding: 20px 40px 10px;
      border-bottom: 1px solid #F2F2F2;
    }
    /deep/.el-dialog__body {
      padding: 15px 40px;
      .el-form-item__label {
        text-align: left;
      }
      .adminitem {
        margin-bottom: 15px;
        .el-form-item__label {
          height: 34px;
        }
        .el-form-item__content {
          text-align: left;
          width: 327px;
          height: 34px;
          .el-select {
            width: 100%;
          }
          .el-input {
            width: 100%;
          }
          .el-input__inner {
            height: 34px;
          }
          .sup {
            display: block;
            position: absolute;
            right: -15px;
            top: 0;
            color: red;
          }
        }

      }
    }
    /deep/.el-dialog__footer {
      text-align: center;
      padding-top: 50px;
    }
  }
  .choseschool {
     /deep/.el-dialog__header {
      text-align: left;
      padding: 20px 40px 10px;
      border-bottom: 1px solid #F2F2F2;
    }
    /deep/.el-dialog__body {
      padding: 15px 40px 50px;
      .choseschoolbox {
        width: 570px;
        height: 373px;
        box-sizing: border-box;
        padding: 15px 20px;
        border: 1px solid #F2F2F2;
        overflow-y: auto;
        // display: flex;
        // flex-wrap: wrap;
        &::-webkit-scrollbar {
          width:7px;
          height: 7px;
        }
        /* 滚动槽 */
        &::-webkit-scrollbar-track {
          -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.2);
          border-radius:10px;
        }
        /* 滚动条滑块 */
        &::-webkit-scrollbar-thumb {
          border-radius:10px;
          background:rgba(0,0,0,0.2);
          -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.2);
        }
        &::-webkit-scrollbar-thumb:window-inactive {
          background:rgba(0,0,0,0.2);
        }
        .itembox {
          float: left;
          padding-right: 10px;
          width: 50%;
          line-height: 30px;
          text-align: left;
          .el-checkbox {
            white-space: normal;
          }
          .el-checkbox__label {
            display: inline;
          }
          .el-checkbox__input.is-checked+.el-checkbox__label {
            color: #333333;
          }
        }
      }
    }
  }
  .looklist {
     /deep/.el-dialog__header {
      text-align: left;
      padding: 20px 40px 10px;
      border-bottom: 1px solid #F2F2F2;
    }
    /deep/.el-dialog__body {
      padding: 15px 40px 30px;
      .list {
        width: 570px;
        // height: 373px;
        // box-sizing: border-box;
        // padding: 15px 20px;
        // border: 1px solid #F2F2F2;
        .page {
          margin-top: 15px;
        }
      }
    }
  }
</style>
