<template>
  <div>
    <el-dialog
      :title="importStyle && importStyle.title ?importStyle.title:'导入信息'"
      :visible.sync="isImport"
      width="690px"
      height="520px"
      class="importbox"
      :before-close="handleClose">
      <div class="importcontent">
        <el-steps :active="active" finish-status="success">
          <el-step title="上传文件" description="请上传制定格式文件"></el-step>
          <el-step title="校验数据" description="检查数据格式是否正确"></el-step>
          <el-step title="导入数据" description="正在执行导入数据"></el-step>
        </el-steps>
      </div>
      <div class="uploadbox" v-show="active === 0">
        <div class="upload_item">
          <div class="blue"></div>
          <div class="upload_title">下载模版</div>
          <div class="upload_text">点击下方文字按钮，下载模板，并按照正确的格式填写信息</div>
          <a class="downbtn" :href="importStyle && importStyle.importType !== 'student'? $futureImgUrl+'/znpt/template/%E6%95%99%E5%B8%88%E5%8F%8A%E8%81%8C%E5%B7%A5%E4%BF%A1%E6%81%AF%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx':$futureImgUrl+'/znpt/template/%E5%AD%A6%E7%94%9F%E4%BF%A1%E6%81%AF%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'"><span class="icon-xiazai"></span>下载模版</a>
        </div>
        <div class="upload_item">
          <div class="blue"></div>
          <div class="upload_title">上传文件</div>
          <div class="upload_text">点击下方文字按钮，上传文件，格式仅支持xls，xlsx</div>
          <upload-model @filePath="filePath" :config="config" class="downbtn"></upload-model>
          <div class="upload_text" v-if="this.fileData">{{ this.fileData && this.fileData.original }}</div>
        </div>
        <div class="swicthbox">
          <el-button @click="handleClose" size="mini" round>取&nbsp;&nbsp;消</el-button>
          <el-button type="primary" size="mini" round :disabled="isNextOne" @click="nextTwo()">下一步</el-button>
        </div>
      </div>
      <div class="two" v-show="active === 1">
        <div v-if="!isCallBack">数据校验中...</div>
        <div v-else>
          <div class="two_item" :class="bool?'twosuccess':'twoerror'">
            <div class="two_title">校验数据</div>
            <div class="two_text" v-if="bool">数据格式正确，本次共计数据{{ total }}条。</div>
            <div class="two_text" v-else>共导入数据 {{ total }} 条，其中有 <span class="red">{{ errortotal }} 条数据错误</span>，请调整文件后重新上传</div>
          </div>
          <div class="errorList" v-if="!bool">
            <p v-for="(item,index) in datList" :key="index" v-if="item.messages && item.messages.length > 0">
              第{{ index +1 }}行，
              <span v-for="(m,n) in item.messages" :key="n">{{ m }}；</span>

            </p>

          </div>
        </div>
        <div class="swicthbox">
          <el-button @click="backupload" size="mini" round>重新上传</el-button>
          <el-button type="primary" size="mini" round :disabled="isNextTwo" @click="nextThree()">下一步</el-button>
        </div>
      </div>
      <div class="three" v-show="active >= 2">
        <div class="imgbox">
          <img src="/images/uploadfile.png" alt="">
        </div>
        <div class="percentage">
          <el-progress :percentage="percentage"></el-progress>
          <div class="import_text" v-if="isErr">导入成功 - 共计 <span class="rt_text">{{ total }}</span> 条。</div>
          <div class="import_text" v-else></div>
          <!-- <div class="import_text not">导入成功 - 共计 <span class="rt_text">989</span> 条，失败 <span class="rt_not">488</span> 点击下方按钮查看失败数据。</div> -->
        </div>
        <div class="swicthbox">
          <el-button type="primary" size="mini" round @click="submitOk()" :disabled="isNextThree">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="导入失败的数据"
      :visible.sync="isImportError"
      width="430px"
      class="importerrorbox"
      :before-close="handleClose">
      <div class="list">
        <p>身份证号：19289839</p>
        <p>身份证号：19289839</p>
        <p>身份证号：19289839</p>
        <p>身份证号：19289839</p>
        <p>身份证号：19289839</p>
      </div>
    </el-dialog>
  </div>

</template>
<script>
import { batchImportTeacherInfo, batchImportStudentInfo, saveImportTeacherInfo, saveImportStudentInfo } from '@/api/teacherManage.js'
import uploadModel from '@/components/upload/uploadModel.vue'
export default {
  props: {
    isImport: {
      type: Boolean,
      default: true
    },
    importData: {
      type: Object,
      default: () => {}
    },
    importStyle: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    uploadModel
  },
  data() {
    return {
      active: 0,
      bool: false,
      total: 0,
      timer: null,
      percentage: 0,
      errortotal: 0,
      datList: [],
      config: {
        size: 100,
        type: ['xls', 'xlsx'],
        errormsg: '上传文件只能是 xls，xlsx 格式!'
      },
      fileData: {},
      isNextOne: true,
      isNextTwo: true,
      isNextThree: true,
      isImportError: false,
      isCallBack: false,
      isErr: true // 导入失败
    }
  },
  mounted() {

  },
  methods: {
    get() {
      this.percentage++
      if (this.percentage > 85) {
        clearInterval(this.timer)
      }
    },
    handleClose() {
      this.$emit('closeFile', false)
      this.isImportError = false
    },
    filePath(res) {
      console.log(res, 'filePath')
      this.fileData = res.response
      this.isNextOne = false
    },
    nextTwo() {
      this.active = 1
      const data = {
        url: this.fileData.url
      }
      if (this.importData.type === 'company') {
        data.companyId = this.importData.companyId
      } else {
        data.schoolId = this.importData.schoolId
      }
      if (this.importStyle.importType === 'student') {
        batchImportStudentInfo(data).then(res => {
          console.log(res, 'import')
          this.isCallBack = true
          const data = res.data
          if (res.success) {
            this.bool = true
            this.isNextTwo = false
          } else {
            if (data) {
              const errorList = data.filter((v) => {
                return v.messages !== null
              })
              this.errortotal = errorList.length
            } else {
              this.$toast(res.message)
            }
          }
          this.datList = data
          this.total = data.length
        })
      } else {
        batchImportTeacherInfo(data).then(res => {
          const data = res.data
          this.isCallBack = true
          if (res.success) {
            this.bool = true
            this.isNextTwo = false
          } else {
            if (data) {
              const errorList = data.filter((v) => {
                return v.messages !== null
              })
              this.errortotal = errorList.length
            } else {
              this.$toast(res.message)
            }
          }
          this.datList = data
          this.total = data.length
        })
      }
    },
    nextThree() {
      this.active = 2
      const data = {
        url: this.fileData.url
      }
      if (this.importData.type === 'company') {
        data.companyId = this.importData.companyId
      } else {
        data.schoolId = this.importData.schoolId
      }
      this.timer = setInterval(this.get, 10)
      // if (this.percentage > 85) {
      //   clearInterval(this.timer)
      // }
      this.isErr = true
      if (this.importStyle.importType === 'student') {
        saveImportStudentInfo(data).then(res => {
          if (res.success) {
            clearInterval(this.timer)
            this.percentage = 100
            this.isNextThree = false
            this.active = 3
          } else {
            this.$toast(res.message)
          }
        }).catch(err => {
          this.$toast(err.message)
          this.isErr = false
          // console.log(err, 'iiiiiiiii')
        })
      } else {
        saveImportTeacherInfo(data).then(res => {
          if (res.success) {
            clearInterval(this.timer)
            this.percentage = 100
            this.isNextThree = false
            this.active = 3
          } else {
            this.$toast(res.message)
          }
        }).catch(err => {
          this.$toast(err.message)
          this.isErr = false
          // console.log(err, 'iiiiiiiii')
        })
      }
    },
    backupload() {
      this.active = 0
    },
    submitOk() {
      this.$emit('closeFile', false)
      // this.isImportError = true
    }
  }
}
</script>
<style lang="less" scoped>
  .importbox {
    text-align: left;
    /deep/.el-dialog__header {
      text-align: left;
      padding: 20px 40px 10px;
      border-bottom: 1px solid #F2F2F2;
    }
    /deep/.el-dialog__body {
      padding: 15px 40px;
      height: 460px;
      .importcontent {

      }
      .uploadbox {
        padding-top: 35px;
        .upload_item {
          margin-bottom: 15px;
          position: relative;
          background: #F2F2F2;
          border-radius: 8px;
          padding: 11px 24px;
          overflow: hidden;
          .blue {
            position: absolute;
            width: 11px;
            height: 100%;
            background: #5096F5;
            left: 0;
            top: 0;

          }
          .upload_title {
            font-size: 14px;
            font-weight: 600;
            color: #333333;
            line-height: 20px;
            margin-bottom: 5px;
          }
          .upload_text {
            font-size: 12px;
            font-weight: 500;
            color: #999999;
            margin-bottom: 8px;
            line-height: 17px;
          }
          .downbtn {
            color: #5096F5;
            font-size: 12px;
            margin-bottom: 5px;
            .icon-xiazai, .icon-shangchuan {
              font-family: "font_family" !important;
              font-size: 14px;
              &::before {
                position: relative;
                top: 1px;
                margin-right: 4px;
              }
            }
          }
        }
      }
      .swicthbox {
        text-align: center;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%);
        .el-button {
            width: 86px;
        }
      }
      .two {
        padding-top: 35px;
        .two_item {
          border-radius: 8px;
          padding: 11px 24px;
          &.twosuccess {
            background: rgba(105, 195, 61, 0.05);
            border: 1px solid rgba(105, 195, 61, 0.5);
          }
          &.twoerror {
            background: rgba(245, 108, 108, 0.05);
            border: 1px solid rgba(245, 108, 108, 0.5);
          }
          .two_title {
            height: 20px;
            font-size: 14px;
            font-weight: 600;
            color: #333333;
            line-height: 20px;
          }
          .two_text {
            font-size: 12px;
            font-weight: 500;
            color: #999999;
            line-height: 17px;
            .red {
              color: #F56C6C;
            }
          }
        }
        .errorList {
          margin-top: 15px;
            height: 185px;
            border-radius: 8px;
            border: 1px solid #F2F2F2;
            overflow-y: auto;
            padding: 5px 25px;
            &::-webkit-scrollbar {
              width:7px;
              height: 7px;
            }
            /* 滚动槽 */
            &::-webkit-scrollbar-track {
              -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.2);
              border-radius:10px;
            }
            /* 滚动条滑块 */
            &::-webkit-scrollbar-thumb {
              border-radius:10px;
              background:rgba(0,0,0,0.2);
              -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.2);
            }
            &::-webkit-scrollbar-thumb:window-inactive {
              background:rgba(0,0,0,0.2);
            }
            p {
              border-bottom: 1px solid #F2F2F2;
              font-size: 12px;
              font-weight: 500;
              color: #666666;
              line-height: 25px;
              &:last-of-type {
                border: none;
              }
            }
          }
      }
      .three {
        .imgbox {
          text-align: center;
          padding-top: 30px;
          img {
            width: 212px;
          }
        }
        .percentage {
          width: 420px;
          margin: 30px auto 10px;
          .import_text {
            margin-top: 5px;
            font-size: 14px;
            font-weight: 500;
            color: #999999;
            line-height: 20px;
            .rt_text {
              color: #6BC440;
            }
            .rt_not {
              color: #F56C6C;
            }
          }
        }

      }
    }
  }
  .importerrorbox {
    /deep/.el-dialog__header {
      text-align: left;
      padding: 20px 40px 10px;
      border-bottom: 1px solid #F2F2F2;
    }
    /deep/.el-dialog__body {
      padding: 15px 40px 40px;
      // height: 460px;
      .list {
        width: 349px;
        height: 238px;
        padding: 10px 25px;
        border-radius: 8px;
        border: 1px solid #F2F2F2;
        overflow-y: auto;
        text-align: left;
        &::-webkit-scrollbar {
          width:7px;
          height: 7px;
        }
        /* 滚动槽 */
        &::-webkit-scrollbar-track {
          -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.2);
          border-radius:10px;
        }
        /* 滚动条滑块 */
        &::-webkit-scrollbar-thumb {
          border-radius:10px;
          background:rgba(0,0,0,0.2);
          -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.2);
        }
        &::-webkit-scrollbar-thumb:window-inactive {
          background:rgba(0,0,0,0.2);
        }
        p {
          font-size: 12px;
          font-weight: 500;
          color: #666666;
          line-height: 20px;
          border-bottom: 1px solid #F2F2F2;
          &:last-of-type {
            border: none;
          }
        }
      }
    }
  }
</style>
