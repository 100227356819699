<template>
  <div class="mynews_cont">
    <div class="myNews_tab_box">
      <div class="myNews_tab hover">
        <div v-for="(item,index) in tabInfo" :key="index" :class="showTabMain === index?'myNews_tab_item myNews_tab_active':'myNews_tab_item'" @click="choseTab(index,item)"><span>{{ item.name }}</span></div>
      </div>
    </div>
    <div class="myNews_tab_main">
      <platform v-if="code === '46CDA275'"></platform>
      <netnav v-if="code === 'B9F20DB0'"></netnav>
      <footerinfo v-if="code === '7D9F7391'"></footerinfo>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
import Platform from '@/components/baseinfomanage/Platform.vue'
import Netnav from '@/components/baseinfomanage/Netnav.vue'
import Footerinfo from '@/components/baseinfomanage/Footerinfo.vue'
export default {
  name: 'BaseinfoManage',
  components: { Platform, Netnav, Footerinfo },
  data() {
    return {
      objData: {

      },
      tabInfo: [],
      mainHei: '', // .myNews_tab_main高度
      showTabMain: 0, // 默认显示分类管理 0，文章管理 1
      code: '46CDA275'
    }
  },

  computed: {
    ...mapGetters(['navMain'])
  },
  watch: {
    navMain: {
      handler(newVal) {
        // console.log(newVal, 'newVal')
        this.getMainCon()
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    if (sessionStorage.getItem('showTabMainobj')) {
      const obj = JSON.parse(sessionStorage.getItem('showTabMainobj'))
      this.showTabMain = obj.showTabMain
      this.code = obj.code
    }
  },
  methods: {
    ...mapMutations(['SET_DATASTE', 'SET_NAVMAIN']),
    getMainCon() {
      if (this.navMain) {
        this.tabInfo = this.navMain.children ? this.navMain.children : []
        console.log(this.tabInfo, 'kkkkk')
      }
    },
    choseTab(res, obj) {
      // 切换tab内容
      this.showTabMain = res
      this.code = obj.code
      const myobj = {
        code: obj.code,
        showTabMain: res
      }
      sessionStorage.setItem('showTabMainobj', JSON.stringify(myobj))
      // console.log(obj)
    }
  }

}
</script>
<style lang="less" scoped>
.mynews_cont{
  position: absolute;
  left: 0;
  top: 0;
  width:100%;
  height: 100%;
  overflow: hidden;
}
.myNews_tab_main{
  position: relative;
  height: calc(100% - 65px);
    border-radius: 10px;
}
</style>
