<template>
  <div
    class="platform">
    <el-form label-width="80px">
      <el-form-item label="效果展示">
        <div class="platformbg">
          <img class="imgicon" :src="formdata.imgicon" alt="">
          <div class="plattitle">{{ formdata.plattitle }}</div>
          <div class="netlink">{{ netlink }}</div>
        </div>
      </el-form-item>
      <el-form-item label="平台名称" class="item">
        <el-input class="addname" v-model="formdata.plattitle" placeholder="平台名称"></el-input>
        <!-- <el-checkbox v-model="checked" @change="isShowPaltName">首页显示平台名称</el-checkbox> -->
      </el-form-item>
      <el-form-item class="editorbox item" label="平台icon">
        <uploadcropper @filePath="filePath" :config="config" :cropoption="cropoption"></uploadcropper>
      </el-form-item>
      <el-form-item class="editorbox1 item" label="登录logo">
        <uploadcropper @filePath="filePath1" :config="config1" :cropoption="cropoption1"></uploadcropper>
      </el-form-item>
    </el-form>
    <el-button class="save_btn" type="primary" @click="savePlatForm" round>保存</el-button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getConfigHpStyle } from '@/api/local.js'
import { updataPlatformData } from '@/api/massage.js'
import uploadcropper from '@/components/upload/uploadcropper.vue'
export default {
  name: 'Platform',
  data() {
    return {
      formdata: {
        imgicon: '',
        plattitle: '平台名称'
      },
      config: {
        baseImageUrl: '',
        tip: '* 建议尺寸： 16*16px，ico格式',
        size: 1,
        type: ['image/x-icon'],
        errormsg: '上传图片只能是 ico 格式!'
      },
      cropoption: {
        autoCropWidth: 16,
        autoCropHeight: 16,
        fixedNumber: [16, 16],
        outputType: 'x-icon'
      },
      config1: {
        baseImageUrl: '',
        tip: '* 建议尺寸： 160*55px，JPG、PNG格式，图片小于1M。',
        size: 1,
        type: ['image/jpeg', 'image/png'],
        errormsg: '上传图片只能是 JPG 或 PNG 格式!'
      },
      cropoption1: {
        autoCropWidth: 160,
        autoCropHeight: 55,
        fixedNumber: [160, 55],
        fixed: 'nofixed'
      },
      netlink: 'http://www.aixueyun.cn/',
      id: null,
      showPaltName: 0,
      checked: false
    }
  },
  components: {
    uploadcropper
  },
  computed: {
    ...mapGetters(['indexData'])
  },
  mounted() {
    this.netlink = this.indexData.siteUrl
    this.getConfigHpStyle()
  },
  methods: {
    filePath(res) {
      this.formdata.imgicon = this.$futureImgUrl + res.imgpath
      this.config.baseImageUrl = this.$futureImgUrl + res.imgpath
    },
    filePath1(res) {
      this.formdata.loginImgPath = this.$futureImgUrl + res.imgpath
      this.config1.baseImageUrl = this.$futureImgUrl + res.imgpath
    },
    getConfigHpStyle() {
      const params = {
        website_id: this.indexData.websiteId
      }
      getConfigHpStyle(params).then((res) => {
        if (res.message === 'success') {
          if (res.data.iconPath.indexOf('http') > -1) {
            this.formdata.imgicon = res.data.iconPath
            this.config.baseImageUrl = res.data.iconPath
          } else {
            this.formdata.imgicon = this.$futureImgUrl + res.data.iconPath
            this.config.baseImageUrl = this.$futureImgUrl + res.data.iconPath
          }
          if (res.data.nameShow === 0) {
            this.checked = false
          } else {
            this.checked = true
          }
          this.showPaltName = res.data.nameShow
          this.formdata.plattitle = res.data.name
          this.formdata.baseImageUrl = res.data.iconPath
          this.formdata.plattitle = res.data.name
          this.formdata.loginImgPath = res.data.loginImgPath
          this.config1.baseImageUrl = res.data.loginImgPath
          this.id = res.data.id
          window.document.title = res.data.name + ' - 管理平台' // 设置title
          const link = document.querySelector("link[rel*='icon']")
          link.type = 'image/x-icon'
          link.rel = 'shortcut icon'
          if (res.data.iconPath.indexOf('http') === -1) {
            link.href = this.$futureImgUrl + res.data.iconPath
          } else {
            link.href = res.data.iconPath
          }
        }
      })
    },
    isShowPaltName(res) {
      if (res) {
        this.showPaltName = 1
      } else {
        this.showPaltName = 0
      }
    },
    savePlatForm() {
      const params = {
        id: this.id,
        websiteId: this.indexData.websiteId,
        nameShow: this.showPaltName,
        name: this.formdata.plattitle,
        iconPath: this.formdata.imgicon,
        loginImgPath: this.formdata.loginImgPath
      }
      if (this.formdata.plattitle.trim() === '') {
        this.$message({
          message: '平台名称不能为空！',
          type: 'warning'
        })
        return
      }
      if (!this.formdata.loginImgPath) {
        this.$message({
          message: '请上传登录页logo！',
          type: 'warning'
        })
        return
      }
      updataPlatformData(params).then((res) => {
        if (res.message === 'success') {
          this.$toast('保存成功')
          window.document.title = this.formdata.plattitle + ' - 管理平台' // 设置title
          const link = document.querySelector("link[rel*='icon']")
          link.type = 'image/x-icon'
          link.rel = 'shortcut icon'
          link.href = this.formdata.imgicon
        }
      })
    }
  }

}
</script>
<style lang="less" scoped>
  .platform {
    background: #fff;
    height: 100%;
    padding: 30px;
    position: relative;
    .platformbg {
      width: 665px;
      height: 144px;
      background: url(/images/baseinfo/platbg.png) no-repeat center;
    }
    .imgicon {
      position: absolute;
      left: 30px;
      top: 30px;
      width: 30px;
      height: 30px;
    }
    .plattitle {
      position: absolute;
      left: 75px;
      color: #333333;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      top: 30px;
    }
    .netlink {
      position: absolute;
      bottom: 6px;
      left: 256px;
      height: 50px;
      color: #333333;
      font-size: 16px;
      line-height: 50px;
    }
    .item {
      width: 324px;
    }
    /deep/.el-form-item__content{
      text-align: left;
    }
    .editorbox {
      /deep/.avatar-uploader {
        position: relative;
        text-align: left;
        .el-upload {
          width: 85px;
          height: 85px;
          img {
            width: 100%;
          }
        }
        .el-upload__tip {
          position: absolute;
          left: 100px;
          top: 40px;
          line-height: 1.4;
          width: 100%;
        }
      }
    }
    .editorbox1{
      /deep/.avatar-uploader {
        position: relative;
        text-align: left;
        width: 700px;
        .el-upload {
          height: 85px;
          min-width: 85px;
          img {
            height: 100%;
          }
        }
        .el-upload__tip {
          margin: 40px 0 0 20px;
          line-height: 1.4;
          display: inline-block;
          vertical-align: top;
        }
      }
    }
    /deep/.save_btn{
      width: 194px;
      height: 44px;
      background: #5096F5;
      position: absolute;
      left: 40%;
      bottom: 50px;
    }
  }
</style>
