<template>
  <div class="classtree">
    <div class="searchbox">
      <span>搜索</span><el-input
        placeholder="输入关键字进行过滤"
        v-model="filterText">
      </el-input>
    </div>
    <div class="treenode">
      <el-tree
        ref="vuetree"
        :data="data"
        :props="defaultProps"
        node-key="id"
        class="iconfont"
        :render-after-expand="false"
        :default-expanded-keys="expanded"
        @node-click="handleNodeClick"
        @node-expand="handleExpand"
        :expand-on-click-node="false"
        :highlight-current="true"
        :filter-node-method="filterNode"
        :render-content="renderContent">
        <span class="custom-tree-node" slot-scope="{ node, ddaa }">ssssss{{ ddaa.name }}</span>
      </el-tree>
    </div>
  </div>

</template>
<script>
import { getManageUnit } from '@/api/management.js'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Tree',
  props: {
    // filterText: {
    //   type: String,
    //   default: ''
    // }
  },
  watch: {
    filterText(val) {
      this.$refs.vuetree.filter(val)
    },
    isSaveOk: {
      handler(newVal) {
        if (newVal === true) {
          this.getTree()
        }
      },
      deep: true
    }
  },
  data() {
    return {
      test: [],
      num: 0,
      // filterText: '',
      data: [],
      expanded: [],
      id: 1,
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      renderContent: function (h, { node, data, store }) {
        let classname = ''
        if (data.type === 'school') {
          classname = 'icon-xuexiao'
        } else if (data.type === 'company') {
          classname = 'icon-danwei'
        }
        const titletxt = data.name.length > 10 ? data.name.slice(0, 10) + '...' : data.name
        return (
          <span>
            <i class={classname} ></i>
            <span> </span>
            <span title={data.name}>{titletxt}</span>
          </span>
        )
      }
    }
  },
  components: {

  },
  computed: {
    ...mapGetters(['navMain', 'isSaveOk'])
  },
  mounted() {
    this.getTree()
  },
  methods: {
    ...mapMutations(['SET_NAVMAIN', 'SET_SAVEOK']),
    handleNodeClick(data, node, obj) {
      this.$emit('treeData', data)
      // console.log(data, node, obj, '888888888888')
    },
    setCheckedNodes(bool) {
      // 展开所有节点
      for (var i = 0; i < this.$refs.vuetree.store._getAllNodes().length; i++) {
        // this.$refs.vuetree.store._getAllNodes()[i].expanded = !this.$refs.vuetree.store._getAllNodes()[i].expanded
        this.$refs.vuetree.store._getAllNodes()[i].expanded = bool
      }
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    handleExpand(data) {
      if (data.children && data.children.length > 0) {
        return false
      } else {

      }
    },
    async getTree() {
      getManageUnit({}).then((res) => {
        console.log(res, 'kkkkkk')
        if (res.message === 'success') {
          if (res.success === true) {
            this.SET_SAVEOK(false)
            const data = res.data
            // this.expanded.push(data[0].id)
            this.getSec(data, true)
            this.data = data
            this.pushid(data)
            this.$emit('treeData', this.data[0])
            console.log('datas', this.data[0])
            this.$nextTick(function () {
              this.$refs.vuetree.setCurrentKey(data[0].id) // "vuetree"是你自己在树形控件上设置的 ref="vuetree" 的名称
            })
          } else {

          }
        }
      })
    },
    getSec(data, bool) {
      data.forEach(element => {
        element.children = []
        if (bool) {
          element.name = element.provinceName
          element.type = 'province'
        }

        if (element.websiteAuthCityList && element.websiteAuthCityList.length > 0) {
          element.websiteAuthCityList.forEach(res => {
            res.name = res.cityName
            res.type = 'city'
          })
          element.children = [...element.websiteAuthCityList]
        }
        if (element.websiteAuthAreaList && element.websiteAuthAreaList.length > 0) {
          element.websiteAuthAreaList.forEach(res => {
            res.name = res.areaName
            res.type = 'area'
          })
          element.children = [...element.children, ...element.websiteAuthAreaList]
        }
        if (element.websiteAuthSchoolList && element.websiteAuthSchoolList.length > 0) {
          element.websiteAuthSchoolList.forEach(res => {
            res.name = res.schoolName
            res.type = 'school'
          })
          element.children = [...element.children, ...element.websiteAuthSchoolList]
        }
        if (element.websiteAuthCompanyList && element.websiteAuthCompanyList.length > 0) {
          element.websiteAuthCompanyList.forEach(res => {
            res.name = res.companyName
            res.type = 'company'
          })
          element.children = [...element.children, ...element.websiteAuthCompanyList]
        }
        if (element.children.length > 0) {
          this.getSec(element.children)
        }
      })
    },
    pushid(element) {
      // console.log(element, element[0].id, '3333')
      if (element[0].id) {
        this.expanded.push(element[0].id)
        if (element[0].children.length > 0) {
          this.pushid(element[0].children)
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.classtree {
    position: relative;
    height: calc(100% - 76px);
    .searchbox {
    line-height: 34px;
    padding: 8px 10px;
    border-bottom: 1px solid #F2F2F2;
    display: flex;
    span {
      margin-right: 10px;
    }
    /deep/.el-input {
      width: 200px;
      height: 34px;
      line-height: 34px;
      .el-input__inner {
        height: 100%;
      }
    }
  }
  .treenode {
    position: relative;
    width: 100%;
    height: calc(100% - 80px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width:7px;
      height: 7px;
    }
    /* 滚动槽 */
    &::-webkit-scrollbar-track {
      box-shadow:inset 0 0 6px rgba(0,0,0,0.2);
      border-radius:10px;
    }
    /* 滚动条滑块 */
    &::-webkit-scrollbar-thumb {
      border-radius:10px;
      background:rgba(0,0,0,0.2);
     box-shadow:inset 0 0 6px rgba(0,0,0,0.2);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background:rgba(0,0,0,0.2);
    }
    /deep/.el-tree--highlight-current {
      padding-bottom: 20px;
      .el-tree-node.is-current>.el-tree-node__content {
        background: transparent;
        color: #5096F5;
      }
      .el-tree-node__content {
        background: transparent!important;
        .icon-danwei, .icon-xuexiao {
              font-family: "font_family" !important;
              font-size: 14px;
              font-style: normal;
              &::before {
                position: relative;
                top: 1px;
                margin-right: 4px;
              }
            }
      }
      .el-tree-node__content:hover {
        background: transparent;
        color: #5096F5;
      }
      .el-tree-node__expand-icon {
        &.is-leaf::before {
          color: transparent;
        }
        &::before {
          font-family: "font_family" !important;
          content: "\e60f";
          color: #5096F5;
          position: relative;
          top: 2px;
        }
        &.expanded {
          transform: rotate(0deg);
        }
        &.expanded::before {
          content: "\e60e";
          position: relative;
          top: 2px;
        }
      }
    }
  }
  }

</style>
