<template>
  <div>
    <div class="top">
      <div class="topcon">所有学校</div>
      <div class="topcon changebtn" @click="closeAll(bool)">{{ closeText }}</div>
    </div>
    <tree ref="tree" @treeData="treeData"></tree>
  </div>
</template>
<script>
import Tree from '@/components/form/tree.vue'
export default {
  name: 'LeftClass',
  props: {
    typeName: {
      type: String,
      default: ''
    }
  },
  components: {
    Tree
  },
  data() {
    return {
      defaultAll: 1,
      closeText: '全部关闭',
      bool: true
    }
  },
  mounted() {
    console.log('typeName', this.typeName)
  },
  methods: {
    closeAll(bool) {
      if (this.bool) {
        this.closeText = '全部展开'
      } else {
        this.closeText = '全部关闭'
      }
      this.bool = !this.bool
      this.$refs.tree.setCheckedNodes(this.bool)
    },
    treeData(res) {
      this.SET_TREEDATA(res)
    }
  }
}
</script>
<style lang="less" scoped>
  .top {
    display: flex;
    height: 41px;
    line-height: 41px;
    background: #F2F2F2;

    .topcon {
      flex: 1;
      &.changebtn {
        cursor: pointer;
      }
      &.changebtn:hover {
        color: #5096F5;
      }
    }
  }

</style>
