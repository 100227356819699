<template>
  <div class="whitebg">
    <div v-if="newsData.titleShow == 1 && newsData.title">
      <div class="title">{{ newsData.title }}</div>
      <div class="titlebtm"></div>
    </div>
    <div class="newsimgbox clearfix">
      <div class="newsList" v-for="(news,index) in newsList" :key="index">
        <div v-if="news.titleShow == 1" class="newsmore">
          <div class="title">{{ news.title }}</div>
          <div class="titlebtm"></div>
          <span class="more font14" v-if="newsData.moreShow === 1">更多<img style="height: 12px;margin-left: 2px;position: relative;top: 1px;" src="/images/index/more.png" alt=""></span>
        </div>
        <div class="box" v-if="dataobj[news.id] && dataobj[news.id].length >0">
          <div class="fl">
            <div class="imgbox pointer">
              <a href="javascript:;">
                <img :src="dataobj[news.id] | resetImg('arr',$futureImgUrl)" alt="">
                <p class="font20">{{ dataobj[news.id]?dataobj[news.id][0].firstTitle :'' }}</p>
                <div class="mark"></div>
              </a>
            </div>
          </div>
          <div class="fr">
            <div class="frcon pointer clearfix" v-for="(item,i) in fesetData(dataobj[news.id])" :key="i">
              <a href="javascript:;">
                <div class="imgbox">
                  <img :src="item | resetImg('obj',$futureImgUrl)" alt="">
                </div>
                <div class="newstitlebox">
                  <div class="newstitle font20">{{ item.firstTitle }}</div>
                  <div class="newstext">{{ item.remark }}</div>
                  <div class="newstime">{{ item.publishTime }}</div>
                </div>
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAxtNewSourceList } from '@/api/editIndex.js'
export default {
  name: 'EduNewstwo',
  props: {
    newsData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      newsList: [],
      dataobj: {}
    }
  },
  watch: {
    newsData: {
      handler() {
        this.getAxtNewSourceList()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['indexData']),
    fesetData() {
      return (arr) => {
        return arr && arr.length > 0 ? arr.slice(1, 3) : []
      }
    }
  },
  filters: {
    resetImg(val, type, imgurl) {
      const value = val
      let url
      if (type === 'arr') {
        if (value && value.length > 0) {
          url = value[0].surfaceImg
        }
      } else {
        url = value.surfaceImg
      }
      // url && url.includes('http') ? url : imgurl + url
      return url
    }
  },
  mounted() {
    this.newsList = this.newsData.news.slice(0, 1)
    this.getAxtNewSourceList()
  },
  methods: {
    getAxtNewSourceList() {
      this.newsList.forEach(v => {
        const params = {
          website_id: this.indexData.websiteId,
          cancel_publish: 0
        }
        if (v.dataType === 1) {
          if (!v.dataNewsIds) {
            this.dataobj[v.id] = []
            return
          }
          params.ids = v.dataNewsIds
        } else if (v.dataType === 2) {
          params.classify_id = v.dataNewsTypeId
        }
        // console.log(params, 'qqqqqqq')
        getAxtNewSourceList(params).then((res) => {
          // console.log(res, 'xinwen4')
          if (res.message === 'success') {
            this.$set(this.dataobj, v.id, res.data.rows.slice(0, 3))
          }
        })
      })
      // const params = {
      //   website_id: this.indexData.websiteId,
      //   cancel_publish: 0,
      //   ids: this.newsList[0].dataNewsIds
      // }
      // getAxtNewSourceList(params).then((res) => {
      //   // console.log(res, '新闻一')
      //   if (res.message === 'success') {
      //     this.$set(this.dataobj, this.newsList[0].id, res.data.rows)
      //   }
      // })
    }
  }
}
</script>

<style lang="less" scoped="scoped">
 @marginBtn :20px;
 .whitebg {
   background: #fff;
   padding: 0 10px 30px;
 }
  .newsmore {
    position: relative;
  }
  .newsmore .more {
    color: #3583FF;
    position: absolute;
    top: 50px;
    right: 20px;
    cursor: pointer;
  }
  .titlebtm {
   margin-bottom: 45px;
  }
  .newsimgbox .fl .imgbox {
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }

  .newsimgbox .fr .imgbox {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .newsimgbox .fl .imgbox p {
    width: 100%;
    padding: 0 5%;
    position: absolute;
    left: 0;
    bottom: 10px;
    color: #fff;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all .5s;
  }
  .box {
    display: flex;
    padding-top: 40px;
  }
  .newsimgbox .fl {
    width: 50%;
    float: none;
  }
  .newsimgbox .fr {
    float: none;
    margin-left: 20px;
    width: calc(50% - 20px);
  }
  .newsimgbox .fr .imgbox {
    width: 50%;
    height: 100%;
    float: left;
   margin-right: 20px;
   position: relative;
  }
  .newsimgbox .imgbox img {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    float: left;
    transition: all .5s;
  }
  .newsimgbox .fr .imgbox img {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .newsimgbox .fl .imgbox img {
    height: 100%;
  }
  .newsimgbox .fl .imgbox:hover img{
    transform: scale(1.1);
    transition: all .5s;
  }
  .newsimgbox .imgbox .mark {
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }
  .newsimgbox .fl .imgbox p {
    bottom: 30px;
    z-index: 1;
  }

  .newsimgbox .fr .imgbox:hover p {
    bottom: 30%;
    white-space: normal;
    overflow: visible;
    text-overflow:inherit;
    transform: translateY(50%);
    transition: all .5s;
  }

  .headline {
    text-align: left;
    line-height: 1.8;
    padding-bottom: 10px;
    position: relative;
    font-weight: 500;
  }
  .headline .headnum {
    font-size: 40px;
    font-weight: 800;
    color: #3583FF;
    line-height: 60px;
  }
  .headline .more {
    color: #3583FF;

    position: absolute;
    bottom: 20px;
    right: 0;
    cursor: pointer;
  }
  .newslist {
    margin-top: 15px;
    background-color: #F5F5F7;
    border-radius: 10px;
    padding: 25px;
    text-align: left;
  }
  .newsitem {
    margin-bottom: 20px;
  }
  .newsitem:last-of-type {
    margin-bottom: 0;
  }
  .newsitemtitle {
    float: left;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .newsitemtime {
    float: right;
    width: 25%;
    color: #A7A7A7;
    text-align: right;
  }
  .newsitem:hover .newsitemtitle {
    color: #3583FF;
  }
  .frcon {
    background-color: #F5F5F7;
    height: calc((100% - @marginBtn) / 2);
    border-radius: 10px;
    margin-bottom: @marginBtn;
    padding-right: 20px;
    text-align: left;
    position: relative;
    overflow: hidden;
  }
  .frcon:last-of-type {
    margin-bottom: 0;
  }
  .newstitle {
    font-weight: 500;
    color: #212121;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 15px;
    margin-bottom: 0px;
  }
  .frcon:hover .newstitle{
    color: #3583FF;
  }
  .newstext {
    color: #666666;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .newstime {
    position: absolute;
    bottom: 5px;
    left: calc(48% + 20px );
    color: #A7A7A7;
  }
  @media screen and (min-width: 1620px) {
    .newstext {
      color: #666666;
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .newstime {
      bottom: 15px;
    }

  }
</style>
