<template>
  <!-- <div class="addNavBox">
    <div class="addNavCont">
      <div class="addNavTitle">
        添加导航
      </div>
      <el-form ref="form" :model="form" label-width="130px">
        <el-form-item label="导航名称">
          <el-input v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="自定义链接">
          <el-input v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="选择上级导航">
          <el-input v-model="name"></el-input>
        </el-form-item>
      </el-form>
      <div class="btnBox">
        <el-button round size="small">取消</el-button>
        <el-button type="primary" round size="small">保存</el-button>
      </div>
      <i class="el-icon-close closeBtn"></i>
    </div>
  </div> -->
  <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogVisible" width="570px" :before-close="handleClick">
    <el-form :model="form">
      <el-form-item label="导航名称" :label-width="formLabelWidth">
        <el-input v-model="form.navName" autocomplete="off" maxlength="6" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="选择页面类型" :label-width="formLabelWidth">
        <el-select v-model="form.linkType" placeholder="请选择页面类型" @change="changeLinkType">
          <el-option v-for="(item,index) in linkInfo" :key="index" :label="item.name" :value="item.id" :disabled="filterarr.includes(item.id)"></el-option>
          <el-option label="自定义链接" :value="isLinkOut"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="自定义链接" :label-width="formLabelWidth" v-show="showNavLink">
        <el-input v-model="form.navLink" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClick(0)">取 消</el-button>
      <el-button type="primary" @click="handleClick(1)">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { updateConfigAddHpStyleHeaderNav, updateConfigUpdateHpStyleHeaderNav } from '@/api/massage.js'
export default {
  name: 'AddNav',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    linkInfo: {
      type: Array,
      default: null
    },
    headerInfo: {
      type: Object,
      default: null
    },
    editorData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      name: '',
      formLabelWidth: '100px',
      title: '添加导航',
      form: {
        navName: '',
        navLink: '',
        linkType: ''
      },
      showNavLink: false, // 是否显示自定义链接输入框
      isLinkOut: 0,
      editorId: null, // 修改导航是用到的id  item中的id
      filterarr: []
    }
  },
  watch: {
    editorData: {
      handler(newVal) { // 监听信息  是添加 !=null 或者 编辑 =null
        if (newVal === null) {
          this.form = {
            navName: '',
            navLink: '',
            linkType: ''
          }
          return
        }
        this.form.navName = newVal.data.name
        this.editorId = newVal.data.id
        this.title = '编辑导航'
        if (newVal.data.type === 0) {
          this.form.linkType = newVal.data.linkId
        } else {
          this.form.linkType = 0
          this.form.navLink = newVal.data.urlPath
          this.showNavLink = true
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['indexData'])
  },
  mounted() {
    console.log(this.headerInfo, 'headerInfo', this.linkInfo)
    const arr = []
    this.headerInfo.nav_list.forEach(v => {
      if (v.linkId && v.type === 0) {
        arr.push(v.linkId)
      }
    })
    this.filterarr = [...arr]
  },
  methods: {
    changeLinkType(res) {
      if (res === 0) {
        this.showNavLink = true
      } else {
        this.showNavLink = false
      }
    },
    handleClick(res) {
      if (res === 1) {
        if (this.form.navName.trim() === '') {
          this.$message({
            message: '导航名字不能为空！',
            type: 'warning'
          })
          return
        }
        if (this.form.linkType === '') {
          this.$message({
            message: '页面类型不能为空！',
            type: 'warning'
          })
          return
        }
        if (this.form.linkType === 0) {
          if (this.form.navLink.trim() === '') {
            this.$message({
              message: '自定义链接不能为空！',
              type: 'warning'
            })
            return
          }
        }
        const params = {
          websiteId: this.indexData.websiteId,
          styleId: this.headerInfo.styleId,
          headerId: this.headerInfo.id,
          level: 1,
          name: this.form.navName,
          type: 1
        }
        console.log('data', params)
        if (this.form.linkType === 0) {
          params.type = 1 // 外链
          params.urlPath = this.form.navLink && this.form.navLink.includes('http') ? this.form.navLink : 'http://' + this.form.navLink
        } else {
          params.type = 0 // 內链
          params.linkId = this.form.linkType
        }
        if (this.editorData === null) {
          console.log('djks')
          updateConfigAddHpStyleHeaderNav(params).then((res) => {
            if (res.data) {
              const updataData = {
                close: false,
                success: true
              }
              this.$emit('submit', updataData)
              this.$toast('添加成功')
            }
          })
        } else {
          params.id = this.editorId
          params.linkId = null
          updateConfigUpdateHpStyleHeaderNav(params).then((res) => {
            if (res.data) {
              const updataData = {
                close: false,
                success: true
              }
              this.$emit('submit', updataData)
              this.$toast('编辑成功')
            }
          })
        }
      } else {
        const updataData = {
          close: false,
          success: false
        }
        this.$emit('submit', updataData)
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog__header{
  text-align: left;
  font-size: 16px;
}
/deep/.el-form-item__content{
    width: 347px;
    border-radius: 10px;
    .el-input__inner{
      height: 35px;
      line-height: 35px;
      border: 1px solid #ccc;
      width: 319px;
    }
    .el-input .el-input__count{
      margin-right: 10px;
    }
    .el-form-item{
      margin-bottom: 10px;
    }
  }
/deep/.el-form-item__label{
      font-size: 14px;
      color: #7B7B7B;
      text-align: left;
    }
</style>
