<template>
  <div class="banner">
    <div class="edit_box">
      <div class="title">标题栏</div>
      <div class="radio_box">
        <el-radio-group v-model="linkobj.titleShow" @change="change">
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="0">不显示</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="edit_box">
      <div class="title">是否展示<span>更多</span></div>
      <div class="radio_box">
        <el-radio-group v-model="linkobj.moreShow" @change="change">
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="0">不显示</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="edit_box">
      <div class="title">标题名称</div>
      <div class="radio_box">
        <el-input v-model="linkobj.title" placeholder="请输入标题名称" clearable :disabled="linkobj.titleShow === 0" @change="change"/>
        <div class="line"></div>
      </div>
    </div>
    <div class="edit_box">
      <div class="title">板块样式</div>
      <div class="stylelist">
        <div class="newsstyle" v-for="index in 6" :key="index" :class="[{'active': active === index},'news'+index]" @click="changeStyle(index)"></div>
      </div>
    </div>
    <div class="edit_box" v-show="linkobj.style === 4 || linkobj.style === 5 ||linkobj.style === 6">
      <div class="title">每列个数</div>
      <div class="stylelist">
        <el-input-number
          v-model="num"
          size="medium"
          @change="handleChange"
          :min="1"
          :max="10"
          label="描述文字"></el-input-number>
      </div>
    </div>
    <div class="edit_box" v-show="linkobj.style === 4 || linkobj.style === 5 ||linkobj.style === 6">
      <div class="title">选择列</div>
      <div class="radio_box">
        <el-select v-model="column" placeholder="全部分类" @change="choseColumn">
          <el-option
            v-for="item in columnList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="edit_box">
      <div class="title">文章内容</div>
      <div class="radio_box">
        <el-radio-group v-model="articlerule" @change="changeType">
          <el-radio :label="1">默认规则</el-radio>
          <el-radio :label="0">手动选择文章</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="edit_box" v-show="articlerule === 0">
      <div class="title">列标题名称</div>
      <div class="radio_box">
        <el-input v-model="columnName" placeholder="请输入标题名称" clearable @change="changeColumnName"/>
      </div>
    </div>
    <div class="edit_list" v-show="articlerule === 0">
      <div v-for="(item, index) in newsColumnList" :key="index" class="list">
        <div class="txt" :title="item.firstTitle">{{ item.firstTitle }}</div>
        <a href="javascript:;" class="btn_delete" @click="deleteimg(index)"><img src="/images/myNews/delete_n.png" alt=""></a>
      </div>
      <el-button class="btn_addlist" @click="add">
        <img src="/images/index/btn_add.png" alt="">
        选择文章
      </el-button>
    </div>
    <div class="edit_box" v-show="articlerule === 1">
      <div class="title">选择文章类型</div>
      <div class="radio_box">
        <el-select v-model="value" placeholder="请选择" @change="choseNewsType">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
    <!--  -->
    <el-dialog
      title="选择新闻"
      :visible.sync="linkshow"
      width="880px"
      height="500px;"
      class="linkdialog"
      :destroy-on-close="true"
      :before-close="handleClose">
      <SeachModule @searchData="searchData" v-if="linkshow">
        <TableNews ref="tabledata" :loading="loading" :tableData="tableData" :infos="newsColumnList"/>
        <el-pagination
          v-if="total > 10"
          class="pagination"
          @current-change="handleCurrentChange"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </SeachModule>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="mini" round>取 消</el-button>
        <el-button type="primary" size="mini" @click="closelink" round>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getAxtNewSourceList } from '@/api/editIndex.js'
import { getAxtNewClassifyList } from '@/api/list.js'
import SeachModule from '@/components/netManage/SeachModule.vue'
import TableNews from '@/components/netManage/TableNews.vue'
export default {
  name: 'EditNews',
  data() {
    return {
      linkobj: {}, //
      efitlink: 0, //
      linkshow: false, //
      linkinput: null, //
      loading: false, //
      tableData: [], //
      total: 0, // 总数
      active: 1, // 板块样式
      articlerule: 1, // 文章默认还是手动
      options: [], // 文章类型数组
      value: 1, // 文章类型
      column: 0, // 第几列
      num: 5, // 每列新闻个数
      columnBaseList: [
        { name: '第一列', id: 0 },
        { name: '第二列', id: 1 },
        { name: '第三列', id: 2 },
        { name: '第四列', id: 3 }
      ],
      columnList: [],
      columnName: '',
      newsColumnList: [], // 每列新闻
      firstTitle: null // 新闻标题
    }
  },
  components: { SeachModule, TableNews },
  props: {
    newsData: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    newsData: {
      handler() {
        this.getlist()
      },
      deep: true
    },
    active: {
      handler(newval) {
        console.log(newval, '数据更新')
        this.resetArticlerule()
        this.column = 0
        if (newval === 4) {
          this.columnList = this.columnBaseList.slice(0, 4)
        } else if (newval === 5) {
          this.columnList = this.columnBaseList.slice(0, 3)
        } else if (newval === 6) {
          this.columnList = this.columnBaseList.slice(0, 2)
        }
      },
      deep: true
    },
    articlerule: {
      handler(newval) {
        console.log(newval, 'articlerule数据更新')
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['indexData', 'newstype'])
  },
  mounted() {
    this.getlist(true)
    this.getAxtNewClassifyList()
  },
  methods: {
    add() {
      this.linkshow = true
      this.getAxtNewSourceList()
    },
    // 获取新闻类型
    getAxtNewClassifyList() {
      const data = {
        website_id: this.indexData.websiteId
      }
      getAxtNewClassifyList(data).then(res => {
        // console.log(res, 'myinfo')
        if (res.message === 'success') {
          this.options = res.data.rows
        }
      })
    },
    searchData(res) {
      this.firstTitle = res
      this.getAxtNewSourceList(1, this.firstTitle)
    },
    handleClose() {
      this.linkshow = false
    },
    // 更改列标题名称
    changeColumnName() {
      this.linkobj.news[this.column].title = this.columnName
      this.$emit('changedata', {
        type: 'news',
        data: this.linkobj
      })
    },
    closelink() {
      const data = this.$refs.tabledata.infosdata
      if (data.length > this.num && this.linkobj.news[this.column].dataType === 1) {
        this.$toast('最多选' + this.num + '篇文章')
        return false
      }
      this.linkshow = false
      const arr = []
      data.forEach(v => {
        arr.push(v.id)
      })
      const ids = arr.join(',')
      if (ids === '') {
        this.linkobj.news[this.column].dataNewsIds = '0'
      } else {
        this.linkobj.news[this.column].dataNewsIds = ids
      }
      this.getColumnList(this.linkobj.news[this.column].dataType, ids, this.linkobj.news[this.column].dataNewsTypeId)
      this.$emit('changedata', {
        type: 'news',
        data: this.linkobj
      })
    },
    deleteimg(index) {
      this.newsColumnList.splice(index, 1)
      const arr = []
      this.newsColumnList.forEach((v) => {
        arr.push(v.id)
      })
      const ids = arr.join(',')
      if (ids === '') {
        this.linkobj.news[this.column].dataNewsIds = '0'
      } else {
        this.linkobj.news[this.column].dataNewsIds = ids
      }
      console.log(this.linkobj.news[this.column], '9999999999999999')
      this.$emit('changedata', {
        type: 'news',
        data: this.linkobj
      })
    },
    //
    handleChange() {
      this.linkobj.news.forEach(v => {
        v.dataSize = this.num
        if (v.dataType === 1) {
          if (this.newsColumnList.length > this.num) {
            const arr = v.dataNewsIds.split(',')
            const str = arr.slice(0, this.num).join(',')
            v.dataNewsIds = str
            this.getColumnList(1, v.dataNewsIds, v.dataNewsTypeId)
          }
        }
      })
      this.$emit('changedata', {
        type: 'news',
        data: this.linkobj
      })
    },
    // 选择第几列
    choseColumn() {
      this.resetArticlerule(this.column)
      this.columnName = this.linkobj.news[this.column].title
      this.$emit('changedata', {
        type: 'news',
        data: this.linkobj
      })
    },
    // 获取数据
    getlist(isFirst) {
      this.linkobj = { ...this.newsData }
      this.active = this.linkobj.style
      this.num = this.linkobj.news[0].dataSize
      // this.column = 0
      // console.log(this.linkobj.news[0], 'lllllllllllllllll')
      if (isFirst) {
        this.resetArticlerule()
      }
    },
    change() {
      console.log('change')
      this.$emit('changedata', {
        type: 'news',
        data: this.linkobj
      })
    },
    // 更改列表样式
    changeStyle(id) {
      this.linkobj.style = id
      this.active = id
      this.column = 0
      this.pushNewsList()
      this.$emit('changedata', {
        type: 'news',
        data: this.linkobj
      })
      // this.resetArticlerule()
    },
    // 新闻列表长度不够时添加长度
    pushNewsList() {
      if (this.active === 1 || this.active === 2 || this.active === 3) {
        this.linkobj.news = this.linkobj.news.slice(0, 1)
      }
      if (this.active === 4) {
        this.linkobj.news = this.linkobj.news.slice(0, 4)
      }
      if (this.active === 5) {
        this.linkobj.news = this.linkobj.news.slice(0, 3)
      }
      if (this.active === 6) {
        this.linkobj.news = this.linkobj.news.slice(0, 2)
      }
      if (this.active === 4 && this.linkobj.news.length < 4) {
        // this.linkobj.news
        this.pushFun()
      } else if (this.active === 5 && this.linkobj.news.length < 3) {
        this.pushFun()
      } else if (this.active === 6 && this.linkobj.news.length < 2) {
        this.pushFun()
      }
    },
    pushFun() {
      this.linkobj.news.push({
        title: '',
        titleShow: 1,
        id: this.linkobj.news.length,
        moreShow: 0,
        dataType: 1,
        dataNewsIds: '0',
        dataNewsTypeId: 1,
        dataSize: 5
      })
      this.pushNewsList()
    },
    // 重置
    resetArticlerule(index = 0) {
      if (this.linkobj.news[index].dataType === 1) {
        this.articlerule = 0
        this.columnName = this.linkobj.news[index].title
        this.getColumnList(this.linkobj.news[index].dataType, this.linkobj.news[index].dataNewsIds, this.linkobj.news[index].dataNewsTypeId)
      } else if (this.linkobj.news[index].dataType === 2) {
        this.value = this.linkobj.news[index].dataNewsTypeId
        this.articlerule = 1
      }
    },
    // 选择默认还是手动
    changeType() {
      this.linkobj.news.forEach((v, i) => {
        if (this.column === i && this.articlerule === 0) {
          v.dataType = 1
        } else if (this.column === i && this.articlerule === 1) {
          v.dataType = 2
        }
      })
      this.value = this.linkobj.news[this.column].dataNewsTypeId
      this.columnName = this.linkobj.news[this.column].title
      //
      const nameList = this.options.filter((v) => {
        return v.id === this.value
      })
      if (nameList && nameList.length > 0) {
        this.linkobj.news[this.column].title = nameList[0].name
      }
      this.getColumnList(this.linkobj.news[this.column].dataType, this.linkobj.news[this.column].dataNewsIds, this.linkobj.news[this.column].dataNewsTypeId)
      this.$emit('changedata', {
        type: 'news',
        data: this.linkobj
      })
    },
    // 默认规则选择类型
    choseNewsType() {
      this.linkobj.news[this.column].dataNewsTypeId = this.value
      const nameList = this.options.filter((v) => {
        return v.id === this.value
      })
      if (nameList && nameList.length > 0) {
        this.linkobj.news[this.column].title = nameList[0].name
        this.columnName = nameList[0].name
      }
      this.$emit('changedata', {
        type: 'news',
        data: this.linkobj
      })
    },
    // 获取新闻
    getAxtNewSourceList(page, firstTitle) {
      const params = {
        website_id: this.indexData.websiteId,
        cancel_publish: 0,
        pageNo: page || 1,
        pageSize: 10,
        title: firstTitle || null
      }
      getAxtNewSourceList(params).then((res) => {
        if (res.message === 'success') {
          this.tableData = res.data.rows
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    // 获取每列新闻
    getColumnList(dataType, dataNewsIds, dataNewsTypeId) {
      const params = {
        website_id: this.indexData.websiteId,
        cancel_publish: 0

      }
      if (dataType === 1) {
        if (!dataNewsIds) {
          this.newsColumnList = []
          return
        }
        params.ids = dataNewsIds
      } else if (dataType === 2) {
        params.classify_id = dataNewsTypeId
        params.pageSize = this.num
      }
      getAxtNewSourceList(params).then((res) => {
        // console.log(res, 'xinwen4')
        if (res.message === 'success') {
          this.newsColumnList = res.data.rows
        }
      })
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.getAxtNewSourceList(val)
    }
  }
}
</script>

<style lang="less" scoped>
.banner{
  text-align: left;
  font-size: 20px;
  .title{
    font-size: 14px;
    font-weight: 500;
    color: #666666;
    span{
      color: #5096F5;
    }
  }
  .radio_box{
    margin:5px 0 20px;
    /deep/.el-input{
      margin-top: 10px;
      .el-input__inner{
        border-radius: 8px;
      }
    }
    .line{
      margin-top: 20px;
      height: 3px;
      background: #F3F5FA;
    }
  }
  .stylelist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 10px;
      .newsstyle {
        margin-bottom: 5px;
        width: 85px;
        height: 55px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #CCCCCC;
        cursor: pointer;
      }
      .news1 {
        background: url(/images/editindex/news1.png) no-repeat center;
      }
      .news1.active {
        background: url(/images/editindex/news1bg.png) no-repeat center;
      }
      .news2 {
        background: url(/images/editindex/news2.png) no-repeat center;
      }
      .news2.active {
        background: url(/images/editindex/news2bg.png) no-repeat center;
      }
      .news3 {
        background: url(/images/editindex/news3.png) no-repeat center;
      }
      .news3.active {
        background: url(/images/editindex/news3bg.png) no-repeat center;
      }
      .news4 {
        background: url(/images/editindex/news4.png) no-repeat center;
      }
      .news4.active {
        background: url(/images/editindex/news4bg.png) no-repeat center;
      }
      .news5 {
        background: url(/images/editindex/news5.png) no-repeat center;
      }
      .news5.active {
        background: url(/images/editindex/news5bg.png) no-repeat center;
      }
      .news6 {
        background: url(/images/editindex/news6.png) no-repeat center;
      }
      .news6.active {
        background: url(/images/editindex/news6bg.png) no-repeat center;
      }
    }
  .edit_list{
    border-radius:  6px;
    background: #F2F2F2;
    margin: 20px 0 0;
    padding: 10px;
    .list{
      margin-bottom: 5px;
      height: 44px;
      border-radius: 6px;
      background: #fff;
      line-height: 44px;
      font-size: 12px;
      color: #666666;
      padding: 0 30px;
      position: relative;
      .txt{
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

    }
    .btn_delete{
      position: absolute;
      top: 10px;
      right: 10px;
      height: 28px;
      width: 28px;
      transition: 0.4s all ease;
      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;

      }
    }
  }

  .btn_addlist{
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #5096F5;
    padding: 0;
    height: 44px;
    img{
      width: 12px;
      margin-right: 10px;
    }
    border: none;
  }
  .linkdialog{
    .el-input{
      width: 350px;
    }
    span{
      color: #7B7B7B;
    }
    .pagination{
      text-align: center;
      margin-top: 20px;
    }
  }
}
</style>
