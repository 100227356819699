<template>
  <div class="showinfo mynews_cont">
    <div class="myNews_tab_box">
      <div class="myNews_tab hover">
        <div v-for="(item,index) in tabInfo" :key="index" :class="showTabMain === index?'myNews_tab_item myNews_tab_active':'myNews_tab_item'" @click="choseTab(index,item)"><span>{{ item.name }}</span></div>
      </div>
    </div>
    <div class="myNews_tab_main">
      <teacherinfo v-show="showTabMain == 0"></teacherinfo>
      <schoolinfo v-show="showTabMain == 1"></schoolinfo>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
import Teacherinfo from '@/components/mynews/teacherinfo.vue'
import Schoolinfo from '@/components/mynews/schoolinfo.vue'
export default {
  name: 'Showinfo',
  data() {
    return {
      txt: '',
      tabInfo: [],
      showTabMain: 0 // 默认显示分类管理 0，文章管理 1
    }
  },
  components: { Teacherinfo, Schoolinfo },
  watch: {
    navMain: {
      handler(newVal) {
        // console.log(newVal, 'newVal')
        this.tabInfo = this.navMain.children ? this.navMain.children : []
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['navMain'])
  },
  mounted() {
    if (sessionStorage.getItem('showTabMaininfo')) {
      this.showTabMain = parseInt(sessionStorage.getItem('showTabMaininfo'))
    }
  },
  methods: {
    ...mapMutations(['SET_NAVMAIN']),
    choseTab(res, obj) {
      // 切换tab内容
      this.showTabMain = res
      sessionStorage.setItem('showTabMaininfo', res)
      // console.log(obj)
    }
  }
}
</script>
<style lang="less" scoped>
.mynews_cont{
  position: absolute;
  left: 0;
  top: 0;
  width:100%;
  height: 100%;
  overflow: hidden;
}
.myNews_tab_main{
  position: relative;
  height: calc(100% - 65px);
  background: white;
}
</style>
