<template>
  <div class="permissionBox">
    <div class="permissionManageTop">
      <div class="studentNumver intBox">
        <div class="intName">名称</div>
        <el-input v-model="name" placeholder="请输入内容" class="numberInt int"></el-input>
      </div>
      <div class="studentRoll intBox">
        <div class="intName">工号</div>
        <el-input v-model="number" placeholder="请输入内容" class="rollInt int"></el-input>
      </div>
      <div class="intBox">
        <div class="inquireBtn" @click="inquireManager">查询</div>
      </div>
    </div>
    <!-- 按钮组 -->
    <div class="btnBox">
      <!-- <div class="btnItem" @click="addManageClick(true)">
        <img class="addIcon" src="images/icon/addIcon.png" alt=""> 添加权限
      </div> -->
      <el-button icon="el-icon-plus" round @click="addManageClick(true)" >添加权限</el-button>
    </div>
    <!-- 管理人员列表 表格 -->
    <div class="tableBox">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="tableData"
        border
        style="width: 100%">
        <el-table-column
          prop="name"
          label="姓名"
        >
        </el-table-column>
        <el-table-column
          prop="jobNum"
          label="工号">
        </el-table-column>
        <el-table-column
          prop="school"
          label="学校/单位"
        >
        </el-table-column>
        <!-- <el-table-column
          prop="type"
          width="100px"
          label="类型">
        </el-table-column> -->
        <el-table-column
          prop="operate"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="permissionView(scope.row)">查看权限</el-button>
            <el-button @click="delManager(scope.row)" type="text" size="small" class="delTxt">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pageBox">
      <el-pagination
        background
        :hide-on-single-page="true"
        layout="prev, pager, next"
        :page-size="12"
        :total="managerTotal"
        :current-page.sync="currentPageManager"
        @current-change="handleCurrentChangeManager"
      >
      </el-pagination>
    </div>
    <!-- 添加管理员 -->
    <div class="addManager">
      <el-dialog title="添加管理员" :visible.sync="addManagerVisible">
        <div class="itemBox">
          <div class="mustDo">*</div>
          <div class="title">选择用户</div>
          <div class="doThings">
            <div class="addBtn" @click="choseManager">{{ chose_user === ''?'点击选择用户':chose_user.name }}</div>
          </div>
        </div>
        <div class="perssionBox">
          <div class="itemBox">
            <div class="mustDo">*</div>
            <div class="title">操作权限</div>
          </div>
          <div class="chosePermission">
            <el-checkbox-group v-model="permissionCheckList" @change="changePermissionList">
              <table>
                <thead>
                  <tr>
                    <th>一级菜单</th>
                    <th>二级菜单</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in addPermissionList" :key="item.id">
                    <td>
                      <el-checkbox class="menuItem" :label="item" @change="changeLevelOne(item)">{{ item.name }}</el-checkbox>
                    </td>
                    <td>
                      <el-checkbox class="menuItem" :label="v" v-for="(v, i) in item.children" :key="i" @change="changeLevelTwo(v)">{{ v.name }}</el-checkbox>
                    </td>
                  </tr>
                </tbody>
              </table>
            </el-checkbox-group>
          </div>
        </div>
        <div class="itemBox">
          <div class="mustDo">*</div>
          <div class="title">数据权限</div>
          <div class="doThings">
            <div class="addBtn" @click="clickGrant">点击分配权限</div>
          </div>
        </div>
        <div class="itemBox">
          <div class="mustDo"></div>
          <div class="title">已选权限</div>
          <div class="doThings">
            <!-- <div class="closeAll" @click="isDefaultExpandAll = !isDefaultExpandAll">全部关闭</div> -->
          </div>
        </div>
        <div class="itemBox treeBox">
          <el-tree
            :data="hasChosePermission"
            :props="defaultProps"
            :default-expand-all="isDefaultExpandAll"
          >
          </el-tree>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addManageClick(false)">取消</el-button>
          <el-button type="primary" @click="addManageSure()">保存</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 选择用户 -->
    <div class="choseUser">
      <el-dialog title="选择用户" :visible.sync="choseUserVisible">
        <div class="chsoeBox">
          <div class="studentRoll intBox">
            <div class="intName">工号</div>
            <el-input v-model="user_num" placeholder="请输入内容" class="rollInt int"></el-input>
          </div>
          <div class="intBox">
            <div class="inquireBtn" @click="clickUserView">查询</div>
          </div>
        </div>
        <el-table
          ref="multipleTable"
          :data="userData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          height="320">
          <el-table-column
            type="operate"
            width="55"
            class="addBtnBox">
            <template slot-scope="scope">
              <div class="addBtn" @click="addManager(scope.row)">+</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名">
          </el-table-column>
          <el-table-column
            prop="jobNum"
            label="工号">
          </el-table-column>
          <el-table-column
            prop="phone"
            label="手机号">
          </el-table-column>
          <el-table-column
            prop="email"
            label="邮箱">
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="pageBox">
          <el-pagination
            background
            :hide-on-single-page="true"
            layout="prev, pager, next"
            :total="userTotal"
            :page-size="12"
            @current-change="handleCurrentChangeUser"
            :current-page.sync="currentPageUser"
          >
          </el-pagination>
        </div>
      </el-dialog>
    </div>
    <!-- 数据权限 -->
    <div class="dataManage choseUser">
      <el-dialog title="数据权限" :visible.sync="dataManageVisible">
        <div class="chsoeBox">
          <div class="tabBox">
            <div class="choseUnit">选择单位</div>
            <!-- <div class="choseUnit" @click="isCloseChoseDataPermission = !isCloseChoseDataPermission">{{ isCloseChoseDataPermission?'全部关闭':'全部展开' }}</div> -->
          </div>
          <div class="studentRoll intBox">
            <el-input v-model="filterText" placeholder="请输入关键词" class="rollInt int"></el-input>
          </div>
          <div class="intBox">
            <!-- <div class="inquireBtn">查询</div> -->
          </div>
        </div>
        <div class="unitList">
          <el-tree
            :data="choseDataPermissionList"
            show-checkbox
            :props="defaultProps"
            :filter-node-method="filterNode"
            ref="tree"
            @check-change="checkChangeDataPermission">
          </el-tree>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addPermissionClick(false)">取消</el-button>
          <el-button type="primary" @click="addDataManageSure()">保存</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 查看权限 -->
    <div class="checkPermission choseUser">
      <el-dialog title="查看权限" :visible.sync="checkPermissionVisible">
        <div class="permissionBox">
          <div class="doPermission item">
            <div class="title">操作权限</div>
            <div class="doPermissionCont">
              <el-tree
                :data="permissionList"
                :default-expand-all="true"
                :props="defaultProps"
              >
              </el-tree>
            </div>
          </div>
          <div class="dataPermission item">
            <div class="title">数据权限</div>
            <div class="doPermissionCont">
              <el-tree
                :data="dataPermissionListList"
                :props="defaultProps"
                :default-expand-all="true"
              >
              </el-tree>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { getManagerList, getManagerAuthMenuByTeacherId, getManageUnit, getManageMenuList, saveManagerAuth, deleteManagerAuth } from '@/api/permission.js'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      name: '', // 名称
      number: '', // 工号
      currentPageUser: 1,
      currentPageManager: 1,
      managerTotal: null, // 管理员总数 用于分页
      userTotal: null, // 用户总数  用于分页
      tableData: [],
      addManagerVisible: false, // 添加管理员弹窗
      choseUserVisible: false, // 选择用户
      userData: [],
      dataManageVisible: false, // 数据权限
      checkPermissionVisible: false, // 查看权限
      filterText: '', // 数据权限查询
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      dataList: [],
      permissionList: [], // 查看权限 列表
      addPermissionList: [], // 添加权限--> 操作权限列表
      dataPermissionListList: [], // 查看数据权限
      choseDataPermissionList: [], // 分配数据权限
      isCloseChoseDataPermission: true, // 是否展开数据权限 树
      userCurrentPage: 1, // 选择用户当前页
      user_num: '', // 选择用户  工号
      chose_user: '', // 选择的用户
      permissionCheckList: [], // 选择的菜单权限 集合
      authMenus: [], // 操作权限  提交参数
      authProvinces: [], // 数据权限 提交参数
      hasChosePermission: [], // 已选权限
      isDefaultExpandAll: true // 是否全部展开已选的树
    }
  },
  watch: {
    treeData: {
      handler(newVal) {
        this.getManagerList(2, 1)
        if (this.treeData.type && this.treeData.type === 'school') {
          this.schoolId = this.treeData.schoolId
          this.companyId = null
        }
        if (this.treeData.type && this.treeData.type === 'company') {
          this.companyId = this.treeData.companyId
          this.schoolId = null
        }
      },
      deep: true
    },
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  computed: {
    ...mapGetters(['treeData', 'indexData'])
  },
  mounted() {
    this.getManagerList(2, 1)
  },
  methods: {
    // type:  2:管理员列表 0:用户列表
    getManagerList(type, pageNo, name, num) {
      const params = {
        adminType: type,
        pageSize: 12,
        pageNo: pageNo,
        provinceId: this.treeData.provinceId
      }
      if (num) {
        params.jobNum = num
      }
      if (name) {
        params.name = name
      }
      if (this.treeData.type === 'city') {
        params.cityId = this.treeData.cityId
      } else if (this.treeData.type === 'area') {
        params.areaId = this.treeData.cityId
      } else if (this.treeData.type === 'company') {
        params.companyId = this.treeData.companyId
      } else {
        params.schoolId = this.treeData.schoolId
      }
      getManagerList(params).then((res) => {
        // console.log('人员列表', res)
        if (res.message === 'success') {
          if (type === 2) {
            this.tableData = res.data.rows
            this.tableData.forEach(v => {
              if (v.schoolName) {
                v.school = v.schoolName
              }
              if (v.companyName) {
                v.school = v.companyName
              }
            })
            this.managerTotal = res.data.total
            this.currentPageManager = res.data.pageNo
          } else {
            this.userData = res.data.rows
            this.userTotal = res.data.total
            this.currentPageUser = res.data.pageNo
          }
        }
      })
    },
    // 查询管理员
    inquireManager() {
      if (!Number(this.number) && this.number !== '') {
        this.number = ''
        this.$toast('请输入正确的工号')
        return
      }
      this.getManagerList(2, 1, this.name, this.number)
    },
    // 查看权限
    permissionView(val) {
      const data = { teacherId: val.id }
      this.permissionList = []
      this.dataPermissionListList = []
      // 操作权限
      getManagerAuthMenuByTeacherId(data).then((res) => {
        if (res.message === 'success') {
          const data = res.data
          // this.getIsCheck(data)
          this.permissionList = this.getIsCheck(data)
        }
      })
      // 数据权限
      getManageUnit(data).then((res) => {
        if (res.message === 'success') {
          const data = res.data
          this.getSec(data, true)
          this.dataPermissionListList = data
        }
      })
      this.checkPermissionVisible = true
    },
    getIsCheck(data) {
      const datas = data.filter((v) => {
        return v.check !== -1
      })
      datas.forEach(v => {
        v.children = v.children.filter(item => item.check === 1)
      })
      return datas
    },
    getSec(data, bool) {
      data.forEach((element, index) => {
        // if (element.ckeck === -1) {
        //   return
        // }
        element.children = []
        if (bool) {
          element.name = element.provinceName
        }

        if (element.websiteAuthCityList && element.websiteAuthCityList.length > 0) {
          element.websiteAuthCityList.forEach(res => {
            res.name = res.cityName
          })
          element.websiteAuthCityList = element.websiteAuthCityList.filter((v) => {
            return v.ckeck !== -1
          })
          element.children = [...element.websiteAuthCityList]
        }
        if (element.websiteAuthAreaList && element.websiteAuthAreaList.length > 0) {
          element.websiteAuthAreaList.forEach(res => {
            res.name = res.areaName
          })
          element.websiteAuthAreaList = element.websiteAuthAreaList.filter((v) => {
            return v.ckeck !== -1
          })
          element.children = [...element.children, ...element.websiteAuthAreaList]
        }
        if (element.websiteAuthSchoolList && element.websiteAuthSchoolList.length > 0) {
          element.websiteAuthSchoolList.forEach(res => {
            res.name = res.schoolName
            res.type = 'school'
          })
          element.websiteAuthSchoolList = element.websiteAuthSchoolList.filter((v) => {
            return v.ckeck !== -1
          })
          element.children = [...element.children, ...element.websiteAuthSchoolList]
        }
        if (element.websiteAuthCompanyList && element.websiteAuthCompanyList.length > 0) {
          element.websiteAuthCompanyList.forEach(res => {
            res.name = res.companyName
            res.type = 'company'
          })
          element.websiteAuthCompanyList = element.websiteAuthCompanyList.filter((v) => {
            return v.ckeck !== -1
          })
          element.children = [...element.children, ...element.websiteAuthCompanyList]
        }
        if (element.children.length > 0) {
          this.getSec(element.children)
        }
      })
    },
    // 点击分配权限
    clickGrant() {
      this.getDataPeimission()
      // 显示分配权限弹窗
      this.dataManageVisible = true
    },
    // 分配权限  树
    getDataPeimission() {
      const data = {}
      getManageUnit(data).then((res) => {
        if (res.message === 'success') {
          const data = res.data
          console.log('shu', data)
          this.getSec_chose(data, true)
          this.choseDataPermissionList = data
        }
      })
    },
    // 分配权限查询
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    getSec_chose(data, bool) {
      data.forEach((element, index) => {
        element.children = []
        if (bool) {
          element.name = element.provinceName
        }

        if (element.websiteAuthCityList && element.websiteAuthCityList.length > 0) {
          element.websiteAuthCityList.forEach(res => {
            res.name = res.cityName
            res.type = 'city'
          })
          element.children = [...element.websiteAuthCityList]
        }
        if (element.websiteAuthAreaList && element.websiteAuthAreaList.length > 0) {
          element.websiteAuthAreaList.forEach(res => {
            res.name = res.areaName
            res.type = 'area'
          })
          element.children = [...element.children, ...element.websiteAuthAreaList]
        }
        if (element.websiteAuthSchoolList && element.websiteAuthSchoolList.length > 0) {
          element.websiteAuthSchoolList.forEach(res => {
            res.name = res.schoolName
            res.type = 'school'
          })
          element.children = [...element.children, ...element.websiteAuthSchoolList]
        }
        if (element.websiteAuthCompanyList && element.websiteAuthCompanyList.length > 0) {
          element.websiteAuthCompanyList.forEach(res => {
            res.name = res.companyName
            res.type = 'company'
          })
          element.children = [...element.children, ...element.websiteAuthCompanyList]
        }
        if (element.children.length > 0) {
          this.getSec_chose(element.children)
        }
      })
    },
    // 分配数据权限 选择权限
    checkChangeDataPermission(data, checked, indeterminate) {
      setTimeout(() => {
        data.checked = checked
        data.indeterminate = indeterminate
        // 如果选择某一父节点，则给该节点的所有子节点添加checked indeterminate 属性
        if (checked && !indeterminate) {
          if (data.children && data.children.length > 0) {
            if (data.websiteAuthCityList) {
              data.websiteAuthCityList.forEach(v => {
                v.checked = checked
                v.indeterminate = indeterminate
              })
            }
            if (data.websiteAuthAreaList) {
              data.websiteAuthAreaList.forEach(v => {
                v.checked = checked
                v.indeterminate = indeterminate
              })
            }
            if (data.websiteAuthSchoolList) {
              data.websiteAuthSchoolList.forEach(v => {
                v.checked = checked
                v.indeterminate = indeterminate
              })
            }
            if (data.websiteAuthCompanyList) {
              data.websiteAuthCompanyList.forEach(v => {
                v.checked = checked
                v.indeterminate = indeterminate
              })
            }
            this.dealTreeIsChose(data.children, checked, indeterminate)
          }
        }
        // 已选择权限
        const nodes = JSON.parse(JSON.stringify(this.choseDataPermissionList))
        console.log('chose', this.choseDataPermissionList)
        this.dealHasChoseTree(nodes)
        this.hasChosePermission = nodes
      }, 10)
    },
    // 保存数据权限
    addDataManageSure() {
      console.log('jjjjjj', this.choseDataPermissionList)
      this.deleteTree(this.choseDataPermissionList)
      this.dealTree(this.choseDataPermissionList)
      this.authProvinces = this.choseDataPermissionList
      this.dataManageVisible = false
    },
    // 给每个item 添加选中状态
    dealTreeIsChose(data, checked, indeterminate) {
      data.forEach(v => {
        if (v.children && v.children.length > 0) {
          v.checked = checked
          v.indeterminate = indeterminate
          if (v.websiteAuthCityList) {
            v.websiteAuthCityList.forEach(x => {
              x.checked = checked
              x.indeterminate = indeterminate
            })
          }
          if (v.websiteAuthAreaList) {
            v.websiteAuthAreaList.forEach(x => {
              x.checked = checked
              x.indeterminate = indeterminate
            })
          }
          if (v.websiteAuthSchoolList) {
            v.websiteAuthSchoolList.forEach(x => {
              x.checked = checked
              x.indeterminate = indeterminate
            })
          }
          if (v.websiteAuthCompanyList) {
            v.websiteAuthCompanyList.forEach(x => {
              x.checked = checked
              x.indeterminate = indeterminate
            })
          }
          this.dealTreeIsChose(v.children, checked, indeterminate)
        }
      })
    },
    dealHasChoseTree(data) {
      data.forEach(v => {
        v.children = v.children.filter(item => item.checked || item.indeterminate)
        if (v.children && v.children.length > 0) {
          if (v.websiteAuthCityList) {
            v.websiteAuthCityList.forEach(e => {
              e.children = e.children.filter(item => item.checked || item.indeterminate)
            })
          }
          if (v.websiteAuthAreaList) {
            v.websiteAuthAreaList.forEach(e => {
              e.children = e.children.filter(item => item.checked || item.indeterminate)
            })
          }
          if (v.websiteAuthSchoolList) {
            v.websiteAuthSchoolList.forEach(e => {
              e.children = e.children.filter(item => item.checked || item.indeterminate)
            })
          }
          if (v.websiteAuthCompanyList) {
            v.websiteAuthCompanyList.forEach(e => {
              e.children = e.children.filter(item => item.checked || item.indeterminate)
            })
          }
          this.dealHasChoseTree(v.children)
        }
      })
    },
    dealTree(data) {
      data.forEach(v => {
        if (v.checked && !v.indeterminate && v.operate === 1) {
          v.authType = 1
        } else if (v.type === 'school') {
          v.authType = 1
        } else {
          v.authType = 0
        }
        if (v.children && v.children.length > 0) {
          v.authUnits = []
          if (v.websiteAuthCityList) {
            v.authCitys = v.children.filter(item => item.type === 'city' && (item.checked || item.indeterminate))
          }
          if (v.websiteAuthAreaList) {
            v.authAreas = v.children.filter(item => item.type === 'area' && (item.checked || item.indeterminate))
          }
          if (v.websiteAuthSchoolList && v.websiteAuthSchoolList.length > 0) {
            v.authUnits = v.children.filter(item => item.type === 'school' && (item.checked || item.indeterminate))
          }
          if (v.websiteAuthCompanyList && v.websiteAuthCompanyList.length > 0) {
            const arr = v.children.filter(item => item.type === 'company' && (item.checked || item.indeterminate))
            if (v.authUnits.length > 0) {
              v.authUnits = [...v.authUnits, ...arr]
            } else {
              v.authUnits = arr
            }
          }
          this.dealTree(v.children)
        }
        for (const i in v) {
          if (['checked', 'indeterminate', 'operate', 'children', 'type', 'websiteAuthCityList', 'websiteAuthAreaList', 'websiteAuthSchoolList', 'websiteAuthCompanyList', 'companyId', 'schoolId', 'companyName', 'parentCompanyId'].includes(i)) {
            delete v[i]
          }
          if (['cityId', 'cityName', 'areaId', 'areaName'].includes(i)) {
            if (v[i] === null) {
              delete v[i]
            }
          }
        }
      })
    },
    deleteTree(data) {
      console.log('datas', data)
      data.forEach(v => {
        for (const i in v) {
          if (!['checked', 'indeterminate', 'operate', 'children', 'type', 'websiteAuthCityList', 'websiteAuthAreaList', 'websiteAuthSchoolList', 'websiteAuthCompanyList', 'provinceName', 'provinceId', 'cityName', 'cityId', 'areaName', 'areaId', 'companyId', 'schoolId', 'companyName', 'parentCompanyId'].includes(i)) {
            delete v[i]
          }
          // console.log('type', v.type)
          // if (i === 'companyId') {
          //   v.unitId = v[i]
          // }
          if (i === 'type') {
            if (v[i] === 'school') {
              v.unitType = 2
              v.unitId = v.schoolId
              v.pUnitId = v.companyId
              console.log('schoolId', v.schoolId, v)
            } else if (v[i] === 'company') {
              v.unitType = 1
              v.unitId = v.companyId
              v.pUnitId = v.parentCompanyId
            }
          }
          // if (i === 'parentCompanyId') {
          //   v.pUnitId = v[i]
          // }
        }
        if (v.children && v.children.length > 0) {
          this.deleteTree(v.children)
        }
      })
    },
    // 选择用户
    choseManager() {
      this.getManagerList(0, 1)
      this.choseUserVisible = true
    },
    // 查询用户
    clickUserView() {
      if (!Number(this.user_num) && this.user_num !== '') {
        this.user_num = ''
        this.$toast('请输入正确的工号')
        return
      }
      this.getManagerList(0, 1, '', this.user_num)
    },
    // 选择用户 翻页
    handleCurrentChangeUser(page) {
      this.userCurrentPage = page
      this.getManagerList(0, page)
    },
    // 管理员列表翻页
    handleCurrentChangeManager(page) {
      this.getManagerList(2, page)
    },
    // 添加管理员
    addManageClick(is) {
      // 清空上次添加的数据
      this.chose_user = ''
      this.authProvinces = []
      this.authMenus = []
      this.hasChosePermission = []
      this.getManageMenuList()
      this.addManagerVisible = is
    },
    // 选择数据权限
    addPermissionClick(boole) {
      // this.hasChosePermission = []

      this.dataManageVisible = false
    },
    // 获取操作权限列表
    getManageMenuList() {
      this.addPermissionList = []
      this.permissionCheckList = []
      getManageMenuList().then((res) => {
        if (res.message === 'success') {
          this.addPermissionList = res.data
        }
      })
    },
    // 选择一级列表
    changeLevelOne(item) {
      console.log('选择一级菜单', this.permissionCheckList)
      // console.log('选择一级菜单11', item)
      // 判断一级菜单是否选中  选中一级菜单 默认选择所有一级菜单下的二级菜单   否则清空该一级菜单下的全部二级菜单
      if (this.permissionCheckList.indexOf(item) !== -1) {
        if (item.children && item.children.length > 0) {
          item.children.forEach(v => {
            this.permissionCheckList.push(v)
          })
        }
      } else {
        this.permissionCheckList.splice(this.permissionCheckList.findIndex(v => v.id === item.id), 1)
        this.permissionCheckList = this.permissionCheckList.filter(x => x.pid !== item.id)
        // this.permissionCheckList.splice(this.permissionCheckList.findIndex(v => v.pid === item.id), 1)
      }
      console.log('选择一级菜单11', this.permissionCheckList)
    },
    // 选择二级菜单
    changeLevelTwo(item) {
      // 选择二级菜单  默认选中一级菜单   如果二级菜单没有选中的 这取消选中一级菜单
      this.addPermissionList.forEach(v => {
        if (item.pid === v.id) {
          if (this.permissionCheckList.indexOf(v) === -1) {
            this.permissionCheckList.push(v)
          }
        }
      })
      this.permissionCheckList.forEach(v => {
        if (v.level === 1 && this.permissionCheckList.findIndex(x => x.pid === v.id) === -1) {
          this.permissionCheckList.splice(this.permissionCheckList.findIndex(x => x.id === v.id), 1)
        }
      })
    },
    // 选择操作列表
    changePermissionList(res) {
      this.authMenus = []
      this.permissionCheckList.forEach(ele => {
        const obj = {}
        obj.createTime = ele.createTime
        obj.menuCode = ele.code
        obj.menuLevel = ele.level
        obj.menuName = ele.name
        obj.menuPId = ele.pid
        obj.menuId = ele.id
        if (ele.level !== 1) {
          if (ele.children && ele.children.length > 0) {
            ele.children.forEach(v => {
              const objs = {}
              objs.createTime = v.createTime
              objs.menuCode = v.code
              objs.menuLevel = v.level
              objs.menuName = v.name
              objs.menuPId = v.pid
              objs.menuId = v.id
              this.authMenus.push(objs)
            })
          }
        }
        this.authMenus.push(obj)
      })
      this.unique(this.authMenus)
    },
    unique(arr) {
      return arr.filter(function (item, index, arr) {
        // 当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
        return arr.indexOf(item, 0) === index
      })
    },
    addManager(obj) {
      this.chose_user = obj
      this.choseUserVisible = false
    },
    addManageSure() {
      if (this.chose_user === '') {
        this.$toast('请选择用户')
        return
      }
      if (this.authProvinces.length === 0) {
        this.$toast('请分配数据权限')
        return
      }
      if (this.authMenus.length === 0) {
        this.$toast('请选择操作权限')
        return
      }
      const data = {
        teacherId: this.chose_user.id,
        authProvinces: this.authProvinces,
        authMenus: this.authMenus
      }
      saveManagerAuth(data).then((res) => {
        if (res.message === 'success') {
          this.addManagerVisible = false
          this.$confirm('授权成功！', '提示', {
            confirmButtonText: '确定',
            showCancelButton: false,
            confirmButtonClass: 'sureButton',
            roundButton: true,
            type: 'success'
          }).then(() => {
            this.name = ''
            this.number = ''
            this.getManagerList(2, 1)
          }).catch(() => {

          })
        }
      })
    },
    // 删除管理员
    delManager(row) {
      const url = '/axt-usercenter/manage_teacher/deleteManagerAuth?managerId=' + row.id
      deleteManagerAuth(url).then((res) => {
        if (res.message === 'success') {
          this.$toast('删除成功')
          this.getManagerList(2, 1)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.permissionBox{
    width: 100%;
    height: 100%;
    .permissionManageTop {
        width: 100%;
        overflow: hidden;
        .intBox {
            float: left;
            margin-right: 32px;
            margin-bottom: 15px;
            // display: flex;
            // flex-direction: row;
            .intName {
                min-width: 30px;
                display: inline;
                font-size: 14px;
                color: #666666;
                padding-right: 10px;
            }
            .int {
                display: inline;
            }
            /deep/.el-input__inner {
                width: 224px;
                height: 34px;
                border-radius: 4px;
                font-size: 14px;
                color: #666666;
            }
            .inquireBtn {
                width: 56px;
                height: 34px;
                background: #5096F5;
                border-radius: 4px;
                font-size: 14px;
                color: #ffffff;
                text-align: center;
                line-height: 34px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .selBox {
            /deep/.el-input__inner {
                width: 123px;
                height: 34px;
                border-radius: 4px;
                font-size: 14px;
                color: #666666;
            }
            /deep/.el-input__icon {
                line-height: 34px;
            }
        }
    }
    .btnBox{
        margin-top: 10px;
        text-align: left;
        /deep/.el-button {
          margin-right: 15px;
          padding:9px 23px;
          &.is-round {
            border: 1px solid #5096F5;
            color: #5096F5;
          }
        }
        .btnItem {
            width: 107px;
            height: 30px;
            border-radius: 17px;
            border: 1px solid #5096F5;
            line-height: 30px;
            color: #5096F5;
            font-size: 14px;
            margin-right: 22px;
            .addIcon {
                width: 12px;
                height: 12px;
            }
            .delIcon {
                width: 13px;
                height: 13px;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
    .tableBox,.choseUser {
        margin-top: 20px;
        max-height: calc(100% - 240px);
        overflow-y: auto;
        /deep/.el-table--enable-row-transition .el-table__body td{
            text-align: center;
            font-size: 14px;
            color: #666666;
            font-weight: 500;
        }
        /deep/.el-table th, .el-table tr{
            background: #F2F2F2;
            border-radius: 4px 4px 0px 0px;
            text-align: center;
            font-size: 14px;
            color: #666666;
            font-weight: 500;
        }
        /deep/.el-table td, .el-table th {
          padding: 5px 0;
        }
        .delTxt {
            color: #F56C6C;
        }
    }
        .pageBox {
          margin-top: 15px;
        }
    // 添加学生弹窗
    .addManager {
        /deep/.el-dialog {
            width: 690px;
            min-height: 490px;
            .el-dialog__header {
                padding: 20px 40px 10px;
                text-align: left;
                font-weight: 600;
                color: #333333;
                font-size: 16px;
                border-bottom: 1px solid #F2F2F2;
            }
            .el-dialog__body {
                padding: 22px;
                .itemBox {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 15px;
                    .mustDo {
                        color: #F56C6C;
                        font-size: 14px;
                        padding-top: 10px;
                        width: 30px;
                    }
                    .title{
                        font-size: 14px;
                        font-weight: 600;
                        color: #333333;
                        line-height: 34px;
                        padding-right: 20px;
                    }
                    .addBtn {
                        width: 170px;
                        height: 34px;
                        border-radius: 8px;
                        border: 1px solid #5096F5;
                        line-height: 34px;
                        text-align: center;
                        color: #5096F5;
                        font-size: 14px;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                    .closeAll{
                        font-size: 14px;
                        color: #5096F5;
                        line-height: 34px;
                    }
                }
                .treeBox{
                  width: 610px;
                  height: 147px;
                  border: 1px solid #F2F2F2;
                  overflow-y: auto;
                  .el-tree {
                    width: 100%;
                  }
                }
                .perssionBox{
                        width: 100%;
                        .itemBox{
                            margin-bottom: 0;
                        }
                        .chosePermission{
                            width: 100%;
                            min-height: 100px;
                            margin-left: 30px;
                            margin-bottom: 15px;
                            display: flex;
                            table{
                                width: 93%;
                                border-collapse:collapse;
                                thead {
                                  font-size: 14px;
                                }
                            }
                            tr{
                                border-bottom: 1px solid #F2F2F2;
                                th{
                                    text-align: left;
                                    height: 20px;
                                    padding: 5px 0;
                                }
                                th:nth-child(1){
                                    width: 130px;
                                }
                                td{
                                    text-align: left;
                                    padding: 5px 0;
                                    height: 20px;
                                    .menuItem{
                                        margin-bottom: 10px;
                                    }
                                }
                            }
                        }
                    }
            }
            .el-dialog__footer {
                text-align: center;
                .el-button {
                    width: 60px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    padding: 0;
                    border-radius: 15px;
                }
            }
        }
    }
    .choseUser{
        .chsoeBox{
            overflow: hidden;
            .intBox {
                float: left;
                margin-right: 10px;
                margin-bottom: 15px;
                // display: flex;
                // flex-direction: row;
                .intName {
                    min-width: 30px;
                    display: inline;
                    font-size: 14px;
                    color: #666666;
                    padding-right: 10px;
                }
                .int {
                    display: inline;
                }
                /deep/.el-input__inner {
                    width: 224px;
                    height: 34px;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #666666;
                }
                .inquireBtn {
                    width: 56px;
                    height: 34px;
                    background: #5096F5;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #ffffff;
                    text-align: center;
                    line-height: 34px;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
        /deep/.el-dialog {
            width: 690px;
            min-height: 490px;
            .el-dialog__header {
                padding: 20px 40px 10px;
                text-align: left;
                font-weight: 600;
                color: #666;
                font-size: 16px;
                border-bottom: 1px solid #F2F2F2;
            }
            .el-dialog__body {
                padding: 10px 40px;
                .el-table__body, .el-table__footer, .el-table__header{
                    height: 40px;
                }
                .el-table td, .el-table th{
                    padding: 0;
                    color: #666;
                }
                .el-table td, .el-table th {
                  padding: 5px 0;
                }
                .addBtn{
                    width: 15px;
                    height: 15px;
                    border: 1px solid #5096F5;
                    border-radius: 50%;
                    color: #5096F5;
                    text-align: center;
                    line-height: 12px;
                    font-size: 8px;
                    margin-left: 10px;
                }
                &:hover{
                    cursor: pointer;
                }
            }
        }
        .pageBox{
            margin-top: 15px;
        }
    }
    .dataManage {
        .tabBox{
            display: flex;
            flex-direction:row;
            padding-bottom:10px;
            .choseUnit {
                margin-right: 70px;
            }
        }
        .unitList{
            width: 610px;
            height: 280px;
            overflow-y: auto;
            border: 1px solid #F2F2F2;
            /deep/.el-tree{
                margin: 15px 0 10px 20px;
            }
        }
        /deep/.el-dialog__footer {
            text-align: center;
            .el-button {
                width: 60px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                padding: 0;
                border-radius: 15px;
            }
        }
    }
    .checkPermission{
        width: 610px;
        margin: 10px auto;
        overflow: hidden;
        .item {
            width: 295px;
            .title {
                font-size: 14px;
                color: #333333;
                padding-bottom: 10px;
                text-align: left;
            }
            .doPermissionCont {
                width: 295px;
                height: 348px;
                border: 1px solid #F2F2F2;
                overflow-y: auto;
            }
        }
        .doPermission  {
            float: left;
        }
        .dataPermission {
            float: right;
        }
    }
}
</style>
