<template>
  <div class="inform">
    <div class="myNews_tab_box">
      <div class="myNews_tab hover">
        <div v-for="(item,index) in tabInfo" :key="index" :class="showTabMain === index?'myNews_tab_item myNews_tab_active':'myNews_tab_item'" @click="choseTab(index,item)"><span>{{ item.name }}</span></div>
      </div>
    </div>
    <div class="myNews_tab_main">
      <notice> </notice>
    </div>
  </div>
</template>
<script>
import Notice from '@/components/mynews/noticeinfo.vue'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Inform',
  data() {
    return {
      txt: '',
      tabInfo: [],
      showTabMain: 0 // 默认显示分类管理 0，文章管理 1
    }
  },
  components: {
    Notice
  },
  watch: {
    navMain: {
      handler(newVal) {
        console.log(newVal, 'newVal')
        this.tabInfo = this.navMain.children ? this.navMain.children : []
        this.tabInfo = this.tabInfo.filter((v) => {
          return v.code !== '5E42E0AD'
        })
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['navMain'])
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['SET_NAVMAIN']),
    choseTab(res, obj) {
      // 切换tab内容
      this.showTabMain = res
      // console.log(obj)
    }
  }
}
</script>
