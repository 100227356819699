<template>
  <!-- <div id="editor">asasd</div> -->
  <!-- <div>asdasdsadsadasadsad</div> -->
  <el-dialog
    title="预览"
    :visible="isView"
    width="800"
    :modal="true"
    :append-to-body="true"
    :before-close="closeview"
    class="addClassify_cont">
    <div class="addClassify_info">
      <el-form label-width="80px">
        <el-form-item label="文章名称">
          <el-input class="addname" v-model="classifyTitle" disabled placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="文章分类">
          <el-input class="addname" v-model="newsViewCon.classifyName" disabled placeholder="请输入名称"></el-input>

        </el-form-item>
        <el-form-item label="文章详情" class="articleview">
          <div v-if="newsViewCon.myLink" style="width:600px"><a class="link" :href="newsViewCon.myLink" target="_blank">{{ newsViewCon.myLink }}</a></div>
          <div v-if="newsViewCon.newContent" v-html="newsViewCon.newContent"></div>
        </el-form-item>
      </el-form>
    </div>
    <!-- <template #footer>
      <span class="dialog-footer">
        <el-button round class="btn" @click="closeview">取 消</el-button>
        <el-button round class="btn" type="primary" @click="closeview">确 定</el-button>
      </span>
    </template> -->
  </el-dialog>

</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AddArticle',
  props: {
    isView: {
      type: Boolean,
      default: false
    },
    newsViewCon: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // dialogVisible: true,
      classifyTitle: '',
      options: [],
      value: 0,
      title: ''
    }
  },
  created() {
    this.options = this.newstype
    console.log(this.options, 'this.options')
  },
  watch: {
    dialogVisible() {

    },
    newsViewCon: {
      handler(newVal) {
        console.log(newVal, 'cccccccccc')
        this.classifyTitle = newVal.firstTitle || ''
        this.value = newVal.classifyId || 0
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['indexData', 'newstype'])
  },
  mounted() {
    // this.options = this.newstype
    // console.log(this.options, 'this.options')
  },
  methods: {
    closeview() {
      const data = { title: '', isClose: true }
      this.$emit('closeview', data)
    }
  }
}
</script>
<style lang="less">

  .addClassify_cont {
    table,table tr, table td, table th{
      border: 1px solid #555;
    }
    .el-dialog {
      min-width: 700px;
      border-radius: 8px;
      // width: 50%;

      // border-radius: 5px;
      // margin-top: 0!important;
      // top: 50%;
      // transform: translateY(-50%);
    }
    .el-dialog__body {
      padding: 15px 20px;
    }
    .el-form-item__content{
      width: 490px;
      border-radius: 10px;
      text-align: left;
    }
    .articleview .el-form-item__content {
      width: 88%;
      max-height: 40vh;
      overflow-y: auto;
    }
    .editorbox .el-form-item__content {
      width: 88%;
    }
    .articleview .el-form-item__content img {
      max-width: 96%;
    }
    .el-input__inner{
      height: 35px;
      line-height: 35px;
      border: 1px solid #ccc;
    }
    .el-form-item{
      margin-bottom: 10px;
    }
    .el-form-item__label{
      font-size: 14px;
      color: #7B7B7B;
      text-align: left;
    }
    .article {
      width: 165px;
    }
    .article .is-focus input {
      color: #5096F5;
    }
    .el-icon-arrow-up:before {
      content: "";
    }
    .is-focus .el-icon-arrow-up:before {
      content: "";
      color: #5096F5;
    }
  }
</style>
<style lang="less" scoped>

.addClassify_box{
  width:850px;
  padding: 20px;
  box-sizing: border-box;
  background: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border-radius: 10px;
}
.addClassify_title{
    font-size: 16px;
    color: #333;
    text-align: left;
    font-weight: 600;
}
// .addClassify_info{
//     // padding-left: 20px;
//     color: #ccc;
// }
.articleview .link{
  color: #666;
  &:hover{
    color: #5096F5;
  }
}

.waring{
  font-size: 12px;
  color: #999;
  text-align: left;
  padding-left: 80px;
}
.waring span{
    color: red;
}
.btn{
  width: 60px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  padding: 0!important;
}
.cancel_btn{
  color: #999999!important;
  border: 1px solid #CCCCCC!important;
}
.sure_btn{
  background: #5096F5;
  color: white!important;
}
.addClassify_btn{
    text-align: right;
    padding-right: 20px;
}
</style>
