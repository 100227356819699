<template>
  <div class="about">
    <h1>则是资源</h1>
  </div>
</template>
<script>
export default {
  name: 'Resinfo',
  data() {
    return {

    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {

  }
}
</script>
