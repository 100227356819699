<template>
  <div class="conbox">
    <p class="font18 pointer" v-for="(list,i) in dataobj" :key="i" :title="list.firstTitle">
      <a href="javascript:;">{{ list.firstTitle }}</a>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAxtNewSourceList } from '@/api/editIndex.js'
export default {
  name: 'EduNewsThree',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      newsList: [],
      dataobj: []
    }
  },
  computed: {
    ...mapGetters(['indexData'])
  },
  watch: {
    item: {
      handler() {
        this.getAxtNewSourceList()
      },
      deep: true
    }
  },
  mounted() {
    this.getAxtNewSourceList()
  },
  methods: {
    getAxtNewSourceList() {
      const params = {
        website_id: this.indexData.websiteId,
        cancel_publish: 0
      }
      if (this.item.dataType === 1) {
        if (!this.item.dataNewsIds) {
          this.dataobj = []
          return
        }
        params.ids = this.item.dataNewsIds
      } else if (this.item.dataType === 2) {
        params.classify_id = this.item.dataNewsTypeId
        params.pageSize = this.item.dataSize
      }

      getAxtNewSourceList(params).then((res) => {
        if (res.message === 'success') {
          this.dataobj = res.data.rows.slice(0, this.item.dataSize)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped="scoped">
#white {
  background: #fff;
  padding: 0 10px;
}
  .newslist {
    text-align: left;
  }
  .con {
    background-color: #F5F5F7;
    border-radius: 10px;
    text-align: left;
    display: flex;
    height: 100%;
    .contitle {
      float: left;
      writing-mode:vertical-lr;
      text-align: center;
      letter-spacing: 5px;
      width: 70px;
      background: #3583FF;
      border-radius: 10px 0px 0px 10px;
      padding: 24px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.4;
      color: #fff;
    }
    .conbox {
      flex: 1;
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      p {
        width: 85%;
        padding-left: 10px;
        font-size: 16px;
        margin-bottom: 15px;
        color: #555555;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        a {
          color: #555;
          display: block;
        }
        &:hover a {
          color:#3583FF ;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
        &::before {
          content:"";
          display: block;
          width: 3px;
          height: 3px;
          position: absolute;
          top: 10px;
          left: 0;
          margin-right: 8px;
          background: #555555;
        }
      }
    }
    .more {
      position: absolute;
      bottom: 10px;
      right: 20px;
      color:#999999 ;
      &:hover {
        color:#3583FF ;
      }
    }
  }
  .newsclassify {
    padding: 45px 0;
  }
  @media screen and (min-width: 1920px) {

  }
</style>
